import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import ReactPlayer from "react-player";
import Paper from "@material-ui/core/Paper";
import Background from "../../Assests/Background.png";
import IconButton from "@material-ui/core/IconButton";
import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";
import CloseIcon from "../../Assests/ic_cancel_24_px.png";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import GoogleMapReact from "google-map-react";
import { fitBounds } from "google-map-react";

import Carousel, { Modal, ModalGateway } from "react-images";

import { withFirebase } from "../Firebase";

import BackgroundTop from "../../Assests/Background_Top.png";
import BackgroundBottom from "../../Assests/Background_Bottom.png";
import BackgroundModal from "../../Assests/ModalShape.png";
import ReportLogo from "../../Assests/ic_report_24_px.png";
import FarmerLogo from "../../Assests/ic_farmer_13_px.png";
import FieldIcon from "../../Assests/ic_field_blue_18px.png";
import CropLogo from "../../Assests/ic_crop_18_px.png";
import WeatherLogo from "../../Assests/ic_cloud_24_px.png";
import TagIcon from "../../Assests/group_7.png";
import PlayIcon from "../../Assests/ic_play_circle_selected_24_px.png";
import PrevPlayIcon from "../../Assests/prevPlay.png";
import NextPlayIcon from "../../Assests/nextPlay.png";

import "./index.css";

const SVG = ({
  fill1 = "#E62D2",
  fill2 = "#7E1E1E",
  width = "77",
  height = "115",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 77 115"
  >
    <defs>
      <filter
        id="a"
        width="134.8%"
        height="122.4%"
        x="-17.4%"
        y="-11.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.214588995 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      filter="url(#a)"
      transform="translate(4 2)"
    >
      <path
        fill={fill1}
        d="M69 34.494C69 15.444 53.554 0 34.5 0 15.446 0 0 15.443 0 34.494 0 50.787 23.998 76.09 31.148 97.418l1.996 7.586c0 .878.72 1.597 1.598 1.597.068 0 .134-.012.2-.02-.001.14.003.28 0 .419.005-.14.017-.281.025-.422a1.598 1.598 0 0 0 1.372-1.574l1.997-8.983C44.785 73.673 69 51.456 69 34.494"
      />
      <path
        fill={fill2}
        d="M22 32.5C22 25.596 27.596 20 34.5 20S47 25.596 47 32.5 41.404 45 34.5 45 22 39.404 22 32.5"
      />
    </g>
  </svg>
);

let svgColor = [
  { fill1: "#E62D2A", fill2: "#7E1E1E" },
  { fill1: "rgba(0,0,250,1)", fill2: "rgba(0,0,200,1)" },
  { fill1: "rgba(0,250,0,1)", fill2: "rgba(0,200,0,1)" },
  { fill1: "rgba(125,125,0,1)", fill2: "rgba(100,100,0,1)" },
  { fill1: "rgba(125,225,0,1)", fill2: "rgba(100,200,0,1)" },
  { fill1: "rgba(125,125,125,1)", fill2: "rgba(100,100,100,1)" },
  { fill1: "rgba(0,125,125,1)", fill2: "rgba(0,100,100,1)" },
  { fill1: "rgba(0,0,250,1)", fill2: "rgba(0,0,200,1)" },
  { fill1: "rgba(0,0,100,1)", fill2: "rgba(0,0,75,1)" },
];

var sectionStyle = {
  height: "100%",
  width: "100vw",
  backgroundPosition: "center",
  backgroundSize: "cover",
  overflow: "hidden",
};

class ScoutingReportPage extends React.PureComponent {
  static defaultProps = {
    zoom: 5,
  };
  state = {
    emailAddress: "",
    selectedPin: 0,
    images: null,
    suggestions: null,
    products: null,
    reportDate: "",
    transcripts: {},
    audio: {},
    currentAudioIndex: -1,
    locations: [],
    center: null,
    zoom: 5,
    selectedIndex: 0,
    lightboxIsOpen: false,
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile:
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1,
    preparedBy: null,
    providedTo: null,
    fieldName: null,
    crop: null,
    weather: null,
    audioError: "",
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    this.props.firebase.updateWorkgroupPath("");

    let reportKey = this.props.match.params.id;

    let bounds = {
      nw: {
        lat: 9999,
        lng: 9999,
      },
      se: {
        lat: -9999,
        lng: -9999,
      },
    };

    const size = {
      width: 640, // Map width in pixels
      height: 444, // Map height in pixels
    };

    let preparedBy = null;
    let fieldName = null;
    let providedTo = null;
    let crop = null;
    let weather = null;

    this.props.firebase
      .urlSharedReport(reportKey)
      .once("value", async (snapshot) => {
        let reportsArray = snapshot.val().reportsArray;

        if (!reportsArray.isArray) reportsArray = Object.values(reportsArray);

        let images = {};
        let suggestions = {};
        let products = {};
        let fullres_images = {};
        let transcripts = {};
        let locations = [];
        let audio = {};
        // console.log(snapshot.val());
        preparedBy = snapshot.val().hasOwnProperty("preparedBy")
          ? snapshot.val().preparedBy
          : null;
        fieldName = snapshot.val().hasOwnProperty("fieldName")
          ? snapshot.val().fieldName
          : null;
        providedTo = snapshot.val().hasOwnProperty("providedTo")
          ? snapshot.val().providedTo
          : null;
        crop = snapshot.val().hasOwnProperty("crop")
          ? snapshot.val().crop
          : null;
        weather = snapshot.val().hasOwnProperty("weather")
          ? snapshot.val().weather
          : null;

        //console.log(reportsArray);

        if (reportsArray.length > svgColor.length)
          svgColor = this.randomColorsArray(reportsArray.length);

        for (let i = 0; i < reportsArray.length; i++) {
          locations.push({
            lat: reportsArray[i].location.latitude,
            lng: reportsArray[i].location.longitude,
          });

          if (reportsArray[i].location.latitude < bounds.nw.lat)
            bounds.nw.lat = reportsArray[i].location.latitude;

          if (reportsArray[i].location.latitude > bounds.se.lat)
            bounds.se.lat = reportsArray[i].location.latitude;

          if (reportsArray[i].location.longitude > bounds.se.lng)
            bounds.se.lng = reportsArray[i].location.longitude;

          if (reportsArray[i].location.longitude < bounds.nw.lng)
            bounds.nw.lng = reportsArray[i].location.longitude;
        }

        bounds.nw.lat = bounds.nw.lat - 0.003;
        bounds.se.lat = bounds.se.lat + 0.003;
        bounds.se.lng = bounds.se.lng + 0.003;
        bounds.nw.lng = bounds.nw.lng - 0.003;

        let { center, zoom } = fitBounds(bounds, size);

        var d = new Date(snapshot.val().dateForSorting);

        this.setState({
          reportDate: d.toLocaleDateString(),
          locations: locations,
          center: center,
          zoom: zoom,
          preparedBy: preparedBy,
          fieldName: fieldName,
          providedTo: providedTo,
          crop: crop,
          weather: weather,
        });

        var promises = [];

        for (let i = 0; i < reportsArray.length; i++) {
          if (reportsArray[i].hasOwnProperty("suggestions")) {
            suggestions[i] = reportsArray[i].suggestions;
          } else suggestions[i] = [];

          if (reportsArray[i].hasOwnProperty("products")) {
            products[i] = reportsArray[i].products;
          } else products[i] = [];

          if (reportsArray[i].hasOwnProperty("imageArray")) {
            images[i] = new Array(reportsArray[i].imageArray.length);
            for (let j = 0; j < reportsArray[i].imageArray.length; j++) {
              let promise = this.props.firebase
                .getStorageImg(
                  "shared_images/" + reportKey,
                  "thumb_" +
                    reportsArray[i].imageName +
                    "_" +
                    (j + 1).toString() +
                    ".jpg",
                  true
                )
                .then((url) => {
                  images[i][j] = { source: { regular: url } };
                });

              promises.push(promise);
            }
          } else images[i] = [];
        }

        Promise.all(promises).then(() => {
          this.setState({
            images: images,
            suggestions: suggestions,
            products: products,
          });
        });

        promises = [];

        for (let i = 0; i < reportsArray.length; i++) {
          if (reportsArray[i].hasOwnProperty("imageArray")) {
            //console.log(reportsArray[i]);
            fullres_images[i] = new Array(reportsArray[i].imageArray.length);
            for (let j = 0; j < reportsArray[i].imageArray.length; j++) {
              let promise = this.props.firebase
                .getStorageImg(
                  "shared_images/" + reportKey,
                  reportsArray[i].imageName + "_" + (j + 1).toString() + ".jpg",
                  true
                )
                .then((url) => {
                  fullres_images[i][j] = { source: { regular: url } };
                });

              promises.push(promise);
            }
          } else fullres_images[i] = [];
        }

        Promise.all(promises).then(() => {
          this.setState({
            fullres_images: fullres_images,
          });
        });

        promises = [];

        for (let i = 0; i < reportsArray.length; i++) {
          transcripts[i] = "";

          if (reportsArray[i].hasOwnProperty("recordArray")) {
            audio[i] = [];
            for (let j = 0; j < reportsArray[i].recordArray.length; j++) {
              transcripts[i] = reportsArray[i].recordArray[j].hasOwnProperty(
                "transcript"
              )
                ? transcripts[i] +
                  reportsArray[i].recordArray[j].transcript +
                  ". "
                : transcripts[i];

              if (reportsArray[i].recordArray[j].hasOwnProperty("filename")) {
                let promise = this.props.firebase.getStorageAudio(
                  "shared_audio/" + reportKey,
                  reportsArray[i].recordArray[j].filename,
                  (url) => {
                    //console.log(url);
                    audio[i].push({ source: url });
                  }
                );

                promises.push(promise);
              }
            }
          } else audio[i] = [];
        }

        Promise.all(promises).then(() => {
          if (this.player && audio[0].length > 0 && audio[0][0])
            this.player.src = audio[0][0].source;

          if (this.player && audio[0].length > 0)
            this.player.addEventListener("canplaythrough", (e) => {
              this.setState({
                duration: this.player.duration,
              });
            });

          if (this.player)
            this.player.addEventListener("timeupdate", (e) => {
              this.setState({
                currentTime: e.target.currentTime,
              });
            });

          this.setState({
            transcripts: transcripts,
            audio: audio,
            currentAudioIndex: audio[0].length > 0 ? 0 : -1,
          });
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.player.removeEventListener("timeupdate", () => {});
    this.player.removeEventListener("canplaythrough", () => {});
  }

  updateDimensions = () => {
    this.setState({ width: this.state.width, height: window.innerHeight });
  };

  toMMSS = function (sec) {
    var sec_num = parseInt(sec, 10);
    var minutes = Math.floor(sec_num / 60);
    var seconds = sec_num - minutes * 60;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  handlePlayStateChange = () => {
    this.player
      .play()
      .then(() => {
        this.setState({ audioError: "" });
      })
      .catch((error) => {
        //console.log(error.message);
        this.setState({ audioError: error.message });
      });
  };

  handlePrevPlay = () => {
    let index = this.state.currentAudioIndex;
    if (index > 0) {
      this.player.src =
        this.state.audio[this.state.selectedPin][index - 1].source;
      this.setState({ currentAudioIndex: index - 1 });
    }
  };

  handleNextPlay = () => {
    let index = this.state.currentAudioIndex;
    if (index < this.state.audio[this.state.selectedPin].length - 1) {
      this.player.src =
        this.state.audio[this.state.selectedPin][index + 1].source;
      this.setState({ currentAudioIndex: index + 1 });
    }
  };

  renderPlayer = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {this.state.audio.hasOwnProperty(this.state.selectedPin) &&
        this.state.audio[this.state.selectedPin].length > 1 ? (
          <div
            style={{
              display: "flex",
              width: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={this.handlePrevPlay}
          >
            <img style={{ height: 22 }} src={PrevPlayIcon} />
          </div>
        ) : (
          <div style={{ height: 34 }} />
        )}
        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            width: 40,
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={this.handlePlayStateChange}
        >
          {this.state.audio &&
          this.state.audio.hasOwnProperty(this.state.selectedPin) &&
          this.state.audio[this.state.selectedPin].length > 0 ? (
            <img style={{ height: 34 }} src={PlayIcon} />
          ) : (
            <div style={{ height: 34 }} />
          )}

          {this.state.audio.hasOwnProperty(this.state.selectedPin) &&
          this.state.audio[this.state.selectedPin].length > 1 ? (
            <a
              style={{
                marginTop: 4,
                height: 14,
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {this.state.currentAudioIndex == -1
                ? "0/0"
                : this.state.currentAudioIndex +
                  1 +
                  "/" +
                  this.state.audio[this.state.selectedPin].length}
            </a>
          ) : (
            <a
              style={{
                marginTop: 4,
                height: 14,
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            ></a>
          )}
        </div>
        {this.state.audio.hasOwnProperty(this.state.selectedPin) &&
        this.state.audio[this.state.selectedPin].length > 1 ? (
          <div
            style={{
              display: "flex",
              width: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={this.handleNextPlay}
          >
            <img style={{ height: 22 }} src={NextPlayIcon} />
          </div>
        ) : (
          <div style={{ height: 34 }} />
        )}
        {this.state.audio &&
        this.state.audio.hasOwnProperty(this.state.selectedPin) &&
        this.state.audio[this.state.selectedPin].length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              marginLeft: 0,
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {this.state.hasOwnProperty("currentTime")
              ? this.toMMSS(this.state.currentTime)
              : "0:00"}
          </div>
        ) : null}
        {this.state.audio &&
        this.state.audio.hasOwnProperty(this.state.selectedPin) &&
        this.state.audio[this.state.selectedPin].length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flex: 7,
              marginLeft: 8,
              height: 2,
              backgroundColor: "rgba(0, 0, 0, 0.26)",
            }}
          />
        ) : null}
        {this.state.audio &&
        this.state.audio.hasOwnProperty(this.state.selectedPin) &&
        this.state.audio[this.state.selectedPin].length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              marginLeft: 8,
              marginRight: 8,
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {this.state.hasOwnProperty("duration")
              ? this.toMMSS(this.state.duration)
              : "0:00"}
          </div>
        ) : null}

        <audio ref={(ref) => (this.player = ref)} preload="metadata" />
      </div>
    );
  };

  handlePinCLick = (index) => {
    if (this.state.audio[index].length > 0)
      this.player.src = this.state.audio[index][0].source;
    this.setState({
      currentAudioIndex: this.state.audio[index].length > 0 ? 0 : -1,
      selectedPin: index,
    });
  };

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  getMapOptions = (maps: Maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      minZoom: 11,
      maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: false,
    };
  };

  prevObservation = () => {
    this.setState({ selectedPin: this.state.selectedPin - 1 });
  };

  nextObservation = () => {
    this.setState({ selectedPin: this.state.selectedPin + 1 });
  };

  randomColorsArray(total) {
    let cbrt = Math.ceil(Math.cbrt(total));
    var iG = 255 / cbrt;
    var jG = 255 / cbrt;
    var kG = 255 / cbrt;
    var r = []; // hold the generated colors
    for (var x = 0; x < total; x++) {
      let k = Math.floor(x / (cbrt * cbrt));
      let j = Math.floor((x - k * cbrt * cbrt) / cbrt);
      let i = x - k * cbrt * cbrt - j * cbrt;

      r.push({
        fill1:
          "rgba(" +
          (255 - iG * i).toString() +
          "," +
          (jG * j).toString() +
          "," +
          (kG * k).toString() +
          ",1)",
        fill2:
          "rgba(" +
          Math.round(0.8 * 255 - iG * i).toString() +
          "," +
          Math.round(0.8 * jG * j).toString() +
          "," +
          Math.round(0.8 * kG * k).toString() +
          ",1)",
      });
    }

    return r;
  }

  render() {
    const { selectedIndex, lightboxIsOpen } = this.state;

    const markers = this.state.locations.map((item, index) => {
      let mSize = 20;
      if (this.state.selectedPin == index) mSize = 25;

      return (
        <div
          lat={item.lat}
          lng={item.lng}
          style={{ marginTop: -mSize, marginLeft: -40 }}
          onClick={() => this.handlePinCLick(index)}
        >
          <SVG
            fill1={svgColor[index].fill1}
            fill2={svgColor[index].fill2}
            height={mSize}
          />
        </div>
      );
    });

    let images = null;
    if (this.state.images)
      images = this.state.images[this.state.selectedPin].map((item, j) => {
        return (
          <div onClick={() => this.toggleLightbox(j)} key={j}>
            <img
              style={{ marginLeft: 16, borderRadius: 2, height: 97, width: 97 }}
              src={item.source.regular}
            />
          </div>
        );
      });

    let suggestions = null;
    if (this.state.suggestions)
      suggestions = this.state.suggestions[this.state.selectedPin].map(
        (item, j) => {
          return (
            <div
              onClick={() =>
                window.open("https://agrio.app/library/" + item.html, "_blank")
              }
              style={{
                cursor: "pointer",
                borderRadius: 6,
                marginLeft: 12,
                marginRight: 12,
                backgroundColor: "#ecf0f3",
              }}
              key={j}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 6,
                  fontWeight: "Bold",
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: "rgba(0, 0, 0, 0.87)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ margin: 2 }}
                  src={require("../../Assests/ic_library_books_24_px.png")}
                  width={17.5}
                  height={17.5}
                />
                {item.name}
              </div>
            </div>
          );
        }
      );

    let products = null;
    if (this.state.products)
      products = this.state.products[this.state.selectedPin].map((item, j) => {
        return (
          <div
            onClick={() => window.open(item.label, "_blank")}
            style={{
              cursor: "pointer",
              borderRadius: 6,
              marginTop: this.state.isMobile ? 12 : 0,
              marginLeft: 12,
              marginRight: 12,
              backgroundColor: "#ecf0f3",
            }}
            key={j}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: 6,
              }}
            >
              <img
                style={{ margin: 2 }}
                src={require("../../Assests/product_icon.png")}
                width={20}
                height={35}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 6,
                    fontWeight: "Bold",
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {item.englishName + " | " + item.nativeName}
                </div>
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 6,
                    marginBottom: 6,
                    fontWeight: "normal",
                    fontFamily: "Roboto",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {item.activeIngredient}
                </div>
              </div>
            </div>
          </div>
        );
      });

    return (
      <Fragment>
        <section style={sectionStyle}>
          <div style={{ width: "100%" }}>
            <Navigation type={"minimal"} />

            <div
              style={{
                height: 240,
                width: "100%",
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                backgroundImage: "url(" + BackgroundTop + ")",
              }}
            />

            <Grid container direction={"column"} spacing={24}>
              <Grid item xs={12}>
                <div
                  style={{
                    marginTop: "-80px",
                    marginLeft: "20px",
                    marginRight: "0px",
                    height: this.state.isMobile ? "1200px" : "684px",
                    marginBottom: this.state.isMobile ? "100px" : "0px",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "0px",
                      marginTop: "20px",
                      marginRight: "0px",
                      height: this.state.isMobile ? "250px" : "124px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ flex: 1, alignItems: "flex-start" }} />

                      <div
                        style={{
                          display: "flex",
                          height: 40,
                          marginLeft: "20px",
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                          backgroundColor: "#01496d",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: 20,
                            marginRight: 20,
                            height: "22px",
                            fontSize: 20,
                            font: "Roboto",
                            fontWeight: "bold",
                            color: "#ffffff",
                            textAlign: "right",
                          }}
                        >
                          Field Report {this.state.reportDate}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        marginTop: 32,
                        display: "flex",
                        flex: 1,
                        height: this.state.isMobile ? 140 : 50,
                        flexDirection: this.state.isMobile ? "column" : "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        {this.state.preparedBy ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ width: 15, height: 17 }}
                                src={ReportLogo}
                              />
                            </div>
                            <div style={{ marginLeft: 9 }}>
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Prepared by:
                              </div>
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "normal",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                {this.state.preparedBy}
                              </div>
                              <div
                                style={{
                                  marginTop: 4,
                                  width: 117,
                                  height: 3,
                                  backgroundColor: "#7ed321",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}

                        {this.state.fieldName ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ width: 15, height: 17 }}
                                src={FieldIcon}
                              />
                            </div>
                            <div style={{ marginLeft: 9 }}>
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Plot name:
                              </div>
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "normal",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                {this.state.fieldName}
                              </div>
                              <div
                                style={{
                                  marginTop: 4,
                                  width: 117,
                                  height: 3,
                                  backgroundColor: "#7ed321",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}

                        {this.state.providedTo ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ width: 17, height: 17 }}
                                src={FarmerLogo}
                              />
                            </div>
                            <div style={{ marginLeft: 9 }}>
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Provided to:
                              </div>
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "normal",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Name of farmer
                              </div>
                              <div
                                style={{
                                  marginTop: 4,
                                  width: 117,
                                  height: 3,
                                  backgroundColor: "#7ed321",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        {this.state.crop ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ width: 18, height: 19 }}
                                src={CropLogo}
                              />
                            </div>
                            <div style={{ marginLeft: 9 }}>
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Crop:
                              </div>
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "normal",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                {this.state.crop}
                              </div>
                              <div
                                style={{
                                  marginTop: 4,
                                  width: 117,
                                  height: 3,
                                  backgroundColor: "#7ed321",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {this.state.weather ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div style={{}}>
                              <img
                                style={{ width: 26, height: 17 }}
                                src={WeatherLogo}
                              />
                            </div>
                            <div style={{ marginLeft: 9 }}>
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                Weather:
                              </div>
                              <div
                                style={{
                                  marginTop: 6,
                                  fontWeight: "normal",
                                  fontFamily: "Roboto",
                                  fontSize: 14,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }}
                              >
                                18 | Humidity: 67%
                              </div>
                              <div
                                style={{
                                  marginTop: 4,
                                  width: 117,
                                  height: 3,
                                  backgroundColor: "#7ed321",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "0px",
                      marginRight: "20px",
                      marginTop: "30px",
                      display: "flex",
                      flex: this.state.isMobile ? 3 : 3,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: this.state.isMobile ? "column" : "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "0px",
                        flex: 1,
                        height: this.state.isMobile ? "300px" : "444px",
                        width: "100%",
                        borderStyle: "solid",
                        borderWidth: 2,
                        borderColor: "#01496d",
                        borderRadius: 12,
                        overflow: "hidden",
                      }}
                    >
                      {this.state.center ? (
                        <GoogleMapReact
                          options={this.getMapOptions}
                          bootstrapURLKeys={{ key: "blah" }}
                          defaultCenter={this.state.center}
                          defaultZoom={this.state.zoom}
                        >
                          {markers}
                        </GoogleMapReact>
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: this.state.isMobile ? "0px" : "20px",
                        marginTop: this.state.isMobile ? "60px" : "0px",
                        marginRight: this.state.isMobile ? "0px" : "20px",
                        flex: this.state.isMobile ? 2 : 2,
                        height: this.state.isMobile ? "700px" : "444px",
                        width: "100%",
                        borderStyle: "solid",
                        borderWidth: 2,
                        borderColor: "#e62d2a",
                        borderRadius: 12,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <div style={{ display: "flex", flex: 2 }}>
                        <IconButton
                          color={"primary"}
                          disabled={this.state.selectedPin == 0}
                          onClick={this.prevObservation}
                        >
                          <ArrowLeftIcon fontSize={"large"} />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            flex: 0.8,
                            alignItems: "center",
                            justifyContent: "center",
                            height: 107,
                            marginTop: -30,
                          }}
                        >
                          <SVG
                            fill1={svgColor[this.state.selectedPin].fill1}
                            fill2={svgColor[this.state.selectedPin].fill2}
                          />
                        </div>
                        <div style={{ flex: 4 }}>
                          <div
                            style={{
                              fontFamily: "Roboto",
                              marginTop: 10,
                              marginBottom: 4,
                              fontSize: 22,
                              fontWeight: "bold",
                              fontStyle: "normal",
                              letterSpacing: 0,
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            Observation{" "}
                            {(
                              "0" + (this.state.selectedPin + 1).toString()
                            ).slice(-2)}
                          </div>
                          <div
                            style={{
                              width: 117,
                              height: 3,
                              backgroundColor: "#7ed321",
                            }}
                          />
                          <p
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 14,
                              fontWeight: "500",
                              fontStyle: "normal",
                              letterSpacing: 0,
                              color: "#e62d2a",
                            }}
                          >
                            {this.state.audioError}
                          </p>
                        </div>
                        <IconButton
                          color={"primary"}
                          disabled={
                            this.state.selectedPin ==
                            this.state.locations.length - 1
                          }
                          onClick={this.nextObservation}
                        >
                          <ArrowRightIcon fontSize={"large"} />
                        </IconButton>
                      </div>
                      <div
                        ref={(el) => (this.container = el)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          flex: 1,
                          marginLeft: 16,
                        }}
                      >
                        {this.renderPlayer()}
                      </div>
                      {this.state.images == null ? (
                        <div
                          style={{
                            flex: 3,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress color={"#01496d"} />
                        </div>
                      ) : (
                        <div class="scroll">{images}</div>
                      )}

                      <div style={{ flex: 1, marginLeft: 16 }}>
                        <div
                          style={{
                            fontFamily: "Roboto",
                            marginTop: 10,
                            marginBottom: 4,
                            fontSize: 22,
                            fontWeight: "bold",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          Summary:
                        </div>
                        <div
                          style={{
                            width: 117,
                            height: 3,
                            backgroundColor: "#7ed321",
                          }}
                        />
                      </div>
                      <div style={{ flex: 3, marginLeft: 16, marginRight: 16 }}>
                        <p
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 14,
                            fontWeight: "500",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {this.state.transcripts.hasOwnProperty(
                            this.state.selectedPin
                          ) &&
                          this.state.transcripts[this.state.selectedPin] != ""
                            ? this.state.transcripts[this.state.selectedPin]
                            : "No summary was provided"}
                        </p>
                        {this.state.suggestions == null ? (
                          <div
                            style={{
                              flex: 3,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress color={"#01496d"} />
                          </div>
                        ) : (
                          <div class="scroll">{suggestions}</div>
                        )}
                        {this.state.products && (
                          <div class="scroll">{products}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + BackgroundBottom + ")",
                    paddingTop: 160,
                  }}
                >
                  <div
                    style={{
                      marginLeft: this.state.isMobile ? "45px" : "200px",
                    }}
                  >
                    <Grid container spacing={24}>
                      <Grid item xs={24}>
                        <p
                          style={{
                            width: this.state.isMobile
                              ? this.state.width - 80
                              : "528px",
                            fontSize: 32,
                            font: "Roboto",
                            fontWeight: "Bold",
                            color: "#ffffff",
                          }}
                        >
                          {" "}
                          Join Agrio, and create your own digital scouting
                          reports
                        </p>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Link to={"/google-play"}>
                            <img
                              style={{ height: "35.23px" }}
                              src={GooglePlayButton}
                            />
                          </Link>
                          <Link to={"/apple-store"}>
                            <img
                              style={{ marginLeft: "12px", height: "35.23px" }}
                              src={AppStoreButton}
                            />
                          </Link>
                        </div>
                      </Grid>
                      <Grid item xs={24}>
                        <p
                          style={{
                            width: this.state.isMobile
                              ? this.state.width - 80
                              : "528px",
                            height: "57px",
                            fontSize: 32,
                            font: "Roboto",
                            fontWeight: "Bold",
                            color: "#ffffff",
                          }}
                        />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Link to={"/facebook-page"} target="_blank">
                            <img style={{}} src={FacebookIcon} />
                          </Link>
                          <Link to={"/linkedin-page"} target="_blank">
                            <img src={LinkedInIcon} />
                          </Link>
                          <Link to={"/instegram-page"} target="_blank">
                            <img src={InstegramIcon} />
                          </Link>
                          <Link to={"/twitter-page"} target="_blank">
                            <img src={TwitterIcon} />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        marginTop: "50px",
                        fontSize: 11,
                        font: "Roboto",
                        fontWeight: "Regular",
                        color: "#4A4A4A",
                      }}
                    >
                      <a>All rights reserved to Saillog LTD. 2019 - </a>
                      <Link to={"/terms-conditions"} target="_blank">
                        Terms of Use
                      </Link>
                      <a> | </a>
                      <Link to={"/privacy-policy"} target="_blank">
                        Privacy Policy{" "}
                      </Link>
                      <a> | </a>
                      <a>Contact</a>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.toggleLightbox}>
              <Carousel
                currentIndex={selectedIndex}
                frameProps={{ autoSize: "height" }}
                views={
                  this.state.fullres_images
                    ? this.state.fullres_images[this.state.selectedPin]
                    : this.state.images[this.state.selectedPin]
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY,
})(withFirebase(ScoutingReportPage));
