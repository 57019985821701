import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";

import firebase from "firebase/compat/app";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TextField from "@material-ui/core/TextField";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import AlertDialog from "../AlertDialog";

import Chat from "../Chat";

import CircularProgress from "@material-ui/core/CircularProgress";
import ImageResponse from "../../Assests/Fast_time.png";

import * as Lists from "../Lists.js";
import * as Helper from "../../Helper";

import { withStyles } from "@material-ui/core/styles";

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { ContactlessOutlined } from "@material-ui/icons";

//import RefreshIcon from '@material-ui/icons/Refresh';

import Alerts from "./Alerts";

const styles = {
  searchBar: {
    zIndex: "auto",
  },
  scroll: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(100,100,100,.3)",
      outline: "1px solid white",
    },
  },
  paper: { display: "flex", flexDirection: "row", marginTop: 10 },
  main: { display: "flex", flexDirection: "column", marginTop: 10 },
};

const rNumber = Math.floor(Math.random() * 7);

var randomAnsweringUser;

switch (rNumber) {
  case 0:
    randomAnsweringUser = "mJwbocqj2lgwC71t94LLbEVUzuX2";

    break;
  case 1:
    randomAnsweringUser = "BYImLayWiBY4vIi8kRynrpFNbrh2";

    break;
  case 2:
    randomAnsweringUser = "MrgJT8FR3AeEOfnQgTrIwEs7m5i1";

    break;
  case 3:
    randomAnsweringUser = "2yKErQVIgnUQ5x2GIVxJubPER7f1";

    break;
  case 4:
    randomAnsweringUser = "mJwbocqj2lgwC71t94LLbEVUzuX2";

    break;
  case 5:
    randomAnsweringUser = "jhkXg3PZk8YrzRtnceHBQUb4Mmt1";

    break;
  case 6:
    randomAnsweringUser = "2qpXDY3i1hV9Tn5ziQ4pfhconIM2";

    break;
  default:
    console.log("The random number is out of range");
    break;
}

class ResponseTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diseaseList: null,
      compareArray: [],
      selectedCompareImg: null,
      selectedImgIndex: 0,
      feedPostData: "",
      postId: null,
      matchObj: {},
      leftLoaded: false,
      leftLargeLoaded: false,
      rightLoaded: false,
      rightLargeLoaded: false,
      responses: null,
      sharingUser: null,
      searchText: "",
      isAddImageOpen: false,
      editCropNameDialogIsOpen: false,
      newCropName: "",
      isLabeler: false,
      answeringUser: this.props.firebase.auth.currentUser.uid,
      cropLoaded: false,
      harvieDialogOpen: false,
      imageDescription: [],
      additionalComment: "",
      alertInfo: null,
    };

    this.libraryImgObjPerCrop = {};
  }

  async componentDidMount() {
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling")
      .child("Labelers")
      .child(this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then((result) => {
        if (result.val()) {
          this.setState({ isLabeler: true }, () => {
            this.handleFeedPostSelection();
          });
        } else {
          this.setState({
            isLabeler: false,
            feedPostData: null,
            postId: null,
            searchText: "",
          });
        }
      })
      .catch((error) => {
        prompt(error);
      });
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    if (this.responseListener) this.responseListener.off();
  }

  downloadImages = async (cropName, diseaseTag) => {
    let libraryImgObj = this.libraryImgObjPerCrop.hasOwnProperty(cropName)
      ? { ...this.libraryImgObjPerCrop[cropName] }
      : {};
    //console.log(diseaseTag);
    if (libraryImgObj.hasOwnProperty(diseaseTag)) {
      return Promise.all([]).then(() => {
        return {
          diseaseTag: diseaseTag,
          imgArray: libraryImgObj[diseaseTag].imgArray,
          imgFullResArray: libraryImgObj[diseaseTag].imgFullResArray,
          imgFolderArray: libraryImgObj[diseaseTag].imgFolderArray,
          imgNameArray: libraryImgObj[diseaseTag].imgNameArray,
        };
      });
    }

    let imageInfo = this.state.diseaseList[diseaseTag];

    let promises = [];

    if (
      imageInfo &&
      imageInfo.hasOwnProperty("numImages") &&
      imageInfo.numImages > 0
    ) {
      let imgArray = new Array(imageInfo.numImages);
      let imgFullResArray = new Array(imageInfo.numImages);
      let imgFolderArray = new Array(imageInfo.numImages);
      let imgNameArray = new Array(imageInfo.numImages);

      for (let index = 0; index < imageInfo.numImages; index++) {
        let imgFolder =
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" + cropName + "/" + imageInfo.folderName
            : "diseaseInfo/" +
              this.props.firebase.workgroupPath +
              "/" +
              cropName +
              "/" +
              imageInfo.folderName;

        let imgName = "image" + (index + 1).toString() + ".jpg";

        imgFolderArray[index] = imgFolder;
        imgNameArray[index] = imgName;

        let promise = this.props.firebase
          .getStorageImg(imgFolder, imgName, false)
          .then((url) => {
            imgArray[index] = url;
          })
          .catch((error) => {
            prompt(error);
          });

        promises.push(promise);

        promise = this.props.firebase
          .getStorageImg(imgFolder, imgName, true)
          .then((url2) => {
            imgFullResArray[index] = url2;
          })
          .catch((error) => {
            prompt(error);
          });

        promises.push(promise);
      }

      return Promise.all(promises).then(() => {
        return {
          diseaseTag: diseaseTag,
          imgArray: imgArray,
          imgFullResArray: imgFullResArray,
          imgFolderArray: imgFolderArray,
          imgNameArray: imgNameArray,
        };
      });
    } else
      return {
        diseaseTag: diseaseTag,
        imgArray: [],
        imgFullResArray: [],
        imgFolderArray: [],
        imgNameArray: [],
      };
  };

  loadCropListOfPathogens = async () => {
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/diseaseInfoImages/" +
          this.state.feedPostData.cropName
      )
      .once("value")
      .then((diseaseListSnap) => {
        let diseaseList = diseaseListSnap.val() ? diseaseListSnap.val() : {};

        let b = Object.keys(diseaseList);
        let d = b.sort();

        this.setState(
          {
            diseaseList: diseaseList,
          },
          () => {
            let promises = [];
            for (let d_ind = 0; d_ind < d.length; d_ind++)
              promises.push(
                this.downloadImages(this.state.feedPostData.cropName, d[d_ind])
              );

            Promise.all(promises).then((result) => {
              let compareArray = [];

              if (
                !this.libraryImgObjPerCrop.hasOwnProperty(
                  this.state.feedPostData.cropName
                )
              )
                this.libraryImgObjPerCrop[this.state.feedPostData.cropName] =
                  {};

              for (let j = 0; j < result.length; j++) {
                let showImgArray = diseaseList[
                  result[j].diseaseTag
                ].hasOwnProperty("showImgArray")
                  ? diseaseList[result[j].diseaseTag].showImgArray
                  : null;

                let imageCluster = diseaseList[
                  result[j].diseaseTag
                ].hasOwnProperty("imageCluster")
                  ? diseaseList[result[j].diseaseTag].imageCluster
                  : null;

                let plantPart = diseaseList[
                  result[j].diseaseTag
                ].hasOwnProperty("plantPart")
                  ? diseaseList[result[j].diseaseTag].plantPart
                  : null;

                this.libraryImgObjPerCrop[this.state.feedPostData.cropName][
                  result[j].diseaseTag
                ] = {
                  imgArray: result[j].imgArray,
                  imgFullResArray: result[j].imgFullResArray,
                  imgFolderArray: result[j].imgFolderArray,
                  imgNameArray: result[j].imgNameArray,
                };

                for (let k = 0; k < result[j].imgArray.length; k++)
                  compareArray.push({
                    diseaseTag: result[j].diseaseTag,
                    img: result[j].imgArray[k],
                    imgFullRes: result[j].imgFullResArray[k],
                    imgFolder: result[j].imgFolderArray[k],
                    imgName: result[j].imgNameArray[k],
                    showImg:
                      showImgArray == null || showImgArray.indexOf(k) != -1,
                    cluster:
                      imageCluster && imageCluster.length > k
                        ? imageCluster[k]
                        : null,
                    plantPart:
                      plantPart && plantPart.length > k ? plantPart[k] : null,
                  });
              }

              this.setState({
                compareArray: compareArray,
                selectedCompareImg: null,
                cropLoaded: true,
              });
            });
          }
        );
      })
      .catch((error) => console.log(error));
  };

  handleFeedPostSelection = async () => {
    let postId = null;

    let resolved = false;
    let promptFlag = false;

    let postData = await this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
      .child(this.props.firebase.auth.currentUser.uid)
      .orderByChild("priority")
      .limitToFirst(1)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          postId = Object.keys(snapshot.val())[0];

          this.post = Object.values(snapshot.val())[0];

          // console.log(this.post);

          if (this.post.hasOwnProperty("cid"))
            return this.props.firebase.db
              .ref(this.props.firebase.workgroupPath + "/channels")
              .child(this.post.cid)
              .child("sharedWithMe")
              .child(this.post.mid)
              .once("value")
              .then((result) => {
                // console.log(result.val());
                // return result.val();

                if (result.val())
                  return {
                    ...result.val(),
                    cid: this.post.cid,
                    mid: this.post.mid,
                  };
                else
                  return {
                    cid: this.post.cid,
                    mid: this.post.mid,
                  };
              })
              .catch((error) => {
                prompt(error);
              });
          else {
            // console.log(this.post);
            let mid = this.post.sharingUser + this.post.imageName;
            return this.props.firebase.db
              .ref(this.props.firebase.workgroupPath + "/channels")
              .child("general feed")
              .child("sharedWithMe")
              .child(mid)
              .once("value")
              .then((result) => {
                // console.log(result.val());
                if (result.val())
                  return { ...result.val(), cid: "general feed", mid: mid };
                else
                  return this.props.firebase.db
                    .ref(this.props.firebase.workgroupPath + "/channels")
                    .child("gardening feed")
                    .child("sharedWithMe")
                    .child(mid)
                    .once("value")
                    .then((result2) => {
                      // console.log(result2.val());
                      if (result2.val())
                        return {
                          ...result2.val(),
                          cid: "gardening feed",
                          mid: mid,
                        };
                      else return this.post;
                    })
                    .catch((error) => {
                      prompt(error);
                    });
              })
              .catch((error) => {
                prompt(error);
              });
          }
        } else return null;
      })
      .catch((error) => {
        prompt(error);
      });

    // console.log(postData);

    if (postId.indexOf("Contact") != -1 || postData.dateForSorting === 1000)
      return this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
        .child(this.props.firebase.auth.currentUser.uid)
        .child(postId)
        .remove()
        .then(() => {
          this.handleFeedPostSelection();
        });
    else if (postData == null) {
      this.setState({
        feedPostData: null,
        postId: null,
        searchText: "",
        cropLoaded: false,
        compareArray: [],
      });

      return;
    }

    console.log(postData);

    if (postData.hasOwnProperty("bucket")) {
      let feedPostData = {
        numOfImages: 1,
        bucket: postData.bucket,
        paths: [postData.path],
        cropName: postData.hasOwnProperty("crop") ? postData.crop : "",
        pathology: postData.pathology,
        pathologyCode: postData.pathologyCode,
        imageSource: [""],
        imageSourceHighRes: [""],
        cropVerifieldName: postData.hasOwnProperty("crop")
          ? [postData.crop]
          : [""],
      };

      // console.log(postData.path);

      let ref = firebase
        .storage()
        .refFromURL("gs://" + postData.bucket + "/" + postData.path);

      let promise1 = ref
        .getDownloadURL()
        .then((url) => {
          // console.log(url);
          feedPostData["imageSource"][0] = url;
          feedPostData["imageSourceHighRes"][0] = url;
        })
        .catch((error) => {
          console.log(error);
        });

      promise1.then(() => {
        this.setState(
          {
            feedPostData: feedPostData,
            postId: postId,
            selectedImgIndex: 0,
            selectedCompareImg: null,
            matchObj: {},
            diseaseList: null,
            leftLoaded: false,
            leftLargeLoaded: false,
            responses: [],
            searchText:
              postData.pathologyCode === undefined
                ? ""
                : postData.pathologyCode,
            compareArray: [],
          },
          () => {
            this.loadCropListOfPathogens();
          }
        );
      });
    } else {
      let sharingUser = postData.sharingUser;
      let imageName = postData.imageName;

      this.props.firebase.getImgMetaData(sharingUser, imageName, (metaData) => {
        console.log(metaData);
        let numOfImages = metaData.numOfImages;
        let cropName = metaData.cropName;
        let country = metaData.hasOwnProperty("country")
          ? metaData.country
          : "";
        let city = metaData.hasOwnProperty("city") ? metaData.city : "";
        let humidity = metaData.hasOwnProperty("humidity")
          ? metaData.humidity
          : "";
        let latitude = metaData.hasOwnProperty("latitude")
          ? metaData.latitude
          : "";
        let longitude = metaData.hasOwnProperty("longitude")
          ? metaData.longitude
          : "";
        let gps = metaData.hasOwnProperty("gps") ? metaData.gps : false;
        let imageHardwareSource = metaData.hasOwnProperty("imageSource")
          ? metaData.imageSource
          : "Folder";
        let temp_min = metaData.hasOwnProperty("temp_min")
          ? metaData.temp_min
          : "";
        let temp_max = metaData.hasOwnProperty("temp_max")
          ? metaData.temp_max
          : "";
        let folderGPSdate = metaData.hasOwnProperty("folderGPSdate")
          ? metaData.folderGPSdate
          : null;
        let questionsString = metaData.hasOwnProperty("questionsInfo")
          ? Helper.questionsInfoToString(metaData.questionsInfo)
          : "";

        let feedPostData = {
          numOfImages: numOfImages,
          cropName: cropName,
          country: country,
          city: city,
          humidity: humidity,
          latitude: latitude,
          longitude: longitude,
          gps: gps,
          imageHardwareSource: imageHardwareSource,
          temp_min: temp_min,
          temp_max: temp_max,
          folderGPSdate: folderGPSdate,
          questionsString: questionsString,
          imageSource: ["", "", "", "", "", ""],
          imageSourceHighRes: ["", "", "", "", "", ""],
          cropVerifieldName: [],
        };

        let promises = [];

        promises.push(
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/cropClassification")
            .child(sharingUser)
            .child(imageName)
            .once("value")
            .then((snap) => {
              if (snap.val()) feedPostData["cropClassification"] = snap.val();
            })
        );

        promises.push(
          this.props.firebase.db
            .ref(
              this.props.firebase.workgroupPath + "/cropClassification_generic"
            )
            .child(sharingUser)
            .child(imageName)
            .once("value")
            .then((snap) => {
              if (snap.val())
                feedPostData["cropClassification_generic"] = snap.val();
            })
        );

        promises.push(
          this.props.firebase.db
            .ref("FBfunctionResults/classifyDisease")
            .child(imageName)
            .once("value")
            .then((snap) => {
              if (snap.val())
                feedPostData["diseaseClassification"] = snap.val();
            })
        );

        promises.push(
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/imageClassification/")
            .child(sharingUser)
            .child(imageName)
            .child("acceptedAnswer")
            .once("value")
            .then((snap) => {
              feedPostData["acceptedAnswer"] = snap.val();
            })
        );

        promises.push(
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/imageClassification/")
            .child(sharingUser)
            .child(imageName)
            .child("rejectedAnswers")
            .once("value")
            .then((snap) => {
              feedPostData["rejectedAnswers"] = snap.val();
            })
        );

        if (numOfImages === undefined) prompt("No images");

        for (let imgNum = 0; imgNum < numOfImages; imgNum++) {
          feedPostData["cropVerifieldName"].push(null);

          let promise1 = this.props.firebase
            .getStorageImg(
              this.props.firebase.workgroupPath !== ""
                ? "images/" +
                    this.props.firebase.workgroupPath +
                    "/" +
                    sharingUser
                : "images/" + sharingUser,
              imageName + "_" + (imgNum + 1).toString() + ".jpg",
              false
            )
            .then((url) => {
              if (url === "Not Found") promptFlag = true;
              feedPostData["imageSource"][imgNum] = url;

              return;
            })
            .catch((error) => {
              prompt(error);
            });

          promises.push(promise1);

          let promise2 = this.props.firebase
            .getStorageImg(
              this.props.firebase.workgroupPath !== ""
                ? "images/" +
                    this.props.firebase.workgroupPath +
                    "/" +
                    sharingUser
                : "images/" + sharingUser,
              imageName + "_" + (imgNum + 1).toString() + ".jpg",
              true
            )
            .then((url2) => {
              if (url2 === "Not Found") promptFlag = true;
              feedPostData["imageSourceHighRes"][imgNum] = url2;

              // let img = new Image();
              // img.src = url2;

              return;
            })
            .catch((error) => {
              prompt(error);
            });

          promises.push(promise2);
        }

        let responses = null;

        console.log(postData);
        if (postData.hasOwnProperty("cid")) {
          if (this.responseListener) this.responseListener.off();

          let promise = new Promise((resolve, reject) => {
            this.responseListener = this.props.firebase.db
              .ref(this.props.firebase.workgroupPath + "/channels")
              .child(postData.cid)
              .child("sharedWithMe")
              .child(postData.mid)
              .child("responses");

            this.responseListener.on("value", (result) => {
              if (resolved)
                this.setState({
                  responses: result.val() ? Object.values(result.val()) : [],
                });
              else {
                responses = result.val() ? Object.values(result.val()) : [];
                return resolve();
              }
            });
          });

          promises.push(promise);
        }

        Promise.all(promises)
          .then(() => {
            if (promptFlag) prompt("Some of the images not Found");

            resolved = true;
            console.log(imageName);

            const dateStr = imageName.substring(15);
            const date = new Date(dateStr);
            const timestamp = date.getTime();

            console.log(timestamp);

            // const timestamp = postData.dateForSorting;

            this.setState({
              feedPostData: {
                ...feedPostData,
                dateForSorting: timestamp,
              },
              postId: postId,
              selectedImgIndex: 0,
              selectedCompareImg: null,
              matchObj: {},
              diseaseList: null,
              leftLoaded: false,
              leftLargeLoaded: false,
              responses: responses,
              sharingUser: sharingUser,
              imageName: imageName,
              searchText: "",
              cropLoaded: false,
              compareArray: [],
              alertInfo: null,
            });
          })
          .catch((error) => {
            prompt(error);
          });
      });
    }
  };

  handleSelectCompareToImage = (index) => {
    // console.log(index);
    // console.log(this.state.compareArray[index]);

    if (
      this.state.feedPostData["cropVerifieldName"][
        this.state.selectedImgIndex
      ] != null
    )
      this.setState({
        rightLoaded: false,
        rightLargeLoaded: false,
        selectedCompareImg: { ...this.state.compareArray[index], index: index },
      });
    else
      this.alertDialog.handleOpen(
        "Crop was not verified",
        "Please confirm that the crop name is correct before proceeding"
      );
  };

  handleDefer = () => {
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
      .child(this.props.firebase.auth.currentUser.uid)
      .child(this.state.postId)
      .child("priority")
      .set(this.post.priority + 30000 * 24 * 60 * 60 * 1000)
      .then(() => {
        this.handleFeedPostSelection();
      })
      .catch((error) => {
        prompt(error);
      });
  };

  handleAddToTest = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    let matchObj = this.state.matchObj;
    let keys = Object.keys(matchObj);

    if (keys.length < this.state.feedPostData["numOfImages"]) {
      this.alertDialog.handleOpen(
        "Task not completed",
        "Match all the images before submitting"
      );
      return;
    }

    let result = { labels: {}, dateForSorting: dateForSorting };

    for (let i = 0; i < keys.length; i++) {
      let compareImgMatched = matchObj[keys[i]]
        ? this.state.compareArray[matchObj[keys[i]].index]
        : null;
      if (compareImgMatched)
        result["labels"][i] = {
          cropName: this.state.feedPostData["cropVerifieldName"][i],
          diseaseTag: compareImgMatched.diseaseTag,
          imgFolder: compareImgMatched.imgFolder,
          imgName: compareImgMatched.imgName,
          confidence: matchObj[keys[i]].confidence,
        };
      else
        result["labels"][i] = {
          cropName: this.state.feedPostData["cropVerifieldName"][i],
        };
    }

    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling/Test")
      .child(this.props.firebase.auth.currentUser.uid)
      .child(this.state.postId)
      .set(result)
      .then(() => {
        this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
          .child(this.props.firebase.auth.currentUser.uid)
          .child(this.state.postId)
          .remove()
          .then(() => {
            this.handleFeedPostSelection();
          })
          .catch((error) => {
            prompt(error);
          });
      })
      .catch((error) => {
        prompt(error);
      });
  };

  handleDelete = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    if (this.state.postId)
      this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
        .child(this.props.firebase.auth.currentUser.uid)
        .child(this.state.postId)
        .remove()
        .then(() => {
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/Labeling/Skipped")
            .child(this.props.firebase.auth.currentUser.uid)
            .child(this.state.postId)
            .set({ dateForSorting: dateForSorting })
            .then(() => {
              this.handleFeedPostSelection();
            })
            .catch((error) => {
              prompt(error);
            });
        })
        .catch((error) => {
          prompt(error);
        });
  };

  handleSubmit = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    let matchObj = this.state.matchObj;
    let keys = Object.keys(matchObj);

    if (keys.length < this.state.feedPostData["numOfImages"]) {
      this.alertDialog.handleOpen(
        "Task not completed",
        "Match all the images before submitting"
      );
      return;
    }

    let result = { labels: {}, dateForSorting: dateForSorting };
    if (this.state.feedPostData.hasOwnProperty("bucket")) {
      result["bucket"] = this.state.feedPostData.bucket;
      result["paths"] = this.state.feedPostData.paths;
    }

    let alreadyUpdated = {};

    for (let i = 0; i < keys.length; i++) {
      let compareImgMatched = matchObj[keys[i]]
        ? this.state.compareArray[matchObj[keys[i]].index]
        : null;
      if (compareImgMatched) {
        result["labels"][i] = {
          cropName: this.state.feedPostData["cropVerifieldName"][i],
          diseaseTag: compareImgMatched.diseaseTag,
          imgFolder: compareImgMatched.imgFolder,
          imgName: compareImgMatched.imgName,
          confidence: matchObj[keys[i]].confidence,
        };

        try {
          if (
            !alreadyUpdated.hasOwnProperty(
              this.state.feedPostData["cropVerifieldName"][i]
            ) ||
            !alreadyUpdated[
              this.state.feedPostData["cropVerifieldName"][i]
            ].hasOwnProperty(compareImgMatched.diseaseTag)
          ) {
            this.props.firebase.db
              .ref(
                this.props.firebase.workgroupPath +
                  "/treatmentProtocols/changedSinceLastTraining"
              )
              .child(this.state.feedPostData["cropVerifieldName"][i])
              .child(compareImgMatched.diseaseTag)
              .set(true)
              .catch((error) => console.log(error));

            if (
              !alreadyUpdated.hasOwnProperty(
                this.state.feedPostData["cropVerifieldName"][i]
              )
            )
              alreadyUpdated[this.state.feedPostData["cropVerifieldName"][i]] =
                {};

            alreadyUpdated[this.state.feedPostData["cropVerifieldName"][i]][
              compareImgMatched.diseaseTag
            ] = true;
          }
        } catch (err) {
          console.log(err);
        }
      } else
        result["labels"][i] = {
          cropName: this.state.feedPostData["cropVerifieldName"][i],
        };
    }

    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling/Archive")
      .child(this.props.firebase.auth.currentUser.uid)
      .child(this.state.postId)
      .set(result)
      .then(() => {
        this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/Labeling/Assignments")
          .child(this.props.firebase.auth.currentUser.uid)
          .child(this.state.postId)
          .remove()
          .then(() => {
            this.handleFeedPostSelection();
          })
          .catch((error) => {
            prompt(error);
          });
      })
      .catch((error) => {
        prompt(error);
      });
  };

  handleMatchWith = (confidence) => {
    let matchObj = { ...this.state.matchObj };
    matchObj[this.state.selectedImgIndex] = {
      index: this.state.selectedCompareImg.index,
      confidence: confidence,
    };

    let newSelectedImgIndex =
      this.state.selectedImgIndex < this.state.feedPostData.numOfImages - 1
        ? this.state.selectedImgIndex + 1
        : 0;

    let alertRadius = 50;
    let date = new Date();
    let timestamp = date * 1;

    let setStateObj = { matchObj: matchObj };
    console.log(this.state.feedPostData);
    if (
      this.state.feedPostData.latitude !== undefined &&
      parseFloat(timestamp) -
        parseFloat(this.state.feedPostData.dateForSorting) <
        72 * 60 * 60 * 1000
    )
      setStateObj["alertInfo"] = {
        uid: this.state.sharingUser,
        crop: this.state.feedPostData.cropName,
        alertItem: this.state.selectedCompareImg.diseaseTag,
        latitude: this.state.feedPostData.latitude,
        longitude: this.state.feedPostData.longitude,
        alertRadius: alertRadius,
      };
    this.setState(setStateObj, () => {
      if (newSelectedImgIndex != this.state.selectedImgIndex)
        this.handleSelectLeftImage(newSelectedImgIndex);
    });
  };

  handleUnmatch = () => {
    let matchObj = { ...this.state.matchObj };
    delete matchObj[this.state.selectedImgIndex];

    this.setState({ matchObj: matchObj });
  };

  handleNoMatch = () => {
    let matchObj = { ...this.state.matchObj };
    matchObj[this.state.selectedImgIndex] = null;

    let newSelectedImgIndex =
      this.state.selectedImgIndex < this.state.feedPostData.numOfImages - 1
        ? this.state.selectedImgIndex + 1
        : this.state.selectedImgIndex;

    this.setState({ matchObj: matchObj }, () => {
      if (newSelectedImgIndex != this.state.selectedImgIndex)
        this.handleSelectLeftImage(newSelectedImgIndex);
    });
  };

  handleWrongCrop = () => {
    let feedPostData = { ...this.state.feedPostData };
    feedPostData["cropVerifieldName"][this.state.selectedImgIndex] = "Unknown";
    this.setState({ feedPostData: feedPostData });
  };

  handleVerifyCrop = () => {
    let feedPostData = { ...this.state.feedPostData };
    feedPostData["cropVerifieldName"][this.state.selectedImgIndex] =
      feedPostData["cropName"];

    let newSelectedImgIndex =
      this.state.selectedImgIndex < feedPostData.numOfImages - 1
        ? this.state.selectedImgIndex + 1
        : 0;

    this.setState({ feedPostData: feedPostData }, () => {
      if (newSelectedImgIndex != this.state.selectedImgIndex)
        this.handleSelectLeftImage(newSelectedImgIndex);
    });
  };

  handleLeftLoaded = () => {
    // console.log("left loaded");
    if (this.state.leftLoaded == false) this.setState({ leftLoaded: true });
  };

  handleLeftLargeLoaded = () => {
    // console.log("large loaded");
    if (this.state.leftLargeLoaded == false)
      this.setState({ leftLargeLoaded: true });
  };

  handleRightLoaded = () => {
    if (this.state.rightLoaded == false) this.setState({ rightLoaded: true });
  };

  handleRightLargeLoaded = () => {
    if (this.state.rightLargeLoaded == false)
      this.setState({ rightLargeLoaded: true });
  };

  createMessage = (diseaseName, confidence, diseaseTag) => {
    let message = `Hello, and thank you for the upload. We see symptoms of ${diseaseName} with ${confidence.toLowerCase()} confidence. Please refer to our page to read more information #${diseaseTag}`;
    let answeringUser = "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1";

    let uid = this.post.mid.substring(0, 28);

    console.log(uid);

    return this.props.firebase
      .premium(uid)
      .once("value")
      .then((result) => {
        if (result.val() == null) {
          const randomNumber = Math.floor(Math.random() * 7);
          answeringUser = randomAnsweringUser;

          switch (randomNumber) {
            case 0:
              message = `Hey there, it might be ${diseaseName}. You can read more here #${diseaseTag}. Have a lovely day!`;
              break;
            case 1:
              message = `Greetings! There's a chance that you're dealing with ${diseaseName}. If you'd like to learn more, you can read the page #${diseaseTag}. I hope you find it helpful.`;
              break;
            case 2:
              message = `Hi there! I think it's likely that you have ${diseaseName}. If you want to read more about it, you can search for #${diseaseTag} online. Have a fabulous day!`;
              break;
            case 3:
              message = `Hello! According to my analysis, it seems that ${diseaseName} might be the issue. You can find more information on the topic by following this link #${diseaseTag}.`;
              break;
            case 4:
              message = `It looks like ${diseaseName}. #${diseaseTag}.`;
              break;
            case 5:
              message = `Hello, did you consider the option of ${diseaseName}?. Look for the typical symptoms and recommendations here #${diseaseTag}. Hope it helps!`;
              break;
            case 6:
              message = `${diseaseName} in my opinion. Look here for more details: #${diseaseTag}.`;
              break;
            default:
              console.log("The random number is out of range");
              break;
          }
        }
        return { answeringUser, message };
      })
      .catch((error) => {
        prompt(error);
      });
  };

  initMessage = async () => {
    return new Promise(async (resolve) => {
      let diseaseTag = this.state.selectedCompareImg
        ? this.state.selectedCompareImg.diseaseTag
        : null;
      let confidence = this.state.matchObj[this.state.selectedImgIndex]
        ? this.state.matchObj[this.state.selectedImgIndex].confidence
        : "";
      const diseaseName = diseaseTag
        ? diseaseTag
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase()
        : "";

      let message =
        this.state.feedPostData["cropVerifieldName"][
          this.state.selectedImgIndex
        ] !== "Unknown"
          ? ""
          : "Hello, please provide us with the correct name of the plant.";

      let answeringUser = this.props.firebase.auth.currentUser.uid;

      if (
        this.state.feedPostData["cropVerifieldName"][
          this.state.selectedImgIndex
        ] !== "Unknown" &&
        this.props.firebase.auth.currentUser.uid ===
          "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
      )
        ({ answeringUser, message } = await this.createMessage(
          diseaseName,
          confidence,
          diseaseTag
        ));

      this.setState({ answeringUser: answeringUser, message: message });

      console.log(answeringUser);
      console.log(message);
    });
  };

  handleChange = (event, expanded) => {
    if (expanded) this.initMessage();
  };
  sendMessage = (message) => {
    if (this.post.cid && this.post.mid) {
      var nowDate = new Date();
      var dateForSorting = nowDate * 1;

      let dbPath =
        this.props.firebase.workgroupPath +
        "/channels/" +
        this.post.cid +
        "/sharedWithMe/" +
        this.post.mid +
        "/responses/" +
        dateForSorting +
        this.state.answeringUser;

      var responseObj = {
        answeringUser: this.state.answeringUser,
        answerDate: nowDate.toString(),
        answerText: message,
        addedImageName: "",
        dateForSorting: dateForSorting,
      };

      this.props.firebase._setDatabase(dbPath, responseObj, 1000);

      dbPath =
        this.props.firebase.workgroupPath +
        "/channels/" +
        this.post.cid +
        "/sharedWithMe/" +
        this.post.mid;

      // this.props.firebase._updateDatabase(dbPath, {
      //       dateForSorting: dateForSorting,
      //       solved: dateForSorting + this.props.firebase.auth.currentUser.uid,
      //       seen: false
      //     }, 1000);

      //Solved should initiate feedback, maybe add functions listener?

      this.props.firebase._updateDatabase(
        dbPath,
        {
          dateForSorting: dateForSorting,
          seen: false,
        },
        1000
      );
    }
  };

  handleSearch = (event) => {
    console.log(this.state.searchText);
    console.log(event);
    if (
      event.target.value.length > 3 &&
      this.state.compareArray.length == 0 &&
      this.state.cropLoaded == false
    )
      this.setState(
        {
          searchText: event.target.value,
        },
        () => {
          // console.log("auto seearch");
          this.loadCropListOfPathogens();
        }
      );
    else this.setState({ searchText: event.target.value });
  };

  handleChangeImageDescription = (event) => {
    let tmp = [...this.state.imageDescription];
    tmp[this.state.selectedImgIndex] = event.target.value;
    this.setState({
      imageDescription: tmp,
    });
  };

  handleChangeAdditionalComment = (event) => {
    this.setState({
      additionalComment: event.target.value,
    });
  };

  handleAdd = () => {
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/diseaseInfoImages"
      )
      .child(this.state.feedPostData.cropName)
      .child(this.state.searchText)
      .once("value")
      .then((result) => {
        let imageNum = result.val() ? result.val().numImages : 0;

        let oldFolderName = result.val() ? result.val().folderName : null;

        const input = prompt(
          "Enter an image number",
          (imageNum + 1).toString()
        );
        if (input) {
          let imageName = "image" + input + ".jpg";
          if (parseInt(input) > imageNum) imageNum = parseInt(input);

          this.setState({
            addToCrop: this.state.feedPostData.cropName,
            addToFolderName: oldFolderName
              ? oldFolderName
              : this.state.searchText,
            addFileName: imageName,
            addImageNum: imageNum,
            isAddImageOpen: true,
          });
        }
      })
      .catch((error) => {
        prompt(error);
      });

    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/changedSinceLastTraining"
      )
      .child(this.state.feedPostData.cropName)
      .child(this.state.searchText)
      .set(true);
  };

  handleAddImageClose = () => {
    this.setState({
      isAddImageOpen: false,
    });
  };

  handleAddImageSave = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    let fileSourcePath;
    let bucketSource = "agrio-c2539.appspot.com";
    let bucketDest = "agrio-c2539.appspot.com";

    if (this.state.feedPostData.hasOwnProperty("paths")) {
      bucketSource = "image_offline_data";
      fileSourcePath =
        this.state.feedPostData.paths[this.state.selectedImgIndex];
    } else {
      fileSourcePath =
        this.props.firebase.workgroupPath !== ""
          ? "images/" +
            this.props.firebase.workgroupPath +
            "/" +
            this.state.sharingUser
          : "images/" + this.state.sharingUser;

      fileSourcePath =
        fileSourcePath +
        "/" +
        this.state.imageName +
        "_" +
        (this.state.selectedImgIndex + 1).toString() +
        ".jpg";
    }

    let fileDestPath =
      this.props.firebase.workgroupPath === ""
        ? "diseaseInfo/" +
          this.state.addToCrop +
          "/" +
          this.state.addToFolderName +
          "/" +
          this.state.addFileName
        : "diseaseInfo/" +
          this.props.firebase.workgroupPath +
          "/" +
          this.state.addToCrop +
          "/" +
          this.state.addToFolderName +
          "/" +
          this.state.addFileName;

    this.props.firebase.db
      .ref("/storageOperations/copyFile/" + dateForSorting)
      .set({
        bucketSource: bucketSource,
        bucketDest: bucketDest,
        fileSourcePath: fileSourcePath,
        fileDestPath: fileDestPath,
      })
      .then(() => {
        return this.props.firebase.db
          .ref(
            this.props.firebase.workgroupPath +
              "/treatmentProtocols/diseaseInfoImages"
          )
          .child(this.state.feedPostData.cropName)
          .child(this.state.searchText)
          .update({
            folderName: this.state.addToFolderName,
            numImages: this.state.addImageNum,
          })
          .then(() => {
            this.setState({
              isAddImageOpen: false,
            });
          })
          .catch((error) => {
            prompt(error);
          });
      })
      .catch((error) => {
        prompt(error);
      });
  };

  handleEditCropName = () => {
    this.setState({ editCropNameDialogIsOpen: true });
  };

  handleEditCropNameClose = () => {
    this.setState({ editCropNameDialogIsOpen: false });
  };

  handleEditCropNameSave = () => {
    let ImgMetaDataPath =
      this.props.firebase.workgroupPath +
      "/user/" +
      this.state.sharingUser +
      "/images/" +
      this.state.imageName +
      "/";

    if (this.state.feedPostData.cropName !== undefined)
      this.props.firebase.db
        .ref(ImgMetaDataPath)
        .child("originalCropName")
        .set(this.state.feedPostData.cropName)
        .catch((error) => console.log(error));

    this.props.firebase.db
      .ref(ImgMetaDataPath)
      .child("cropName")
      .set(this.state.newCropName)
      .then(() => {
        this.setState({ editCropNameDialogIsOpen: false });
      })
      .catch((error) => {
        prompt(error);
      });
  };

  handleCropNameEdit = (event) => {
    this.setState({ newCropName: event.target.value });
  };

  handleSelectLeftImage = (selectedImgIndex) => {
    this.setState({
      selectedImgIndex: selectedImgIndex,
      leftLoaded: false,
      leftLargeLoaded: false,
      selectedCompareImg: this.state.matchObj[selectedImgIndex]
        ? this.state.compareArray[this.state.matchObj[selectedImgIndex].index]
        : null,
    });
  };

  handlePlantPartChange = async (index, event, selection) => {
    // if (selection === "None")
    //   this.props.firebase.db
    //     .ref(
    //       this.props.firebase.workgroupPath +
    //         "/treatmentProtocols/diseaseInfoImages/" +
    //         this.state.feedPostData.cropName
    //     )
    //     .child(this.state.compareArray[index].diseaseTag)
    //     .child("plantPart")
    //     .child(
    //       parseInt(
    //         this.state.compareArray[index].imgName.split(".")[0].substring(5)
    //       ) - 1
    //     )
    //     .remove();
    // else
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/diseaseInfoImages/" +
          this.state.feedPostData.cropName
      )
      .child(this.state.compareArray[index].diseaseTag)
      .child("plantPart")
      .child(
        parseInt(
          this.state.compareArray[index].imgName.split(".")[0].substring(5)
        ) - 1
      )
      .set(Helper.partOptions[selection]);
  };

  openImageDescription = () => {
    this.setState({
      harvieDialogOpen: true,
      imageDescription: new Array(this.state.feedPostData.numOfImages).fill(""),
      additionalComment: "",
    });
  };

  harvieSubmit = () => {
    let cid = "general feed";
    let mid;
    if (this.post.hasOwnProperty("mid")) {
      mid = this.post.mid;
      cid = this.post.cid;
    } else mid = this.post.sharingUser + this.post.imageName;

    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/imageCaptioning/")
      .child(this.state.sharingUser)
      .child(this.state.imageName)
      .set({
        cid: cid,
        mid: mid,
        crop: this.state.feedPostData.cropName,
        imageDescription: this.state.imageDescription,
        additionalComment: this.state.additionalComment,
      })
      .catch((err) => console.log(err));
  };

  handleAlertsSent = () => {
    this.setState({ alertInfo: null });
  };

  render() {
    const { classes, selectedTab } = this.props;

    let buttons = [];

    if (this.state.feedPostData)
      for (let i = 0; i < this.state.feedPostData.numOfImages; i++)
        buttons.push(
          <div
            style={{
              display: "flex",
              height: 60,
              width: 60,
              alignItems: "center",
              justifyContent: "center",
              borderRightStyle: "solid",
              borderRightWidth: 0.5,
              borderColor: "rgba(0,0,0,0.17)",
              backgroundColor:
                this.state.selectedImgIndex == i ? "#7ED321" : "transparent",
            }}
            onClick={() => {
              this.handleSelectLeftImage(i);
            }}
          >
            <Typography
              style={{
                margin: 4,
                fontFamily: "Roboto",
                fontSize: 12,
                fontWeight: "bold",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: this.state.selectedImgIndex == i ? "#ffffff" : "#000000",
              }}
            >
              {i + 1}
            </Typography>
            {this.state.matchObj.hasOwnProperty(i) ? (
              <CheckIcon
                fontSize={"small"}
                className={classes.searchBar}
                color={"action"}
              />
            ) : null}
          </div>
        );

    if (this.state.feedPostData == "")
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      );
    else if (this.state.feedPostData == null)
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: "140px" }} src={ImageResponse} />
          <Typography
            style={{
              marginTop: 8,
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: "bold",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            No images to show
          </Typography>
          <Typography
            style={{
              marginTop: 8,
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {this.state.isLabeler == true
              ? "Once images are shared in one of the feeds they will show up here"
              : "You are not assigned as a responder. Go to the Workgroup settings to change the list of responders."}
          </Typography>
        </div>
      );
    else
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.state.feedPostData && (
              <Paper className={classes.main}>
                <AppBar
                  className={classes.searchBar}
                  position="sticky"
                  color="default"
                  elevation={0}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className={classes.scroll}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 2,
                        overflowX: "auto",
                      }}
                    >
                      {buttons}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",

                        padding: 10,
                      }}
                    >
                      {this.state.feedPostData &&
                        this.state.feedPostData["cropVerifieldName"][
                          this.state.selectedImgIndex
                        ] == null && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#ffffff",
                              borderRadius: 10,
                            }}
                          >
                            {this.props.firebase.auth.currentUser.uid ===
                              "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                              <IconButton
                                disabled={false}
                                onClick={this.handleEditCropName}
                                color="inherit"
                              >
                                <EditIcon
                                  className={classes.searchBar}
                                  color={false ? "disabled" : "action"}
                                />
                              </IconButton>
                            )}
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontSize: 18,
                                fontWeight: "bold",
                                fontStyle: "normal",
                                letterSpacing: 0,
                                textAlign: "center",
                                color: "#000000",
                              }}
                            >
                              {this.state.feedPostData.cropName}?
                            </Typography>
                            <Button
                              style={{ marginLeft: 10 }}
                              onClick={this.handleVerifyCrop}
                              className={classes.searchBar}
                              color={"action"}
                            >
                              Yes
                            </Button>
                            <Button
                              style={{ marginLeft: 10 }}
                              onClick={this.handleWrongCrop}
                              className={classes.searchBar}
                              color={"action"}
                            >
                              No
                            </Button>
                            <Button
                              style={{ marginLeft: 10 }}
                              onClick={this.handleWrongCrop}
                              className={classes.searchBar}
                              color={"action"}
                            >
                              Maybe
                            </Button>
                          </div>
                        )}
                      {this.state.feedPostData &&
                        this.state.feedPostData["cropVerifieldName"][
                          this.state.selectedImgIndex
                        ] != null &&
                        !this.state.matchObj.hasOwnProperty(
                          this.state.selectedImgIndex
                        ) &&
                        this.state.selectedCompareImg == null && (
                          <Typography
                            style={{
                              margin: 10,
                              fontFamily: "Roboto",
                              fontSize: 14,
                              fontWeight: "normal",
                              fontStyle: "normal",
                              letterSpacing: 0,
                              textAlign: "center",
                              color: "#000000",
                            }}
                          >
                            Select image to compare or
                          </Typography>
                        )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",

                          backgroundColor: "#ffffff",
                          borderRadius: 10,
                        }}
                      >
                        {this.state.feedPostData &&
                          this.state.feedPostData["cropVerifieldName"][
                            this.state.selectedImgIndex
                          ] != null && (
                            <Button
                              onClick={
                                this.state.matchObj.hasOwnProperty(
                                  this.state.selectedImgIndex
                                )
                                  ? this.handleUnmatch
                                  : this.state.selectedCompareImg
                                  ? () =>
                                      this.setState({
                                        selectedCompareImg: null,
                                      })
                                  : this.handleNoMatch
                              }
                              className={classes.searchBar}
                              color={"action"}
                            >
                              {this.state.matchObj.hasOwnProperty(
                                this.state.selectedImgIndex
                              )
                                ? "Undo"
                                : this.state.selectedCompareImg
                                ? "Unselect"
                                : "Skip"}
                            </Button>
                          )}
                        {this.state.selectedCompareImg != null &&
                          !this.state.matchObj.hasOwnProperty(
                            this.state.selectedImgIndex
                          ) && (
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                              }}
                            >
                              <Button
                                style={{ marginLeft: 10 }}
                                onClick={() => this.handleMatchWith("Low")}
                                className={classes.searchBar}
                                color={"action"}
                              >
                                Low
                              </Button>
                              <Button
                                style={{ marginLeft: 10 }}
                                onClick={() => this.handleMatchWith("Moderate")}
                                className={classes.searchBar}
                                color={"action"}
                              >
                                Moderate
                              </Button>
                              <Button
                                style={{ marginLeft: 10 }}
                                onClick={() => this.handleMatchWith("High")}
                                className={classes.searchBar}
                                color={"action"}
                              >
                                High
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: 12,
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ecf0f3",
                          borderRadius: 10,
                          marginTop: 14,
                          marginBottom: 14,
                          marginRight: 10,
                        }}
                        onClick={this.handleDefer}
                        color="inherit"
                      >
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 14,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          Defer
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#ecf0f3",
                          borderRadius: 10,
                          marginTop: 14,
                          marginBottom: 14,
                          marginRight: 10,
                        }}
                        onClick={this.handleDelete}
                        color="inherit"
                      >
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 14,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          Delete
                        </Typography>
                      </Button>
                      {this.props.firebase.auth.currentUser.uid ===
                        "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                        <Button
                          style={{
                            backgroundColor: "#ecf0f3",
                            borderRadius: 10,
                            marginTop: 14,
                            marginBottom: 14,
                            marginRight: 10,
                          }}
                          onClick={this.handleAddToTest}
                          color="inherit"
                        >
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 14,
                              fontWeight: "normal",
                              fontStyle: "normal",
                              letterSpacing: 0,
                              textAlign: "center",
                              color: "#000000",
                            }}
                          >
                            Test
                          </Typography>
                        </Button>
                      )}
                      <Button
                        style={{
                          backgroundColor: "#ecf0f3",
                          borderRadius: 10,
                          marginTop: 14,
                          marginBottom: 14,
                          marginRight: 10,
                        }}
                        onClick={this.handleSubmit}
                        color="inherit"
                      >
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 14,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          Submit
                        </Typography>
                      </Button>
                    </div>
                  </div>
                </AppBar>

                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                  <div
                    className={classes.scroll}
                    style={{
                      position: "relative",
                      width: 500,
                      height: 500,
                      overflowY: "scroll",
                      direction: "rtl",
                      margin: 10,
                    }}
                  >
                    <GlassMagnifier
                      style={{
                        width: "100%",
                        height: "auto",
                        opacity: this.state.leftLoaded ? 1 : 0.1,
                      }}
                      imageSrc={
                        this.state.feedPostData.imageSource[
                          this.state.selectedImgIndex
                        ]
                      }
                      imageAlt={this.state.selectedImgIndex}
                      largeImageSrc={
                        this.state.leftLargeLoaded
                          ? this.state.feedPostData.imageSourceHighRes[
                              this.state.selectedImgIndex
                            ]
                          : this.state.feedPostData.imageSource[
                              this.state.selectedImgIndex
                            ]
                      }
                      magnifierSize="50%"
                      onImageLoad={this.handleLeftLoaded}
                      onLargeImageLoad={this.handleLeftLargeLoaded}
                    />
                    {this.state.leftLargeLoaded == false && (
                      <CircularProgress
                        style={{
                          zIndex: 10,
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                        disableShrink
                      />
                    )}
                  </div>
                  {this.state.selectedCompareImg && (
                    <div
                      className={classes.scroll}
                      style={{
                        position: "relative",
                        width: 500,
                        height: 500,
                        overflowY: "scroll",
                        margin: 10,
                      }}
                    >
                      <div>
                        <GlassMagnifier
                          style={{
                            zIndex: 0,
                            width: "100%",
                            height: "auto",
                            opacity: this.state.rightLoaded ? 1 : 0.1,
                          }}
                          imageSrc={
                            this.state.matchObj.hasOwnProperty(
                              this.state.selectedImgIndex
                            )
                              ? this.state.compareArray[
                                  this.state.matchObj[
                                    this.state.selectedImgIndex
                                  ].index
                                ].img
                              : this.state.selectedCompareImg.img
                          }
                          imageAlt="User's image"
                          largeImageSrc={
                            this.state.matchObj.hasOwnProperty(
                              this.state.selectedImgIndex
                            )
                              ? this.state.compareArray[
                                  this.state.matchObj[
                                    this.state.selectedImgIndex
                                  ].index
                                ].imgFullRes
                              : this.state.rightLargeLoaded
                              ? this.state.selectedCompareImg.img
                              : this.state.selectedCompareImg.imgFullRes
                          }
                          magnifierSize="50%"
                          onImageLoad={this.handleRightLoaded}
                          onLargeImageLoad={this.handleRightLargeLoaded}
                        />
                        {this.state.rightLargeLoaded == false && (
                          <CircularProgress
                            style={{
                              zIndex: 10,
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                            }}
                            disableShrink
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.feedPostData &&
                    this.state.feedPostData["cropVerifieldName"][
                      this.state.selectedImgIndex
                    ] != null &&
                    this.state.selectedCompareImg == null && (
                      <div
                        style={{
                          display: "flex",
                          width: 500,
                          height: 500,
                          margin: 10,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 20,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            letterSpacing: 0,
                            textAlign: "center",
                            color: "#000000",
                          }}
                        >
                          Select matching image
                        </Typography>
                      </div>
                    )}
                </div>
              </Paper>
            )}
            {this.state.feedPostData && (
              <Paper className={classes.paper}>
                <div
                  className={classes.scroll}
                  style={{
                    display: "flex",
                    width: 1020,
                    overflowX: "scroll",
                    alignItems: "flex-start",
                    overscrollBehaviorX: "contain",
                  }}
                >
                  {this.state.compareArray.length > 0 ? (
                    <List style={{ display: "flex", flexDirection: "row" }}>
                      {this.state.compareArray.map((item, index) => {
                        if (
                          item.diseaseTag
                            .toLowerCase()
                            .indexOf(this.state.searchText.toLowerCase()) != -1
                        )
                          return (
                            <div>
                              <ListItem
                                style={{
                                  backgroundColor: "#ffffff",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                onClick={() =>
                                  this.handleSelectCompareToImage(index)
                                }
                              >
                                <img
                                  src={item.img}
                                  style={{
                                    borderRadius: 3,
                                    height: "200",
                                    width: "auto",
                                  }}
                                  width={200}
                                  height={200}
                                />
                                <ListItemText
                                  style={{
                                    color: item.showImg ? "black" : "red",
                                  }}
                                  primary={
                                    item.cluster == null
                                      ? item.diseaseTag
                                      : item.diseaseTag +
                                        "(" +
                                        item.cluster +
                                        ")"
                                  }
                                  secondary={""}
                                />
                              </ListItem>
                              <div style={{ margin: 4 }}>
                                <Autocomplete
                                  blurOnSelect={true}
                                  forcePopupIcon={true}
                                  autoSelect={true}
                                  disableClearable={false}
                                  maxMenuHeight={240}
                                  size="small"
                                  id="pp"
                                  options={Object.keys(Helper.partOptions)}
                                  getOptionLabel={(option) => option}
                                  defaultValue={[]}
                                  onChange={(event, selection) =>
                                    this.handlePlantPartChange(
                                      index,
                                      event,
                                      selection
                                    )
                                  }
                                  value={
                                    Helper.partOptionsInverse[item.plantPart]
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      style={{ backgroundColor: "#ffffff" }}
                                      {...params}
                                      variant="outlined"
                                      label=""
                                      placeholder="Part"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          );
                      })}
                      {this.state.searchText !== "" && (
                        <ListItem
                          style={{
                            backgroundColor: "#ffffff",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={this.handleAdd}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 200,
                              height: 200,
                            }}
                          >
                            <AddIcon style={{ height: "80", width: "auto" }} />
                          </div>
                          <ListItemText primary={""} secondary={""} />
                        </ListItem>
                      )}
                    </List>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        height: 200,
                        width: 1000,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.searchText !== "" && (
                        <ListItem
                          style={{
                            backgroundColor: "#ffffff",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={this.handleAdd}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 200,
                              height: 200,
                            }}
                          >
                            <AddIcon style={{ height: "80", width: "auto" }} />
                          </div>
                          <ListItemText primary={""} secondary={""} />
                        </ListItem>
                      )}

                      {this.state.searchText === "" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 2,
                            height: 200,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              marginLeft: 12,
                            }}
                          >
                            {this.state.feedPostData.hasOwnProperty(
                              "diseaseClassification"
                            ) &&
                              this.state.feedPostData[
                                "diseaseClassification"
                              ].hasOwnProperty("PathologyArray") && (
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: 10,
                                  }}
                                >
                                  {this.state.feedPostData[
                                    "diseaseClassification"
                                  ].PathologyArray.slice(0, 3).map(
                                    (item, index) => {
                                      return (
                                        <Typography
                                          style={{
                                            fontFamily: "Roboto",
                                            fontSize: 12,
                                            fontWeight: "normal",
                                            fontStyle: "normal",
                                            letterSpacing: 0,
                                            textAlign: "left",
                                            color:
                                              this.state.feedPostData.hasOwnProperty(
                                                "acceptedAnswer"
                                              ) &&
                                              this.state.feedPostData[
                                                "acceptedAnswer"
                                              ] === index
                                                ? "#7ED321"
                                                : this.state.feedPostData.hasOwnProperty(
                                                    "rejectedAnswers"
                                                  ) &&
                                                  this.state.feedPostData[
                                                    "rejectedAnswers"
                                                  ] &&
                                                  this.state.feedPostData[
                                                    "rejectedAnswers"
                                                  ].indexOf(index) !== -1
                                                ? "red"
                                                : "#000000",
                                          }}
                                        >
                                          {this.state.feedPostData[
                                            "diseaseClassification"
                                          ].PathologyArray[index] +
                                            " " +
                                            this.state.feedPostData[
                                              "diseaseClassification"
                                            ].ConfidenceArray[index]
                                              .toString()
                                              .substring(0, 3)}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </div>
                              )}

                            {this.state.feedPostData.hasOwnProperty(
                              "cropClassification"
                            ) &&
                              this.state.feedPostData.hasOwnProperty(
                                "cropClassification_generic"
                              ) && (
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: 10,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: 12,
                                      fontWeight: "normal",
                                      fontStyle: "normal",
                                      letterSpacing: 0,
                                      textAlign: "left",
                                      color: "#000000",
                                    }}
                                  >
                                    {this.state.feedPostData &&
                                    this.state.feedPostData.cropClassification.hasOwnProperty(
                                      this.state.selectedImgIndex
                                    ) &&
                                    this.state.feedPostData.cropClassification[
                                      this.state.selectedImgIndex
                                    ].hasOwnProperty("crop")
                                      ? this.state.feedPostData
                                          .cropClassification[
                                          this.state.selectedImgIndex
                                        ].crop +
                                        " " +
                                        this.state.feedPostData.cropClassification[
                                          this.state.selectedImgIndex
                                        ].probability.substring(0, 3)
                                      : "empty"}{" "}
                                    (staging)
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: 12,
                                      fontWeight: "normal",
                                      fontStyle: "normal",
                                      letterSpacing: 0,
                                      textAlign: "left",
                                      color: "#000000",
                                    }}
                                  >
                                    {this.state.feedPostData &&
                                    this.state.feedPostData.cropClassification_generic.hasOwnProperty(
                                      this.state.selectedImgIndex
                                    ) &&
                                    this.state.feedPostData.cropClassification_generic[
                                      this.state.selectedImgIndex
                                    ].hasOwnProperty("crop")
                                      ? this.state.feedPostData
                                          .cropClassification_generic[
                                          this.state.selectedImgIndex
                                        ].crop +
                                        " " +
                                        this.state.feedPostData.cropClassification_generic[
                                          this.state.selectedImgIndex
                                        ].probability.substring(0, 3)
                                      : "empty"}
                                  </Typography>
                                </div>
                              )}
                          </div>
                          <div style={{ display: "flex", flex: 1 }}>
                            {this.state.cropLoaded == false && (
                              <Button onClick={this.loadCropListOfPathogens}>
                                Load images
                              </Button>
                            )}
                          </div>
                        </div>
                      )}

                      {this.state.searchText === "" &&
                        this.state.cropLoaded && (
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 20,
                              fontWeight: "normal",
                              fontStyle: "normal",
                              letterSpacing: 0,
                              textAlign: "center",
                              color: "#000000",
                            }}
                          >
                            No images are available for this crop
                          </Typography>
                        )}
                    </div>
                  )}
                </div>
              </Paper>
            )}

            {this.state.feedPostData && (
              <Paper className={classes.paper}>
                <div
                  className={classes.scroll}
                  style={{
                    display: "flex",
                    width: 1020,
                    marginBottom: 40,
                    overflowX: "scroll",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    overscrollBehaviorX: "contain",
                  }}
                >
                  <div
                    style={{
                      margin: 8,
                      width: this.state.harvieDialogOpen ? 700 : 300,
                    }}
                  >
                    {this.state.harvieDialogOpen ? (
                      <div>
                        <div>
                          <TextField
                            id="imageDescription"
                            label="Image description"
                            fullWidth
                            defaultValue=""
                            onChange={this.handleChangeImageDescription}
                            value={
                              this.state.imageDescription[
                                this.state.selectedImgIndex
                              ]
                            }
                            variant="outlined"
                          />
                        </div>
                        <div style={{ marginTop: 12 }}>
                          <TextField
                            id="AdditionalComment"
                            label="Additional comment"
                            fullWidth
                            defaultValue=""
                            onChange={this.handleChangeAdditionalComment}
                            value={this.state.additionalComment}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ) : (
                      <TextField
                        id="search_path"
                        label="search"
                        fullWidth
                        onChange={this.handleSearch}
                        value={this.state.searchText}
                        variant="outlined"
                      />
                    )}
                  </div>
                  <div style={{ margin: 8 }}>
                    {this.state.harvieDialogOpen ? (
                      <Button onClick={this.harvieSubmit}>Submit</Button>
                    ) : (
                      <Button onClick={this.openImageDescription}>
                        Comment as Harvie
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            )}
          </div>
          {this.state.feedPostData && this.state.responses != null && (
            <div style={{ position: "fixed", bottom: 0, right: 0 }}>
              <Accordion onChange={this.handleChange}>
                <AccordionSummary
                  expandIcon={<ExpandLessIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <div className={classes.column}>
                    <Typography className={classes.heading}>
                      {"Post messages (" + this.state.responses.length + ")"}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Chat
                    key={"chat"}
                    width={400}
                    myUID={this.props.firebase.auth.currentUser.uid}
                    answeringUser={this.state.answeringUser}
                    sharingUser={this.state.sharingUser}
                    responses={this.state.responses}
                    includeMessaging={true}
                    message={this.state.message}
                    sendMessage={this.sendMessage}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <Dialog
            open={this.state.isAddImageOpen}
            onClose={this.handleAddImageClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{"Add image"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {"Add " +
                  this.state.addFileName +
                  " to " +
                  this.state.addToFolderName +
                  " of " +
                  this.state.addToCrop}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAddImageClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleAddImageSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.editCropNameDialogIsOpen}
            onClose={this.handleEditCropNameClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{"New name"}</DialogTitle>
            <DialogContent>
              <TextField
                id="crop name"
                label="crop name"
                fullWidth
                rowsMax={1}
                defaultValue=""
                onChange={this.handleCropNameEdit}
                value={this.state.newCropName}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleEditCropNameClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleEditCropNameSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <AlertDialog
            onRef={(actualChild) => (this.alertDialog = actualChild)}
          />
          {this.state.alertInfo && (
            <div style={{ position: "fixed", bottom: 0, right: 0 }}>
              <Alerts
                {...this.state.alertInfo}
                handleAlertsSent={this.handleAlertsSent}
              />
            </div>
          )}
        </div>
      );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(
  withFirebase(withStyles(styles)(ResponseTool))
);
