import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import FieldAnalytics from "./FieldAnalytics";

import * as Helper from "../../Helper";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

const styles = {
  root: {
    display: "flex",
    minHeight: "75vh",
  },
  drawer: {
    flexShrink: 0,
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: "48px 36px 0",
    background: "#eaeff1",
  },
};

class RemoteSensing extends React.PureComponent {
  constructor() {
    super();

    //this.NDVIModal = React.createRef();

    this.state = {
      mobileOpen: false,
      selectedTab: 0,
      FarmerUID: "",
      FarmerName: "",
      farmerNames: [],
      memberTypes: [],
      farmerUIDs: null,
      postsVector: [],
      cropVector: [],
      feedPostsVector: [],
      feedPostData: null,
      organization: "Saillog",
      alerts: null,
      notCopying: true,
    };
  }

  getStorageImg = (userId, imageName, highResFlag, callback) => {
    let smallImagePath =
      "tmpSmallimages/" +
      this.props.firebase.workgroupPath +
      "/" +
      userId +
      "/small_" +
      imageName;
    let imagePath =
      "images/" +
      this.props.firebase.workgroupPath +
      "/" +
      userId +
      "/" +
      imageName;

    try {
      if (highResFlag == true) {
        const imageRef = this.props.firebase
          .imageURL(imagePath)
          .getDownloadURL()
          .then((url: string) => {
            callback(url);
          })
          .catch((error) => {
            callback("Not Found");
          });
      } else {
        const imageRef = this.props.firebase
          .imageURL(smallImagePath)
          .getDownloadURL()
          .then((url: string) => {
            callback(url);
          })
          .catch((error) => {
            //console.log(error.message);
            const imageRef = this.props.firebase
              .imageURL(imagePath)
              .getDownloadURL()
              .then((url: string) => {
                callback(url);
              })
              .catch((err) => {
                callback("Not Found");
              });
          });
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  updateArchiveList = (Items, Thread, userId, callback) => {
    var dataVector = [],
      length = Object.keys(Items).length,
      counter = 0;

    for (let k in Items) {
      let folderName = userId;
      let fileName = "thumb_" + k + "_1" + ".jpg";

      this.getStorageImg(folderName, fileName, true, (thumbUrl) => {
        this.props.firebase
          .channelPosts(Thread, userId, k)
          .once("value", (snapshot) => {
            counter = counter + 1;

            let thing = {
              name: k,
              userId: userId,
              imageName: k,
              seen: true,
              Thread: Thread,
              thumbUrl: thumbUrl,
              sharingUser: userId,
            };

            thing.cropName = "";
            if (Items[k].hasOwnProperty("cropName"))
              thing.cropName = Items[k].cropName;

            if (snapshot.val()) thing["ThreadExist"] = true;
            else thing["ThreadExist"] = false;

            thing["numResponses"] = 0;
            if (snapshot.val() && snapshot.val().hasOwnProperty("responses")) {
              let numResponses = 0;
              let size = 0,
                key;
              for (key in snapshot.val().responses) numResponses++;
              thing["numResponses"] = numResponses;
            }

            if (snapshot.val() && snapshot.val().hasOwnProperty("seen")) {
              thing["seen"] = snapshot.val().seen;
            }

            if (Items[k].hasOwnProperty("dateForSorting"))
              thing.date = Items[k].dateForSorting;
            else thing.date = 1000;

            dataVector.push(thing);

            if (counter == length) {
              dataVector.sort(function (a, b) {
                return parseFloat(b.date) - parseFloat(a.date);
              });

              callback(dataVector);
            }
          });
      });
    }
  };

  updateRequestList = (Items, Thread, userId, sourceType, callback) => {
    var dataVector = [],
      thing,
      length = Object.keys(Items).length,
      counter = 0,
      text;

    for (let k in Items) {
      this.props.firebase
        .cropMetadata(Items[k].sharingUser, Items[k].imageName)
        .once("value", (metaData) => {
          let cropName = "";
          if (metaData.val()) {
            cropName = metaData.val();
          }

          this.props.firebase
            .user2nickname(
              this.props.firebase.workgroupPath,
              Items[k].sharingUser
            )
            .then((name) => {
              let userNickName = name;

              let folderName = Items[k].sharingUser;
              let fileName = "thumb_" + Items[k].imageName + "_1" + ".jpg";
              let postType = "";
              let postDate = Items[k].date;

              if (
                Items[k].hasOwnProperty("imageName") &&
                Items[k].imageName.indexOf("Contact") !== -1
              ) {
                folderName = "Misc";
                fileName = "thumb_opening screen on olives background.jpg";
                cropName = Items[k].text;
                postType = "Contact";
              }

              if (
                Items[k].hasOwnProperty("imageName") &&
                Items[k].imageName.indexOf("Alert") !== -1
              ) {
                folderName = "Misc";
                fileName = "thumb_opening screen on olives background.jpg";
                cropName = Items[k].text;
                postType = "Alert";
                postDate = new Date(Items[k].dateForSorting).toString();
              }

              this.getStorageImg(folderName, fileName, true, (thumbUrl) => {
                counter = counter + 1;

                var numResponses = 0;
                if (Items[k].hasOwnProperty("responses")) {
                  var size = 0,
                    key;
                  for (key in Items[k].responses) numResponses++;
                }

                if (Items[k].hasOwnProperty("text")) text = Items[k].text;
                else text = "";

                thing = {
                  name: k,
                  imageName: Items[k].imageName,
                  sharingUser: Items[k].sharingUser,
                  date: Items[k].dateForSorting,
                  postDate: postDate,
                  text: text,
                  userId: userId,
                  Thread: Thread,
                  userName: userNickName,
                  sourceType: sourceType,
                  thumbUrl: thumbUrl,
                  numResponses: numResponses,
                  cropName: cropName,
                  postType: postType,
                };

                if (Items[k].hasOwnProperty("seen")) thing.seen = Items[k].seen;

                // for incoming alerts
                if (Items[k].hasOwnProperty("location")) {
                  thing.location = Items[k].location;
                  thing.radius = Items[k].radius;
                }

                if (Items[k].hasOwnProperty("fieldID"))
                  thing.fieldID = Items[k].fieldID;

                if (Items[k].hasOwnProperty("solved"))
                  thing.solved = Items[k].solved;

                dataVector.push(thing);

                if (counter == length) {
                  dataVector.sort(function (a, b) {
                    return parseFloat(b.date) - parseFloat(a.date);
                  });
                  callback(dataVector);
                }
              });
            });
        });
    }
  };

  stopListeningToFarmerPosts = () => {
    if (this.state.selectedTab != 1)
      if (this.state.FarmerName == "") {
        //console.log("no need to turn off posts listner");
      } else {
        this.props.firebase.farmerPosts(this.state.FarmerUID).off();
      }
  };

  startListeningToFarmerPosts = (UID) => {
    this.props.firebase
      .farmerPosts(UID)
      .orderByChild("dateForSorting")
      .limitToLast(10)
      .on("value", (snapshot) => {
        var Archives = "";

        if (snapshot.val()) {
          Archives = snapshot.val();
        }

        if (Archives == "") {
          //console.log("no Archives");
        } else {
          this.updateArchiveList(
            Archives,
            "general feed",
            UID,
            (dataVector) => {
              this.setState((state) => ({ postsVector: dataVector }));
            }
          );
        }
      });
  };

  startListeningToFeedPosts = (Thread) => {
    this.props.firebase
      .feedPosts("general feed")
      .orderByChild("dateForSorting")
      .limitToLast(10)
      .on("value", (snapshot) => {
        var Archives = "";

        if (snapshot.val()) {
          Archives = snapshot.val();
        }

        if (Archives == "") {
          //console.log("no Archives");
        } else {
          this.updateRequestList(
            Archives,
            Thread,
            "",
            "forum",
            (dataVector) => {
              this.setState((state) => ({ feedPostsVector: dataVector }));
            }
          );
        }
      });
  };

  copyFieldToPremiumFolder = (uid) => {
    //if the user have at least one field with a polygon he will be added to premiumWithPolygon
    return this.props.firebase.db
      .ref("/plotList/")
      .child(uid)
      .once("value")
      .then((snap) => {
        let polygonExist = false;

        let promises = [];

        if (snap.val()) {
          let fields = snap.val();
          let fieldIDs = Object.keys(fields);

          for (let i = 0; i < fieldIDs.length; i++)
            if (fields[fieldIDs[i]].hasOwnProperty("polygon")) {
              polygonExist = true;

              let promise = this.props.firebase
                .plotGeoLocation(uid, fieldIDs[i])
                .once("value")
                .then((result) => {
                  // console.log(result.val());
                  if (result.val()) {
                    return this.props.firebase
                      .premiumPlotGeoLocation(uid, fieldIDs[i])
                      .set(result.val());
                  } else {
                    console.log("something is wrong");
                    console.log(uid);
                    console.log(fieldIDs[i]);
                    return;
                  }
                });

              promises.push(promise);
            }

          if (polygonExist)
            promises.push(
              this.props.firebase.db
                .ref("/PremiumFolder/premiumWithPolygon/")
                .child(uid)
                .set({ flag: true })
            );
        }

        return Promise.all(promises);
      });
  };

  copyPremiumData = () => {
    // console.log("clicked")
    // console.log(this.props.userType);

    this.setState({ notCopying: false });

    if (this.props.userType === "admin")
      this.props.firebase
        .premiumFarmers()
        .once("value")
        .then((snapshot) => {
          console.log("copy start");
          let farmerUIDs = Object.keys(snapshot.val());

          //copy locations to PremiumFolder
          let promises = [];
          this.props.firebase.db.ref("/PremiumFolder/").remove();
          for (let j = 0; j < farmerUIDs.length; j++) {
            // console.log(farmerUIDs[j]);

            promises.push(this.copyFieldToPremiumFolder(farmerUIDs[j]));
          }
          //-------------------------------
          // console.log("before promise");
          Promise.all(promises).then((result) => {
            // console.log("promise finished");
            let filteredFarmerUIDs = [];
            for (let i = 0; i < result.length; i++) {
              if (result[i] == true)
                //console.log(farmerUIDs[i] + " " + result[i]);
                filteredFarmerUIDs.push(farmerUIDs[i]);
            }

            this.props.firebase
              .fetchUsersInfo(
                this.props.firebase.workgroupPath,
                filteredFarmerUIDs,
                false,
                false
              )
              .then((info) => {
                console.log("copy finished");
                this.setState({
                  farmerUIDs: info.uids,
                  farmerNames: info.nicknames,
                  memberTypes: info.memberTypes,
                  notCopying: true,
                });
              });
          });
          //console.log(Object.keys(snapshot.val()));
        });
  };

  handleTabChange = (event, value) => {
    //console.log(value);

    this.setState((state) => ({ selectedTab: value }));

    // --- Initiate listners

    if (value == 0 && this.state.farmerUIDs == null) {
      if (false) {
        this.props.firebase.db
          .ref("/PremiumFolder/premiumWithPolygon/")
          .on("value", (snapshot) => {
            let farmerUIDs = Object.keys(snapshot.val());

            this.props.firebase
              .fetchUsersInfo(
                this.props.firebase.workgroupPath,
                farmerUIDs,
                false,
                false
              )
              .then((info) => {
                this.setState({
                  farmerUIDs: info.uids,
                  farmerNames: info.nicknames,
                  memberTypes: info.memberTypes,
                });
              });
            //console.log(Object.keys(snapshot.val()));
          });
      } else if (this.props.firebase.workgroupPath === "") {
        this.props.firebase.db
          .ref("PremiumFolder/premiumWithPolygon")
          .once("value")
          .then((snap) => {
            // console.log(snap.val());
            let filteredFarmerUIDs = Object.keys(snap.val());
            this.props.firebase
              .fetchUsersInfo(
                this.props.firebase.workgroupPath,
                filteredFarmerUIDs,
                false,
                false
              )
              .then((info) => {
                // console.log(info);
                this.setState({
                  farmerUIDs: info.uids,
                  farmerNames: info.nicknames,
                  memberTypes: info.memberTypes,
                });
              });
          });
      } else
        this.props.firebase.organizationFarmers().on("value", (snapshot) => {
          let farmerUIDs = snapshot.val() ? Object.keys(snapshot.val()) : [];
          this.props.firebase
            .fetchUsersInfo(
              this.props.firebase.workgroupPath,
              farmerUIDs,
              false,
              false
            )
            .then((info) => {
              this.setState({
                farmerUIDs: info.uids,
                farmerNames: info.nicknames,
                memberTypes: info.memberTypes,
              });
            });
          //console.log(Object.keys(snapshot.val()));
        });
    }

    if (value == 4 && this.state.alerts == null)
      this.props.firebase
        .alerts(this.state.organization)
        .on("value", (snapshot) => {
          let alerts = snapshot.val();

          this.setState({
            alerts: alerts,
          });
        });
  };

  handleFarmerChange = (selectedOption) => {
    //console.log('selected: ', selectedOption);

    if (selectedOption == null) this.setState((state) => ({ FarmerName: "" }));
    else {
      // console.log(selectedOption);

      this.props.firebase
        .cropVector(selectedOption.UID)
        .once("value", (result) => {
          let cropVector = [];

          if (result && result.val()) cropVector = result.val().cropVector;

          //console.log("cropVector: " + cropVector);

          this.setState({
            FarmerName: selectedOption.value,
            FarmerUID: selectedOption.UID,
            cropVector: cropVector,
          });
        });
    }

    //farmer posts
    if (0 == 1) {
      if (this.state.FarmerName == "") {
        //console.log("no need to turn off posts listner");
      } else {
        this.stopListeningToFarmerPosts();
      }

      if (selectedOption == null)
        this.setState((state) => ({ FarmerName: "" }));
      else {
        //console.log(selectedOption.value);
        this.setState((state) => ({
          FarmerName: selectedOption.value,
          FarmerUID: selectedOption.UID,
        }));

        this.startListeningToFarmerPosts(selectedOption.UID);
      }
    }
  };

  //   componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(([key, val]) =>
  //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(([key, val]) =>
  //       prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  componentDidMount() {
    this.handleTabChange({}, 0);
  }

  componentWillUnmount() {
    this.props.firebase.organizationFarmers(this.state.organization).off();
    this.props.firebase.alerts(this.state.organization).off();
    // console.log("unmount");
    this.stopListeningToFarmerPosts();
  }

  handleFeedPostSelection = (index) => {
    let sharingUser = this.state.feedPostsVector[index].sharingUser;
    let imageName = this.state.feedPostsVector[index].imageName;
    let sharingUserName = "";

    this.listener1 =
      this.props.firebase.getUserInformationReference(sharingUser);

    this.props.firebase.getUserInformation(this.listener1, (info) => {
      if (info.hasOwnProperty("nickname")) sharingUserName = info.nickname;
    });

    this.props.firebase.getImgMetaData(sharingUser, imageName, (metaData) => {
      let numOfImages = metaData.numOfImages;
      let cropName = metaData.cropName;
      let country = "";
      let city = "";
      let humidity = "";
      let latitude = "";
      let longitude = "";
      let gps = false;
      let imageHardwareSource = "Folder";
      let temp_min = "";
      let temp_max = "";
      let folderGPSdate = null;
      let questionsString = "";

      if (metaData.hasOwnProperty("country")) {
        country = metaData.country;
        humidity = metaData.humidity;
      }

      if (metaData.hasOwnProperty("latitude")) {
        latitude = metaData.latitude;
        longitude = metaData.longitude;
      }

      if (metaData.hasOwnProperty("gps")) gps = metaData.gps;

      if (metaData.hasOwnProperty("imageSource"))
        imageHardwareSource = metaData.imageSource;

      if (metaData.hasOwnProperty("temp_min")) {
        temp_min = metaData.temp_min;
        temp_max = metaData.temp_max;
      }

      if (metaData.hasOwnProperty("folderGPSdate"))
        folderGPSdate = metaData.folderGPSdate;

      if (metaData.hasOwnProperty("questionsInfo"))
        questionsString = Helper.questionsInfoToString(metaData.questionsInfo);

      this.setState({
        feedPostData: {
          numOfImages: numOfImages,
          cropName: cropName,
          country: country,
          city: city,
          humidity: humidity,
          latitude: latitude,
          longitude: longitude,
          gps: gps,
          imageHardwareSource: imageHardwareSource,
          temp_min: temp_min,
          temp_max: temp_max,
          folderGPSdate: folderGPSdate,
          questionsString: questionsString,
          imageSource: ["", "", "", "", "", ""],
          imageSourceHighRes: ["", "", "", "", "", ""],
        },
      });

      Helper.asyncLoop(
        metaData.numOfImages,
        function (loop) {
          // log the iteration
          var index = loop.iteration();

          var imgNum = index + 1;

          this.props.firebase
            .getStorageImg(
              "images" + this.props.firebase.workgroupPath + "/" + sharingUser,
              imageName + "_" + imgNum.toString() + ".jpg",
              false
            )
            .then((url) => {
              var arrayvar = this.state.feedPostData.imageSource.slice();
              arrayvar[index] = url;

              let tmpPostData = this.state.feedPostData;
              tmpPostData["imageSource"] = arrayvar;

              this.setState({
                feedPostData: tmpPostData,
              });
            });

          this.props.firebase
            .getStorageImg(
              "images" + this.props.firebase.workgroupPath + "/" + sharingUser,
              imageName + "_" + imgNum.toString() + ".jpg",
              true
            )
            .then((url2) => {
              var arrayvar2 =
                this.state.feedPostData.imageSourceHighRes.slice();
              arrayvar2[index] = url2;

              let tmpPostData = this.state.feedPostData;
              tmpPostData["imageSourceHighRes"] = arrayvar2;

              this.setState({
                feedPostData: tmpPostData,
              });
            });

          // Okay, for cycle could continue

          loop.next();
        }.bind(this)
      );
    });

    this.props.firebase
      .getStorageImg(
        "images" + this.props.firebase.workgroupPath + "/" + sharingUser,
        "profilePic/" + "thumb_img.jpg",
        true
      )
      .then((url) => {
        let tmpPostData = this.state.feedPostData;
        tmpPostData["image_uri"] = url;

        this.setState({
          feedPostData: tmpPostData,
        });
      });

    this.props.firebase.getUserRating(sharingUser, (rating) => {
      //console.log("rating: ", this.state.sharingUser);
      //TODO change to "once" and not a listener
      this.listener4 = this.props.firebase.listenUserTypeReference(sharingUser);

      this.props.firebase.listenUserType(this.listener4, (result) => {
        //console.log("result: ", result);

        var userTypeTemp = Helper.userRanking(sharingUser, result, rating);
        //console.log("userTypeTemp: ", userTypeTemp);
        let tmpPostData = this.state.feedPostData;
        tmpPostData["userType"] = result;
        tmpPostData["userTypePresent"] = userTypeTemp;

        this.setState({
          feedPostData: tmpPostData,
        });
      });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <FieldAnalytics
        key={"FieldAnalytics"}
        classes={classes}
        FarmerNames={this.state.farmerNames}
        memberTypes={this.state.memberTypes}
        FarmerUIDs={this.state.farmerUIDs}
        handleOpenNDVIModal={this.props.handleOpenNDVIModal}
        modalSelection={this.props.modalSelection}
        menuType={this.props.userType === "admin" ? "WGAdmin" : "User"}
        notCopying={this.state.notCopying}
        copyPremiumData={this.copyPremiumData}
        workgroupID={this.props.workgroupID}
        handleWeatherDrawerToggle={this.props.handleWeatherDrawerToggle}
      />
    );
  }
}

RemoteSensing.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  // console.log(userType);
  // console.log(!(!authUser || userType!=="admin"));
  return !(
    !authUser ||
    (workgroupID == null &&
      userType !== "admin" &&
      premiumLevel === null &&
      premiumLevel === "Premium")
  );
};

export default withAuthorization(condition)(
  withFirebase(withStyles(styles)(RemoteSensing))
);
