var api = {
  baseUrl_v1: "https://api.weather.com/v1/geocode/",
  baseUrl_v3: "https://api.weather.com/v3/wx/",
  dailyForecastUrl: "forecast/daily/15day",
  hourlyForecastUrl: "forecast/hourly/15day",
  currentWeatherUrl: "conditions/historical/dailysummary/30day",
  fifteenminuteForecastPost: "/forecast/fifteenminute.json",
  key: "e441426aa1514ec781426aa151dec7f3",
  getDailyForecastUrl() {
    return this.baseUrl_v3 + this.dailyForecastUrl;
  },
  getHourlyForecastUrl() {
    return this.baseUrl_v3 + this.hourlyForecastUrl;
  },
  getCurrentWeatherUrl() {
    return this.baseUrl_v3 + this.currentWeatherUrl;
  },
  getCurrentFifteenMinuteForecastUrl(queryStringData) {
    return (
      this.baseUrl_v1 +
      queryStringData.lat +
      "/" +
      queryStringData.lon +
      this.fifteenminuteForecastPost
    );
  },
  //https://api.weather.com/v1/geocode/34.063/-84.217/forecast/fifteenminute.json?language=en-US&units=e&apiKey=yourApiKey
};

class weatherIBM {
  static returnPreparedUrl(url, tmpUnits, queryStringData) {
    //units=e, expressed in celsius when units=m, s, or h.
    let units = tmpUnits === "F" ? "e" : "m";

    let language = "en-US";
    language = language.split("-")[0];

    if (queryStringData)
      return (
        url +
        "?geocode=" +
        queryStringData.lat +
        "," +
        queryStringData.lon +
        "&format=json&units=" +
        units +
        "&language=" +
        language +
        "&apiKey=" +
        api.key
      );
    else
      return (
        url + "?language=" + language + "&units=" + units + "&apiKey=" + api.key
      );
  }

  static prepareAPIUrlForCurrentWeather(Mode, tmpUnits, latitude, longitude) {
    //get the current weather api data
    var url;

    var queryStringData = {
      lat: latitude,
      lon: longitude,
    };

    if (Mode === "weather") url = api.getCurrentWeatherUrl();
    else if (Mode === "daily") url = api.getDailyForecastUrl();
    else if (Mode === "fifteen")
      url = api.getCurrentFifteenMinuteForecastUrl(queryStringData);
    else url = api.getHourlyForecastUrl();

    if (Mode === "fifteen") {
      //console.log(this.returnPreparedUrl(url, tmpUnits, null));
      return this.returnPreparedUrl(url, tmpUnits, null);
    } else return this.returnPreparedUrl(url, tmpUnits, queryStringData);
  }

  static fetchApiDataForCurrentWeather(query, callback) {
    //make an API call to the end point for CURRENT WEATHER
    try {
      fetch(query)
        .then((response) => {
          //console.log(response);
          return response.json();
        })
        .then((responseData) => {
          //console.log('responseData : ',responseData);
          callback(responseData);
        })
        .catch((error) => {
          console.log(error);
          callback(null);
        });
    } catch (err) {
      console.log(err);
      callback(null);
    }
  }

  static handleResponseForCurrentWeather(currentWeatherData) {
    //make API call for 10 day Forecast
    this.fetchApiDataForForecast(
      this.prepareAPIUrlForForecast(),
      currentWeatherData
    );
  }

  static fetchWeather(mode, tmpUnits, latitude, longitude) {
    return new Promise((resolve, reject) => {
      this.fetchApiDataForCurrentWeather(
        this.prepareAPIUrlForCurrentWeather(
          mode,
          tmpUnits,
          latitude,
          longitude
        ),
        (response) => {
          //console.log(response);
          resolve(response);
        }
      );
    });
  }
}

export default weatherIBM;
