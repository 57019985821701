import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FileDrop } from 'react-file-drop';
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import './index.css';

class MediaCapture extends Component {

    constructor() {
      super();
      this.state = {
         image: null,
         images: [],
         urls: [],
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({images: nextProps.images, urls: nextProps.urls});
    }


    handleDrop = (files, ev) => {

        let images = this.state.images.concat([]);
        let urls = this.state.urls.concat([]);
        // Initialize FileReader browser API
        const reader = new FileReader();
        // onload callback gets called after the reader reads the file data
        reader.onload = (e) => {
          // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
          images.push(e.target.result);

          // console.log(e.target);

          //let localImageUrl = URL.createObjectURL(files[0]);
          let localImageUrl = files[0];
          // console.log(localImageUrl);
          urls.push(localImageUrl);
          //this.setState({images: images });
          this.props.callback({images: images, urls: urls});

        };
        // Read the file as Data URL (since we accept only images)
        reader.readAsDataURL(files[0]);

  }

  handleCancelClick = (remove_index) => {
    let images = [];
    let urls = [];

    this.state.images.forEach((item, index) => {
      if (index!=remove_index)
        images.push(item);
        urls.push(this.state.urls[index]);
    })

    //this.setState({images: images});
    this.props.callback({images: images, urls: urls});
  }


    render() {
        const { classes, boxStyle, maxImages } = this.props;

        const styles = { flex: 1, display: "flex", alignItems:"center", justifyContent: "center", border: '1px dashed lightgray', borderRadius: boxStyle.borderRadius, margin: boxStyle.margin, minWidth: boxStyle.width, height: boxStyle.height, color: 'gray'};

        let images = this.state.images.map((item, j) => {
            return (
              <div style={{display: "flex", margin: 12, position: 'relative', width: boxStyle.width, height: boxStyle.height, margin: boxStyle.margin}} key={j}>
              <div style={{position: 'absolute', zIndex: 10, top: -20, right: -20}}>
              <IconButton
                disabled={false}
                onClick={(e) => this.handleCancelClick(j)}
                color="inherit"
              >
                <CancelIcon className={classes.searchBar} color={"action"} />
              </IconButton>
             </div>

              <div style={{display: "flex", position: 'absolute', zIndex: 1, top: 0, left: 0, alignItems: 'center', justifyContent: 'center'}}>
                    <img
                        src={item}
                        style={{maxHeight: boxStyle.height - 4, maxWidth: boxStyle.width - 4, borderRadius: boxStyle.borderRadius}}
                    />

                  </div>
              </div>
          )});

        return (
          <Fragment>

          {images}
{maxImages && images.length<maxImages &&
    <div style={styles}>

      <FileDrop
        onFrameDragEnter={(event) => {}}
        onFrameDragLeave={(event) => {}}
        onFrameDrop={(event) => {}}
        onDragOver={(event) => {}}
        onDragLeave={(event) => {}}
        onDrop={this.handleDrop}
      >
        Drop some files here!
      </FileDrop>
      </div>
    }
  </Fragment>
        );
    }
}

export default MediaCapture;
