import React, { useEffect } from "react";
import { latLng2Tile, tile2LatLng } from "google-map-react";
import PropTypes from "prop-types";

var loadedID;
var loadedIndex;

const initTiles = () => {
  loadedID = {};
  loadedIndex = {};
};

const UrlTiles = (props) => {
  const { map, google, fieldData, date, remoteIndex, hide } = props;

  // console.log(hide);

  if (hide) {
    let keys = Object.keys(loadedIndex);
    for (let i = keys.length - 1; i >= 0; i--) {
      let removeIndex = loadedIndex[keys[i]];
      map.overlayMapTypes.removeAt(removeIndex);
      delete loadedID[keys[i]];
      delete loadedIndex[keys[i]];
    }

    return null;
  }
  // console.log(fieldData);
  if (fieldData) {
    if (
      loadedID.hasOwnProperty(fieldData.UID + fieldData.fieldID) &&
      loadedID[fieldData.UID + fieldData.fieldID] === remoteIndex + date
    ) {
      // console.log("already loaded...");
      return null;
    }

    if (
      !loadedID.hasOwnProperty(fieldData.UID + fieldData.fieldID) &&
      date === ""
    ) {
      // console.log("no layer to load...");
      return null;
    }

    if (
      loadedID.hasOwnProperty(fieldData.UID + fieldData.fieldID) &&
      loadedID[fieldData.UID + fieldData.fieldID] !== remoteIndex + date
    ) {
      let removeIndex = loadedIndex[fieldData.UID + fieldData.fieldID];
      map.overlayMapTypes.removeAt(removeIndex);

      for (let key in loadedIndex)
        if (loadedIndex[key] > removeIndex)
          loadedIndex[key] = loadedIndex[key] - 1;

      if (date === "") {
        // console.log(
        //   "removing tile..." + fieldData.UID + " " + fieldData.fieldID
        // );
        delete loadedID[fieldData.UID + fieldData.fieldID];
        delete loadedIndex[fieldData.UID + fieldData.fieldID];
        return null;
      }
      // console.log("changing tile...");
    }

    // console.log(fieldData);
    //
    // console.log("loading map tiles");

    var minZoom = 0.0;
    var maxZoom = 18.0;
    var tilePrefix;
    // let apiURL = "https://storage.googleapis.com/agrio-c2539.appspot.com/";
    // let apiURL = "https://storage.cloud.google.com/agrio-c2539.appspot.com/";
    let apiURL = "firebasestorage.googleapis.com/v0/b/";
    let seperator = "%2F";

    // console.log(remoteIndex);
    switch (remoteIndex) {
      case "NDVI":
        tilePrefix =
          apiURL +
          "agrio-c2539.appspot.com/o/" +
          "remoteSensing" +
          seperator +
          "NDVI" +
          seperator +
          fieldData.UID +
          seperator +
          fieldData.fieldID +
          seperator +
          date +
          seperator;
        break;
      case "redEdge":
        tilePrefix =
          apiURL +
          "agrio-c2539.appspot.com/o/" +
          "remoteSensing" +
          seperator +
          "redEdge" +
          seperator +
          fieldData.UID +
          seperator +
          fieldData.fieldID +
          seperator +
          date +
          seperator;
        break;
      case "NDVI_diff":
        tilePrefix =
          apiURL +
          "resultscans/o/" +
          "diffs" +
          seperator +
          "remoteSensing" +
          seperator +
          "NDVI" +
          seperator +
          fieldData.UID +
          seperator +
          fieldData.fieldID +
          seperator +
          date +
          seperator;
        break;
      case "redEdge_diff":
        tilePrefix =
          apiURL +
          "resultscans/o/" +
          "diffs" +
          seperator +
          "remoteSensing" +
          seperator +
          "redEdge" +
          seperator +
          fieldData.UID +
          seperator +
          fieldData.fieldID +
          seperator +
          date +
          seperator;
        break;
    }

    var tileSuffix = "?alt=media";

    // console.log(tilePrefix);

    // if (map)
    //    map.fitBounds(latLngBounds);

    var overlayMapType = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        if (zoom < minZoom || zoom > maxZoom) {
          return null;
        }
        var numTiles = 1 << zoom;
        var x = ((coord.x % numTiles) + numTiles) % numTiles;

        var bound1 = latLng2Tile(
          { lat: fieldData.polygonBound[2], lng: fieldData.polygonBound[0] },
          zoom
        );
        var bound2 = latLng2Tile(
          { lat: fieldData.polygonBound[3], lng: fieldData.polygonBound[1] },
          zoom
        );

        //console.log("inside " + x + " " + coord.y + " bounds: " + bound1.x + " " + bound2.x + " " + bound2.y + " " + bound1.y);

        //console.log(zoom);
        //console.log(maxZoom);

        if (
          x < bound1.x ||
          x > bound2.x ||
          coord.y < bound2.y ||
          coord.y > bound1.y
        )
          return;

        let tmp = [
          tilePrefix,
          zoom,
          seperator,
          x,
          seperator,
          coord.y,
          tileSuffix,
        ]
          .join("")
          .replace(/ /g, "%20")
          .replace(/:/g, "%3A")
          .replace(/\+/g, "%2B")
          .replace(/\-/g, "%2D");

        // console.log("https://" + tmp);

        return "https://" + tmp;
      },
      tileSize: new google.maps.Size(256, 256),
    });

    overlayMapType.addListener("tilesloaded", () => {
      // console.log("Overlay tiles loaded");
    });

    if (map) {
      map.overlayMapTypes.push(overlayMapType);
      loadedID[fieldData.UID + fieldData.fieldID] = remoteIndex + date;
      loadedIndex[fieldData.UID + fieldData.fieldID] =
        map.overlayMapTypes.length - 1;
    }
  }

  // Do we need to render anything??
  return null;
};

UrlTiles.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default UrlTiles;
export { initTiles };
