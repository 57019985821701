import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext, {
  WorkgroupIDContext,
  UserTypeContext,
  UserPremiumLevelContext,
} from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    async componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async (authUser) => {
          //not that easy to listen on usertype and premium. In any case the block of unautherized happens in the render right now
          // console.log(condition(authUser, null, "admin", "Pro"));
          if (!condition(authUser, null, "admin", "Pro")) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <WorkgroupIDContext.Consumer>
          {(workgroupID) => {
            // console.log(workgroupID);
            return (
              <UserTypeContext.Consumer>
                {(userType) => {
                  // console.log(userType);
                  return (
                    <AuthUserContext.Consumer>
                      {(authUser) => {
                        // console.log(authUser);
                        return (
                          <UserPremiumLevelContext.Consumer>
                            {(premiumLevel) => {
                              // console.log(premiumLevel);
                              return condition(
                                authUser,
                                workgroupID,
                                userType,
                                premiumLevel
                              ) ? (
                                <Component
                                  premiumLevel={premiumLevel}
                                  userType={userType}
                                  workgroupID={workgroupID}
                                  {...this.props}
                                />
                              ) : null;
                            }}
                          </UserPremiumLevelContext.Consumer>
                        );
                      }}
                    </AuthUserContext.Consumer>
                  );
                }}
              </UserTypeContext.Consumer>
            );
          }}
        </WorkgroupIDContext.Consumer>
      );
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
