import React, { Component, Fragment } from "react";

import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import Navigation from "../Navigation";
import Grid from "@material-ui/core/Grid";

import { withAuthorization } from "../Session";

//import { SignUpLink } from '../SignUp';
import * as ROUTES from "../../constants/routes";

import Background from "../../Assests/Background.png";

var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

class DeadEndPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <section style={sectionStyle}>
          <div style={{ width: "100%" }}>
            <Navigation type={"signout"} />
          </div>
          <Grid
            style={{
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={2}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginRight: this.state.isMobile ? "0px" : "50px",
                }}
              >
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "403px",
                    fontSize: 44,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Hello Agrio user!{" "}
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "451px",
                    fontSize: 24,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Our web application is currently just for admins and users
                  which are subscribed to the "Pro" package and above. Please
                  use the mobile application, we hope to offer you more content
                  in our web application in the future
                </p>
                <Grid container spacing={2}></Grid>
              </div>
            </Grid>
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            ></Grid>
            <Grid item xs={24}>
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginTop: this.state.isMobile ? "0px" : "80px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    ></p>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                  </Grid>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        height: "75px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    ></p>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                  </Grid>
                </Grid>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "528px",
                    marginTop: "150px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2019 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    Terms of Use
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy{" "}
                  </Link>
                  <a> | </a>
                  <a>Contact</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !!authUser;
};

export default withAuthorization(condition)(DeadEndPage);
