import React, { Component } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";

import TimerIcon from "@material-ui/icons/Timer";
import DialpadIcon from "@material-ui/icons/Dialpad";
import Assistant from "@material-ui/icons/Assistant";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";
import SelectMembers from "../SelectMembers";
import * as Lists from "../Lists.js";
import * as Helper from "../../Helper";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

//import RefreshIcon from '@material-ui/icons/Refresh';

class WorkgroupSettingsPage extends Component {
  constructor() {
    super();

    this.state = {
      WGname: "",
      newWGname: "",
      workgroupLogo: null,
      postExpiration: null,
      WGnameDialogOpen: false,
      PEDialogOpen: false,
      respondersDialogOpen: false,
      labelers: null,
    };
  }

  async componentDidMount() {
    this.workgroupNameListener = this.props.firebase.workgroupName();
    this.workgroupNameListener.on("value", (snap) => {
      if (snap.val())
        this.setState({
          WGname: snap.val().value,
          newWGname: snap.val().value,
        });
    });

    this.postExpirationListener = this.props.firebase.postExpiration();
    this.postExpirationListener.on("value", (snap) => {
      if (snap.val()) this.setState({ postExpiration: snap.val() });
    });

    this.labelersListener = this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling")
      .child("Labelers");
    this.labelersListener.on("value", (snap) => {
      if (snap.val()) this.setState({ labelers: Object.keys(snap.val()) });
      else this.setState({ labelers: [] });
    });

    this.props.firebase
      .getStorageImg(
        this.props.firebase.workgroupPath === ""
          ? "images//misc"
          : "images/" + this.props.firebase.workgroupPath + "/misc",
        "logo.jpg",
        true
      )
      .then((url) => {
        //console.log("url: ",url);
        this.setState({
          workgroupLogo: url,
        });
      });
  }

  componentWillUnmount() {
    this.workgroupNameListener.off();
    this.postExpirationListener.off();
  }

  handleNewWGnameChange = (event) => {
    this.setState({ newWGname: event.target.value });
  };

  handleWGnameDialogOpen = () => {
    this.setState({ WGnameDialogOpen: true });
  };

  handlePostExpirationDialogOpen = () => {
    this.setState({ PEDialogOpen: true });
  };

  handleRespondersDialogOpen = () => {
    this.setState({ respondersDialogOpen: true });
  };

  handleWGnameDialogClose = () => {
    this.setState({ WGnameDialogOpen: false });
  };

  handlePostExpirationDialogClose = () => {
    this.setState({ PEDialogOpen: false });
  };

  handleRespondersDialogClose = () => {
    this.setState({ respondersDialogOpen: false });
  };

  handleSaveWGname = () => {
    this.props.firebase.workgroupName().set({ value: this.state.newWGname });
    this.handleWGnameDialogClose();
  };

  handleSavePE = (time) => {
    this.props.firebase.postExpiration().set(time);
    this.handlePostExpirationDialogClose();
  };

  handleUploadLogo = async (e) => {
    let fileUpload = e.target.files[0];

    let blob = new Blob([fileUpload], { type: "image/jpeg" });
    await this.props.firebase._uploadImageToFirebase(
      blob,
      this.props.firebase.workgroupPath === ""
        ? "images/misc"
        : "images/" + this.props.firebase.workgroupPath + "/misc",
      "logo.jpg"
    );

    this.props.firebase
      .getStorageImg(
        this.props.firebase.workgroupPath === ""
          ? "images/misc"
          : "images/" + this.props.firebase.workgroupPath + "/misc",
        "logo.jpg",
        true
      )
      .then((url) => {
        //console.log("url: ",url);
        this.setState({
          workgroupLogo: url,
        });
      });
  };

  saveLabelers = (labelersObj) => {
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/Labeling")
      .child("Labelers")
      .set(labelersObj);
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          className={classes.paper}
          style={{
            zIndex: 5,
            width: 700,
            minHeight: 70,
            margin: 12,
            overflow: "visible",
          }}
        >
          <Typography
            style={{
              margin: 20,
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: "bold",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "left",
              color: "#000000",
            }}
          >
            Workgroup settings
          </Typography>

          <div
            style={{ height: 1, margin: 0, backgroundColor: "rgba(0,0,0,0.1)" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 3,
              marginTop: 40,
            }}
          >
            <div
              style={{
                marginLeft: 60,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: 30,
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "dotted",
                  borderWidth: 1,
                  borderColor: "rgba(0,0,0,0.1)",
                }}
              >
                {this.state.workgroupLogo && (
                  <img
                    style={{ maxHeight: 128, maxWidth: 128 }}
                    src={
                      this.state.workgroupLogo === "Not Found"
                        ? require("../../Assests/Agrio Logo.png")
                        : this.state.workgroupLogo
                    }
                  />
                )}
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.handleUploadLogo}
                />
                <label htmlFor="contained-button-file">
                  <Button component="span">
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 18,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "#01496d",
                      }}
                    >
                      Edit Photo
                    </Typography>
                  </Button>
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 30,
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Typography
                style={{
                  marginLeft: 40,
                  fontFamily: "Roboto",
                  fontSize: 24,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: "#000000",
                }}
              >
                {this.state.WGname ? this.state.WGname : "New group"}
              </Typography>

              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <IconButton
                  style={{ marginRight: 40, alignSelf: "flex-end" }}
                  onClick={this.handleWGnameDialogOpen}
                >
                  <img
                    src={require("../../Assests/edit.png")}
                    width={32}
                    height={32}
                  />
                </IconButton>
              </div>
            </div>
          </div>

          <div
            style={{
              height: 1,
              margin: 20,
              backgroundColor: "rgba(0,0,0,0.1)",
            }}
          />

          <Tooltip
            title={
              "Assign workgroup members as responders to provide diagnosis when posts are shared in the feeds"
            }
          >
            <div
              style={{
                marginTop: 40,
                marginLeft: 60,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <HeadsetMicIcon fontSize="large" />

              <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <Typography
                  style={{
                    marginLeft: 40,
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Forum responders -{" "}
                  {this.state.labelers && this.state.labelers.length > 0
                    ? this.state.labelers.length.toString() + " assigned"
                    : "None"}
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <IconButton
                    style={{ marginRight: 40, alignSelf: "flex-end" }}
                    onClick={this.handleRespondersDialogOpen}
                  >
                    <img
                      src={require("../../Assests/edit.png")}
                      width={32}
                      height={32}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Tooltip>

          <div
            style={{
              height: 1,
              margin: 20,
              backgroundColor: "rgba(0,0,0,0.1)",
            }}
          />
          <Tooltip
            title={
              "Set the expiration time for a post that was shared in the feed. After the expiration time users cannot comment on the post."
            }
          >
            <div
              style={{
                marginTop: 40,
                marginLeft: 60,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TimerIcon fontSize="large" />

              <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <Typography
                  style={{
                    marginLeft: 40,
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Post expiration time{" "}
                  {this.state.postExpiration
                    ? "- " + this.state.postExpiration
                    : ""}
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <IconButton
                    style={{ marginRight: 40, alignSelf: "flex-end" }}
                    onClick={this.handlePostExpirationDialogOpen}
                  >
                    <img
                      src={require("../../Assests/edit.png")}
                      width={32}
                      height={32}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Tooltip>

          <div
            style={{
              height: 1,
              margin: 40,
              backgroundColor: "rgba(0,0,0,0.1)",
            }}
          />
          <Tooltip
            title={
              "This is the pin code that users need to type when they request to join your Workgroup."
            }
          >
            <div
              style={{
                marginTop: 40,
                marginBottom: 40,
                marginLeft: 60,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <DialpadIcon fontSize="large" />

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  style={{
                    marginLeft: 40,
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  PIN - {this.props.firebase.workgroupID.substring(2)}
                </Typography>
              </div>
            </div>
          </Tooltip>
          <Dialog
            onClose={this.handleWGnameDialogClose}
            aria-labelledby="invite-dialog-title"
            open={this.state.WGnameDialogOpen}
          >
            <DialogTitle id="form-dialog-title">
              Change workgroup name
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="New name"
                label="New name"
                fullWidth
                multiline
                onChange={this.handleNewWGnameChange}
                value={this.state.newWGname}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleWGnameDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSaveWGname} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={this.handlePostExpirationDialogClose}
            aria-labelledby="pe-dialog-title"
            open={this.state.PEDialogOpen}
          >
            <DialogTitle id="form-dialog-title">
              Change post expiration time
            </DialogTitle>
            <List>
              <ListItem
                button
                onClick={() => this.handleSavePE("1 day")}
                key={"1 day"}
              >
                <ListItemText primary={"1 day"} />
              </ListItem>

              <ListItem
                button
                onClick={() => this.handleSavePE("1 week")}
                key={"1 week"}
              >
                <ListItemText primary={"1 week"} />
              </ListItem>

              <ListItem
                button
                onClick={() => this.handleSavePE("1 month")}
                key={"1 month"}
              >
                <ListItemText primary={"1 month"} />
              </ListItem>

              <ListItem
                button
                onClick={() => this.handleSavePE("Never")}
                key={"Never"}
              >
                <ListItemText primary={"Never"} />
              </ListItem>
            </List>
          </Dialog>

          <Dialog
            onClose={this.handleRespondersDialogClose}
            aria-labelledby="pe-dialog-title"
            open={this.state.respondersDialogOpen}
          >
            <DialogTitle
              style={{ boxShadow: "0px -5px 10px 2px rgba(0,0,0,0.1)" }}
              id="form-dialog-title"
            >
              Assign post responders
            </DialogTitle>
            <div style={{ height: "60vh", overflow: "hidden" }}>
              <SelectMembers
                selected={this.state.labelers}
                disableIfEmpty={false}
                from={"workgroup"}
                buttonText={"Save"}
                handleSave={this.saveLabelers}
                emptyText={
                  "No members yet. Invite members to your Workgroup and you will be able to assign them to response to post."
                }
              />
            </div>
          </Dialog>
        </Paper>
        <div style={{ height: 300 }} />
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(
  withFirebase(WorkgroupSettingsPage)
);
