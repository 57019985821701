import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ChatComponent from "./ChatComponent";
import TextField from "@material-ui/core/TextField";
import ChatRoomItem from "./ChatRoomItem";
import Typography from "@material-ui/core/Typography";
import SelectMembers from "../SelectMembers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import EmptyChat from "../../Assests/workgroup_together_illustration.png";

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
  input: {
    color: "#ffffff",
  },
  root: {
    "& .MuiFilledInput-root": {
      background: theme.palette.common.blue,
    },
  },
  inputRoom: {
    color: "#000000",
  },
  rootRoom: {
    "& .MuiFilledInput-root": {
      background: "transparent",
    },
  },
  customScrollbar: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRightStyle: "solid",
      borderRightWidth: 1,
      borderRightColor: "#000000",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
});

class ChatDrawer extends PureComponent {
  constructor() {
    super();
    this.state = {
      text: "",
      responses: {},
      selectedChatId: null,
      chatRef: null,
      chatName: null,
      createGroupDialogOpen: false,
      createGroupPageNum: 0,
      createdChatName: "",
    };

    this.listener = {};
    this.responses = {};
    this.selectedChatId = null;
  }

  componentDidMount() {}

  componentWillUnmount() {
    // console.log("unmout");
    let keys = Object.keys(this.listener);
    for (let i = 0; i < keys.length; i++) this.listener[keys[i]].off();
  }

  markAsSeen = () => {};

  handleClick = (event) => {
    let id = event.id;
    this.selectedChatId = id;

    if (process.env.NODE_ENV === "development") console.log(id);

    let chatRef = "Chats/" + id.replaceAll("_", "/");

    this.chatName(event).then((name) => {
      this.setState({ chatName: name });
    });

    if (this.listener.hasOwnProperty(id)) {
      this.setState({
        responses: this.responses[id],
        selectedChatId: id,
        chatRef: chatRef,
      });
      return;
    }

    let ref = this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/" + chatRef)
      .child("responses")
      .orderByChild("dateForSorting");

    this.listener[id] = ref.limitToLast(10);

    this.listener[id].on("value", (result) => {
      if (result.val()) {
        let responses = this.responses.hasOwnProperty(id)
          ? this.responses[id]
          : {};
        this.responses[id] = { ...responses, ...result.val() };

        if (this.selectedChatId === id) {
          this.setState({
            responses: this.responses[id],
            selectedChatId: id,
            chatRef: chatRef,
          });
        }

        //TODO need to check if the responses are not of the user..in such a case marking as seen is not needed
        this.markAsSeen();
      } else {
        this.responses[id] = {};
        if (this.selectedChatId === id)
          this.setState({
            responses: {},
            selectedChatId: id,
            chatRef: chatRef,
          });
      }
    });
  };

  onSubmit = () => {
    let nowDate = new Date();
    let dateForSorting = nowDate * 1;

    this.props.firebase._setDatabase(
      this.props.firebase.workgroupPath +
        "/" +
        this.state.chatRef +
        "/responses/" +
        dateForSorting +
        this.props.firebase.auth.currentUser.uid,
      {
        answeringUser: this.props.firebase.auth.currentUser.uid,
        answerText: this.state.text,
        addedImageName: "",
        dateForSorting: dateForSorting,
        deleted: false,
      },
      1000
    );
  };

  chatName = async (item) => {
    return new Promise((resolve, reject) => {
      let split = item.id.split("_");

      if (item.hasOwnProperty("chatName")) return resolve(item.chatName);
      else if (split[0] === "OneOnOne") {
        if (this.props.firebase.auth.currentUser.uid === split[1])
          return this.props.firebase
            .user2nickname(this.props.firebase.workgroupPath, split[2])
            .then((name) => {
              return resolve(name);
            });
        else
          return this.props.firebase
            .user2nickname(this.props.firebase.workgroupPath, split[1])
            .then((name) => {
              return resolve(name);
            });
      } else return resolve("Chat name");
    });
  };

  handleOpenAddGroup = () => {
    this.setState({ createGroupDialogOpen: true, createGroupPageNum: 0 });
  };

  handleCreateGroupDialogClose = () => {
    this.setState({ createGroupDialogOpen: false });
  };

  createGroup = (selectedObj) => {
    let inviteeArray = Object.keys(selectedObj);

    let nowDate = new Date();
    let dateForSorting = nowDate * 1;

    let memberObj = {};
    memberObj[this.props.firebase.auth.currentUser.uid] = dateForSorting;
    for (let i = 0; i < inviteeArray.length; i++)
      memberObj[inviteeArray[i]] = dateForSorting;

    this.props.firebase._setDatabase(
      this.props.firebase.workgroupPath +
        "/Chats/Groups/" +
        this.props.firebase.auth.currentUser.uid +
        "/" +
        dateForSorting,
      {
        dateForSorting: dateForSorting,
        memberObj: memberObj,
        creator: this.props.firebase.auth.currentUser.uid,
        chatName: this.state.createdChatName.trim(),
      }
    );

    this.handleCreateGroupDialogClose();
  };

  handleCreatedChatNameChange = (event) => {
    //console.log(event.target.value);
    this.setState({ createdChatName: event.target.value });
  };

  handleContinue = () => {
    this.setState({ createGroupPageNum: 1 });
  };

  render() {
    const {
      classes,
      chatRooms,
      notifyUsers,
      adminFlag,
      handleAllRead,
      ...other
    } = this.props;

    return (
      <Drawer variant="permanent" {...other} style={{ zIndex: 1001 }}>
        <div
          className={classes.scrollBar}
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                height: 100,
                flexDirection: "row",
                justifyContent: "space-between",
                borderRightStyle: "solid",
                borderRightWidth: 1,
                borderRightColor: "rgba(0,0,0,1)",
              }}
            >
              <ListItem
                style={{ height: 100, flex: 4 }}
                className={classNames(
                  classes.firebase,
                  classes.item,
                  classes.itemCategory
                )}
              >
                Chat
              </ListItem>

              <ListItem
                dense
                style={{ height: 100, flex: 1, justifyContent: "flex-end" }}
                className={classNames(
                  classes.firebase,
                  classes.item,
                  classes.itemCategory
                )}
              >
                <IconButton
                  disabled={false}
                  onClick={this.handleOpenAddGroup}
                  color="inherit"
                >
                  <GroupAddIcon color={"white"} />
                </IconButton>
              </ListItem>
            </div>

            {chatRooms.length > 0 ? (
              <List className={classes.customScrollbar} disablePadding>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {chatRooms.map((item) => (
                    <ChatRoomItem
                      key={item.id}
                      item={item}
                      chatName={this.chatName}
                      handleClick={this.handleClick}
                    />
                  ))}
                </div>
              </List>
            ) : (
              <div
                style={{
                  height: "80vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ height: "140px" }} src={EmptyChat} />
                <Typography
                  style={{
                    marginTop: 8,
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  No chat rooms to show
                </Typography>
                <Typography
                  style={{
                    width: 200,
                    marginTop: 8,
                    fontFamily: "Roboto",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  Create chat rooms and invite members to join.
                </Typography>
              </div>
            )}
          </div>
          {chatRooms.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: 100,

                  justifyContent: "center",
                }}
              >
                <ListItem
                  style={{ height: 100, flex: 2 }}
                  className={classNames(
                    classes.firebase,
                    classes.item,
                    classes.itemCategory
                  )}
                >
                  {this.state.chatName ? this.state.chatName : ""}
                </ListItem>
              </div>

              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  overflowY: "auto",
                }}
              >
                <ChatComponent
                  key={this.state.chatRef}
                  myUID={this.props.firebase.auth.currentUser.uid}
                  responses={Object.values(this.state.responses)}
                  chatRef={this.state.chatRef}
                />
              </div>

              <div
                style={{
                  position: "relative",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                }}
                align="right"
              >
                <TextField
                  disabled={this.state.chatRef == null}
                  className={classes.root}
                  inputProps={{ className: classes.input }}
                  multiline
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue="Normal"
                  variant="filled"
                  value={this.state.text}
                  placeholder={"Type a message"}
                  onChange={(event) =>
                    this.setState({ text: event.target.value })
                  }
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      this.onSubmit();
                      ev.preventDefault();
                    }
                  }}
                  fullWidth
                />
              </div>
            </div>
          )}
        </div>
        <Dialog
          onClose={this.handleCreateGroupDialogClose}
          aria-labelledby="pe-dialog-title"
          open={this.state.createGroupDialogOpen}
        >
          <DialogTitle
            style={{ boxShadow: "0px 5px 10px 2px rgba(0,0,0,0.1)" }}
            id="create-dialog-title"
          >
            {this.state.createGroupPageNum == 0
              ? "Create chat group"
              : "Select group members"}
          </DialogTitle>
          <div style={{ height: "60vh", width: 400, overflow: "hidden" }}>
            <div
              style={{
                position: "relative",
                left: -1 * this.state.createGroupPageNum * 400,
                width: 800,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: 400, overflow: "hidden" }}>
                <DialogContent style={{ height: "55vh" }}>
                  <TextField
                    className={classes.rootRoom}
                    inputProps={{ className: classes.inputRoom }}
                    multiline
                    hiddenLabel
                    id="room-name"
                    defaultValue="Normal"
                    variant="filled"
                    value={this.state.createdChatName}
                    placeholder={"Type the group name"}
                    onChange={this.handleCreatedChatNameChange}
                    fullWidth
                  />
                </DialogContent>
                <div style={{ boxShadow: "0px -5px 10px 2px rgba(0,0,0,0.1)" }}>
                  <Button
                    disabled={this.state.createdChatName === ""}
                    style={{ width: "100%", textAlign: "center" }}
                    onClick={this.handleContinue}
                    color="primary"
                  >
                    Continue
                  </Button>
                </div>
              </div>
              <div style={{ width: 400, overflow: "hidden" }}>
                <SelectMembers
                  selected={[]}
                  disableIfEmpty={true}
                  from={
                    this.props.firebase.workgroupPath !== "" &&
                    this.props.userType === "admin"
                      ? "workgroup"
                      : "team"
                  }
                  buttonText={"Create"}
                  handleSave={this.createGroup}
                  emptyText={
                    this.props.firebase.workgroupPath !== "" &&
                    this.props.userType === "admin"
                      ? "No members yet. Invite members to your Workgroup and you will be able to invite them to chat rooms."
                      : "No members yet. Invite members to your team and you will be able to invite them to chat rooms."
                  }
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Drawer>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(
    !authUser ||
    (workgroupID == null &&
      userType !== "admin" &&
      premiumLevel === null &&
      premiumLevel === "Premium")
  );
};

export default withAuthorization(condition)(
  withStyles(styles)(withFirebase(ChatDrawer))
);
