import * as geolib from "geolib";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

export function questionsInfoToString(questionsInfo) {
  let resultStr = "";

  if (questionsInfo.hasOwnProperty("noSpray"))
    resultStr = resultStr + "Not sprayed" + " | ";
  else if (
    questionsInfo.hasOwnProperty("insecticides") ||
    questionsInfo.hasOwnProperty("fungicides") ||
    questionsInfo.hasOwnProperty("herbicides")
  ) {
    resultStr = resultStr + "Sprayed with ";
    if (questionsInfo.hasOwnProperty("insecticides"))
      resultStr = resultStr + "Insecticides" + " • ";
    if (questionsInfo.hasOwnProperty("fungicides"))
      resultStr = resultStr + "Fungicides" + " • ";
    if (questionsInfo.hasOwnProperty("herbicides"))
      resultStr = resultStr + "Herbicides" + " • ";

    resultStr = resultStr.slice(0, -2) + "| ";
  }

  if (questionsInfo.hasOwnProperty("spread")) {
    if (questionsInfo["spread"] == "few")
      resultStr = resultStr + "Few plants" + " | ";
    else if (questionsInfo["spread"] == "many")
      resultStr = resultStr + "Many plants" + " | ";
    else if (questionsInfo["spread"] == "entire")
      resultStr = resultStr + "Entire crop" + " | ";
  }

  if (
    questionsInfo.hasOwnProperty("leafSymptoms") ||
    questionsInfo.hasOwnProperty("stemSymptoms") ||
    questionsInfo.hasOwnProperty("fruitSymptoms") ||
    questionsInfo.hasOwnProperty("rootsSymptoms")
  ) {
    resultStr = resultStr + "Shows on ";
    if (questionsInfo.hasOwnProperty("leafSymptoms"))
      resultStr = resultStr + "Leaf" + " • ";
    if (questionsInfo.hasOwnProperty("stemSymptoms"))
      resultStr = resultStr + "Stem" + " • ";
    if (questionsInfo.hasOwnProperty("fruitSymptoms"))
      resultStr = resultStr + "Fruit" + " • ";
    if (questionsInfo.hasOwnProperty("rootsSymptoms"))
      resultStr = resultStr + "Roots" + " • ";
    resultStr = resultStr.slice(0, -2) + "| ";
  }

  if (resultStr === "") return resultStr;
  else return resultStr.slice(0, -2);
}

export function asyncLoop(iterations, func, callback) {
  var index = 0;

  var done = false;
  var loop = {
    next: function () {
      if (done) {
        return;
      }

      if (index < iterations) {
        index++;
        func(loop);
      } else {
        done = true;
        //callback();
      }
    },

    iteration: function () {
      return index - 1;
    },

    break: function () {
      done = true;
      callback();
    },
  };
  loop.next();
  return loop;
}

export function userRanking(uid, Type, rating) {
  return "Fix";
}

export function polygonArea(points) {
  var area = geolib.getAreaOfPolygon(points) / 4046.86; //in acres
  //console.log(area);
  return area;
}

export function getDistance(start, end) {
  var distance =
    geolib.getDistance(
      { latitude: start[0], longitude: start[1] },
      { latitude: end[0], longitude: end[1] }
    ) / 1000; //in km

  return distance;
}

export function milidateToDayMonthYear(miliDate) {
  let date = new Date(parseFloat(miliDate)); // Date 2011-05-09T06:08:45.178Z
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day; // 2011-05-09
}

export function dateToString(date) {
  var year = date.getFullYear();

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = months[date.getMonth()];

  var d = date.getDate();

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var day = days[date.getDay()];

  return (
    day +
    " " +
    month +
    " " +
    d +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
}

export function sortByValue(array, value, type) {
  if (array.length == 0) return array;

  if (type === "num")
    return array.sort(function (a, b) {
      return parseFloat(b[value]) - parseFloat(a[value]);
    });
  else if (type === "str")
    return array.sort(function (a, b) {
      if (a[value] < b[value]) {
        return -1;
      }
      if (a[value] > b[value]) {
        return 1;
      }
      return 0;
    });
}

export async function getUniqueBrowserHash(callback) {
  const fp = await FingerprintJS.load();

  const { visitorId } = await fp.get();

  // console.log(visitorId);

  return callback(visitorId);

  //   Fingerprint2.get((components) => {
  //     var values = components.map((component) => {
  //       return component.value;
  //     });
  //     var murmur = Fingerprint2.x64hash128(values.join(""), 31);
  //     return callback(murmur);
  //   });
  // } catch (err) {
  //   console.log(err);
  // }
}

export const partOptions = {
  //unique ids, if changed in the future dont override previous ids
  None: -1,
  Roots: 0,
  Stem: 1,
  Branch: 7,
  "Leaf top": 2,
  "Leaf underside": 3,
  Flower: 4,
  Fruit: 5,
  Overview: 6,
};

export const partOptionsInverse = {};
for (const key in partOptions) {
  partOptionsInverse[partOptions[key]] = key;
}
