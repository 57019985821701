import React, { Component, Fragment } from 'react';

import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import Navigation from '../Navigation';
import Grid from "@material-ui/core/Grid";

import { withAuthorization } from '../Session';


import Background from '../../Assests/Background.png';


var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: 'cover',
  overflow: "hidden"
};

class FacebookDataDeletionInstructionsURL extends Component {
  constructor(props) {
    super(props);

    this.state = { };
  }

  render() {

  return (
    <Fragment>
    <section style={ sectionStyle }>
    <div style={{width: "100%"}}>
    <Navigation type={"signout"}/>
    </div>
    <Grid style={{paddingLeft: this.state.isMobile ? 0 : 100, paddingRight: this.state.isMobile ? 0 : 100}} container spacing={2}>
    <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
    <div style={{marginLeft: this.state.isMobile ? "50px" : "0px", marginRight: this.state.isMobile ? "0px" : "50px"}}>
    <p style={{width: this.state.isMobile ? this.state.width - 80 : "403px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#ffffff"}}>
Facebook Data Deletion Instructions
    </p>
    <p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#ffffff"}}>
Agrio uses Facebook login to allow users to authenticate themselves. We do not save personal data on our servers. The Facebook policy requires us to provide Data Deletion Instructions URL. To delete your information:
</p>
<p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#ffffff"}}>
 1. Click on the "Settings" in the Setting & Privacy section on Facebook<br/><br/>
 2. Look for Agrio in the "Apps and Websites" section and click on it.<br/><br/>
 3. Scroll down and click "Remove".
</p>
<p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#ffffff"}}>
Once completed your app activities will be removed.
</p>
    <Grid container spacing={2}>

    </Grid>
    </div>
    </Grid>
    <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>

    </Grid>
    <Grid item xs={24}>
    <div style={{marginLeft: this.state.isMobile ? "50px" : "0px", marginTop: this.state.isMobile ? "0px" : "80px"}}>
    <Grid container spacing={2}>
    <Grid item xs={24}>
    <p style={{width: this.state.isMobile ? this.state.width - 80 : "528px", fontSize: 32, font: "Roboto", fontWeight: "Bold", color:"#ffffff"}}></p>
    <div style={{display: 'flex', flexDirection: 'row'}}>

      </div>
      </Grid>
      <Grid item xs={24}>
      <p style={{width: this.state.isMobile ? this.state.width - 80 :"528px", height: "75px", fontSize: 32, font: "Roboto", fontWeight: "Bold", color:"#ffffff"}}></p>
    <div style={{display: 'flex', flexDirection: 'row'}}>

    </div>
    </Grid>
    </Grid>
    <p style={{width: this.state.isMobile ? this.state.width - 80 :"528px", marginTop: "150px", fontSize: 11, font: "Roboto", fontWeight: "Regular", color:"#4A4A4A"}}>
    <a>
    All rights reserved to Saillog LTD. 2019 - </a>
    <Link to={'/terms-conditions'} target="_blank">Terms of Use</Link><a> | </a>
    <Link to={'/privacy-policy'} target="_blank">Privacy Policy </Link><a> | </a>
    <a>Contact</a>
    </p>
    </div>
    </Grid>
    </Grid>

    </section>
    </Fragment>
  );
}
}


export default FacebookDataDeletionInstructionsURL;
