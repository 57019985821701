import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import Carousel, { Modal, ModalGateway } from "react-images";
import Timestamp from "../Timestamp";

import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  messageArea: {
    overflowY: "auto",
    width: "100%",
  },
  itemPrimaryLeft: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemPrimaryRight: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondaryLeft: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondaryRight: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemTime: {
    color: "gray",
    fontSize: 12,
    "&$textDense": {
      fontSize: 12,
    },
  },
});

var cachedUrls = {};

class ChatItem extends Component {
  constructor() {
    super();

    this.state = {
      imageUrl: null,
      lightboxIsOpen: false,
    };
  }

  async componentDidMount() {
    if (this.props.chatRef && this.props.item.addedImageName !== "") {
      console.log(this.props.chatRef);
      console.log(this.props.item.addedImageName);
      if (
        cachedUrls.hasOwnProperty(
          this.props.chatRef + this.props.item.addedImageName
        )
      )
        this.setState({
          imageUrl:
            cachedUrls[this.props.chatRef + this.props.item.addedImageName],
        });
      else
        this.props.firebase
          .getStorageImg(
            this.props.firebase.workgroupPath === ""
              ? "images/" + this.props.chatRef
              : "images/" +
                  this.props.firebase.workgroupPath +
                  "/" +
                  this.props.chatRef,
            this.props.item.addedImageName,
            false
          )
          .then((url) => {
            cachedUrls[this.props.chatRef + this.props.item.addedImageName] =
              url;
            this.setState({
              imageUrl: url,
            });
          });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  toggleLightbox = () => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
    }));
  };

  render() {
    const { classes, myUID, item, index, nickname } = this.props;
    const { lightboxIsOpen } = this.state;

    return (
      <React.Fragment>
        <ListItem
          style={{
            justifyContent:
              item.answeringUser === myUID ? "flex-end" : "flex-start",
          }}
          key={index}
          onClick={item.addedImageName === "" ? null : this.toggleLightbox}
        >
          <div
            style={{
              display: "flex",
              marginTop: 14,
              width: "75%",

              justifyContent:
                item.answeringUser === myUID ? "flex-end" : "flex-start",
            }}
          >
            <div
              style={{
                display: "inline-block",

                borderRadius: 8,
                backgroundColor:
                  item.answeringUser === myUID ? "#232f3e" : "#01496d",
              }}
            >
              <div style={{ margin: 8 }}>
                {item.addedImageName === "" ? (
                  <ListItemText
                    align={"left"}
                    classes={{
                      primary: classes.itemPrimaryLeft,
                      secondary: classes.itemSecondaryLeft,
                    }}
                    primary={nickname}
                    secondary={item.answerText}
                  ></ListItemText>
                ) : (
                  <div>
                    <ListItemText
                      align={"left"}
                      classes={{
                        primary: classes.itemPrimaryLeft,
                      }}
                      primary={nickname}
                    />
                    <img
                      src={this.state.imageUrl}
                      style={{ borderRadius: 3, maxWidth: 200, maxHeight: 200 }}
                    />
                  </div>
                )}

                <ListItemText
                  classes={{
                    primary: classes.itemPrimaryRight,
                    secondary: classes.itemTime,
                  }}
                  align={"right"}
                  secondary={Timestamp(item.dateForSorting)}
                ></ListItemText>
              </div>
            </div>
          </div>
        </ListItem>

        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.toggleLightbox}>
              <div
                style={{
                  width: "40vw",
                }}
              >
                <Carousel
                  currentIndex={0}
                  frameProps={{ autoSize: "width" }}
                  views={[{ source: { regular: this.state.imageUrl } }]}
                />
              </div>
            </Modal>
          ) : null}
        </ModalGateway>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withFirebase(ChatItem));
