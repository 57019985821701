import React, { Component, Fragment } from "react";

import { withRouter, Link } from "react-router-dom";

import { withAuthorization } from "../Session";

import BounceLoader from "react-spinners/BounceLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;

//import { SignUpLink } from '../SignUp';
import * as ROUTES from "../../constants/routes";

class UserRoutingPage extends Component {
  constructor(props) {
    console.log("constructor");

    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (
      this.props.workgroupID !== null ||
      this.props.userType === "admin" ||
      this.props.userType === "editor" ||
      this.props.premiumLevel === "Pro"
    )
      this.props.history.push(ROUTES.HOME);
    //this.props.history.push(ROUTES.PRICING);
    else this.props.history.push(ROUTES.DEADEND);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <BounceLoader size={105} color={"#7ed321"} loading={true} />
        </div>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  console.log(!!authUser);
  return !!authUser;
};

export default withAuthorization(condition)(withRouter(UserRoutingPage));
