import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";

import FilterListIcon from "@material-ui/icons/FilterList";

import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import TextField from "@material-ui/core/TextField";
import MediaCapture from "../MediaCapture";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import * as Lists from "../Lists.js";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Editor, EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";

import "./index.css";
import "../../../node_modules/draft-js/dist/Draft.css";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as Helper from "../../Helper";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const cropOptions = Object.keys(Lists.cropIcons);

//import RefreshIcon from '@material-ui/icons/Refresh';

class TreatmentProtocolsPage extends Component {
  constructor() {
    super();

    this.onChangeDescription = (editorState) =>
      this.setState({ description: editorState });
    this.onChangeConventional = (editorState) =>
      this.setState({ conventional: editorState });
    this.onChangeCultural = (editorState) =>
      this.setState({ cultural: editorState });
    this.onChangeManagement = (editorState) =>
      this.setState({ management: editorState });
    this.onChangeOrganic = (editorState) =>
      this.setState({ organic: editorState });
    this.onChangeBiological = (editorState) =>
      this.setState({ biological: editorState });
    this.onChangeCorrectionMeasures = (editorState) =>
      this.setState({ correctionMeasures: editorState });
    this.onChangeAdditional = (editorState) =>
      this.setState({ additional: editorState });

    this.setEditorConventional = (editor) => {
      this.editorConventional = editor;
    };

    this.activeMaterials = {};
    this.activeMaterialsByLanguage = {};
    this.activeMaterialsByLanguageReverse = {};

    this.defaultLanguages = [
      "ar",
      "en",
      "es",
      "fr",
      "it",
      "he",
      "hi",
      "id",
      "iw",
      "pt",
      "ru",
      "ta",
      "te",
      "vi",
    ];

    this.textChanges = {};
    this.machineTranslation = {};

    this.anchorRef = React.createRef();

    this.state = {
      PorotocolLanguages: {},
      pageIDs: [],
      workgroupPathPerPageID: {},
      SelectedPage: null,
      languages: [],
      language: "",
      description: EditorState.createEmpty(),
      conventional: EditorState.createEmpty(),
      cultural: EditorState.createEmpty(),
      management: EditorState.createEmpty(),
      organic: EditorState.createEmpty(),
      biological: EditorState.createEmpty(),
      correctionMeasures: EditorState.createEmpty(),
      additional: EditorState.createEmpty(),
      commonName: "",
      transferingInsect: "",
      class: "",
      hosts: [],
      host: null,
      affiliateProducts: null,
      productCountry: null,
      productsByActiveIngridient: null,
      productActiveIngridient: null,
      published: false,
      selectedIndex: 0,
      lightboxIsOpen: false,
      width: window.innerWidth,
      height: window.innerHeight,
      imgUrls: [],
      addedImages: [],
      pageSelectText: "",
      lastTrained: null,
      machineTranslation: false,
      needToSaveImages: false,
      hostPathogens: {},
      filterOpen: false,
      filterHost: null,
      saving: false,
      visImage: null,
      imageCluster: null,
      plantPart: null,
    };
  }

  buildDataBaseProtocols = () => {
    let fields = [
      "Conventional",
      "Cultural",
      "Management",
      "Organic",
      "Biological",
      "Correction Measures",
      "Additional",
      "name",
      "Transfering Insect",
      "class",
    ];

    this.props.firebase
      .treatmentPages()
      .once("value")
      .then(async (result) => {
        let pages = result.val();
        console.log(pages);
        let pageIDs = Object.keys(pages);
        for (let ind in pageIDs) {
          let pageID = pageIDs[ind];
          let pageInfo = pages[pageID];
          //console.log(pageInfo);

          this.props.firebase.db
            .ref("treatmentPagesLanguages")
            .once("value", async (langs) => {
              let languages = langs.val();

              for (let j = 0; j < languages.length; j++) {
                let lang = languages[j];
                this.props.firebase.db
                  .ref("treatmentProtocols/pageLanguages")
                  .child(pageID)
                  .child(lang)
                  .set(true);
              }

              for (let k = 0; k < fields.length; k++) {
                if (pageInfo.hasOwnProperty(fields[k])) {
                  if (k < 7)
                    for (let j = 0; j < languages.length; j++) {
                      let lang = languages[j];
                      let lngStr = "";

                      for (let i = 0; i < pageInfo[fields[k]].length; i++)
                        await this.props.firebase
                          .treatmentBlocks(lang, pageInfo[fields[k]][i])
                          .once("value", async (result2) => {
                            let str = result2.val();
                            lngStr = lngStr + str;
                          });
                      this.props.firebase.db
                        .ref("treatmentProtocols/data")
                        .child(pageID)
                        .child(fields[k])
                        .child(lang)
                        .set(lngStr);
                    }
                  else if (k == 7)
                    for (let j = 0; j < languages.length; j++) {
                      let lang = languages[j];
                      let lngStr;

                      await this.props.firebase.db
                        .ref("diseaseCommonName")
                        .child(pageID)
                        .child(lang)
                        .once("value", async (result2) => {
                          lngStr = result2.val();
                        });
                      this.props.firebase.db
                        .ref("treatmentProtocols/data")
                        .child(pageID)
                        .child(fields[k])
                        .child(lang)
                        .set(lngStr);
                    }
                  else {
                    let lngStr = pageInfo[fields[k]];
                    this.props.firebase.db
                      .ref("treatmentProtocols/data")
                      .child(pageID)
                      .child(fields[k])
                      .set(lngStr);
                  }
                  //console.log(lngStr);
                }
              }
            });
        }
      });
  };

  async componentDidMount() {
    //this.buildDataBaseProtocols();

    let publicProtocols = {};
    let workgroupProtocols = {};
    let publicHosts = {};
    let workgroupHosts = {};

    this.props.firebase
      .treatmentPorotocolsLanguages("")
      .on("value", (result) => {
        publicProtocols = result.val() ? result.val() : {};
        let pageIDs = Object.keys({
          ...publicProtocols,
          ...workgroupProtocols,
        });

        let workgroupPathPerPageID = {};
        for (let i = 0; i < pageIDs.length; i++)
          if (workgroupProtocols.hasOwnProperty(pageIDs[i]))
            workgroupPathPerPageID[pageIDs[i]] =
              this.props.firebase.workgroupPath;
          else workgroupPathPerPageID[pageIDs[i]] = "";

        this.setState({
          PorotocolLanguages: { ...publicProtocols, ...workgroupProtocols },
          pageIDs: pageIDs,
          workgroupPathPerPageID: workgroupPathPerPageID,
        });
        // console.log(pageIDs);
      });

    if (this.props.firebase.workgroupPath !== "")
      this.props.firebase
        .treatmentPorotocolsLanguages(this.props.firebase.workgroupPath)
        .on("value", (result) => {
          workgroupProtocols = result.val() ? result.val() : {};
          let pageIDs = Object.keys({
            ...publicProtocols,
            ...workgroupProtocols,
          });

          let workgroupPathPerPageID = {};
          for (let i = 0; i < pageIDs.length; i++)
            if (workgroupProtocols.hasOwnProperty(pageIDs[i]))
              workgroupPathPerPageID[pageIDs[i]] =
                this.props.firebase.workgroupPath;
            else workgroupPathPerPageID[pageIDs[i]] = "";

          this.setState({
            PorotocolLanguages: { ...publicProtocols, ...workgroupProtocols },
            pageIDs: pageIDs,
            workgroupPathPerPageID: workgroupPathPerPageID,
          });
          // console.log(pageIDs);
        });

    this.props.firebase.db
      .ref("/treatmentProtocols")
      .child("hosts")
      .on("value", (result) => {
        publicHosts = result.val() ? result.val() : {};
        let hostPathogens = {};
        let hosts = { ...publicHosts, ...workgroupHosts };
        for (let p in hosts)
          for (let host in hosts[p]) {
            if (hostPathogens.hasOwnProperty(host)) hostPathogens[host].push(p);
            else hostPathogens[host] = [p];
          }

        this.setState({
          hostPathogens: hostPathogens,
        });
      });

    if (this.props.firebase.workgroupPath !== "")
      this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/treatmentProtocols")
        .child("hosts")
        .on("value", (result) => {
          workgroupHosts = result.val() ? result.val() : {};
          let hostPathogens = {};
          let hosts = { ...publicHosts, ...workgroupHosts };
          for (let p in hosts)
            for (let host in hosts[p]) {
              if (hostPathogens.hasOwnProperty(host))
                hostPathogens[host].push(p);
              else hostPathogens[host] = [p];
            }

          this.setState({
            hostPathogens: hostPathogens,
          });
        });

    if (Object.keys(this.activeMaterials).length == 0)
      this.props.firebase
        .activeMaterials()
        .once("value")
        .then((result) => {
          this.activeMaterials = result.val();
        });
    console.log("here");
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/affiliationActiveIngredient"
      )
      .once("value")
      .then((products) => {
        console.log(products.val());
        this.setState({
          productsByActiveIngridient: products.val(),
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.treatmentPorotocolsLanguages("").off();
    if (this.props.firebase.workgroupPath !== "")
      this.props.firebase
        .treatmentPorotocolsLanguages(this.props.firebase.workgroupPath)
        .off();

    this.props.firebase.db.ref("/treatmentProtocols").child("hosts").off();

    if (this.props.firebase.workgroupPath !== "")
      this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/treatmentProtocols")
        .child("hosts")
        .off();

    if (this.trainedAIListenr) this.trainedAIListenr.off();
  }

  handleProductCountryChange = async (event, selection) => {
    if (selection) {
      this.props.firebase
        .affiliateProductList(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage,
          selection
        )
        .once("value")
        .then((products) => {
          this.setState({
            affiliateProducts: products.val(),
            productCountry: selection,
          });
        });
    } else {
      this.setState({
        affiliateProducts: null,
        productCountry: null,
      });
    }
  };

  handleProductActiveIngridientChange = async (event, selection) => {
    if (selection) {
      this.setState({
        productsToAdd: this.state.productsByActiveIngridient[selection],
        productActiveIngridient: selection,
      });
    } else {
      this.setState({
        productsToAdd: null,
        productActiveIngridient: null,
      });
    }
  };

  handleHostChange = async (event, selection) => {
    // console.log("change");
    // console.log(this.state.SelectedPage);
    // console.log(selection);

    if (selection) {
      if (this.trainedAIListenr) this.trainedAIListenr.off();

      this.trainedAIListenr = this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/trainedVectorsDates/"
        )
        .child(selection)
        .child(this.state.SelectedPage);

      this.trainedAIListenr.on("value", (result) => {
        this.setState({ lastTrained: result.val() ? result.val() : -1 });
      });

      let workgroupPath = this.props.firebase.workgroupPath;

      let imageInfo = await this.props.firebase
        .treatmentPorotocolImagesInfo(
          workgroupPath,
          this.state.SelectedPage,
          selection
        )
        .once("value");

      if (workgroupPath !== "" && imageInfo.val() == null) {
        workgroupPath = "";

        imageInfo = await this.props.firebase
          .treatmentPorotocolImagesInfo(
            workgroupPath,
            this.state.SelectedPage,
            selection
          )
          .once("value");

        if (imageInfo.val()) this.setSstate({ needToSaveImages: true });
      }

      if (imageInfo.val()) {
        let numImages = imageInfo.val().numImages;
        let folderName = imageInfo.val().folderName;

        let showImgArray = imageInfo.val().hasOwnProperty("showImgArray")
          ? imageInfo.val().showImgArray
          : [];

        let imageCluster = imageInfo.val().hasOwnProperty("imageCluster")
          ? imageInfo.val().imageCluster
          : new Array(numImages).fill(1);

        let plantPart = imageInfo.val().hasOwnProperty("plantPart")
          ? imageInfo.val().plantPart
          : new Array(numImages).fill(null);

        let visImage = [];

        for (let i = 0; i < numImages; i++)
          if (showImgArray.indexOf(i) == -1) visImage.push(false);
          else visImage.push(true);

        let images = new Array(numImages);
        let promises = [];

        if (numImages == 0)
          this.setState({
            host: null,
            imgUrls: [],
            addedUrls: [],
            lastTrained: null,
            visImage: null,
            imageCluster: null,
            plantPart: null,
          });
        else {
          for (let j = 0; j < numImages; j++) {
            let promise = this.props.firebase
              .getStorageImg(
                workgroupPath === ""
                  ? "tmpSmalldiseaseInfo/" + selection + "/" + folderName
                  : "tmpSmalldiseaseInfo/" +
                      workgroupPath +
                      "/" +
                      selection +
                      "/" +
                      folderName,
                "small_image" + (j + 1).toString() + ".jpg",
                false
              )
              .then((url) => {
                images[j] = {
                  source: { regular: url },
                  path:
                    workgroupPath === ""
                      ? "diseaseInfo/" +
                        selection +
                        "/" +
                        folderName +
                        "/image" +
                        (j + 1).toString() +
                        ".jpg"
                      : "diseaseInfo/" +
                        workgroupPath +
                        "/" +
                        selection +
                        "/" +
                        folderName +
                        "/image" +
                        (j + 1).toString() +
                        ".jpg",
                };
              });

            promises.push(promise);
          }

          Promise.all(promises).then(() => {
            //console.log("here");
            this.setState({
              visImage: visImage,
              imageCluster: imageCluster,
              plantPart: plantPart,
              imgUrls: images,
              addedImages: [],
              folderName: folderName,
            });
          });
        }
      } else {
        this.setState({
          imgUrls: [],
          addedImages: [],
          visImage: null,
          imageCluster: null,
          plantPart: null,
        });
      }

      this.setState({ host: selection });
    } else {
      this.setState({
        host: null,
        imgUrls: [],
        addedImages: [],
        lastTrained: null,
        visImage: null,
        imageCluster: null,
        plantPart: null,
      });
    }
  };

  getActiveMaterial = (code) => {
    return new Promise((resolve, reject) => {
      if (this.activeMaterials.hasOwnProperty(code))
        resolve(this.activeMaterials[code]);
      else {
        resolve(code);
      }
    });
  };

  findSubstancesCodes = (st, language) => {
    return new Promise((resolve, reject) => {
      var regex = /#[0-9]+/g;

      let codes = null;

      if (st) codes = st.match(regex);

      if (codes) {
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        let unique = codes.filter(onlyUnique);

        //console.log(unique);

        let count = 0;
        for (let i = 0; i < unique.length; i++) {
          this.getActiveMaterial(unique[i].substring(1)).then((Obj) => {
            count = count + 1;
            //console.log(Obj[this.state.language]);
            if (Obj) {
              if (Obj.hasOwnProperty(language)) {
                // st = st.replace(
                //   new RegExp(unique[i], "g"),
                //   "<span style='color:" + Obj.color + "'>" + Obj[language] + "</span>"
                // );
                st = st.replace(new RegExp(unique[i], "g"), Obj[language]);
              } else {
                // st = st.replace(
                //   new RegExp(unique[i], "g"),
                //   "<span style='color:" + Obj.color + "'>" + Obj["en"] + "</span>"
                // );
                st = st.replace(new RegExp(unique[i], "g"), Obj["en"]);
              }
            } else {
              //console.log(unique[i].substring(1) + " is missing");
            }

            if (count == unique.length) {
              return resolve(st);
            }
          });
        }
      } else return resolve(st);
    });
  };

  loadPageContent = async (pageID, language) => {
    if (this.state.pageIDs.indexOf(pageID) > -1) {
      let workgroupPath = this.state.workgroupPathPerPageID[pageID];

      if (!this.activeMaterialsByLanguage.hasOwnProperty(language)) {
        this.activeMaterialsByLanguage[language] = {};
        this.activeMaterialsByLanguageReverse[language] = {};
        for (let code in this.activeMaterials) {
          this.activeMaterialsByLanguage[language][code] =
            this.activeMaterials[code][language];
          this.activeMaterialsByLanguageReverse[language][
            this.activeMaterials[code][language]
          ] = code;
        }
      }

      let description = this.props.firebase
        .treatmentPorotocolDescription(workgroupPath, pageID, language)
        .once("value");
      let conventional = this.props.firebase
        .treatmentPorotocolConventional(workgroupPath, pageID, language)
        .once("value");
      let cultural = this.props.firebase
        .treatmentPorotocolCultural(workgroupPath, pageID, language)
        .once("value");
      let management = this.props.firebase
        .treatmentPorotocolManagement(workgroupPath, pageID, language)
        .once("value");
      let organic = this.props.firebase
        .treatmentPorotocolOrganic(workgroupPath, pageID, language)
        .once("value");
      let biological = this.props.firebase
        .treatmentPorotocolBiological(workgroupPath, pageID, language)
        .once("value");
      let correctionMeasures = this.props.firebase
        .treatmentPorotocolCorrectionMeasures(workgroupPath, pageID, language)
        .once("value");
      let additional = this.props.firebase
        .treatmentPorotocolAdditional(workgroupPath, pageID, language)
        .once("value");
      let commonName = this.props.firebase
        .treatmentPorotocolCommonName(workgroupPath, pageID, language)
        .once("value");
      let scientificName = this.props.firebase
        .treatmentPorotocolScientificName(workgroupPath, pageID, language)
        .once("value");
      let transferingInsect = this.props.firebase
        .treatmentPorotocolTransferingInsect(workgroupPath, pageID)
        .once("value");
      let _class = this.props.firebase
        .treatmentPorotocolClass(workgroupPath, pageID)
        .once("value");
      let hosts = this.props.firebase
        .treatmentPorotocolHosts(workgroupPath, pageID)
        .once("value");
      let published = this.props.firebase
        .treatmentPorotocolPublished(workgroupPath, pageID)
        .once("value");
      let machineTranslation = this.props.firebase
        .treatmentPorotocolMachineTranslation(workgroupPath, pageID, language)
        .once("value");
      let products = this.props.firebase
        .affiliateProductList(workgroupPath, pageID, "US")
        .once("value");

      let results = await Promise.all([
        description,
        conventional,
        cultural,
        management,
        organic,
        biological,
        correctionMeasures,
        additional,
        commonName,
        scientificName,
        transferingInsect,
        _class,
        hosts,
        published,
        machineTranslation,
        products,
      ]);

      const blocksFromHTML = stateFromHTML(
        results[0].val()
          ? await this.findSubstancesCodes(results[0].val(), language)
          : ""
      );
      const blocksFromHTML_0 = stateFromHTML(
        results[1].val()
          ? await this.findSubstancesCodes(results[1].val(), language)
          : ""
      );
      const blocksFromHTML_1 = stateFromHTML(
        results[2].val()
          ? await this.findSubstancesCodes(results[2].val(), language)
          : ""
      );
      const blocksFromHTML_2 = stateFromHTML(
        results[3].val()
          ? await this.findSubstancesCodes(results[3].val(), language)
          : ""
      );
      const blocksFromHTML_3 = stateFromHTML(
        results[4].val()
          ? await this.findSubstancesCodes(results[4].val(), language)
          : ""
      );
      const blocksFromHTML_4 = stateFromHTML(
        results[5].val()
          ? await this.findSubstancesCodes(results[5].val(), language)
          : ""
      );
      const blocksFromHTML_5 = stateFromHTML(
        results[6].val()
          ? await this.findSubstancesCodes(results[6].val(), language)
          : ""
      );
      const blocksFromHTML_6 = stateFromHTML(
        results[7].val()
          ? await this.findSubstancesCodes(results[7].val(), language)
          : ""
      );

      this.setState({
        SelectedPage: pageID,
        languages: Object.keys(this.state.PorotocolLanguages[pageID]),
        language: language,
        description: EditorState.createWithContent(blocksFromHTML),
        conventional: EditorState.createWithContent(blocksFromHTML_0),
        cultural: EditorState.createWithContent(blocksFromHTML_1),
        management: EditorState.createWithContent(blocksFromHTML_2),
        organic: EditorState.createWithContent(blocksFromHTML_3),
        biological: EditorState.createWithContent(blocksFromHTML_4),
        correctionMeasures: EditorState.createWithContent(blocksFromHTML_5),
        additional: EditorState.createWithContent(blocksFromHTML_6),
        commonName: results[8].val() ? results[8].val() : "",
        scientificName: results[9].val() ? results[9].val() : "",
        transferingInsect: results[10].val() ? results[10].val() : "",
        class: results[11].val() ? results[11].val() : "",
        hosts: results[12].val() ? Object.keys(results[12].val()) : [],
        published: results[13].val() ? results[13].val() : false,
        machineTranslation: results[14].val() ? results[14].val() : false,
        affiliateProducts: results[15].val() ? results[15].val() : null,
        productCountry: "US",
        host: null,
        imgUrls: [],
        addedImages: [],
        addedUrls: [],
        lastTrained: null,
      });
    } else {
      this.setState({
        SelectedPage: pageID,
        languages: this.defaultLanguages,
        language: "en",
        description: EditorState.createEmpty(),
        conventional: EditorState.createEmpty(),
        cultural: EditorState.createEmpty(),
        management: EditorState.createEmpty(),
        organic: EditorState.createEmpty(),
        biological: EditorState.createEmpty(),
        correctionMeasures: EditorState.createEmpty(),
        additional: EditorState.createEmpty(),
        commonName: "",
        scientificName: "",
        transferingInsect: "",
        class: "",
        hosts: [],
        affiliateProducts: null,
        productCountry: null,
        published: false,
        machineTranslation: false,
        host: null,
        imgUrls: [],
        addedImages: [],
        addedUrls: [],
        lastTrained: null,
      });
    }
  };

  handlePageChange = async (event, selection) => {
    //should alert that changes will be lost
    this.textChanges = {};
    console.log(selection);
    if (selection) {
      let filtered = selection.replace(/[^a-zA-Z]/g, "").replace(/ /g, "");
      // console.log(filtered);
      // this.setState({pageSelectText: filtered});

      // console.log(filtered);

      return this.loadPageContent(
        filtered,
        this.state.language === "" ? "en" : this.state.language
      );
    } else {
      return this.setState({
        SelectedPage: null,
        languages: [],
        language: "",
        description: EditorState.createEmpty(),
        conventional: EditorState.createEmpty(),
        cultural: EditorState.createEmpty(),
        management: EditorState.createEmpty(),
        organic: EditorState.createEmpty(),
        biological: EditorState.createEmpty(),
        correctionMeasures: EditorState.createEmpty(),
        additional: EditorState.createEmpty(),
        commonName: "",
        scientificName: "",
        transferingInsect: "",
        class: "",
        hosts: [],
        host: null,
        published: false,
        imgUrls: [],
        addedImages: [],
        addedUrls: [],
        lastTrained: null,
      });
    }
  };

  encodeActiveMaterial = (text, language) => {
    for (let activeMaterial in this.activeMaterialsByLanguageReverse[
      language
    ]) {
      text = text.replace(
        new RegExp(activeMaterial, "gi"),
        "#" + this.activeMaterialsByLanguageReverse[language][activeMaterial]
      );
    }

    return text;
  };

  extractLanguageSpecificTextData = (language) => {
    let obj = {
      description: this.encodeActiveMaterial(
        stateToHTML(this.state.description.getCurrentContent()),
        language
      ),
      conventional: this.encodeActiveMaterial(
        stateToHTML(this.state.conventional.getCurrentContent()),
        language
      ),
      cultural: this.encodeActiveMaterial(
        stateToHTML(this.state.cultural.getCurrentContent()),
        language
      ),
      management: this.encodeActiveMaterial(
        stateToHTML(this.state.management.getCurrentContent()),
        language
      ),
      organic: this.encodeActiveMaterial(
        stateToHTML(this.state.organic.getCurrentContent()),
        language
      ),
      biological: this.encodeActiveMaterial(
        stateToHTML(this.state.biological.getCurrentContent()),
        language
      ),
      correctionMeasures: this.encodeActiveMaterial(
        stateToHTML(this.state.correctionMeasures.getCurrentContent()),
        language
      ),
      additional: this.encodeActiveMaterial(
        stateToHTML(this.state.additional.getCurrentContent()),
        language
      ),
      commonName: this.state.commonName,
      scientificName: this.state.scientificName,
    };
    //console.log(obj);
    return obj;
  };

  handleLanguageChange = async (event, selection) => {
    // console.log(selection);
    // this.textChanges[selection] = this.extractLanguageSpecificTextData(selection);
    // console.log(this.textChanges);
    this.loadPageContent(this.state.SelectedPage, selection);
  };

  handleClassChange = async (event, selection) => {
    this.setState({ class: selection });
  };

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  // <TextField
  //           id="ConventionalTreatment"
  //           label="Conventional treatment"
  //           multiline
  //           fullWidth
  //           rowsMax={20}
  //           defaultValue=""
  //           value={this.state.conventional}
  //           variant="outlined"
  // />

  handleCancelClick = (remove_index) => {
    let images = [];

    //console.log(remove_index);

    this.state.imgUrls.forEach((item, index) => {
      if (index != remove_index) images.push(item);
    });

    //console.log(images);

    this.setState({ imgUrls: images, needToSaveImages: true });
  };

  handleDiscard = async () => {
    //console.log("discard");

    this.textChanges = {};

    let host = this.state.host;

    await this.handlePageChange(null, this.state.SelectedPage);

    if (host != null) {
      //console.log("discard images");

      this.handleHostChange(null, host);
    }
  };

  handleSave = async () => {
    // console.log("save");
    // console.log(this.state.SelectedPage);

    this.setState({ saving: true });

    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    //Save text

    let hostsObj = {};
    for (let i = 0; i < this.state.hosts.length; i++)
      hostsObj[this.state.hosts[i]] = true;

    let protocolLanguages = this.state.PorotocolLanguages.hasOwnProperty(
      this.state.SelectedPage
    )
      ? { ...this.state.PorotocolLanguages[this.state.SelectedPage] }
      : {};

    let dbPromises = [];

    this.textChanges[this.state.language] =
      this.extractLanguageSpecificTextData(this.state.language);
    this.machineTranslation[this.state.language] =
      this.state.machineTranslation;
    // console.log(this.textChanges);
    for (let language in this.textChanges) {
      if (!(language in protocolLanguages)) protocolLanguages[language] = true;

      if (
        this.textChanges[language].description &&
        this.textChanges[language].description !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolDescription(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].description)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolDescription(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].conventional &&
        this.textChanges[language].conventional !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolConventional(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].conventional)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolConventional(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].cultural &&
        this.textChanges[language].cultural !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCultural(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].cultural)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCultural(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].management &&
        this.textChanges[language].management !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolManagement(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].management)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolManagement(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].organic &&
        this.textChanges[language].organic !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolOrganic(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].organic)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolOrganic(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].biological &&
        this.textChanges[language].biological !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolBiological(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].biological)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolBiological(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].correctionMeasures &&
        this.textChanges[language].correctionMeasures !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCorrectionMeasures(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].correctionMeasures)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCorrectionMeasures(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].additional &&
        this.textChanges[language].additional !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolAdditional(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].additional)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolAdditional(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].commonName &&
        this.textChanges[language].commonName !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCommonName(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].commonName)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCommonName(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );
      if (
        this.textChanges[language].scientificName &&
        this.textChanges[language].scientificName !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolScientificName(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .set(this.textChanges[language].scientificName)
        );
      else
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolScientificName(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              language
            )
            .remove()
        );

      this.props.firebase
        .treatmentPorotocolMachineTranslation(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage,
          language
        )
        .set(this.machineTranslation[language]);
    }

    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolTransferingInsect(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage
        )
        .set(this.state.transferingInsect)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolClass(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage
        )
        .set(this.state.class)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolHosts(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage
        )
        .set(hostsObj)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolPublished(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage
        )
        .set(this.state.published)
    );

    Promise.all(dbPromises).then(() => {
      this.props.firebase
        .treatmentPorotocolsLanguages(
          this.props.firebase.workgroupPath,
          this.props.firebase.workgroupPath
        )
        .child(this.state.SelectedPage)
        .set(protocolLanguages);

      this.props.firebase
        .treatmentPorotocolsChangeLog(dateForSorting.toString())
        .set({
          pageID: this.state.SelectedPage,
          languages: Object.keys(this.textChanges),
          uid: this.props.firebase.auth.currentUser.uid,
        });

      this.textChanges = {};

      if (this.state.host == null || this.state.needToSaveImages == false)
        this.setState({ saving: false });
    });

    //Save images

    let showImgArray = [];
    for (
      let i = 0;
      i < this.state.imgUrls.length + this.state.addedUrls.length;
      i++
    )
      if (this.state.visImage && this.state.visImage[i] == true)
        showImgArray.push(i);

    if (this.state.host != null && this.state.needToSaveImages) {
      //TODO make it cleaner..no need to delete eeverything and upload all images again
      //console.log("delete folders in storage");

      //TODO folder reomve does not work in firebase..not deleting the folder might leave junk..
      //this.props.firebase.deleteProtocolImages(this.state.host, this.state.SelectedPage);

      let promises = [];

      for (let i = 0; i < this.state.imgUrls.length; i++) {
        let fileDest =
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" +
              this.state.host +
              "/" +
              this.state.SelectedPage +
              "/image" +
              (i + 1).toString() +
              ".jpg"
            : "diseaseInfo/" +
              this.props.firebase.workgroupPath +
              "/" +
              this.state.host +
              "/" +
              this.state.SelectedPage +
              "/image" +
              (i + 1).toString() +
              ".jpg";

        let promise = this.props.firebase.db
          .ref(
            "/storageOperations/copyFile/" +
              (parseFloat(dateForSorting) + i).toString()
          )
          .set({
            fileSourcePath: this.state.imgUrls[i].path,
            fileDestPath: fileDest,
          });

        promises.push(promise);

        let refChild = fileDest.split(".").join("");
        refChild = refChild.split("/").join("");

        promise = this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/storageURLs")
          .child(refChild)
          .remove();

        promises.push(promise);
      }

      for (let i = 0; i < this.state.addedUrls.length; i++) {
        let blob = new Blob([this.state.addedUrls[i]], { type: "image/jpeg" });
        let promise = this.props.firebase._uploadImageToFirebase(
          blob,
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" + this.state.host + "/" + this.state.SelectedPage
            : "diseaseInfo/" +
                this.props.firebase.workgroupPath +
                "/" +
                this.state.host +
                "/" +
                this.state.SelectedPage,
          "image" + (this.state.imgUrls.length + i + 1).toString() + ".jpg"
        );
        promises.push(promise);

        let refChild =
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" + this.state.host + "/" + this.state.SelectedPage
            : "diseaseInfo/" +
              this.props.firebase.workgroupPath +
              "/" +
              this.state.host +
              "/" +
              this.state.SelectedPage +
              "/" +
              "image" +
              (this.state.imgUrls.length + i + 1).toString() +
              ".jpg";

        refChild = refChild.split(".").join("");
        refChild = refChild.split("/").join("");

        promise = this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/storageURLs")
          .child(refChild)
          .remove();

        promises.push(promise);
      }

      promises.push(
        this.props.firebase.db
          .ref(
            this.props.firebase.workgroupPath +
              "/treatmentProtocols/changedSinceLastTraining"
          )
          .child(this.state.host)
          .child(this.state.SelectedPage)
          .set(true)
      );

      Promise.all(promises).then(() => {
        this.props.firebase
          .treatmentPorotocolImagesInfo(
            this.props.firebase.workgroupPath,
            this.state.SelectedPage,
            this.state.host
          )
          .set({
            folderName: this.state.SelectedPage,
            numImages: this.state.imgUrls.length + this.state.addedUrls.length,
            showImgArray: showImgArray,
            imageCluster: this.state.imageCluster,
            plantPart: this.state.plantPart,
          });

        this.setState({ needToSaveImages: false, saving: false });

        this.handleHostChange(null, null);

        //TODO folderName might be different if the page is being edited..old folder need to be deleted
      });
    } else if (this.state.host != null) {
      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/diseaseInfoImages"
        )
        .child(this.state.host)
        .child(this.state.SelectedPage)
        .child("showImgArray")
        .set(showImgArray);

      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/diseaseInfoImages"
        )
        .child(this.state.host)
        .child(this.state.SelectedPage)
        .child("imageCluster")
        .set(this.state.imageCluster);

      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/diseaseInfoImages"
        )
        .child(this.state.host)
        .child(this.state.SelectedPage)
        .child("plantPart")
        .set(this.state.plantPart);
    }
  };

  handleUpdateAddedImages = (obj) => {
    this.setState({
      addedImages: obj.images,
      addedUrls: obj.urls,
      needToSaveImages: true,
    });
  };

  handleAddHost = (event, selection) => {
    // console.log(selection);
    this.setState({ hosts: selection });
  };

  handleChangePublished = (event) => {
    this.setState({ published: event.target.checked });
  };

  handleChangeMachineTranslation = (event) => {
    this.setState({ machineTranslation: event.target.checked });
  };

  handleCommonNameChange = (event) => {
    this.setState({ commonName: event.target.value });
  };

  handleScientificNameChange = (event) => {
    this.setState({ scientificName: event.target.value });
  };

  // trainAI = () => {
  //   this.setState({lastTrained: null});
  //
  //   let nowDate = new Date();
  //   let dateForSorting = nowDate * 1;
  //   let completed = [];
  //   for (let i=0; i<this.state.imgUrls.length; i++) {
  //
  //            let listener = this.props.firebase.db.ref(this.props.firebase.workgroupPath + "/treatmentProtocols/trainedVectors/" + this.state.host + "/" + this.state.SelectedPage + "/vectors/" + i.toString());
  //
  //
  //            completed.push(false);
  //            console.log(dateForSorting + "_" + i.toString());
  //
  //            listener.remove()
  //            .then(() => {
  //            this.props.firebase.db
  //            .ref("/FBfunctionJobs/CreateVectorOnEC2")
  //            .child(dateForSorting + "_" + i.toString())
  //            .set({
  //              workgroupPath: this.props.firebase.workgroupPath,
  //              filePath: this.props.firebase.workgroupPath === "" ?
  //              "diseaseInfo/" + this.state.host + "/" + this.state.folderName + "/image" + (i + 1).toString() + ".jpg" :
  //              "diseaseInfo/" + this.props.firebase.workgroupPath + "/" + this.state.host + "/" + this.state.folderName + "/image" + (i + 1).toString() + ".jpg",
  //              databaseDest: this.props.firebase.workgroupPath + "/treatmentProtocols/trainedVectors/" + this.state.host + "/" + this.state.SelectedPage + "/vectors/" + i.toString(),
  //              serverAddress: "http://ec2-35-165-154-72.us-west-2.compute.amazonaws.com:3000"
  //            });
  //
  //            listener.on("value", result => {
  //              if (result.val()) {
  //                 completed[i]=true;
  //                 listener.off();
  //                 if (completed.every((item) => item == true))
  //                   this.props.firebase.db.ref(this.props.firebase.workgroupPath + "/treatmentProtocols/trainedVectorsDates/" + this.state.host + "/" + this.state.SelectedPage)
  //                  .set(dateForSorting);
  //              }
  //            });
  //          });
  //
  //   }
  //
  // }

  trainAI = () => {
    this.setState({ lastTrained: null });

    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/treatmentProtocols/trainedVectors/" +
          this.state.host +
          "/" +
          this.state.SelectedPage
      )
      .remove();

    let nowDate = new Date();
    let dateForSorting = nowDate * 1;
    let completed = [];
    for (let i = 0; i < this.state.imgUrls.length; i++) {
      // let listener = this.props.firebase.db.ref(this.props.firebase.workgroupPath +
      //   "/treatmentProtocols/trainedVectors/" + this.state.host +
      //   "/" + this.state.SelectedPage + "/vectors/" + i.toString());

      let listener = this.props.firebase.db
        .ref("FBfunctionResults/classify/generic_v3")
        .child(dateForSorting + "_" + i.toString());

      completed.push(false);
      console.log(dateForSorting + "_" + i.toString());

      let filename =
        this.props.firebase.workgroupPath === ""
          ? "diseaseInfo/" +
            this.state.host +
            "/" +
            this.state.folderName +
            "/image" +
            (i + 1).toString() +
            ".jpg"
          : "diseaseInfo/" +
            this.props.firebase.workgroupPath +
            "/" +
            this.state.host +
            "/" +
            this.state.folderName +
            "/image" +
            (i + 1).toString() +
            ".jpg";

      listener.remove().then(() => {
        this.props.firebase.db
          .ref("FBfunctionJobs/classify/generic")
          .child(dateForSorting + "_" + i.toString())
          .set({
            bucket: "agrio-c2539.appspot.com",
            id: dateForSorting + "_" + i.toString(),
            filename: filename,
            workgroupID: this.props.firebase.workgroupID,
            crop: this.state.host,
          });

        listener.on("value", (result) => {
          if (result.val()) {
            completed[i] = true;
            listener.off();

            this.props.firebase.db
              .ref(
                this.props.firebase.workgroupPath +
                  "/treatmentProtocols/trainedVectors/" +
                  this.state.host +
                  "/" +
                  this.state.SelectedPage +
                  "/vectors/" +
                  i.toString()
              )
              .set(result.val().vector);

            listener.remove();

            if (completed.every((item) => item == true))
              this.props.firebase.db
                .ref(
                  this.props.firebase.workgroupPath +
                    "/treatmentProtocols/trainedVectorsDates/" +
                    this.state.host +
                    "/" +
                    this.state.SelectedPage
                )
                .set(dateForSorting);
          }
        });
      });
    }
  };

  translateOnCloud = (text, field, language) => {
    return new Promise((resolve, reject) => {
      let date = new Date();
      let timestamp = date * 1;
      let _ref = this.props.firebase.db.ref(
        "/translations/protocols" +
          this.state.SelectedPage +
          field +
          timestamp +
          "_" +
          language
      );

      return _ref
        .remove()
        .then(() => {
          return _ref
            .set({ value: text, hashhack: false, finished: false })
            .then(() => {
              _ref.on("value", (snapshot2) => {
                // console.log(snapshot2.val());
                if (snapshot2.val().finished == true) {
                  _ref.off();
                  _ref.remove();

                  // console.log(snapshot2.val().value.replace(/# /g, "#"));
                  //TODO some temporery fixes to the #000 that is being changed in translation

                  this.textChanges[language][field] = snapshot2
                    .val()
                    .value.replace(/#[\s]*/g, "#");
                  this.textChanges[language][field] = this.textChanges[
                    language
                  ][field].replace(/№ /g, "#");

                  this.textChanges[language][field] = this.textChanges[
                    language
                  ][field].replace(/&#39;/g, "'");

                  // if (this.textChanges[language][field].indexOf("&#39;") != -1) {
                  //   console.log("warning");

                  //   alert("&#39; char was found in " + language);
                  // }

                  return resolve();
                }
              });
            })
            .catch((error) => {
              console.log(error);
              _ref.remove();
            });
        })
        .catch((error) => {
          console.log(error);
          _ref.remove();
        });
    });
  };

  translate = () => {
    let hov = {};
    let fields = [
      "description",
      "conventional",
      "cultural",
      "management",
      "organic",
      "biological",
      "correctionMeasures",
      "additional",
    ];
    let promises = [];

    this.textChanges["en"] = this.extractLanguageSpecificTextData(
      this.state.language
    );
    this.machineTranslation["en"] = false;

    for (let language in this.defaultLanguages)
      if (this.defaultLanguages[language] !== "en") {
        let translateTo = this.defaultLanguages[language];

        this.textChanges[translateTo] = {};

        let promise = this.props.firebase
          .treatmentPorotocolCommonName(
            this.props.firebase.workgroupPath,
            this.state.SelectedPage,
            translateTo
          )
          .once("value")
          .then((commonSnap) => {
            if (
              !commonSnap.val() ||
              commonSnap.val() === "" ||
              commonSnap.val() === "<p><br></p>"
            )
              this.textChanges[translateTo]["commonName"] =
                this.textChanges["en"]["commonName"];
            else this.textChanges[translateTo]["commonName"] = commonSnap.val();
          });

        promises.push(promise);

        this.textChanges[translateTo]["scientificName"] =
          this.textChanges["en"]["scientificName"];
        this.machineTranslation[translateTo] = true;

        for (let field_ind in fields)
          if (this.textChanges["en"][fields[field_ind]] !== "<p><br></p>") {
            hov[fields[field_ind] + "_" + translateTo] = true;
            // console.log(hov);
            promises.push(
              this.translateOnCloud(
                this.textChanges["en"][fields[field_ind]],
                fields[field_ind],
                translateTo
              )
                .then(() => {
                  // console.log(fields[field_ind] + "_" + translateTo);
                  delete hov[fields[field_ind] + "_" + translateTo];
                  // console.log(hov);
                })
                .catch((err) => console.log(err))
            );
          }
      }

    Promise.all(promises).then(() => {
      console.log("pre_finish");
      this.handleSave();
      this.setState({ languages: this.defaultLanguages });
      console.log("finish");
    });
  };

  handleToggle = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({ filterOpen: false });
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ filterOpen: false });
    } else if (event.key === "Escape") {
      this.setState({ filterOpen: false });
    }
  };

  handleChangeHostFilter = (host) => {
    if (host === "None") this.setState({ filterHost: null });
    else this.setState({ filterHost: host });

    this.setState({ filterOpen: false });
  };

  handleChangeCheckVisImage = (index) => {
    let visImage = [...this.state.visImage];
    visImage[index] = !visImage[index];
    this.setState({ visImage: visImage });
  };

  handleChangeImageCluster = (event, index) => {
    let imageCluster = [...this.state.imageCluster];
    imageCluster[index] = parseInt(event.target.value);
    this.setState({ imageCluster: imageCluster });
  };

  handlePlantPartChange = (index, selection) => {
    let plantPart = [...this.state.plantPart];
    plantPart[index] = Helper.partOptions[selection];
    this.setState({ plantPart: plantPart });
  };

  addAffiliateProducts = () => {
    for (let key in this.state.productsByActiveIngridient[
      this.state.productActiveIngridient
    ]) {
      this.props.firebase
        .affiliateProductList(
          this.props.firebase.workgroupPath,
          this.state.SelectedPage,
          key
        )
        .once("value")
        .then((products) => {
          let oldArray = products.val() ? Object.values(products.val()) : [];
          let addArray = this.state.productsByActiveIngridient[
            this.state.productActiveIngridient
          ].hasOwnProperty(key)
            ? this.state.productsByActiveIngridient[
                this.state.productActiveIngridient
              ][key]
            : [];
          let newArray = oldArray.concat(addArray);
          const object = Object.fromEntries(
            newArray.map((value, index) => ["p" + index.toString(), value])
          );

          this.props.firebase
            .affiliateProductList(
              this.props.firebase.workgroupPath,
              this.state.SelectedPage,
              key
            )
            .set(object);
        });
    }

    // console.log(
    //   this.state.productsByActiveIngridient[this.state.productActiveIngridient]
    // );
  };

  render() {
    const { classes } = this.props;

    const { selectedIndex, lightboxIsOpen } = this.state;

    const products = this.state.affiliateProducts
      ? Object.keys(this.state.affiliateProducts).join(" ")
      : null;

    const suggestions = this.state.filterHost
      ? this.state.hostPathogens[this.state.filterHost]
      : this.state.pageIDs.map((name, index) => name);

    const hostOptions = this.state.hosts.map((name, index) => name);

    const lastTrainedString = this.state.lastTrained
      ? new Date(this.state.lastTrained).toLocaleString()
      : null;

    let images = null;

    if (this.state.imgUrls)
      images = this.state.imgUrls.map((item, j) => {
        return (
          <div style={{ width: 160, height: 384 }}>
            <div
              style={{
                margin: 12,
                position: "relative",
                width: 160,
                height: 184,
              }}
              key={j}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  top: -20,
                  right: -20,
                }}
              >
                <IconButton
                  disabled={false}
                  onClick={(e) => this.handleCancelClick(j)}
                  color="inherit"
                >
                  <CancelIcon className={classes.searchBar} color={"action"} />
                </IconButton>
              </div>

              <div
                style={{ position: "absolute", zIndex: 1, top: 0, left: 0 }}
                onClick={() => this.toggleLightbox(j)}
              >
                <img
                  style={{ borderRadius: 2, maxWidth: 156, maxHeight: 190 }}
                  src={item.source.regular}
                />
              </div>
            </div>

            <div
              style={{
                margin: 12,
                position: "relative",
                width: 160,
                height: 20,
              }}
            >
              <Checkbox
                checked={this.state.visImage ? this.state.visImage[j] : false}
                onChange={() => this.handleChangeCheckVisImage(j)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            {this.props.firebase.auth.currentUser.uid ===
              "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
              <div
                style={{
                  margin: 12,
                  marginTop: 24,
                  position: "relative",
                  width: 160,
                  height: 20,
                }}
              >
                <TextField
                  id="Cluster"
                  label="Cluster"
                  fullWidth
                  rowsMax={1}
                  defaultValue=""
                  onChange={(e) => this.handleChangeImageCluster(e, j)}
                  value={
                    this.state.imageCluster ? this.state.imageCluster[j] : ""
                  }
                  variant="outlined"
                />
              </div>
            )}
            {this.props.firebase.auth.currentUser.uid ===
              "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
              <div
                style={{
                  margin: 12,
                  marginTop: 44,
                  position: "relative",
                  width: 160,
                  height: 20,
                }}
              >
                <Autocomplete
                  blurOnSelect={true}
                  forcePopupIcon={true}
                  autoSelect={true}
                  disableClearable={false}
                  maxMenuHeight={240}
                  size="small"
                  id="pp"
                  options={Object.keys(Helper.partOptions)}
                  getOptionLabel={(option) => option}
                  defaultValue={[]}
                  onChange={(event, selection) =>
                    this.handlePlantPartChange(j, selection)
                  }
                  value={
                    this.state.plantPart
                      ? Helper.partOptionsInverse[this.state.plantPart[j]]
                      : "None"
                  }
                  renderInput={(params) => (
                    <TextField
                      style={{ backgroundColor: "#ffffff" }}
                      {...params}
                      variant="outlined"
                      label=""
                      placeholder="Part"
                    />
                  )}
                />
              </div>
            )}
          </div>
        );
      });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          backgroundColor: "transparent",
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              zIndex: 5,
            }}
          >
            <Paper
              className={classes.paper}
              style={{
                zIndex: 5,
                minHeight: 70,
                margin: 24,
                overflow: "visible",
              }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid
                      style={{ display: "flex", flexDirectio: "row" }}
                      item
                      xs
                    >
                      <div style={{ flex: 3 }}>
                        <Autocomplete
                          freeSolo
                          forcePopupIcon={true}
                          autoSelect={true}
                          disableClearable={false}
                          maxMenuHeight={240}
                          size="small"
                          id="tag"
                          options={suggestions}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          onChange={this.handlePageChange}
                          value={this.state.SelectedPage}
                          renderInput={(params) => (
                            <TextField
                              style={{ backgroundColor: "#ffffff" }}
                              {...params}
                              variant="outlined"
                              label=""
                              placeholder="Search by tag"
                            />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          ref={this.anchorRef}
                          id="composition-button"
                          aria-controls={
                            this.state.filterOpen
                              ? "composition-menu"
                              : undefined
                          }
                          aria-expanded={
                            this.state.filterOpen ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={this.handleToggle}
                        >
                          <FilterListIcon />
                        </IconButton>

                        <Popper
                          open={this.state.filterOpen}
                          anchorEl={this.anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper
                                style={{
                                  zIndex: 100,
                                  height: 200,
                                  overflowY: "scroll",
                                }}
                              >
                                <ClickAwayListener
                                  onClickAway={this.handleClose}
                                >
                                  <MenuList
                                    autoFocusItem={this.state.filterOpen}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={this.handleListKeyDown}
                                  >
                                    {["None"]
                                      .concat(
                                        Object.keys(
                                          this.state.hostPathogens
                                        ).sort()
                                      )
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            onClick={() =>
                                              this.handleChangeHostFilter(item)
                                            }
                                          >
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {this.state.SelectedPage == null ? (
                  <Typography color="textSecondary" align="center">
                    No page was selected
                  </Typography>
                ) : (
                  <div
                    style={{
                      marginLeft: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      color={"primary"}
                      value={this.state.published}
                      onChange={this.handleChangePublished}
                      checked={this.state.published}
                      name="published"
                    />
                    <Typography color="textSecondary" align="center">
                      Publish
                    </Typography>
                  </div>
                )}
              </div>
            </Paper>

            <Paper
              className={classes.paper}
              style={{
                zIndex: 3,
                minHeight: 70,
                margin: 24,
                overflow: "visible",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  margin: 12,
                }}
              >
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    disableClearable
                    freeSolo
                    forcePopupIcon={true}
                    autoSelect={true}
                    size="small"
                    id="languages"
                    options={this.state.languages}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={this.handleLanguageChange}
                    value={this.state.language}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Language"
                      />
                    )}
                  />
                </div>
                {this.props.firebase.auth.currentUser.uid ===
                  "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                  <div
                    style={{
                      marginLeft: 0,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      color={"primary"}
                      value={this.state.machineTranslation}
                      onChange={this.handleChangeMachineTranslation}
                      checked={this.state.machineTranslation}
                      name="machineTranslation"
                    />
                    <Typography color="textSecondary" align="center">
                      Machine translated
                    </Typography>
                  </div>
                )}
                {this.props.firebase.auth.currentUser.uid ===
                  "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                  <div style={{ marginTop: 6, marginLeft: 4 }}>
                    <Button
                      disabled={this.state.language !== "en"}
                      onClick={this.translate}
                      style={{ marginRight: 5 }}
                      size="medium"
                      className={classes.primary}
                    >
                      Translate
                    </Button>
                  </div>
                )}
              </div>
            </Paper>

            <Paper
              className={classes.paper}
              style={{
                zindex: 1,
                display: "flex",
                flex: 1,
                margin: 24,
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  margin: 12,
                }}
              >
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    limitTags={1}
                    size="small"
                    id="Class"
                    options={[
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Nematodes",
                      "Abiotic Stress",
                      "Genetic related issues",
                      "Oomycete",
                    ]}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    value={this.state.class}
                    onChange={this.handleClassChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Class"
                      />
                    )}
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <TextField
                    id="Common Name"
                    label="Common Name"
                    fullWidth
                    rowsMax={1}
                    defaultValue=""
                    onChange={this.handleCommonNameChange}
                    value={this.state.commonName}
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <TextField
                    id="Scientific Name"
                    label="Scientific Name"
                    fullWidth
                    rowsMax={1}
                    defaultValue=""
                    onChange={this.handleScientificNameChange}
                    value={this.state.scientificName}
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    id="multiple-limit-tags"
                    options={cropOptions} //need a list of all crops
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    value={this.state.hosts}
                    onChange={this.handleAddHost}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Potential hosts"
                      />
                    )}
                    freeSolo
                  />
                </div>
              </div>
            </Paper>
          </div>

          <Paper
            className={classes.paper}
            style={{
              zIndex: 1,
              display: "flex",
              flex: 3,
              margin: 24,
              backgroundColor: "#ffffff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                margin: 12,
              }}
            >
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Short description
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Nematodes",
                      "Abiotic Stress",
                      "Genetic related issues",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.description}
                  onChange={this.onChangeDescription}
                />
              </div>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  lineHeight: 3,
                  textAlign: "left",
                  color: "#000000",
                }}
              >
                Control measures
              </Typography>

              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Introduction
                  </Typography>
                </div>

                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Nematodes",
                      "Abiotic Stress",
                      "Genetic related issues",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.management}
                  onChange={this.onChangeManagement}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Cultural
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Nematodes",
                      "Abiotic Stress",
                      "Genetic related issues",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.cultural}
                  onChange={this.onChangeCultural}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Biological
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nematodes",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.biological}
                  onChange={this.onChangeBiological}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Organic
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nematodes",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.organic}
                  onChange={this.onChangeOrganic}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Conventional
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nematodes",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  ref={this.setEditorConventional}
                  editorState={this.state.conventional}
                  onChange={this.onChangeConventional}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Correction Measures
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Abiotic Stress",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.correctionMeasures}
                  onChange={this.onChangeCorrectionMeasures}
                />
              </div>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -13,
                    marginLeft: 10,
                    padding: 4,
                    backgroundColor: "#ffffff",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#000000",
                    }}
                  >
                    Additional
                  </Typography>
                </div>
                <Editor
                  readOnly={
                    [
                      "Viruses",
                      "Insects",
                      "Bacteria",
                      "Fungi",
                      "Arachnida",
                      "Nutritional disorders",
                      "Physiological Disorder",
                      "Man-made injuries",
                      "Nematodes",
                      "Abiotic Stress",
                      "Genetic related issues",
                      "Oomycete",
                    ].indexOf(this.state.class) == -1
                  }
                  editorState={this.state.additional}
                  onChange={this.onChangeAdditional}
                />
              </div>
            </div>
          </Paper>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              zIndex: 5,
            }}
          >
            <Paper
              className={classes.paper}
              style={{ minHeight: 110, margin: 24, overflow: "visible" }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Autocomplete
                        disableClearable={false}
                        maxMenuHeight={240}
                        size="small"
                        id="cropSelector"
                        options={
                          this.state.filterHost
                            ? [this.state.filterHost]
                            : hostOptions
                        }
                        getOptionLabel={(option) => option}
                        defaultValue={[]}
                        onChange={this.handleHostChange}
                        value={this.state.host}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: "#ffffff" }}
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder="Search by crop name"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {this.state.host == null ? (
                  <Typography color="textSecondary" align="center">
                    No crop was selected
                  </Typography>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        marginTop: 6,
                        marginLeft: 16,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={
                            Lists.cropIcons.hasOwnProperty(this.state.host)
                              ? Lists.cropIcons[this.state.host]
                              : require("../../Assests/crop_image_unavailable_illustration.png")
                          }
                          style={{ borderRadius: 25 }}
                          width={50}
                          height={50}
                        />
                      </div>
                      <div style={{ margin: 12 }}>
                        <Typography color="textPrimary" align="center">
                          {this.state.host}
                        </Typography>
                      </div>
                    </div>

                    {this.state.lastTrained == null ? null : (
                      <div style={{ margin: 12 }}>
                        {this.props.firebase.auth.currentUser.uid ===
                          "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                          <Button
                            disabled={
                              this.state.published == false &&
                              this.state.SelectedPage !== "Healthy"
                            }
                            onClick={this.trainAI}
                            style={{ marginRight: 5 }}
                            size="medium"
                            className={classes.primary}
                          >
                            Train AI
                          </Button>
                        )}
                        {this.state.lastTrained != -1 && (
                          <div>
                            <Typography color="textPrimary" align="left">
                              Last trained
                            </Typography>
                            <Typography color="textSecondary" align="left">
                              {lastTrainedString}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Paper>
          </div>

          <Paper
            className={classes.paper}
            style={{
              display: "flex",
              flex: 3,
              margin: 24,
              backgroundColor: "#ffffff",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div style={{ margin: 12 }}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    lineHeight: 3,
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Image galary
                </Typography>
              </div>
              <div style={{ display: "flex" }} class="scroll">
                {images}

                <MediaCapture
                  callback={this.handleUpdateAddedImages}
                  maxImages={20}
                  images={this.state.addedImages}
                  urls={this.state.addedUrls}
                  classes={this.props.classes}
                  boxStyle={{
                    margin: 12,
                    borderRadius: 2,
                    width: 160,
                    height: 194,
                  }}
                />
              </div>
            </div>
          </Paper>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              zIndex: 5,
            }}
          >
            <Paper
              className={classes.paper}
              style={{ minHeight: 110, margin: 24, overflow: "visible" }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Autocomplete
                        disableClearable={false}
                        maxMenuHeight={240}
                        size="small"
                        id="countrySelector"
                        options={["US", "GB", "AU", "IT", "ES", "FR"]}
                        getOptionLabel={(option) => option}
                        defaultValue={[]}
                        onChange={this.handleProductCountryChange}
                        value={this.state.productCountry}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: "#ffffff" }}
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder="Search by country code"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {this.state.productCountry == null ? (
                  <Typography color="textSecondary" align="center">
                    No country was selected
                  </Typography>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        marginTop: 6,
                        marginLeft: 16,
                      }}
                    >
                      <div style={{ margin: 12 }}>
                        <Typography color="textPrimary" align="center">
                          {this.state.productCountry}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Paper>
          </div>

          <Paper
            className={classes.paper}
            style={{
              display: "flex",
              flex: 3,
              margin: 24,
              backgroundColor: "#ffffff",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div style={{ margin: 12 }}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    lineHeight: 3,
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Products
                </Typography>
              </div>
              <div style={{ display: "flex" }} class="scroll">
                {products}
                <div style={{ width: 200 }}>
                  <Autocomplete
                    disableClearable={false}
                    maxMenuHeight={240}
                    size="small"
                    id="productActiveingSelector"
                    options={
                      this.state.productsByActiveIngridient
                        ? Object.keys(this.state.productsByActiveIngridient)
                        : []
                    }
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={this.handleProductActiveIngridientChange}
                    value={this.state.productActiveIngridient}
                    renderInput={(params) => (
                      <TextField
                        style={{ backgroundColor: "#ffffff" }}
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Search by active ingridient"
                      />
                    )}
                  />
                </div>
                {this.state.productActiveIngridient && (
                  <div
                    onClick={this.addAffiliateProducts}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 200,
                      height: 200,
                    }}
                  >
                    <AddIcon style={{ height: "80", width: "auto" }} />
                  </div>
                )}
              </div>
            </div>
          </Paper>
        </div>

        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            zIndex: 100,
            width: "100%",
            height: 50,
          }}
        >
          <Paper
            className={classes.paper}
            square={true}
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 20,
              width: "100%",
              height: 50,
              backgroundColor: "#ffffff",
            }}
          >
            <Button
              disabled={this.state.SelectedPage == null}
              onClick={this.handleDiscard}
              style={{ marginRight: 5 }}
              size="medium"
              className={classes.primary}
            >
              Discard Changes
            </Button>
            <Button
              disabled={this.state.SelectedPage == null || this.state.saving}
              onClick={() => {
                this.handleSave();
              }}
              variant="contained"
              size="medium"
              color="primary"
              className={classes.primary}
            >
              {this.state.saving ? "Saving..." : "Save"}
            </Button>
          </Paper>
        </div>

        <div style={{ height: 60 }} />

        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.toggleLightbox}>
              <Carousel
                currentIndex={selectedIndex}
                frameProps={{ autoSize: "height" }}
                views={this.state.imgUrls}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(
  withFirebase(TreatmentProtocolsPage)
);
