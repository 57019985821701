import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numAlerts: null,
    };
  }

  async componentDidMount() {
    // console.log("mount");
    this.prepareAlerts()
      .then((result) => {
        // console.log(this.state);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    // console.log("unmount");
  }

  sendLocationBasedAlert = () => {
    let nowDate = new Date();
    let timestamp = (nowDate * 1).toString();

    var filteredUsers = [],
      crops = [],
      locations = [],
      fieldIDs = [];

    for (let i = 0; i < this.state.alertedUserIds.length; i++) {
      filteredUsers.push(this.state.alertedUserIds[i]);
      crops.push(this.state.alertedCrops[i]);
      locations.push(this.state.alertedLocations[i]);

      fieldIDs.push(this.state.alertedFieldIDs[i]);

      // if (workgroupPath === "" && alertedSubscribed[i] == false)
      //   this._setDatabase("/ReceivedFreeAlert/" + alertedUserIds[i], {
      //     date: nowDate * 1,
      //   });
    }

    let alertPath =
      this.props.firebase.workgroupPath + "/locationBasedAlert/" + timestamp;
    //TODO in the server functions need to write the fieldID to the users incoming alert

    console.log(alertPath);
    console.log({
      reportingUser: this.props.uid,
      userIds: filteredUsers,
      crops: crops,
      alertItem: this.props.alertItem,
      locations: locations,
      radius: this.props.alertRadius,
      fieldIDs: fieldIDs,
    });
    this.props.firebase._setDatabase(
      alertPath,
      {
        reportingUser: this.props.uid,
        userIds: filteredUsers,
        crops: crops,
        alertItem: this.props.alertItem,
        locations: locations,
        radius: this.props.alertRadius,
        fieldIDs: fieldIDs,
      },
      1000
    );

    this.props.handleAlertSent();

    //TODO add some conformation that it was sent before reseting
    this.setState({ numAlerts: null });
  };

  prepareAlerts = async () => {
    return new Promise(async (resolve) => {
      let promises = [];
      let diseaseHosts = await this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/treatmentProtocols/hosts")
        .child(this.props.alertItem)
        .once("value")
        .then((result) => {
          return Object.keys(result.val());
        })
        .catch((error) => {
          console.log(error);
        });

      console.log(this.props);

      let alertedUserIds = [],
        alertedCrops = [],
        alertedLocations = [],
        alertedFieldIDs = [],
        numAlerts = 0,
        numQueries = 0,
        numFinsihedQueries = 0;

      if (this.props.latitude === "") {
        this.setState({ numAlerts: 0 });
        return resolve(null);
      }

      let geoQuery = this.props.firebase.geofireRef.query({
        center: [this.props.latitude, this.props.longitude],
        radius: this.props.alertRadius,
      });

      geoQuery.on("key_entered", (key, location, distance) => {
        // console.log(key);
        numQueries = numQueries + 1;

        let splittedKey = key.split("_");
        let Location = location;

        if (splittedKey[0] != this.props.uid) {
          // console.log("user : ", splittedKey);

          let fieldID = splittedKey[1].toString();

          let promise = this.props.firebase
            .getFieldInfoFromUserIDandIndex(
              this.props.firebase.workgroupPath,
              splittedKey[0],
              fieldID
            )
            .then((result) => {
              // console.log(result);
              if (result && diseaseHosts.indexOf(result.crop) != -1) {
                numAlerts = numAlerts + 1;

                alertedCrops.push(result.crop);
                alertedFieldIDs.push(fieldID);
                alertedUserIds.push(splittedKey[0]);
                alertedLocations.push({
                  latitude: Location[0],
                  longitude: Location[1],
                });
              }

              numFinsihedQueries = numFinsihedQueries + 1;
            })
            .catch((err) => console.log(err));
          promises.push(promise);
        } else {
          numFinsihedQueries = numFinsihedQueries + 1;
        }
      });

      geoQuery.on("ready", () => {
        // console.log(alertedUserIds);
        Promise.all(promises).then(() => {
          geoQuery.cancel();

          this.setState(
            {
              alertedUserIds: alertedUserIds,
              alertedCrops: alertedCrops,
              numAlerts: numAlerts,
              alertedLocations: alertedLocations,
              alertedFieldIDs: alertedFieldIDs,
            },
            () => {
              return resolve();
            }
          );
        });
      });
    });
  };

  render() {
    if (this.state.numAlerts !== null)
      return (
        <Button onClick={this.sendLocationBasedAlert} color="primary">
          {"Send " + this.state.numAlerts + " Alerts"}
        </Button>
      );
    else
      return (
        <Typography
          style={{
            margin: 4,
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#000000",
          }}
        >
          Proccessing Alerts...
        </Typography>
      );
  }
}

export default withFirebase(Alerts);
