import React, { PureComponent, Fragment } from "react";

import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import MetaTags from "react-meta-tags";

import { SignUpForm } from "../SignUp/index.js";
import { SignInForm } from "../SignIn/index.js";

import Navigation from "../Navigation/index.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import { SignUpLink } from '../SignUp';
import * as ROUTES from "../../constants/routes.js";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Background from "../../Assests/Background.png";
import Typography from "@material-ui/core/Typography";

import Checkout from "../Checkout/Checkout.js";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";

import * as Helper from "../../Helper.js";

import { withFirebase } from "../Firebase/index.js";
import { withAuthorization } from "../Session/index.js";

import { withTranslation } from "react-i18next";
import i18next from "i18next";

var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

const styles = (theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 2, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.6)"
        : theme.palette.grey[700],
    fontFamily: "Roboto",
  },
  cardBody: {
    height: 700,
    backgroundColor:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.5)"
        : theme.palette.grey[700],
    fontFamily: "Roboto",
  },
  actions: {
    backgroundColor:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.5)"
        : theme.palette.grey[700],
    fontFamily: "Roboto",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1),
  },
});

class AccountDeletion extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      signupModalOpen: false,

      requestRecivedDialog: false,
      showButton: true,
    };
  }

  componentDidMount() {
    // console.log("mount");
    // console.log(modalOpen);
    // console.log(packageTypeMem);
  }

  componentWillUnmount() {
    //console.log("unmount");
  }

  handleSendRequest = () => {
    if (this.props.firebase.auth.currentUser == null) {
      this.setState({ signupModalOpen: true });
    } else {
      let date = new Date();
      let timestamp = date * 1;
      this.props.firebase._setDatabase(
        "deleteRequest/" + this.props.firebase.auth.currentUser.uid,
        { timestamp: timestamp },
        1000
      );

      this.setState({ showButton: false, requestRecivedDialog: true });
    }
  };

  handleRequestRecivedDialogClose = () => {
    this.setState({ requestRecivedDialog: false });
    this.props.history.push(ROUTES.LANDING);
  };

  handleCloseSignUp = () => {
    //cant set state since the component unmounted and mounted once the user logs in

    this.setState({ signupModalOpen: false });
  };

  handleSignUpComplete = () => {
    this.handleCloseSignUp();
    this.handleSendRequest();
  };

  render() {
    const { classes, t } = this.props;

    //console.log(this.props.premiumLevel);

    return (
      <Fragment>
        <MetaTags>
          <title>Agrio | Account and data deletion</title>
          <meta
            id="meta-description"
            name="description"
            content="App that identifies plant disease and pest. Allows growers to monitor fields with satellites and identify problems early. We have affordable plans for farmers, field inspectors, and food producers. Please select the package that is best suitable for your needs. We also offer plant disease api."
          />
          <meta
            id="og-title"
            property="og:title"
            content="Agrio | Account and data deletion"
          />
          <meta
            id="og-description"
            property="og:title"
            content="App that identifies plant disease and pest. Allows growers to monitor fields with satellites and identify problems early. We have affordable plans for farmers, field inspectors, and food producers. Please select the package that is best suitable for your needs. We also offer plant disease api."
          />
        </MetaTags>

        <section style={sectionStyle}>
          <div style={{ width: "100%" }}>
            <Navigation type={"minimal"} />
          </div>

          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                color: "#ffffff",
              }}
              component="h1"
              variant="h2"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              {"Account and Data Deletion"}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontWeight: "normal",
                color: "#ffffff",
              }}
              variant="h5"
              align="left"
              component="p"
            >
              {
                "We value your privacy and control over your personal data. If you wish to have your account and associated data deleted from our systems, please press the button bellow."
              }
            </Typography>

            <div style={{ marginTop: 50, height: "67px" }}>
              <Button
                disabled={this.state.showButton === false}
                onClick={this.handleSendRequest}
                style={{
                  fontSize: 16,
                  font: "Roboto",
                  color: "#ffffff",
                  borderRadius: 4,
                  marginHorizontal: 20,
                  height: "60px",
                  backgroundColor:
                    this.state.showButton === false ? "#9b9b9b" : "#01496D",
                }}
                variant="contained"
              >
                Delete my account and data
              </Button>
            </div>
          </Container>
          {/* End hero unit */}
          <Container component="main">
            <Grid
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              container
              spacing={1}
              alignItems="flex-end"
            ></Grid>
          </Container>

          <Grid
            style={{
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={2}
          >
            <Grid item xs={24}>
              <div style={{ marginTop: this.state.isMobile ? "0px" : "80px" }}>
                <p
                  style={{
                    marginTop: "150px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2019 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    {t("termsOfUse")}
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    {t("privacyPolicy")}{" "}
                  </Link>
                  <a> | </a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>

        <Dialog
          PaperProps={{
            style: { backgroundColor: "transparent" },
          }}
          open={this.state.signupModalOpen}
          onClose={this.handleCloseSignUp}
        >
          <div style={{ padding: 20, backgroundColor: "rgb(63,201,101,0.9)" }}>
            <div style={{}}>
              <SignInForm
                handleSignUpComplete={this.handleSignUpComplete}
                handleChangeToSignUp={null}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.requestRecivedDialog}
          onClose={this.handleRequestRecivedDialogClose}
        >
          <DialogTitle id="form-dialog-title">{"Request Received"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ display: "flex", flex: 5, margin: "20px" }}>
                {"Thank you for sending your request"}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  //return !(!authUser || (userType!=="admin" && premiumLevel!=="Pro"))
  return true;
};

export default withTranslation()(
  withStyles(styles)(
    withAuthorization(condition)(withFirebase(AccountDeletion))
  )
);
