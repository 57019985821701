const icon1 = require("../Assests/crops/acai_palm.png");
const icon2 = require("../Assests/crops/alfalfa.png");
const icon3 = require("../Assests/crops/almonds.png");
const icon4 = require("../Assests/crops/apple.png");
const icon5 = require("../Assests/crops/apricot.png");
const icon6 = require("../Assests/crops/artichoke.png");
const icon7 = require("../Assests/crops/avocado.png");
const icon8 = require("../Assests/crops/banana.png");
const icon9 = require("../Assests/crops/barley.png");
const icon10 = require("../Assests/crops/basil.png");
const icon11 = require("../Assests/crops/bean.png");
const icon12 = require("../Assests/crops/beet.png");
const icon13 = require("../Assests/crops/bermuda_grass.png");
const icon14 = require("../Assests/crops/bitter_gourd.png");
const icon15 = require("../Assests/crops/bitter_melon.png");
const icon16 = require("../Assests/crops/bitter_squash.png");
const icon17 = require("../Assests/crops/blueberry.png");
const icon18 = require("../Assests/crops/broccoli.png");
const icon19 = require("../Assests/crops/cabbage.png");
const icon20 = require("../Assests/crops/calabash.png");
const icon21 = require("../Assests/crops/cannabis.png");
const icon22 = require("../Assests/crops/cantaloupe.png");
const icon23 = require("../Assests/crops/carambola.png");
const icon24 = require("../Assests/crops/carrot.png");
const icon25 = require("../Assests/crops/cassava.png");
const icon26 = require("../Assests/crops/cauliflower.png");
const icon27 = require("../Assests/crops/celery.png");
const icon28 = require("../Assests/crops/cherry.png");
const icon29 = require("../Assests/crops/chickpea.png");
const icon30 = require("../Assests/crops/chili.png");
const icon31 = require("../Assests/crops/chrysanthemum.png");
const icon32 = require("../Assests/crops/citrus.png");
const icon33 = require("../Assests/crops/cocoa_bean.png");
const icon34 = require("../Assests/crops/coconut_palm.png");
const icon35 = require("../Assests/crops/coffee_bean.png");
const icon36 = require("../Assests/crops/coriander.png");
const icon37 = require("../Assests/crops/corn.png");
const icon38 = require("../Assests/crops/cotton.png");
const icon39 = require("../Assests/crops/cucumber.png");
const icon40 = require("../Assests/crops/date_palm.png");
const icon41 = require("../Assests/crops/dill.png");
const icon42 = require("../Assests/crops/eggplant.png");
const icon43 = require("../Assests/crops/fig.png");
const icon44 = require("../Assests/crops/garlic.png");
const icon45 = require("../Assests/crops/geranium.png");
const icon46 = require("../Assests/crops/grape.png");
const icon47 = require("../Assests/crops/guava.png");
const icon48 = require("../Assests/crops/hibiscus.png");
const icon49 = require("../Assests/crops/hops.png");
const icon50 = require("../Assests/crops/kiwi.png");
const icon51 = require("../Assests/crops/lettuce.png");
const icon52 = require("../Assests/crops/litchi.png");
const icon53 = require("../Assests/crops/mango.png");
const icon54 = require("../Assests/crops/mint.png");
const icon55 = require("../Assests/crops/myrtle.png");
const icon56 = require("../Assests/crops/nectarine.png");
const icon57 = require("../Assests/crops/oat.png");
const icon58 = require("../Assests/crops/oil_palm.png");
const icon59 = require("../Assests/crops/olives.png");
const icon60 = require("../Assests/crops/onion.png");
const icon61 = require("../Assests/crops/oregano.png");
const icon62 = require("../Assests/crops/ornamental_palm.png");
const icon63 = require("../Assests/crops/papaya.png");
const icon64 = require("../Assests/crops/parsley.png");
const icon65 = require("../Assests/crops/passion_fruit.png");
const icon66 = require("../Assests/crops/pea.png");
const icon67 = require("../Assests/crops/peach.png");
const icon68 = require("../Assests/crops/peanuts.png");
const icon69 = require("../Assests/crops/pear.png");
const icon70 = require("../Assests/crops/pecan.png");
const icon71 = require("../Assests/crops/pepper.png");
const icon72 = require("../Assests/crops/persimmon.png");
const icon73 = require("../Assests/crops/petunia.png");
const icon74 = require("../Assests/crops/plum.png");
const icon75 = require("../Assests/crops/pomegranate.png");
const icon76 = require("../Assests/crops/potato.png");
const icon77 = require("../Assests/crops/pumpkin.png");
const icon78 = require("../Assests/crops/raspberry.png");
const icon79 = require("../Assests/crops/rice.png");
const icon80 = require("../Assests/crops/roses.png");
const icon81 = require("../Assests/crops/sage.png");
const icon82 = require("../Assests/crops/sorghum.png");
const icon83 = require("../Assests/crops/soy.png");
const icon84 = require("../Assests/crops/spinach.png");
const icon85 = require("../Assests/crops/squash.png");
const icon86 = require("../Assests/crops/strawberry.png");
const icon87 = require("../Assests/crops/sugarcane.png");
const icon88 = require("../Assests/crops/sunflower.png");
const icon89 = require("../Assests/crops/sweet_potato.png");
const icon90 = require("../Assests/crops/tarragon.png");
const icon91 = require("../Assests/crops/tea.png");
const icon92 = require("../Assests/crops/tobacco.png");
const icon93 = require("../Assests/crops/tomato.png");
const icon94 = require("../Assests/crops/turnip.png");
const icon95 = require("../Assests/crops/vanilla.png");
const icon96 = require("../Assests/crops/walnut.png");
const icon97 = require("../Assests/crops/watermelon.png");
const icon98 = require("../Assests/crops/wheat.png");
const icon99 = require("../Assests/crops/zucchini.png");

export const cropIcons = {
"Acai palm": icon1,
"Alfalfa": icon2,
"Almonds": icon3,
"Apple": icon4,
"Apricot": icon5,
"Artichoke": icon6,
"Avocado": icon7,
"Banana": icon8,
"Barley": icon9,
"Basil": icon10,
"Bean": icon11,
"Beet": icon12,
"Bermuda grass": icon13,
"Bottle gourd": icon14,
"Bitter gourd": icon14,
"Bitter melon": icon15,
"Bitter squash": icon16,
"Blueberry": icon17,
"Broccoli": icon18,
"Cabbage": icon19,
"Calabash": icon20,
"Cannabis": icon21,
"Cantaloupe": icon22,
"Carambola": icon23,
"Carrot": icon24,
"Cassava": icon25,
"Cauliflower": icon26,
"Celery": icon27,
"Cherry": icon28,
"Chickpea": icon29,
"Chilli": icon30,
"Chrysanthemum": icon31,
"Citrus": icon32,
"Cocoa": icon33,
"Coconut palm": icon34,
"Coffee": icon35,
"Coriander": icon36,
"Corn": icon37,
"Cotton": icon38,
"Cucumber": icon39,
"Dates": icon40,
"Dill": icon41,
"Eggplant": icon42,
"Fig": icon43,
"Garlic": icon44,
"Geranium": icon45,
"Grape": icon46,
"Guava": icon47,
"Hibiscus": icon48,
"Hops": icon49,
"Kiwi": icon50,
"Lettuce": icon51,
"Litchi": icon52,
"Mango": icon53,
"Mint": icon54,
"Myrtle": icon55,
"Nectarine": icon56,
"Oat": icon57,
"Oil palm": icon58,
"Olives": icon59,
"Onion": icon60,
"Oregano": icon61,
"Ornamental palm": icon62,
"Papaya": icon63,
"Parsley": icon64,
"Passion fruit": icon65,
"Pea": icon66,
"Peach": icon67,
"Peanuts": icon68,
"Pear": icon69,
"Pecan": icon70,
"Pepper": icon71,
"Persimmon": icon72,
"Petunia": icon73,
"Plum": icon74,
"Pomegranate": icon75,
"Potato": icon76,
"Pumpkin": icon77,
"Raspberry": icon78,
"Rice": icon79,
"Roses": icon80,
"Sage": icon81,
"Sorghum": icon82,
"Soy": icon83,
"Spinach": icon84,
"Squash": icon85,
"Strawberry": icon86,
"Sugarcane": icon87,
"Sunflower": icon88,
"Sweet potato": icon89,
"Tarragon": icon90,
"Tea": icon91,
"Tobacco": icon92,
"Tomato": icon93,
"Turnip": icon94,
"Vanilla": icon95,
"Walnut": icon96,
"WaterMelon": icon97,
"Wheat": icon98,
"Zucchini": icon99,
}
