import PropTypes from "prop-types";


var drawingLoaded;
var pinID;

const initDrawing = () => {
  drawingLoaded = false;
  pinID = 0;
}

const getCoordsFromPath = (path) => {
  let coordinates = [];

  for (let i = 0; i < path.length; i++) {
    coordinates.push({
      lat: path.getAt(i).lat(),
      lng: path.getAt(i).lng()
    });
  }

  return coordinates
}

const getLatLng = (obj) => {
  let coordinates = {
      lat: obj.lat(),
      lng: obj.lng()
    };

  return coordinates
}

const MapDrawing = props => {
  const { map, google, callback, updateCoords, updatePins, handleSelectPin } = props;
//console.log("loading map: ");
//console.log(map);
  if (drawingLoaded == false && map) {
    //console.log("loading drawings");
    drawingLoaded = true;
    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT, //RIGHT_CENTER,
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        fillColor: "#ffffff",
        fillOpacity: 0.3,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        zIndex: 1
      }
    });
    //console.log(drawingManager);
    drawingManager.setMap(map);
//console.log("drawing");
    callback(drawingManager);

    google.maps.event.addListener(drawingManager, "polygoncomplete", function(
      polygon
    ) {
      //console.log("polygon complete");
      let path = polygon.getPath();
      let coordinates = getCoordsFromPath(path);

      updateCoords(polygon, coordinates);

      //console.log(coordinates);

      google.maps.event.addListener(path, 'set_at', function() {
        let path = polygon.getPath();
        let coordinates = getCoordsFromPath(path);
        updateCoords(polygon, coordinates);
        //console.log(coordinates);
      });

      google.maps.event.addListener(path, 'insert_at', function() {
        let path = polygon.getPath();
        let coordinates = getCoordsFromPath(path);
        updateCoords(polygon, coordinates);
        //console.log(coordinates);
      });

      drawingManager.setDrawingMode(null);
    });

    google.maps.event.addListener(drawingManager, 'markercomplete', function(marker) {

          marker.setDraggable(true);

          let markerPinID = pinID;
          let coordinates = marker.getPosition();
          updatePins(markerPinID, marker, getLatLng(coordinates));
          handleSelectPin(markerPinID);

          marker.addListener('click', function() {

            handleSelectPin(markerPinID);

          });

          google.maps.event.addListener(marker, 'dragend', function() {
            let coordinates = marker.getPosition();
            updatePins(markerPinID, marker, getLatLng(coordinates));
            //console.log(coordinates);
          });

          pinID = pinID + 1;

    });
  }

  // Do we need to render anything??
  return null;
};

MapDrawing.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object
};

export default MapDrawing;
export {initDrawing};
