import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { GeoFire } from "geofire";
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

var cachedNicknames = {};

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "agrio-c2539.firebaseapp.com",
  databaseURL: "https://agrio-c2539.firebaseio.com",
  projectId: "agrio-c2539",
  storageBucket: "agrio-c2539.appspot.com",
  messagingSenderId: "511122113245",
  appId: "1:511122113245:web:c202fb575b857ffb3b5134",
  measurementId: "G-E8Y1KCKYY1",
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.db = firebase.database();
    this.storage = firebase.storage();

    this.analytics = firebase.analytics();

    this.functions = firebase.functions();

    this.provider = new GoogleAuthProvider();
    this.provider.setCustomParameters({ prompt: "select_account" });
    this.appleProvider = new OAuthProvider("apple.com");
  }

  // Internal variables

  signInWithGoogle = () => {
    const auth = getAuth();
    return signInWithPopup(auth, this.provider);
  };

  signInWithApple = () => {
    const auth = getAuth();
    return signInWithPopup(auth, this.appleProvider);
  };

  updateWorkgroupPath = (workgroupID) => {
    this.workgroupPath = "";
    this.workgroupID = "";
    //console.log(workgroupID);
    if (workgroupID) {
      this.workgroupID = workgroupID;
      this.workgroupPath = "workgroups/" + workgroupID;
    }
  };

  updateGeoRef = (userType) => {
    try {
      let geoRef;

      if (this.workgroupID) {
        geoRef = this.workgroupPath + "/plotGeoLocations";

        this.geofireRef_forQuery = new GeoFire(firebase.database().ref(geoRef));
      } else {
        geoRef = "plotGeoLocations";

        if (userType === "admin")
          this.geofireRef_forQuery = new GeoFire(
            firebase.database().ref("/PremiumFolder/plotGeoLocations")
          );
      }

      this.geofireRef = new GeoFire(firebase.database().ref(geoRef));
    } catch (e) {
      console.log(e);
    }
  };

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth.signOut();
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  _setDatabase = (Ref, Obj, retryInterval) => {
    this.db.ref(Ref).set(Obj, (error) => {
      if (error) {
        setTimeout(() => {
          this._setDatabase(Ref, Obj, retryInterval * 2);
        }, retryInterval);
      }
    });
  };

  _updateDatabase = (Ref, Obj, retryInterval) => {
    this.db.ref(Ref).update(Obj, (error) => {
      if (error) {
        setTimeout(() => {
          this._updateDatabase(Ref, Obj, retryInterval * 2);
        }, retryInterval);
      }
    });
  };

  setLastOnline = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;
    this.db
      .ref(
        this.workgroupPath + "/lastOnline/" + this.auth.currentUser.uid + "/web"
      )
      .set(dateForSorting);
  };

  // user = uid => this.db.ref(this.workgroupPath + "/users/" + uid);
  //
  // users = () => this.db.ref(this.workgroupPath + "/users");

  treatmentPages = () => this.db.ref("treatmentPages");
  treatmentBlocks = (lang, block) =>
    this.db.ref("treatmentBlocks/" + lang + "/" + block);
  treatmentPorotocolsLanguages = (workgroupPath) =>
    this.db.ref(workgroupPath + "/treatmentProtocols/pageLanguages");
  treatmentPorotocolsChangeLog = (date) =>
    this.db
      .ref(this.workgroupPath + "/treatmentProtocols/ChangeLog")
      .child(date);
  //treatmentPages = () => this.db.ref(this.workgroupPath + "/treatmentProtocols/diseaseInfoImages");

  //["Conventional", "Cultural", "Management", "Organic", "Biological", "Correction Measures", "Additional", "name", "Transfering Insect", "class"];

  treatmentPorotocolDescription = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Description")
      .child(lang);
  treatmentPorotocolConventional = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Conventional")
      .child(lang);
  treatmentPorotocolCultural = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Cultural")
      .child(lang);
  treatmentPorotocolManagement = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Management")
      .child(lang);
  treatmentPorotocolOrganic = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Organic")
      .child(lang);
  treatmentPorotocolBiological = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Biological")
      .child(lang);
  treatmentPorotocolCorrectionMeasures = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Correction Measures")
      .child(lang);
  treatmentPorotocolAdditional = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("Additional")
      .child(lang);
  treatmentPorotocolCommonName = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("name")
      .child(lang);
  treatmentPorotocolScientificName = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("scientificName")
      .child(lang);
  treatmentPorotocolTransferingInsect = (workgroupPath, page) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("TransferingInsect");
  treatmentPorotocolClass = (workgroupPath, page) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/data")
      .child(page)
      .child("class");
  treatmentPorotocolHosts = (workgroupPath, page) =>
    this.db.ref(workgroupPath + "/treatmentProtocols/hosts").child(page);
  treatmentPorotocolImagesInfo = (workgroupPath, page, host) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/diseaseInfoImages")
      .child(host)
      .child(page);
  treatmentPorotocolPublished = (workgroupPath, page) =>
    this.db.ref(workgroupPath + "/treatmentProtocols/published").child(page);

  treatmentPorotocolMachineTranslation = (workgroupPath, page, lang) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/machineTranslation")
      .child(page)
      .child(lang);

  affiliateProductList = (workgroupPath, page, country) =>
    this.db
      .ref(workgroupPath + "/treatmentProtocols/affiliationProducts")
      .child(country)
      .child(page);

  activeMaterials = () => this.db.ref("activeMaterials");

  deleteProtocolImages = (host, pageID) => {
    this.storage.ref("diseaseInfo").child(host).child(pageID).delete();
    this.storage.ref("tmpSmalldiseaseInfo").child(host).child(pageID).delete();
  };

  notificationsReference = (workgroupPath) =>
    this.db.ref(
      workgroupPath + "/dashboardNotifications/" + this.auth.currentUser.uid
    );

  chatReference = (workgroupPath) =>
    this.db.ref(workgroupPath + "/ChatPointers/" + this.auth.currentUser.uid);

  workgroupAffiliation = (uid) =>
    this.db.ref("workgroupAffiliation/" + uid + "/choice");

  workgroupName = () => this.db.ref(this.workgroupPath + "/workgroupName/");

  postExpiration = () =>
    this.db.ref(this.workgroupPath + "/Settings/expirationTime/value");

  workgroupAdminList = (uid) => {
    if (this.workgroupPath === "") return this.db.ref("/adminList/" + uid);
    else return this.db.ref(this.workgroupPath + "/workgroupAdminList/" + uid);
  };

  // EDITORS JUST ON workgroup=="" at the moment
  workgroupExpertList = (uid) => {
    if (this.workgroupPath === "") return this.db.ref("/expertList/" + uid);
    else return false;
  };

  isWorkgroupAdmin = (uid) => {
    if (this.workgroupPath === "")
      return this.db.ref("/adminList/" + uid + "/flag");
    else
      return this.db.ref(
        this.workgroupPath + "/workgroupAdminList/" + uid + "/flag"
      );
  };

  isWorkgroupExpert = (uid) => {
    if (this.workgroupPath === "")
      return this.db.ref("/expertList/" + uid + "/flag");
    else
      return this.db.ref(
        this.workgroupPath + "/workgroupExpertList/" + uid + "/flag"
      );
  };

  organizationFarmers = () => this.db.ref(this.workgroupPath + "/members/");
  premiumFarmers = () => this.db.ref("/PremiumSubscription/");
  premium = (uid) => this.db.ref("/PremiumSubscription/" + uid);

  plotGeoLocation = (uid, fieldID) =>
    this.db.ref(
      this.workgroupPath + "/plotGeoLocations/" + uid + "_" + fieldID
    );
  premiumPlotGeoLocation = (uid, fieldID) =>
    this.db.ref("/PremiumFolder/plotGeoLocations/" + uid + "_" + fieldID);

  user2nickname = (workgroupPath, uid) => {
    if (cachedNicknames.hasOwnProperty(uid))
      return new Promise((resolve, reject) => {
        return resolve(cachedNicknames[uid]);
      });
    else
      return this.db
        .ref(workgroupPath + "/user/" + uid + "/name")
        .once("value")
        .then((snap) => {
          let name = snap.val() ? snap.val() : "";
          cachedNicknames[uid] = name;
          return name;
        });
  };

  plotList = (uid) => {
    console.log(uid);
    return this.db.ref(this.workgroupPath + "/plotList/" + uid);
  };

  NDVIdates = (uid, fieldID) =>
    this.db.ref(
      this.workgroupPath + "/remoteSensing/NDVI/" + uid + "/" + fieldID
    );
  NDVImean = (uid, fieldID, date) =>
    this.db.ref(
      this.workgroupPath +
        "/remoteSensing/NDVI_meta/" +
        uid +
        "/" +
        fieldID +
        "/" +
        date +
        "/meanNDVI"
    );
  RedEdgeMean = (uid, fieldID, date) =>
    this.db.ref(
      this.workgroupPath +
        "/remoteSensing/redEdge_meta/" +
        uid +
        "/" +
        fieldID +
        "/" +
        date +
        "/meanNDVI"
    );
  NDVImeanAll = (uid, fieldID) =>
    this.db.ref(
      this.workgroupPath + "/remoteSensing/NDVI_meta/" + uid + "/" + fieldID
    );
  RedEdgeMeanAll = (uid, fieldID) =>
    this.db.ref(
      this.workgroupPath + "/remoteSensing/redEdge_meta/" + uid + "/" + fieldID
    );

  cropGDD = (uid, fieldID) =>
    this.db.ref(
      this.workgroupPath + "/weatherForGDD/" + uid + "/" + fieldID + "/cropGDD"
    );

  //need to change based on organization
  alerts = (oid) => this.db.ref("organizations/" + oid + "/alerts");

  farmerPosts = (uid) =>
    this.db.ref(this.workgroupPath + "/user/" + uid + "/images");

  feedPosts = (Thread) =>
    this.db.ref(this.workgroupPath + "/channels/" + Thread + "/sharedWithMe/");

  imageURL = (imagePath) => this.storage.ref(imagePath);

  channelPosts = (Thread, userId, postName) =>
    this.db.ref(
      this.workgroupPath +
        "/channels/" +
        Thread +
        "/sharedWithMe/" +
        userId +
        postName
    );

  cropMetadata = (sharingUser, imageName) =>
    this.db.ref(
      this.workgroupPath +
        "/user/" +
        sharingUser +
        "/images/" +
        imageName +
        "/cropName"
    );

  _uploadImageToFirebase = (
    blob,
    folder,
    imagename,
    metadata = {
      contentType: "image/jpeg",
      cacheControl: "private, max-age=86400",
    }
  ) => {
    return new Promise((resolve, reject) => {
      // console.log(blob);
      // console.log("upload folder: " + folder + imagename);
      this.storage.setMaxUploadRetryTime(1800000); //default is 60000
      const imageRef = this.storage.ref(folder).child(imagename);

      imageRef
        .put(blob, metadata)
        .then((snapshot) => {
          // console.log("snapshot: ",snapshot.state);
          if (snapshot.state === "success") {
            let refChild = folder + imagename;
            refChild = refChild.split(".").join("");
            refChild = refChild.split("/").join("");

            imageRef.getDownloadURL().then((url: string) => {
              // console.log("url: ",url);
              this.db
                .ref(this.workgroupPath + "/storageURLs")
                .child(refChild)
                .set(url);

              resolve(url);
            });
          } else {
            // console.log("no success");
            reject({ message: "no success in storage upload" });
          }
        })
        .catch((error) => {
          // console.log(error.message);
          reject(error);
        });
    });
  };

  getStorageImg = (
    imPath,
    imageName,
    highResFlag,
    lookAtStorageURLsOnly = false
  ) => {
    return new Promise(async (resolve, reject) => {
      let ind = imageName.lastIndexOf("/");
      let smallImageName;
      if (ind === -1) smallImageName = "small_" + imageName;
      else
        smallImageName = [
          imageName.slice(0, ind + 1),
          "small_",
          imageName.slice(ind + 1),
        ].join("");

      //console.log("smallImageName: ", smallImageName);

      let smallImagePath = "tmpSmall" + imPath + "/" + smallImageName;

      // console.log(smallImagePath);

      let imagePath = imPath + "/" + imageName;

      // console.log("imagePath: ",imagePath);
      if (highResFlag === true) {
        let refChild = imagePath.split(".").join("");
        refChild = refChild.split("/").join("");

        // let snap = await this.db
        //           .ref(this.workgroupPath + "/storageURLs")
        //           .child(refChild)
        //           .once("value");

        let snap = await this.db
          .ref(this.workgroupPath + "/storageURLs")
          .child(refChild)
          .once("value")
          .catch((err) => {
            return null;
          });

        if (snap && snap.val()) {
          return resolve(snap.val());
        }

        if (lookAtStorageURLsOnly) return resolve("Not Found");

        return this.storage
          .ref(imagePath)
          .getDownloadURL()
          .then((url) => {
            return resolve(url);
          })
          .catch((error) => {
            // console.log(error.message);
            resolve("Not Found");
            return;
          });
      } else {
        let refChild = smallImagePath.split(".").join("");
        refChild = refChild.split("/").join("");

        let snap = await this.db
          .ref(this.workgroupPath + "/storageURLs")
          .child(refChild)
          .once("value")
          .catch((err) => {
            return null;
          });

        if (snap && snap.val()) {
          return resolve(snap.val());
        }

        if (lookAtStorageURLsOnly) return resolve("Not Found");

        return this.storage
          .ref(smallImagePath)
          .getDownloadURL()
          .then((url) => {
            return resolve(url);
          })
          .catch((error) => {
            // console.log(error.message);
            return this.storage
              .ref(imagePath)
              .getDownloadURL()
              .then((url) => {
                resolve(url);
                return;
              })
              .catch((err) => {
                resolve("Not Found");
                return;
              });
          });
      }
    });
  };

  getStorageAudio = (filePath, fileName, callback: (url: string) => void) => {
    let audioPath = filePath + "/" + fileName;

    let audioRef = this.storage
      .ref(audioPath)
      .getDownloadURL()
      .then((url: string) => {
        callback(url);
      })
      .catch((error) => {
        callback("Not Found");
      });

    return audioRef;
  };

  getImgMetaData = (sharingUser, imageName, callback) => {
    let ImgMetaDataPath =
      this.workgroupPath +
      "/user/" +
      sharingUser +
      "/images/" +
      imageName +
      "/";
    var metaData = {};

    this.db.ref(ImgMetaDataPath).once("value", (snapshot) => {
      let result = snapshot.val();

      if (result && result.hasOwnProperty("temp_min"))
        metaData = {
          numOfImages: result.numOfImages,
          cropName: result.cropName,
          country: result.country,
          city: result.city,
          latitude: result.latitude,
          longitude: result.longitude,
          temp: result.temp,
          temp_min: result.temp_min,
          temp_max: result.temp_max,
          humidity: result.humidity,
        };
      else if (result && result.hasOwnProperty("city"))
        metaData = {
          numOfImages: result.numOfImages,
          cropName: result.cropName,
          country: result.country,
          city: result.city,
          latitude: result.latitude,
          longitude: result.longitude,
          temp: result.temp,
          humidity: result.humidity,
        };
      else if (result)
        metaData = {
          numOfImages: result.numOfImages,
          cropName: result.cropName,
        };

      if (result && result.hasOwnProperty("gps")) metaData.gps = result.gps;

      if (result && result.hasOwnProperty("sharedIn"))
        metaData.sharedIn = result.sharedIn;

      if (result && result.hasOwnProperty("questionsInfo"))
        metaData.questionsInfo = result.questionsInfo;

      if (result && result.hasOwnProperty("imageSource"))
        metaData.imageSource = result.imageSource;

      if (
        result &&
        result.hasOwnProperty("folderGPS") &&
        result.folderGPS.date &&
        result.folderGPS.latitude &&
        result.folderGPS.longitude
      ) {
        metaData.folderGPSdate = result.folderGPS.date;
        metaData.imageSource = "folderGPS";
        metaData.latitude = result.folderGPS.latitude;
        metaData.longitude = result.folderGPS.longitude;
      }

      callback(metaData);
    });
  };

  getUserInformationReference = (userId) => {
    return this.db.ref(this.workgroupPath + "/user/" + userId + "/info");
  };

  getUserInformation = (_ref, callback) => {
    var info = "";

    _ref.on("value", (snapshot) => {
      if (snapshot.val()) {
        info = snapshot.val();

        callback(info);
      } else callback("");
    });
  };

  getUserRating = (uid, callback) => {
    let usersRating = "/userRating/" + uid;

    var Rating;

    this.db.ref(usersRating).once("value", (snapshot) => {
      if (snapshot.val()) Rating = snapshot.val().value;
      else Rating = 0;

      callback(Rating);
    });
  };

  listenUserTypeReference = (userId) => {
    return this.db.ref("/userType/" + userId);
  };

  listenUserType = (_ref, callback) => {
    _ref.on("value", (snapshot) => {
      var userType = "";

      if (snapshot.val()) {
        userType = snapshot.val().userType;
      }

      callback(userType);
    });
  };

  urlSharedReport = (id) => {
    return this.db.ref("/urlSharedReports/" + id);
  };

  scoutingReportsDates = (workgroupPath, uid) => {
    return this.db.ref(workgroupPath + "/scoutSavedReportsDates/" + uid);
  };

  scoutingReportInfo = (workgroupPath, uid, id) => {
    return this.db.ref(workgroupPath + "/scoutSavedReports/" + uid + "/" + id);
  };

  addField = (userId, item, location) => {
    let plotPath =
      this.workgroupPath + "/plotList/" + userId + "/" + item.fieldID;

    this._setDatabase(plotPath, item, 1000);

    //TODO - make recursive to reduce failures
    // console.log(
    //   userId +
    //     " location: " +
    //     " " +
    //     item.fieldID +
    //     " " +
    //     [location.latitude, location.longitude]
    // );
    this.geofireRef
      .set(userId + "_" + item.fieldID.toString(), [
        location.latitude,
        location.longitude,
      ])
      .then(
        function () {
          console.log("Provided key has been added to GeoFire");
        },
        function (error) {
          console.log("Error: " + error);
        }
      );
  };

  deleteField = (uid, fieldID, delIndex) => {
    this.geofireRef.remove(uid + "_" + fieldID).then();

    this.db
      .ref(this.workgroupPath + "/plotList")
      .child(uid)
      .child(fieldID)
      .remove();
  };

  fetchUsersInfo = async (
    workgroupPath,
    uidList,
    includeThumb,
    includeLastOnline
  ) => {
    return new Promise((resolve, reject) => {
      let indices = [];
      let nicknames = [];
      let memberTypes = [];
      let thumburls = [];
      let lastOnlineTimes = [];
      let uids = [];
      let counter = 0;
      if (uidList.length > 0)
        for (let i = 0; i < uidList.length; i++) {
          let memberType = "Farmer";

          let nickSnap = this.user2nickname(workgroupPath, uidList[i]);
          let adminSnap = this.isWorkgroupAdmin(uidList[i]).once("value");
          let expertSnap = this.isWorkgroupExpert(uidList[i]).once("value");
          let thumburlSnap = includeThumb
            ? this.getStorageImg(
                workgroupPath === ""
                  ? "images/" + uidList[i] + "/profilePic/"
                  : "images/" +
                      workgroupPath +
                      "/" +
                      uidList[i] +
                      "/profilePic/",
                "thumb_img.jpg",
                true,
                true
              )
            : null;

          let lastOnlineSnap = includeLastOnline
            ? this.db
                .ref(workgroupPath + "/lastOnline")
                .child(uidList[i])
                .once("value")
            : null;

          let promises = [nickSnap, adminSnap, expertSnap];

          if (includeThumb) promises.push(thumburlSnap);
          // promises.push(null);

          if (includeLastOnline) promises.push(lastOnlineSnap);
          // console.log(i + " waiting");
          Promise.all(promises)
            .then((data) => {
              // console.log(i + " finish");
              uids.push(uidList[i]);

              nicknames.push(data[0]);

              if (data[1].val()) memberType = "Admin";
              else if (data[2].val()) memberType = "Inspector";

              memberTypes.push(memberType);

              if (includeThumb) thumburls.push(data[3] ? data[3] : "Not Found");

              if (includeLastOnline) {
                let lastOnlineResult =
                  includeThumb === false ? data[3] : data[4];

                let maxDate = 0;

                if (lastOnlineResult.val()) {
                  let dates = Object.values(lastOnlineResult.val());

                  for (let k = 0; k < dates.length; k++)
                    if (dates[k] > maxDate) maxDate = dates[k];
                }

                // console.log(maxDate);

                lastOnlineTimes.push(maxDate);
              }

              indices.push(counter);
              counter++;

              // console.log(counter);
              // console.log(uidList.length);

              if (counter === uidList.length) {
                if (includeLastOnline)
                  indices.sort(function (a, b) {
                    return lastOnlineTimes[a] < lastOnlineTimes[b]
                      ? 1
                      : lastOnlineTimes[a] > lastOnlineTimes[b]
                      ? -1
                      : 0;
                  });

                return resolve({
                  uids: indices.map((n) => uids[n]),
                  nicknames: indices.map((n) => nicknames[n]),
                  memberTypes: indices.map((n) => memberTypes[n]),
                  thumburls: indices.map((n) => thumburls[n]),
                  lastOnlineTimes: indices.map((n) => lastOnlineTimes[n]),
                });
              }
            })
            .catch((err) => console.log(err));
        }
      else
        return resolve({
          uids,
          nicknames,
          memberTypes,
          thumburls,
          lastOnlineTimes,
        });
    });
  };

  setUserInformation = (
    workgroupID,
    userId,
    email,
    phone,
    nickname,
    expertise,
    exposure,
    numRatings,
    rating,
    aboutMe
  ) => {
    let userPath;

    if (workgroupID === "") userPath = "/user/" + userId;
    else userPath = "/workgroups/" + workgroupID + "/user/" + userId;

    let nicknamePath = "/userNickname/" + userId;
    //let userName = email.split("@");
    //userName = userName[0];
    var info = {
      nickname: nickname,
      aboutMe: aboutMe,
    };

    //have to break it otherwise I can eliminate the write rule in the root folder "user"
    this._setDatabase(userPath + "/email", email, 1000);

    this._setDatabase(userPath + "/phone", phone, 1000);

    this._setDatabase(userPath + "/name", nickname, 1000);

    this._setDatabase(userPath + "/info", info, 1000);

    this._setDatabase(
      nicknamePath,
      {
        value: nickname,
      },
      1000
    );
  };

  deleteJoiningUser = (workgroupID, uid) => {
    this.db
      .ref("/workgroups/" + workgroupID + "/members")
      .child(uid)
      .remove();
    this.db
      .ref("/workgroupAffiliation/" + uid)
      .child("choice")
      .remove();
  };

  deleteJoiningRequest = (workgroupID, uid) => {
    let requestPath = "/WGjoinRequests/" + workgroupID;

    this.db
      .ref(requestPath)
      .child(uid)
      .remove((onComplete) => {
        //console.log("request removed");

        this.db
          .ref("/user/" + uid)
          .child("pendingRequests")
          .remove((onComplete) => {
            //console.log("pending removed");
          });
      });
  };

  writeJoiningUser = (workgroupID, uid) => {
    try {
      var nowDate = new Date();

      var dateForSorting = nowDate * 1;

      this._setDatabase(
        "/workgroups/" + workgroupID + "/members/" + uid,
        {
          dateForSorting: dateForSorting,
        },
        1000
      );

      let email = "";
      let phone = "";
      let info = {};

      this.db.ref("/user/" + uid + "/phone").once("value", (snap0) => {
        this.db.ref("/user/" + uid + "/email").once("value", (snap) => {
          this.db.ref("/user/" + uid + "/info").once("value", (snap2) => {
            if (snap0.val()) phone = snap0.val();
            if (snap.val()) email = snap.val();

            if (snap2.val()) info = snap2.val();

            this.setUserInformation(
              workgroupID,
              uid,
              email,
              phone,
              info.hasOwnProperty("nickname") ? info.nickname : "",
              "",
              "",
              "",
              "",
              info.hasOwnProperty("aboutMe") ? info.aboutMe : ""
            );
          });
        });
      });

      this._setDatabase(
        "/workgroupAffiliation/" + uid,
        {
          choice: workgroupID,
        },
        1000
      );
    } catch (error) {
      console.log(error);
    }
  };

  getFieldInfoFromUserIDandIndex = (workgroupPath, uid, fieldID) => {
    console.log("getField");
    let plotPath =
      workgroupPath + "/plotList/" + uid + "/" + fieldID.toString();

    return this.db
      .ref(plotPath)
      .once("value")
      .then((snapshot) => {
        return {
          crop: snapshot.val() ? snapshot.val().crop : null,
        };
      });
  };
}

export default Firebase;
