import React, { Fragment } from "react";

import { CircularProgress } from "@material-ui/core";

import Carousel, { Modal, ModalGateway } from "react-images";

import { withFirebase } from "../Firebase";

import PlayIcon from "../../Assests/ic_play_circle_selected_24_px.png";
import PrevPlayIcon from "../../Assests/prevPlay.png";
import NextPlayIcon from "../../Assests/nextPlay.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "./index.css";

class ReportComponent extends React.PureComponent {
  state = {
    images: null,
    suggestions: null,
    products: null,
    transcripts: null,
    audio: null,
    currentAudioIndex: -1,
    selectedIndex: 0,
    lightboxIsOpen: false,
    isMobile:
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1,
    audioError: "",
  };

  componentDidMount() {
    let reportInfo = this.props.reportInfo;

    let uid = this.props.uid;

    let imagePrePath =
      this.props.firebase.workgroupPath === ""
        ? "images/"
        : "images/" + this.props.firebase.workgroupPath + "/"; //in index.js "shared_images/"
    let audioPrePath =
      this.props.firebase.workgroupPath === ""
        ? "audio/"
        : "audio/" + this.props.firebase.workgroupPath + "/"; //in index.js "shared_audio/"

    let images = null;
    let suggestions = null;
    let products = null;
    let fullres_images = null;
    let transcripts = null;
    let audio = null;

    var promises = [];

    if (reportInfo.hasOwnProperty("suggestions")) {
      suggestions = reportInfo.suggestions;
    } else suggestions = [];

    if (reportInfo.hasOwnProperty("products")) {
      products = reportInfo.products;
    } else products = [];

    if (reportInfo.hasOwnProperty("imageArray")) {
      images = new Array(reportInfo.imageArray.length);
      for (let j = 0; j < reportInfo.imageArray.length; j++) {
        let promise = this.props.firebase
          .getStorageImg(
            imagePrePath + uid,
            "thumb_" + reportInfo.imageName + "_" + (j + 1).toString() + ".jpg",
            true
          )
          .then((url) => {
            images[j] = { source: { regular: url } };
          });

        promises.push(promise);
      }
    } else images = [];

    Promise.all(promises).then(() => {
      this.setState({
        images: images,
        suggestions: suggestions,
        products: products,
      });
    });

    promises = [];

    if (reportInfo.hasOwnProperty("imageArray")) {
      //console.log(reportsArray[i]);
      fullres_images = new Array(reportInfo.imageArray.length);
      for (let j = 0; j < reportInfo.imageArray.length; j++) {
        let promise = this.props.firebase
          .getStorageImg(
            imagePrePath + uid,
            reportInfo.imageName + "_" + (j + 1).toString() + ".jpg",
            true
          )
          .then((url) => {
            fullres_images[j] = { source: { regular: url } };
          });

        promises.push(promise);
      }
    } else fullres_images = [];

    Promise.all(promises).then(() => {
      this.setState({
        fullres_images: fullres_images,
      });
    });

    promises = [];

    transcripts = "";

    if (reportInfo.hasOwnProperty("recordArray")) {
      audio = [];
      for (let j = 0; j < reportInfo.recordArray.length; j++) {
        transcripts = reportInfo.recordArray[j].hasOwnProperty("transcript")
          ? transcripts + reportInfo.recordArray[j].transcript + ". "
          : transcripts;

        if (reportInfo.recordArray[j].hasOwnProperty("filename")) {
          let promise = this.props.firebase.getStorageAudio(
            audioPrePath + uid,
            reportInfo.recordArray[j].filename,
            (url) => {
              //console.log(url);
              audio.push({ source: url });
            }
          );

          promises.push(promise);
        }
      }
    } else audio = [];

    Promise.all(promises).then(() => {
      if (this.player && audio.length > 0 && audio[0])
        this.player.src = audio[0].source;

      // console.log(audio[0].source);

      if (this.player && audio.length > 0)
        this.player.addEventListener("canplaythrough", (e) => {
          console.log(e);
          this.setState({
            duration: this.player.duration,
          });
        });

      if (this.player)
        this.player.addEventListener("timeupdate", (e) => {
          this.setState({
            currentTime: e.target.currentTime,
          });
        });

      this.setState({
        transcripts: transcripts,
        audio: audio,
        currentAudioIndex: audio.length > 0 ? 0 : -1,
      });
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.removeEventListener("timeupdate", () => {});
      this.player.removeEventListener("canplaythrough", () => {});
    }
  }

  toMMSS = function (sec) {
    var sec_num = parseInt(sec, 10);
    var minutes = Math.floor(sec_num / 60);
    var seconds = sec_num - minutes * 60;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  handlePlayStateChange = () => {
    console.log(this.player);
    this.player
      .play()
      .then(() => {
        console.log("inside");
        this.setState({ audioError: "" });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ audioError: error.message });
      });
  };

  handlePrevPlay = () => {
    let index = this.state.currentAudioIndex;
    if (index > 0) {
      this.player.src = this.state.audio[index - 1].source;
      this.setState({ currentAudioIndex: index - 1 });
    }
  };

  handleNextPlay = () => {
    let index = this.state.currentAudioIndex;
    if (index < this.state.audio.length - 1) {
      this.player.src = this.state.audio[index + 1].source;
      this.setState({ currentAudioIndex: index + 1 });
    }
  };

  renderPlayer = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {this.state.audio && this.state.audio.length > 1 ? (
          <div
            style={{
              display: "flex",
              width: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={this.handlePrevPlay}
          >
            <img style={{ height: 22 }} src={PrevPlayIcon} />
          </div>
        ) : null}
        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            width: 40,
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={this.handlePlayStateChange}
        >
          {this.state.audio && this.state.audio.length > 0 ? (
            <img style={{ height: 34 }} src={PlayIcon} />
          ) : null}

          {this.state.audio && this.state.audio.length > 1 ? (
            <a
              style={{
                marginTop: 4,
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {this.state.currentAudioIndex == -1
                ? "0/0"
                : this.state.currentAudioIndex +
                  1 +
                  "/" +
                  this.state.audio.length}
            </a>
          ) : (
            <a
              style={{
                marginTop: 10,
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            ></a>
          )}
        </div>
        {this.state.audio && this.state.audio.length > 1 ? (
          <div
            style={{
              display: "flex",
              width: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={this.handleNextPlay}
          >
            <img style={{ height: 22 }} src={NextPlayIcon} />
          </div>
        ) : null}
        {this.state.audio && this.state.audio.length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              marginLeft: 0,
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {this.state.hasOwnProperty("currentTime")
              ? this.toMMSS(this.state.currentTime)
              : "0:00"}
          </div>
        ) : null}
        {this.state.audio && this.state.audio.length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flex: 7,
              marginLeft: 8,
              height: 2,
              backgroundColor: "rgba(0, 0, 0, 0.26)",
            }}
          />
        ) : null}
        {this.state.audio && this.state.audio.length > 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
              marginLeft: 8,
              marginRight: 8,
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {this.state.hasOwnProperty("duration")
              ? this.toMMSS(this.state.duration)
              : "0:00"}
          </div>
        ) : null}

        <audio ref={(ref) => (this.player = ref)} preload="metadata" />
      </div>
    );
  };

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { selectedIndex, lightboxIsOpen } = this.state;

    let images = null;
    if (this.state.images)
      images = this.state.images.map((item, j) => {
        return (
          <div onClick={() => this.toggleLightbox(j)} key={j}>
            <img
              style={{ marginLeft: 16, borderRadius: 2, height: 97, width: 97 }}
              src={item.source.regular}
            />
          </div>
        );
      });

    let suggestions = null;
    if (this.state.suggestions)
      suggestions = this.state.suggestions.map((item, j) => {
        return (
          <div
            onClick={() =>
              window.open("https://agrio.app/library/" + item.html, "_blank")
            }
            style={{
              cursor: "pointer",
              borderRadius: 6,
              marginLeft: 12,
              marginRight: 12,
              backgroundColor: "#ecf0f3",
            }}
            key={j}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 6,
                fontWeight: "Bold",
                fontFamily: "Roboto",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.87)",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ margin: 2 }}
                src={require("../../Assests/ic_library_books_24_px.png")}
                width={17.5}
                height={17.5}
              />
              {item.name}
            </div>
          </div>
        );
      });

    let products = null;
    if (this.state.products)
      products = this.state.products.map((item, j) => {
        return (
          <div
            onClick={() => window.open(item.label, "_blank")}
            style={{
              cursor: "pointer",
              borderRadius: 6,
              marginTop: this.state.isMobile ? 12 : 0,
              marginLeft: 12,
              marginRight: 12,
              backgroundColor: "#ecf0f3",
            }}
            key={j}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: 6,
              }}
            >
              <img
                style={{ margin: 2 }}
                src={require("../../Assests/product_icon.png")}
                width={20}
                height={35}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 6,
                    fontWeight: "Bold",
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {item.englishName + " | " + item.nativeName}
                </div>
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 6,
                    marginBottom: 6,
                    fontWeight: "normal",
                    fontFamily: "Roboto",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {item.activeIngredient}
                </div>
              </div>
            </div>
          </div>
        );
      });

    return (
      <Fragment>
        <div
          style={{
            backgroundColor: "red",
            display: "flex",
            flexDirection: "column",
            marginLeft: this.state.isMobile ? "0px" : "20px",
            marginTop: this.state.isMobile ? "60px" : "0px",
            marginRight: this.state.isMobile ? "0px" : "20px",
            flex: this.state.isMobile ? 2 : 2,
            backgroundColor: "#ffffff",
          }}
        >
          <div style={{ display: "flex", flex: 2 }}>
            <div style={{ flex: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 270 }}>
                  <div
                    style={{
                      width: 140,
                      fontFamily: "Roboto",
                      marginTop: 10,
                      marginBottom: 4,
                      fontSize: 22,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    Observation
                  </div>
                  <div
                    style={{
                      width: 117,
                      height: 3,
                      backgroundColor: "#7ed321",
                    }}
                  />
                </div>
                <div>
                  <IconButton onClick={this.onClose}>
                    <CloseIcon color="inherit" />
                  </IconButton>
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  fontWeight: "500",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  color: "#e62d2a",
                }}
              >
                {this.state.audioError}
              </p>
            </div>
          </div>
          {this.state.audio && this.state.audio.length > 0 && (
            <div
              ref={(el) => (this.container = el)}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flex: 1,
                marginLeft: 16,
              }}
            >
              {this.renderPlayer()}
            </div>
          )}
          {this.state.images == null ? (
            <div
              style={{
                flex: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color={"#01496d"} />
            </div>
          ) : (
            <div class="scroll">{images}</div>
          )}

          <div style={{ flex: 1, marginLeft: 16 }}>
            <div
              style={{
                fontFamily: "Roboto",
                marginTop: 10,
                marginBottom: 4,
                fontSize: 22,
                fontWeight: "bold",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Summary:
            </div>
            <div
              style={{
                width: 117,
                height: 3,
                backgroundColor: "#7ed321",
              }}
            />
          </div>
          <div style={{ flex: 3, marginLeft: 16, marginRight: 16 }}>
            <p
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {this.state.transcripts && this.state.transcripts !== ""
                ? this.state.transcripts
                : "No summary was provided"}
            </p>
            {this.state.suggestions == null ? (
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color={"#01496d"} />
              </div>
            ) : (
              <div class="scroll">{suggestions}</div>
            )}
            {this.state.products == null ? (
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color={"#01496d"} />
              </div>
            ) : (
              <div class="scroll">{products}</div>
            )}
          </div>
        </div>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={this.toggleLightbox}>
              <Carousel
                currentIndex={selectedIndex}
                frameProps={{ autoSize: "width" }}
                views={
                  this.state.fullres_images
                    ? this.state.fullres_images
                    : this.state.images
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Fragment>
    );
  }
}

export default withFirebase(ReportComponent);
