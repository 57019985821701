import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RateReviewIcon from "@material-ui/icons/RateReview";

import MessageIcon from "@material-ui/icons/Message";
import EventIcon from "@material-ui/icons/Event";
import Timestamp from "../Timestamp";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    color: "inherit",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondary: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    "&$textDense": {
      fontSize: 12,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
});

function NotificationDrawer(props) {
  const {
    classes,
    notifications,
    handleClick,
    notifyUsers,
    adminFlag,
    numCloudyNotifs,
    numNDVINotifs,
    handleAllRead,
    ...other
  } = props;

  return (
    <Drawer variant="permanent" {...other} style={{ zIndex: 1001 }}>
      <List disablePadding>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ListItem
            style={{ flex: 2 }}
            className={classNames(
              classes.firebase,
              classes.item,
              classes.itemCategory
            )}
          >
            Notifications
          </ListItem>

          <ListItem
            button
            dense
            onClick={handleAllRead}
            style={{ flex: 1 }}
            className={classNames(
              classes.firebase,
              classes.item,
              classes.itemCategory
            )}
          >
            <ListItemText
              secondary={"Mark All as Read"}
              classes={{
                secondary: classes.itemSecondary,
              }}
              style={{ textAlign: "right" }}
            />
          </ListItem>

          {adminFlag && (
            <ListItem
              style={{ flex: 1, flexDirection: "column" }}
              button
              dense
              onClick={notifyUsers}
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory
              )}
            >
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                style={{ textAlign: "right" }}
              >
                Notify users
              </ListItemText>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                style={{ textAlign: "right" }}
              >
                ({numCloudyNotifs}/{numNDVINotifs})
              </ListItemText>
            </ListItem>
          )}
        </div>

        {Object.keys(notifications)
          .reverse()
          .map((item) => (
            <ListItem
              button
              dense
              key={item}
              onClick={() => handleClick(item, notifications[item])}
              className={classNames(classes.item, classes.itemCategory)}
              style={{
                backgroundColor:
                  notifications[item].read == false
                    ? "rgba(255,255,255,0.1)"
                    : "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignSelf: "flex-start",
                  marginTop: 4,
                }}
              >
                <ListItemIcon>
                  {notifications[item].type == 1 && <AssessmentIcon />}
                  {notifications[item].type == 2 && <AssignmentIcon />}
                  {notifications[item].type == 3 && <AssignmentIcon />}
                  {notifications[item].type == 4 && <RateReviewIcon />}
                  {notifications[item].type == 5 && <EventIcon />}
                  {notifications[item].type == 98 && <ContactMailIcon />}
                  {notifications[item].type == 99 && <MessageIcon />}
                </ListItemIcon>
              </div>
              <div
                style={{ display: "flex", flex: 6, alignSelf: "flex-start" }}
              >
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                  style={{ textAlign: "left" }}
                >
                  {notifications[item].text}
                </ListItemText>
              </div>
              <div style={{ display: "flex", flex: 2, alignSelf: "flex-end" }}>
                <ListItemText
                  secondary={Timestamp(item)}
                  classes={{
                    secondary: classes.itemSecondary,
                  }}
                  style={{ textAlign: "right" }}
                />
              </div>
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
}

NotificationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationDrawer);
