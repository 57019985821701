import React, { Component } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";

import TimerIcon from "@material-ui/icons/Timer";
import DialpadIcon from "@material-ui/icons/Dialpad";
import Assistant from "@material-ui/icons/Assistant";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import * as Lists from "../Lists.js";
import * as Helper from "../../Helper";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

//import RefreshIcon from '@material-ui/icons/Refresh';

const styles = (theme) => ({
  titlePrimary: {
    color: theme.typography.white,
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  scroll: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(100,100,100,.3)",
      outline: "1px solid white",
    },
  },
});

class SelectMembers extends Component {
  constructor() {
    super();

    this.state = {
      WGname: "",
      newWGname: "",
      workgroupLogo: null,
      postExpiration: null,
      WGnameDialogOpen: false,
      PEDialogOpen: false,
      respondersDialogOpen: false,
      labelers: null,
      memberNames: null,
      selectedObj: {},
    };
  }

  async componentDidMount() {
    let ref =
      this.props.from === "workgroup"
        ? this.props.firebase.organizationFarmers()
        : this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/plotSharing/teamByUser")
            .child(this.props.firebase.auth.currentUser.uid);
    ref.on("value", (snapshot) => {
      let membersUIDs = snapshot.val() ? Object.keys(snapshot.val()) : [];

      this.props.firebase
        .fetchUsersInfo(
          this.props.firebase.workgroupPath,
          membersUIDs,
          true,
          true
        )
        .then((info) => {
          let selectedObj = {};

          let selected = this.props.selected;

          for (let i = 0; i < selected.length; i++) {
            selectedObj[selected[i]] = true;
          }

          this.setState({
            memberUIDs: info.uids,
            memberNames: info.nicknames,
            memberTypes: info.memberTypes,
            thumburls: info.thumburls,
            selectedObj: selectedObj,
          });
        });
    });
  }

  handleChange = (event, uid) => {
    let selectedObj = { ...this.state.selectedObj };
    if (event.target.checked) selectedObj[uid] = true;
    else delete selectedObj[uid];
    this.setState({ selectedObj: selectedObj });
  };

  handleSave = () => {
    this.props.handleSave(this.state.selectedObj);
  };

  render() {
    const { classes } = this.props;

    if (this.state.memberNames && this.state.memberNames.length > 0)
      return (
        <div>
          <List
            className={classes.scroll}
            style={{ height: "55vh", overflow: "auto" }}
          >
            {this.state.memberNames.map((item, index) => {
              return (
                <ListItem button onClick={() => {}} key={index}>
                  <ListItemIcon style={{ flex: 1 }}>
                    <PersonIcon style={{ fontSize: 40 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    secondary={this.state.memberTypes[index]}
                    classes={{
                      primary: classes.titlePrimary,
                      secondary: classes.textPrimary,
                    }}
                    style={{
                      textAlign: "left",
                      flex: 6,
                      height: 60,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  />
                  <Checkbox
                    color={"primary"}
                    value={this.state.selectedObj.hasOwnProperty(
                      this.state.memberUIDs[index]
                    )}
                    onChange={(event) =>
                      this.handleChange(event, this.state.memberUIDs[index])
                    }
                    checked={this.state.selectedObj.hasOwnProperty(
                      this.state.memberUIDs[index]
                    )}
                    name="select"
                  />
                </ListItem>
              );
            })}
          </List>
          <div style={{ boxShadow: "0px -5px 10px 2px rgba(0,0,0,0.1)" }}>
            <Button
              style={{ width: "100%", textAlign: "center" }}
              disabled={
                this.props.disableIfEmpty &&
                Object.keys(this.state.selectedObj).length == 0
              }
              onClick={this.handleSave}
              color="primary"
            >
              {this.props.buttonText}
            </Button>
          </div>
        </div>
      );
    else if (this.state.memberNames && this.state.memberNames.length == 0)
      return (
        <Typography
          style={{
            margin: 20,
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: "#000000",
          }}
        >
          {this.props.emptyText}
        </Typography>
      );
    else return null;
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(
  withStyles(styles)(withFirebase(SelectMembers))
);
