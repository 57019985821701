import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as Helper from "../../Helper";

import { withFirebase } from "../Firebase";

class WorkgroupRequestDialog extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      open: false,
    };
  }

  componentDidMount() {
     this.props.onRef(this);
  }

  handleOpen = (mid, requestObj) => {
     this.setState({...requestObj,
       mid: mid,
       open: true,
     });
  }

  handleSendEmail = async () => {

    let nowDate = new Date();
    let dateForSorting = nowDate * 1;

    await this.props.firebase.db
    .ref("/FBfunctionJobs/SendGridOpenTasks/" + this.state.mid + "_1")
    .set({
      template: "d-9c0d8ac994e6463da67b72eb9a506501",
      email: this.state.email,
      whenToSend: dateForSorting + 1000 * 60 * 60 * 24 * 0
    });

    await this.props.firebase.db
    .ref("/FBfunctionJobs/SendGridOpenTasks/" + this.state.mid + "_2")
    .set({
      template: "d-7ccd5c7e2bb64ac7a0f9b33a86bdcc38",
      email: this.state.email,
      whenToSend: dateForSorting + 1000 * 60 * 60 * 24 * 7
    });

    await this.props.firebase.db
    .ref("/FBfunctionJobs/SendGridOpenTasks/" + this.state.mid + "_3")
    .set({
      template: " d-9a1c51dc7a4e4e8f8bfe6cc355cfcdcd",
      email: this.state.email,
      whenToSend: dateForSorting + 1000 * 60 * 60 * 24 * 14
    });

    await this.props.firebase.db.ref("openWorkgroupRequests_review").child(this.state.mid).child("sent").set("true");

    this.setState({open: false});
  }

  handleDeny = async () => {
     await this.props.firebase.db.ref("openWorkgroupRequests_review").child(this.state.mid).child("sent").set("no");
     this.setState({open: false});
  }

  handleClose = () => {
     this.setState({open: false});
  }


  render() {

    return (
      <div>
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Workgroup request</DialogTitle>
      <DialogContent>
        <DialogContentText>
        {this.state.firstName + " " + this.state.lastName}
        </DialogContentText>
        <DialogContentText>
        {this.state.organizationName}
        </DialogContentText>
        <DialogContentText>
        {this.state.country}
        </DialogContentText>
        <DialogContentText>
        {this.state.fieldsSize}
        </DialogContentText>
        <DialogContentText>
        {this.state.email}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleDeny} color="primary">
          Deny
        </Button>
        <Button onClick={this.handleSendEmail} color="primary">
          Send schedule
        </Button>
      </DialogActions>
    </Dialog>
      </div>
    );
  }
}

export default withFirebase(WorkgroupRequestDialog);
