import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from "./components/Firebase";

import CookieConsent from "react-cookie-consent";
import ReactPixel from "react-facebook-pixel";

import "./i18n";

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init("489147838108732", advancedMatching, options);
ReactPixel.revokeConsent();

global.referalUID = null;

// ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom(event, data); // For tracking custom events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events

// if(module.hot){
//   module.hot.accept()
// }

ReactDOM.render(
  <Suspense fallback="loading">
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
      <CookieConsent
        sameSite={"lax"}
        onAccept={ReactPixel.grantConsent}
        style={{ height: 150 }}
        location="bottom"
        cookieName="myAwesomeCookieName3"
        expires={999}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </FirebaseContext.Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
