import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Timestamp from '../Timestamp';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function FeedList(props) {
  const { classes, Entries, selectedIndex, callback } = props;
  const FeedItems = Entries.map((item, index) => (
    <ListItem style={{backgroundColor: index==selectedIndex ? "rgba(0,0,0,0.1)" : "transparent"}} onClick={() => callback(index)}>
        <img src={item.thumbUrl} style={{borderRadius: 3}} width={50} height={50}/>
      <ListItemText style={{marginLeft: 8}} primary={item.nickname} secondary={Timestamp(item.dateForSorting)}/>
    </ListItem>
  ));

  return (
    <List className={classes.root}>
      {FeedItems}
    </List>
  );
}

FeedList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedList);
