import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as Lists from "./Lists.js";
import Timestamp from "./Timestamp";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function FieldList(props) {
  const { classes, Entries, selected, searchText, callback } = props;
  const selectedRef = useRef(null);
  //console.log(selected);

  let filteredEntries = [];

  for (var i = 0; i < Entries.length; i++) {
    if (
      Entries[i].hasOwnProperty("fieldName") &&
      Entries[i].fieldName.toLowerCase().indexOf(searchText.toLowerCase()) !==
        -1
    )
      filteredEntries.push(Entries[i]);
  }

  useEffect(() => {
    // Scroll into view if the selected element exists and is not already visible
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selected]);

  const Items = filteredEntries.map((item, index) => {
    if (item.hasOwnProperty("polygon"))
      return (
        <ListItem
          ref={!(selected == null) && selected === index ? selectedRef : null}
          style={{
            backgroundColor:
              !(selected == null) && selected == index
                ? "rgba(0,0,0,0.05)"
                : "transparent",
          }}
          onClick={() => callback(null, item.fieldID)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={
                Lists.cropIcons.hasOwnProperty(item.crop)
                  ? Lists.cropIcons[item.crop]
                  : require("../Assests/crop_image_unavailable_illustration.png")
              }
              style={{ borderRadius: 3 }}
              width={50}
              height={50}
            />
          </div>
          <div style={{ marginLeft: 12 }}>
            <ListItemText
              primary={item.fieldName}
              secondary={Timestamp(item.fieldID)}
            />
          </div>
        </ListItem>
      );
    else return null;
  });

  return (
    <div style={{ overflow: "auto", height: "35vh", maxHeight: "35vh" }}>
      <List className={classes.root}>{Items}</List>
    </div>
  );
}

FieldList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FieldList);
