import React, { PureComponent } from "react";

import weatherIBM from "../weatherIBM/weatherIBM";

import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RateReviewIcon from "@material-ui/icons/RateReview";

import MessageIcon from "@material-ui/icons/Message";
import EventIcon from "@material-ui/icons/Event";
import Timestamp from "../Timestamp";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { render } from "react-dom";

import { withFirebase } from "../Firebase";

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
});

var weatherObjectArray = {};

class WeatherDrawer extends PureComponent {
  constructor() {
    super();
    this.state = {
      weatherObject: null,
      selectedDailyIndex: null,
      selectedHourlyIndex: null,
      dailyIndeciesInHourly: [],
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      ((nextProps.open == true && this.props.open == false) ||
        nextProps.tempUnits !== this.props.tempUnits) &&
      nextProps.uid &&
      nextProps.fieldID
    ) {
      let date = new Date();
      let dateForSorting = (date * 1) / 1000;

      if (
        weatherObjectArray.hasOwnProperty(
          nextProps.uid + nextProps.fieldID.toString() + nextProps.tempUnits
        ) &&
        weatherObjectArray[
          nextProps.uid + nextProps.fieldID.toString() + nextProps.tempUnits
        ]["hourly"]["expirationTimeUtc"][0] > dateForSorting
      ) {
        this.setState({
          weatherObject:
            weatherObjectArray[
              nextProps.uid + nextProps.fieldID.toString() + nextProps.tempUnits
            ],
        });
      } else {
        console.log("weather");
        this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/plotList")
          .child(nextProps.uid)
          .child(nextProps.fieldID.toString())
          .child("markerPosition")
          .once("value")
          .then((location) => {
            this.getWeatherIBMData(
              this.props.tempUnits,
              location.val().latitude,
              location.val().longitude,
              (result) => {
                console.log(result);
                weatherObjectArray[
                  nextProps.uid +
                    nextProps.fieldID.toString() +
                    this.props.tempUnits
                ] = result;
                if (result) this.setState({ weatherObject: result });
              }
            );
          });
      }
    }
  }

  getWeatherIBMData = async (tempUnits, latitude, longitude, callback) => {
    let result1 = await weatherIBM.fetchWeather(
      "weather",
      tempUnits,
      latitude,
      longitude
    );
    let result2 = await weatherIBM.fetchWeather(
      "daily",
      tempUnits,
      latitude,
      longitude
    );
    let result3 = await weatherIBM.fetchWeather(
      "hourly",
      tempUnits,
      latitude,
      longitude
    );

    // console.log(result1);
    // console.log(result2);
    // console.log(result3);

    if (result1 && result2 && result3) {
      let weatherObject = {
        weather: result1,
        daily: result2,
        hourly: result3,
      };

      return callback(weatherObject);
    } else {
      return callback(null);
      //TODO handle error
    }
  };

  selectDay = (index) => {
    // console.log("selectDay: " + index);
    // console.log(this.state.selectedDailyIndex);
    if (
      (this.state.selectedDailyIndex != null &&
        index == this.state.selectedDailyIndex) ||
      this.state.weatherObject == null ||
      this.state.weatherObject === undefined
    )
      return;

    let dailyIndeciesInHourly = [];
    let date =
      this.state.weatherObject.daily.validTimeLocal[index].split("T")[0];
    let startInd = 0;

    for (
      let i = 0;
      i < this.state.weatherObject.hourly.validTimeLocal.length;
      i++
    )
      if (
        date === this.state.weatherObject.hourly.validTimeLocal[i].split("T")[0]
      ) {
        startInd = i;
        break;
      }

    for (
      let i = startInd;
      i < this.state.weatherObject.hourly.validTimeLocal.length;
      i++
    )
      if (
        date !== this.state.weatherObject.hourly.validTimeLocal[i].split("T")[0]
      ) {
        break;
      } else dailyIndeciesInHourly.push(i);

    this.setState({
      selectedDailyIndex: index,
      dailyIndeciesInHourly: dailyIndeciesInHourly,
      selectedHourlyIndex: dailyIndeciesInHourly[0],
    });
  };

  selectHour = (index) => {
    if (index == this.state.selectedHourlyIndex) return;

    this.setState({ selectedHourlyIndex: index });
  };

  hourlyItem = (item, index) => {
    let iconName = ("0" + this.state.weatherObject.hourly.iconCode[item]).slice(
      -2
    );

    let icon =
      "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
      iconName +
      ".png?alt=media";
    let validLocalTime = this.state.weatherObject.hourly.validTimeLocal[item]
      .split("T")[1]
      .substring(0, 5);

    return (
      <div
        onClick={() => {
          this.selectHour(item);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 210,
            width: 84,
            alignItems: "center",
            backgroundColor:
              item == this.state.selectedHourlyIndex ? "#DFF1FB" : "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              borderRadius: 19,
              backgroundColor: "#DFF1FB",
              marginTop: 10,
              width: 38,
              height: 38,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={icon}
              style={{
                width: 28,
                height: 28,
              }}
            />
          </div>

          <div
            style={{
              marginTop: 6,
              height: 42,
            }}
          >
            <Typography
              style={{
                width: 80,
                fontFamily: "Roboto",
                fontSize: 12,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#9b9b9b",
              }}
            >
              {this.state.weatherObject.hourly.wxPhraseLong[item]}
            </Typography>
          </div>
          <div style={{ height: 32 }}>
            <Typography
              style={{
                width: 28,
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: -0.2,
                textAlign: "center",
                color: "#707176",
              }}
            >
              {this.state.weatherObject.hourly.temperature[item]}°
              {this.props.tempUnits}
            </Typography>
          </div>
          {this.state.weatherObject.hourly.qpf[item] > 0 ? (
            <div style={{ height: 30 }}>
              <Typography
                style={{
                  height: 15,
                  fontFamily: "Roboto",
                  fontSize: 10,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: -0.2,
                  textAlign: "center",
                  color: "#2A7EA1",
                }}
              >
                ({this.state.weatherObject.hourly.precipChance[item]}%)
              </Typography>
              <Typography
                style={{
                  height: 15,
                  fontFamily: "Roboto",
                  fontSize: 10,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: -0.2,
                  textAlign: "center",
                  color: "#2A7EA1",
                }}
              >
                {this.state.weatherObject.hourly.qpf[item]}{" "}
                {this.props.tempUnits === "C" ? "mm" : "inch"}
              </Typography>
            </div>
          ) : (
            <div style={{ height: 30 }} />
          )}

          <Typography
            style={{
              marginTop: 8,
              width: 38,
              height: 48,
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: -0.2,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {validLocalTime}
          </Typography>
        </div>
        <div
          style={{
            width: 84,
            height: 2,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        />
      </div>
    );
  };

  prepareIconPrefix = (num) => {
    let iconPrefix = num < 10 ? "0" + num.toString() : num.toString();

    return iconPrefix;
  };

  dailyItem = (item, index) => {
    let dayNightIndex = this.state.weatherObject.daily.daypart[0].iconCode[
      index * 2
    ]
      ? index * 2
      : index * 2 + 1;

    let icon =
      "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
      this.prepareIconPrefix(
        this.state.weatherObject.daily.daypart[0].iconCode[dayNightIndex]
      ) +
      ".png?alt=media";
    let date =
      this.state.weatherObject.daily.validTimeLocal[index].split("-")[2];
    date = date.substring(0, 2);

    let validLocalTime = this.state.weatherObject.daily.dayOfWeek[index];

    let rain = this.state.weatherObject.daily.qpf[index];

    let temperatureMin = this.state.weatherObject.daily.temperatureMin[index];
    let temperatureMax = this.state.weatherObject.daily.temperatureMax[index];

    if (
      index == 0 &&
      this.state.weatherObject.daily.dayOfWeek[0] ===
        this.state.weatherObject.weather.dayOfWeek[0]
    ) {
      icon =
        "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
        this.prepareIconPrefix(
          this.state.weatherObject.weather.iconCodeDay[0]
        ) +
        ".png?alt=media";
      rain =
        this.state.weatherObject.weather.precip24Hour[0] +
        this.state.weatherObject.daily.qpf[0];

      temperatureMax = this.state.weatherObject.weather.temperatureMax[0];
      temperatureMin = this.state.weatherObject.daily.temperatureMin[0];
    }

    let rainHi = this.props.tempUnits === "C" ? rain : rain * 25.4;

    return (
      <div
        onClick={() => {
          this.selectDay(index);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          height: 178,
          width: 84,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            index == this.state.selectedDailyIndex ? "#DFF1FB" : "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 84,
            height: 58,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              marginTop: 3,
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: "bold",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {date}
          </Typography>

          <Typography
            style={{
              marginTop: 3,
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            {index == 0 ? "Today" : validLocalTime}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            borderRadius: 19,
            backgroundColor: "#DFF1FB",
            marginVertical: 5,
            width: 38,
            height: 38,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={icon}
            style={{
              width: 28,
              height: 28,
            }}
          />
        </div>

        <div style={{ height: 22 }}>
          <Typography
            style={{
              marginTop: 4,
              width: 80,
              height: 22,
              fontFamily: "Roboto",
              fontSize: 10,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: "#707176",
            }}
          >
            {"L:" + temperatureMin + "  H:" + temperatureMax}°
            {this.props.tempUnits}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: rainHi < 40 ? 42 - rainHi : 2,
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 10,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: rainHi < 0.01 ? "#707176" : "#2A7EA1",
            }}
          >
            {rain.toFixed(2)}
          </Typography>
          <Typography
            style={{
              marginTop: 5,
              fontFamily: "Roboto",
              fontSize: 6,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: rainHi < 0.01 ? "#707176" : "#2A7EA1",
            }}
          >
            {this.props.tempUnits == "C" ? "mm" : "inch"}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            width: 84,
            height: rainHi < 40 ? rainHi : 40,
            backgroundColor: "#2A7EA1",
          }}
        />

        <div
          style={{
            width: 84,
            height: 2,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        />
      </div>
    );
  };

  smallDailyItem = (item, index_arr) => {
    let daily =
      item < 0
        ? (daily = this.state.weatherObject.weather)
        : this.state.weatherObject.daily;

    let index = Math.abs(Math.round(item));

    let dayNightIndex, icon, rain, bgColor, temperatureMin, temperatureMax;

    if (item == 0) {
      icon =
        "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
        this.prepareIconPrefix(
          this.state.weatherObject.weather.iconCodeDay[0]
        ) +
        ".png?alt=media";
      rain =
        this.state.weatherObject.weather.precip24Hour[0] +
        this.state.weatherObject.daily.qpf[0];
      //rain = this.state.weatherObject.weather.precip24Hour[0];
      bgColor = "#DFF1FB";
      temperatureMax = this.state.weatherObject.weather.temperatureMax[0];
      temperatureMin = this.state.weatherObject.daily.temperatureMin[0];
    } else if (item < 0) {
      icon =
        "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
        this.prepareIconPrefix(daily.iconCodeDay[index]) +
        ".png?alt=media";
      rain = daily.precip24Hour[index];
      bgColor = "rgba(251,250,223,0.1)";
      temperatureMax = daily.temperatureMax[index];
      temperatureMin = daily.temperatureMin[index];
    } else {
      dayNightIndex = daily.daypart[0].iconCode[index * 2]
        ? index * 2
        : index * 2 + 1;
      icon =
        "https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/misc%2FweatherIBM_icons%2F" +
        this.prepareIconPrefix(daily.daypart[0].iconCode[dayNightIndex]) +
        ".png?alt=media";
      rain = daily.qpf[index];
      bgColor = index == 0 ? "#DFF1FB" : "rgba(223,251,227,0.1)";
      temperatureMax = daily.temperatureMax[index];
      temperatureMin = daily.temperatureMin[index];
    }

    let date = daily.validTimeLocal[index].split("-");
    date = date[2].substring(0, 2) + "/" + date[1];

    let validLocalTime = daily.dayOfWeek[index];

    let rainHi = this.props.tempUnits === "C" ? rain : rain * 25.4;

    return (
      <div
        style={{
          marginBottom: 6,
          height: 156,
          width: 84,
          alignItems: "center",
          backgroundColor: bgColor,
        }}
      >
        <Typography
          style={{
            marginTop: 6,
            height: 20,
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: "bold",
            lineHeight: 16,
            letterSpacing: 0,
            textAlign: "center",
            color: "#000000",
          }}
        >
          {item == 0 || item == 0.1 ? "Today" : date}
        </Typography>

        <div
          style={{
            borderRadius: 19,
            backgroundColor: "#DFF1FB",
            marginVertical: 5,
            width: 38,
            height: 38,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={icon} style={{ width: 28, height: 28 }} />
        </div>

        <div style={{ height: 22 }}>
          <Typography
            style={{
              marginTop: 4,
              width: 80,
              height: 22,
              fontFamily: "Roboto",
              fontSize: 10,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: "#707176",
            }}
          >
            {"L:" + temperatureMin + "  H:" + temperatureMax}°
            {this.props.tempUnits}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: rainHi < 40 ? 42 - rainHi : 2,
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 10,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: rainHi < 0.01 ? "#707176" : "#2A7EA1",
            }}
          >
            {rain.toFixed(2)}
          </Typography>
          <Typography
            style={{
              marginTop: 5,
              fontFamily: "Roboto",
              fontSize: 6,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: -0.2,
              textAlign: "center",
              color: rainHi < 0.01 ? "#707176" : "#2A7EA1",
            }}
          >
            {this.props.tempUnits == "C" ? "mm" : "inch"}
          </Typography>
        </div>
        <div
          style={{
            marginTop: 2,
            width: 84,
            height: rainHi < 40 ? rainHi : 40,
            backgroundColor: "#2A7EA1",
          }}
        />

        <div
          style={{
            width: 84,
            height: 2,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        />
      </div>
    );
  };

  dailyRender = () => {
    // console.log("here weather");
    // console.log(this.state.weatherObject);

    let wObj = this.state.weatherObject;

    if (wObj == null)
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
          }}
        ></div>
      );

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 30,
              margin: 8,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                letterSpacing: 0,
                textAlign: "left",
                color: "#000000",
              }}
            >
              Daily forecast
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginHorizontal: 8,
              marginTop: 8,
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
              (item, index) => this.dailyItem(item, index)
            )}
          </div>

          {this.state.dailyIndeciesInHourly.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "stretch",
                height: 30,
                margin: 8,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  fontWeight: "bold",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: "#000000",
                }}
              >
                Hourly forecast
              </Typography>

              {this.state.dailyIndeciesInHourly.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    style={{
                      marginLeft: 9,
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      lineHeight: 17,
                      letterSpacing: -0.2,
                      color: "#707176",
                    }}
                  >
                    {"Humidity: "}
                    {
                      wObj.hourly.relativeHumidity[
                        this.state.selectedHourlyIndex
                      ]
                    }
                    %
                  </Typography>
                  <Typography
                    style={{
                      marginLeft: 24,
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      lineHeight: 17,
                      letterSpacing: -0.2,
                      color: "#707176",
                    }}
                  >
                    {"Wind: "}
                    {wObj.hourly.windSpeed[this.state.selectedHourlyIndex]}
                    {this.props.tempUnits == "C" ? "km/h" : "miles/h"}
                  </Typography>
                </div>
              )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {this.state.dailyIndeciesInHourly.map((item, index) =>
              this.hourlyItem(item, index)
            )}
          </div>

          <div style={{ height: 20 }}>
            <Typography
              style={{
                marginLeft: 25,
                fontFamily: "Roboto",
                fontSize: 9,
                fontWeight: "normal",
                fontStyle: "normal",
                lineHeight: 17,
                letterSpacing: -0.2,
                color: "#707176",
              }}
            ></Typography>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { classes, ...other } = this.props;

    return (
      <Drawer variant="permanent" {...other} style={{ zIndex: 1001 }}>
        <List style={{ backgroundColor: "#ffffff" }} disablePadding>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ListItem
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory
              )}
              style={{
                flex: 10,
                backgroundColor: "#ffffff",
                color: "#000000",
                fontFamily: "Roboto",
              }}
            >
              Weather
            </ListItem>

            <ListItem
              button
              dense
              onClick={() => this.props.onChangeTempUnits("F")}
              style={{ flex: 1, backgroundColor: "#ffffff" }}
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory
              )}
            >
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                style={{
                  textAlign: "center",
                  color:
                    this.props.tempUnits === "F"
                      ? "#000000"
                      : "rgba(0,0,0,0.37)",
                  fontWeight: this.props.tempUnits === "F" ? "bold" : "normal",
                  fontFamily: "Roboto",
                }}
              >
                °F
              </ListItemText>
            </ListItem>
            <ListItem
              button
              dense
              onClick={() => {}}
              style={{ flex: 1, backgroundColor: "#ffffff" }}
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory
              )}
            >
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                style={{
                  textAlign: "center",
                  color: "#000000",
                  fontFamily: "Roboto",
                }}
              >
                /
              </ListItemText>
            </ListItem>
            <ListItem
              button
              dense
              onClick={() => this.props.onChangeTempUnits("C")}
              style={{ flex: 1, backgroundColor: "#ffffff" }}
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory
              )}
            >
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
                style={{
                  textAlign: "center",
                  color:
                    this.props.tempUnits === "C"
                      ? "#000000"
                      : "rgba(0,0,0,0.37)",
                  fontWeight: this.props.tempUnits === "C" ? "bold" : "normal",
                  fontFamily: "Roboto",
                }}
              >
                °C
              </ListItemText>
            </ListItem>
          </div>

          {this.dailyRender()}
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(withFirebase(WeatherDrawer));
