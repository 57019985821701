import React from "react";
import { Link } from "react-router-dom";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes.js";
import Button from "@material-ui/core/Button";
import { AuthUserContext } from "../Session";

import AgrioLogo from "../../Assests/Agrio Logo.png";
import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";

import { withAuthorization } from "../Session";

const isMobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

function Navigation(props) {
  //console.log(props.type)
  if (props.type === "minimal")
    return (
      <div>
        <NavigationWithoutAuth {...props} />
      </div>
    );
  else
    return (
      <div>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <NavigationAuth {...props} />
            ) : (
              <NavigationNonAuth {...props} />
            )
          }
        </AuthUserContext.Consumer>
      </div>
    );
}

const NavigationWithoutAuth = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: "67px",
      backgroundColor: "#ffffff",
    }}
  >
    <div style={{ flex: 40, marginTop: "15px" }}>
      <Link to={"/main"}>
        <img
          style={{ width: 130, height: 40, marginLeft: "40px" }}
          src={AgrioLogo}
        />
      </Link>
    </div>
    <div style={{ display: "flex", alignItems: "center", flex: 5 }}></div>
    <div style={{ display: "flex", alignItems: "center", flex: 5 }}></div>
    {isMobile == false &&
    props.type != "landing" &&
    props.type != "pricing" &&
    props.type != "minimal" ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 5,
          marginTop: "15px",
          marginRight: "20px",
        }}
      >
        <Link to={"/google-play"}>
          <img style={{ height: "35.23px" }} src={GooglePlayButton} />
        </Link>
        <Link to={"/apple-store"}>
          <img
            style={{ marginLeft: "12px", height: "35.23px" }}
            src={AppStoreButton}
          />
        </Link>
      </div>
    ) : null}
  </div>
);

const NavigationAuth = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: "67px",
      backgroundColor: "#ffffff",
    }}
  >
    <div style={{ flex: 40, marginTop: "15px" }}>
      <Link to={"/main"}>
        <img
          style={{ width: 130, height: 40, marginLeft: "40px" }}
          src={AgrioLogo}
        />
      </Link>
    </div>
    {(props.type === "full" ||
      props.type === "landing" ||
      props.type === "pricing") &&
      (props.userType === "admin" ||
        props.userType === "editor" ||
        props.premiumLevel === "Pro") && (
        <div style={{ display: "flex", alignItems: "center", flex: 5 }}>
          <Link
            style={{
              width: "90px",
              fontSize: 15,
              font: "Helvectica",
              fontWeight: "Bold",
              color: "#4A4A4A",
              textDecoration: "none",
            }}
            to={ROUTES.HOME}
          >
            Dashboard
          </Link>
        </div>
      )}
    <div style={{ display: "flex", alignItems: "center", flex: 5 }}>
      <SignOutButton />
    </div>
    {isMobile == false &&
    props.type != "landing" &&
    props.type != "pricing" &&
    props.type != "minimal" ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 5,
          marginTop: "15px",
          marginRight: "20px",
        }}
      >
        <Link to={"/google-play"}>
          <img style={{ height: "35.23px" }} src={GooglePlayButton} />
        </Link>
        <Link to={"/apple-store"}>
          <img
            style={{ marginLeft: "12px", height: "35.23px" }}
            src={AppStoreButton}
          />
        </Link>
      </div>
    ) : null}
  </div>
);

const NavigationNonAuth = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: "67px",
      backgroundColor: "#ffffff",
    }}
  >
    <div style={{ flex: 40, marginTop: "15px" }}>
      <Link to={"/main"}>
        <img
          style={{ width: 130, height: 40, marginLeft: "40px" }}
          src={AgrioLogo}
        />
      </Link>
    </div>
    {props.type === "landing" ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/*
  <Button onClick={() => {props.history.push(ROUTES.PRICING)}} style={{fontSize: 15, font: "Helvectica", fontWeight: "Bold", color: "#4A4A4A"}}>
    Pricing
  </Button>
*/}
      </div>
    ) : null}
    <div style={{ display: "flex", alignItems: "center", flex: 2 }}>
      <Link
        style={{
          width: "70px",
          fontSize: 15,
          font: "Helvectica",
          fontWeight: "Bold",
          color: "#4A4A4A",
          textDecoration: "none",
        }}
        to={ROUTES.SIGN_IN}
      >
        Sign In
      </Link>
    </div>
    {isMobile == false &&
    props.type != "landing" &&
    props.type != "pricing" &&
    props.type != "minimal" ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 5,
          marginTop: "15px",
          marginRight: "20px",
        }}
      >
        <Link to={"/google-play"}>
          <img style={{ height: "35.23px" }} src={GooglePlayButton} />
        </Link>
        <Link to={"/apple-store"}>
          <img
            style={{ marginLeft: "12px", height: "35.23px" }}
            src={AppStoreButton}
          />
        </Link>
      </div>
    ) : null}
  </div>
);

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return true;
};

export default withAuthorization(condition)(Navigation);
