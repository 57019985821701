import React from 'react';


const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function plural (string, count, many) {
    if (count == 1) {
        return string;
    } else if (many) {
        return many;
    } else {
        return string + "s";
    }
}

    function _distanceOfTimeInWords (date, compare_to) {
        var is_comparing = false;
        if (compare_to) {
            if (compare_to.toDate) compare_to = compare_to.toDate();
            is_comparing = true;
        } else {
            compare_to = new Date();
        }
        var seconds = Math.floor((compare_to*1 - date) / 1000);
        var is_ago = (seconds >= 0);
        seconds = Math.abs(seconds);

        if (seconds < 60) { // 1 minute
            if (is_comparing) {
                return is_ago ? "Just then" : "Soon";
            } else {
                return 'A few seconds';
            }
        }

        var distance;
        var when = [];


            if (seconds < 60 * 60) { // 1 hour
                distance = Math.round(seconds / 60);
                when = `${distance} ${plural('minute', distance)}`;
            } else if (seconds < 60 * 60 * 24) { // 1 day
                distance = Math.round(seconds / (60 * 60));
                when = `${distance} ${plural('hour', distance)}`;
            } else if (seconds < 60 * 60 * 24 * 7) { // 1 week
                distance = Math.round(seconds / (60 * 60 * 24));
                when = `${distance} ${plural('day', distance)}`;
            } else if (seconds < 60 * 60 * 24 * (365 / 12)) { // 1 month
                distance = Math.round(seconds / (60 * 60 * 24 * 7));
                when = `${distance} ${plural('week', distance)}`;
            } else if (seconds < 60 * 60 * 24 * 30 * 12) { // # 1 year
                distance = Math.round(seconds / (60 * 60 * 24 * (365 / 12)));
                when = `${distance} ${plural('month', distance)}`;
            } else {
              distance = Math.round(seconds / (12 * 60 * 60 * 24 * (365 / 12)));
              when = `${distance} ${plural('year', distance)}`;
            }

        if (is_comparing) {
            return when;
        } else if (is_ago) {
            return `${when} ago`;
        } else {
            return `in ${when}`;
        }
    }



    function _formatDate (date) {
        //var d = this._parseDate(date);
        var d = date;

        if (d === false) {
            return 'never';
        }
        return _distanceOfTimeInWords(d, null);
    }


    function Timestamp (date) {
            return _formatDate(date)
    }


export default Timestamp;
