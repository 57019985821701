import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";

import LandingPage from "../Landing";
import ReferalAgPage from "../ReferalAg";
import ReferalGrPage from "../ReferalGarden";
import ScoutingReportPage from "../ScoutingReportPage";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import DeadEndPage from "../DeadEnd";
import UserRoutingPage from "../UserRouting";
import TrialPage from "../TrialPage";
import AdvertiserLandingPage from "../AdvertiserLandingPage";
//import PricingPage from "../Pricing";
import AccountDeletion from "../AccountDeletion";
import ScrollToTop from "./ScrollToTop";
import FacebookDataDeletionInstructionsURL from "../FacebookDataDeletionInstructionsURL";
import APIdocs from "../APIdocs";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import ReactPixel from "react-facebook-pixel";

const withAnalytics = (Component) => (props) => {
  ReactPixel.pageView();
  return <Component {...props} />;
};

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: "#63ccff",
      main: "#01496d",
      dark: "#006db3",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "initial",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing.unit,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        margin: "0 16px",
        minWidth: 0,
        [theme.breakpoints.up("md")]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing.unit,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <ScrollToTop />
      <div>
        <Route
          exact
          path={ROUTES.LANDING}
          component={withAnalytics(LandingPage)}
        />
        <Route path="/referal/:id" component={withAnalytics(LandingPage)} />
        <Route path="/referalAg/:id" component={withAnalytics(ReferalAgPage)} />
        <Route path="/referalGr/:id" component={withAnalytics(ReferalGrPage)} />
        <Route path={ROUTES.SIGN_UP} component={withAnalytics(SignUpPage)} />
        <Route path={ROUTES.SIGN_IN} component={withAnalytics(SignInPage)} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={withAnalytics(HomePage)} />
        <Route path={ROUTES.ACCOUNT} component={withAnalytics(AccountPage)} />
        <Route path={ROUTES.DEADEND} component={withAnalytics(DeadEndPage)} />
        <Route path={ROUTES.API_DOCS} component={withAnalytics(APIdocs)} />
        <Route path={ROUTES.ACCOUNTDELETION} component={AccountDeletion} />
        <Route
          path={ROUTES.USERROUTING}
          component={withAnalytics(UserRoutingPage)}
        />
        <Route path={ROUTES.TRIAL} component={withAnalytics(TrialPage)} />
        <Route
          path={ROUTES.ADS}
          component={withAnalytics(AdvertiserLandingPage)}
        />
        {/*
        <Route path={ROUTES.PRICING} component={withAnalytics(PricingPage)} />
        */}
        <Route
          path="/main"
          component={() => {
            window.location.href = "https://www.agrio.app";
            return null;
          }}
        />
        <Route
          path="/google-play"
          component={() => {
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.agrio";
            return null;
          }}
        />
        <Route
          path="/apple-store"
          component={() => {
            window.location.href =
              "https://apps.apple.com/us/app/agrio/id1239193220";
            return null;
          }}
        />
        <Route
          path="/facebook-page"
          component={() => {
            window.location.href = "https://www.facebook.com/agrio.saillog/";
            return null;
          }}
        />
        <Route
          path="/linkedin-page"
          component={() => {
            window.location.href =
              "https://www.linkedin.com/company/saillog-ltd";
            return null;
          }}
        />
        <Route
          path="/instegram-page"
          component={() => {
            window.location.href = "https://www.instagram.com/agrio_saillog";
            return null;
          }}
        />
        <Route
          path="/twitter-page"
          component={() => {
            window.location.href = "https://twitter.com/agrio_saillog";
            return null;
          }}
        />
        <Route
          path="/privacy-policy"
          component={() => {
            window.location.href =
              "https://termsfeed.com/privacy-policy/b4064355e39ec3e21ee5761e29dc15a4";
            return null;
          }}
        />
        <Route
          path="/terms-conditions"
          component={() => {
            window.location.href =
              "https://termsfeed.com/terms-conditions/44b9a6d0f3d40b5a417fea7a2e476b92";
            return null;
          }}
        />
        <Route
          path="/report/:id"
          component={withAnalytics(ScoutingReportPage)}
        />
        <Route
          path="/FacebookDataDeletionInstructionsURL"
          component={FacebookDataDeletionInstructionsURL}
        />
      </div>
    </Router>
  </MuiThemeProvider>
);

export default withAuthentication(App);
