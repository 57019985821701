import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TextField from "@material-ui/core/TextField";
import MediaCapture from "../MediaCapture";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";

import * as Lists from "../Lists.js";
import Carousel from "react-multi-carousel";
import { Editor, EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";

import "./index.css";
import "../../../node_modules/draft-js/dist/Draft.css";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

const hostsOptions = Object.keys(Lists.cropIcons);

//import RefreshIcon from '@material-ui/icons/Refresh';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class GDDPage extends Component {
  constructor() {
    super();

    this.onChangeDescription = (editorState) =>
      this.setState({ description: editorState });
    this.onChangeConventional = (editorState) =>
      this.setState({ conventional: editorState });
    this.onChangeCultural = (editorState) =>
      this.setState({ cultural: editorState });
    this.onChangeManagement = (editorState) =>
      this.setState({ management: editorState });
    this.onChangeOrganic = (editorState) =>
      this.setState({ organic: editorState });
    this.onChangeBiological = (editorState) =>
      this.setState({ biological: editorState });
    this.onChangeCorrectionMeasures = (editorState) =>
      this.setState({ correctionMeasures: editorState });
    this.onChangeAdditional = (editorState) =>
      this.setState({ additional: editorState });

    this.setEditorConventional = (editor) => {
      this.editorConventional = editor;
    };

    this.activeMaterials = {};
    this.activeMaterialsByLanguage = {};
    this.activeMaterialsByLanguageReverse = {};

    this.defaultLanguages = [
      "ar",
      "en",
      "es",
      "fr",
      "he",
      "hi",
      "id",
      "iw",
      "pt",
      "ru",
      "ta",
      "te",
      "vi",
    ];

    this.textChanges = {};

    this.state = {
      PorotocolLanguages: {},
      selectedModel: null,
      languages: [],
      language: "",
      description: EditorState.createEmpty(),
      conventional: EditorState.createEmpty(),
      cultural: EditorState.createEmpty(),
      management: EditorState.createEmpty(),
      organic: EditorState.createEmpty(),
      biological: EditorState.createEmpty(),
      correctionMeasures: EditorState.createEmpty(),
      additional: EditorState.createEmpty(),
      commonName: "",
      transferingInsect: "",
      class: "",

      host: null,
      published: false,
      selectedIndex: 0,
      lightboxIsOpen: false,
      width: window.innerWidth,
      height: window.innerHeight,
      imgUrls: [],
      addedImages: [],
      pageSelectText: "",
      lastTrained: null,

      cropOptions: [],
      crop: null,
      hosts: [],
      stages: [],
      pageIDs: [],
      pathogen: null,
    };
  }

  async componentDidMount() {
    this.props.firebase.db.ref("DailyBriefing/Hosts").on("value", (result) => {
      let models = result.val();
      let modelOptions = {};
      for (let crop in models)
        for (let model in models[crop])
          modelOptions[models[crop][model]] = true;

      modelOptions = Object.keys(modelOptions);

      this.setState({
        cropOptions: Object.keys(models),
        models: models,
        modelOptions: modelOptions,
      });
      console.log(result.val());
    });

    this.props.firebase
      .treatmentPorotocolsLanguages(this.props.firebase.workgroupPath)
      .on("value", (result) => {
        let pageIDs = Object.keys(result.val());
        this.setState({
          pageIDs: pageIDs,
        });
        // console.log(pageIDs);
      });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref("DailyBriefing/Hosts").off();
    this.props.firebase
      .treatmentPorotocolsLanguages(this.props.firebase.workgroupPath)
      .off();
  }

  handleCropChange = async (event, selection) => {
    if (selection) {
      let modelOptions = {};
      for (let model in this.state.models[selection])
        modelOptions[this.state.models[selection][model]] = true;

      modelOptions = Object.keys(modelOptions);

      this.setState({ crop: selection, modelOptions: modelOptions });
    } else {
      let modelOptions = {};
      for (let crop in this.state.models)
        for (let model in this.state.models[crop])
          modelOptions[this.state.models[crop][model]] = true;

      modelOptions = Object.keys(modelOptions);

      this.setState({ crop: null, modelOptions: modelOptions });
    }
  };

  loadModelContent = async (model, language) => {
    if (this.state.modelOptions.indexOf(model) != -1) {
      let content = this.props.firebase.db
        .ref("DailyBriefing/Content")
        .child(model)
        .once("value");

      let modelData = this.props.firebase.db
        .ref("DailyBriefing/Models")
        .child(model)
        .once("value");

      let hosts = [];

      for (let crop in this.state.models)
        if (this.state.models[crop].indexOf(model) != -1) hosts.push(crop);

      let results = await Promise.all([content, modelData]);

      // const blocksFromHTML = stateFromHTML(results[0].val() ? results[0].val() : "");
      // const blocksFromHTML_0 = stateFromHTML(results[1].val() ? results[1].val() : "");
      //
      // description: EditorState.createWithContent(blocksFromHTML),
      // conventional: EditorState.createWithContent(blocksFromHTML_0),

      // description: EditorState.createEmpty(),
      // conventional: EditorState.createEmpty(),

      let info = results[0].val();
      let data = results[1].val();

      let stages = [];

      let promises = [];

      for (let i = 0; i < info.Stages.length; i++) {
        let title = info.Stages[i].hasOwnProperty("title")
          ? EditorState.createWithContent(
              stateFromHTML(info.Stages[i].title[language])
            )
          : EditorState.createEmpty();
        let text = info.Stages[i].hasOwnProperty("text")
          ? EditorState.createWithContent(
              stateFromHTML(info.Stages[i].text[language])
            )
          : EditorState.createEmpty();

        stages.push({
          ...data.Stages[i],
          title: title,
          text: text,
        });

        if (
          info.Stages[i].hasOwnProperty("imgPath") &&
          info.Stages[i].hasOwnProperty("thumbName")
        ) {
          let promise = this.props.firebase
            .getStorageImg(
              "dailyBriefing/" + info.Stages[i].imgPath,
              info.Stages[i].thumbName,
              true
            )
            .then((url) => {
              stages[i]["imgUrl"] = { source: { regular: url } };
            });

          promises.push(promise);
        }
      }

      await Promise.all(promises);

      return this.setState({
        selectedModel: model,
        languages: Object.keys(info.Stages[0].title),
        language: language,
        modelName: info ? info.modelName[language] : "",
        pathogen: info ? info.Pathogen : "",
        stages: stages,
        minTmp: data ? data.minTmpThr : null,
        maxTmp: data ? data.maxTmpThr : null,
        hosts: hosts,
      });
    } else {
      return this.setState({
        selectedModel: model,
        languages: this.defaultLanguages,
        language: "en",
        modelName: "",
        pathogen: "",
        stages: [],
        minTmp: null,
        maxTmp: null,
        hosts: [],
      });
    }
  };

  handleModelChange = async (event, selection) => {
    console.log(selection);
    //should alert that changes will be lost
    this.textChanges = {};

    if (selection) {
      let filtered = selection.replace(/[^a-zA-Z]/g, "").replace(/ /g, "");
      // console.log(filtered);
      // this.setState({pageSelectText: filtered});

      // console.log(filtered);

      return this.loadModelContent(
        filtered,
        this.state.language === "" ? "en" : this.state.language
      );
    } else {
      return this.setState({
        selectedModel: null,
        languages: [],
        language: "",
        modelName: "",
        pathogen: "",
        stages: [],
        minTmp: null,
        maxTmp: null,
        hosts: [],
      });
    }
  };

  encodeActiveMaterial = (text, language) => {
    //console.log(text);
    for (let activeMaterials in this.activeMaterialsByLanguageReverse[
      language
    ]) {
      text = text.replace(
        new RegExp(activeMaterials, "g"),
        "#" + this.activeMaterialsByLanguageReverse[language][activeMaterials]
      );
    }

    return text;
  };

  extractLanguageSpecificTextData = (language) => {
    let obj = {
      description: this.encodeActiveMaterial(
        stateToHTML(this.state.description.getCurrentContent()),
        language
      ),
      conventional: this.encodeActiveMaterial(
        stateToHTML(this.state.conventional.getCurrentContent()),
        language
      ),
      cultural: this.encodeActiveMaterial(
        stateToHTML(this.state.cultural.getCurrentContent()),
        language
      ),
      management: this.encodeActiveMaterial(
        stateToHTML(this.state.management.getCurrentContent()),
        language
      ),
      organic: this.encodeActiveMaterial(
        stateToHTML(this.state.organic.getCurrentContent()),
        language
      ),
      biological: this.encodeActiveMaterial(
        stateToHTML(this.state.biological.getCurrentContent()),
        language
      ),
      correctionMeasures: this.encodeActiveMaterial(
        stateToHTML(this.state.correctionMeasures.getCurrentContent()),
        language
      ),
      additional: this.encodeActiveMaterial(
        stateToHTML(this.state.additional.getCurrentContent()),
        language
      ),
      commonName: this.state.commonName,
      scientificName: this.state.scientificName,
    };
    //console.log(obj);
    return obj;
  };

  handleLanguageChange = async (event, selection) => {
    //console.log(selection);
    this.textChanges[this.state.language] =
      this.extractLanguageSpecificTextData(this.state.language);
    this.loadModelContent(this.state.selectedModel, selection);
  };

  handlePathogenChange = async (event, selection) => {
    this.setState({ pathogen: selection });
  };

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  handleCancelClick = (remove_index) => {
    let images = [];

    //console.log(remove_index);

    this.state.imgUrls.forEach((item, index) => {
      if (index != remove_index) images.push(item);
    });

    //console.log(images);

    this.setState({ imgUrls: images });
  };

  handleDiscard = async () => {
    //console.log("discard");

    this.textChanges = {};

    let host = this.state.host;

    await this.handleModelChange(null, this.state.selectedModel);

    if (host != null) {
      //console.log("discard images");

      this.handleHostChange(null, host);
    }
  };

  handleSave = async () => {
    // console.log("save");
    // console.log(this.state.selectedModel);
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    //Save text

    let hostsObj = {};
    for (let i = 0; i < this.state.hosts.length; i++)
      hostsObj[this.state.hosts[i]] = true;

    let protocolLanguages = this.state.PorotocolLanguages.hasOwnProperty(
      this.state.selectedModel
    )
      ? { ...this.state.PorotocolLanguages[this.state.selectedModel] }
      : {};
    this.textChanges[this.state.language] =
      this.extractLanguageSpecificTextData();

    // transferingInsect: this.state.transferingInsect,
    // class: this.state.class,
    // hosts: this.state.hosts,
    //

    let dbPromises = [];

    for (let language in this.textChanges) {
      if (!(language in protocolLanguages)) protocolLanguages[language] = true;

      if (this.textChanges[language].description !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolDescription(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].description)
        );
      if (this.textChanges[language].conventional !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolConventional(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].conventional)
        );
      if (this.textChanges[language].cultural !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCultural(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].cultural)
        );
      if (this.textChanges[language].management !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolManagement(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].management)
        );
      if (this.textChanges[language].organic !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolOrganic(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].organic)
        );
      if (this.textChanges[language].biological !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolBiological(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].biological)
        );
      if (this.textChanges[language].correctionMeasures !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCorrectionMeasures(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].correctionMeasures)
        );
      if (this.textChanges[language].additional !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolAdditional(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].additional)
        );
      if (this.textChanges[language].commonName !== "<p><br></p>")
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolCommonName(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].commonName)
        );
      if (
        this.textChanges[language].scientificName &&
        this.textChanges[language].scientificName !== "<p><br></p>"
      )
        dbPromises.push(
          this.props.firebase
            .treatmentPorotocolScientificName(
              this.props.firebase.workgroupPath,
              this.state.selectedModel,
              language
            )
            .set(this.textChanges[language].scientificName)
        );
    }

    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolTransferingInsect(
          this.props.firebase.workgroupPath,
          this.state.selectedModel
        )
        .set(this.state.transferingInsect)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolClass(
          this.props.firebase.workgroupPath,
          this.state.selectedModel
        )
        .set(this.state.class)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolHosts(
          this.props.firebase.workgroupPath,
          this.state.selectedModel
        )
        .set(hostsObj)
    );
    dbPromises.push(
      this.props.firebase
        .treatmentPorotocolPublished(
          this.props.firebase.workgroupPath,
          this.state.selectedModel
        )
        .set(this.state.published)
    );

    Promise.all(dbPromises).then(() => {
      this.props.firebase
        .treatmentPorotocolsLanguages(this.props.firebase.workgroupPath)
        .child(this.state.selectedModel)
        .set(protocolLanguages);

      this.props.firebase
        .treatmentPorotocolsChangeLog(dateForSorting.toString())
        .set({
          pageID: this.state.selectedModel,
          languages: Object.keys(this.textChanges),
          uid: this.props.firebase.auth.currentUser.uid,
        });

      this.textChanges = {};
    });

    //Save imagse

    if (this.state.host != null) {
      //TODO make it cleaner..no need to delete eeverything and upload all images again
      //console.log("delete folders in storage");

      //TODO folder reomve does not work in firebase..not deleting the folder might leave junk..
      //this.props.firebase.deleteProtocolImages(this.state.host, this.state.selectedModel);

      let promises = [];

      for (let i = 0; i < this.state.imgUrls.length; i++) {
        let promise = fetch(this.state.imgUrls[i])
          .then((res) => res.blob()) // Gets the response and returns it as a blob
          .then((blob) => {
            this.props.firebase._uploadImageToFirebase(
              blob,
              this.props.firebase.workgroupPath === ""
                ? "diseaseInfo/" +
                    this.state.host +
                    "/" +
                    this.state.selectedModel
                : "diseaseInfo/" +
                    this.props.firebase.workgroupPath +
                    "/" +
                    this.state.host +
                    "/" +
                    this.state.selectedModel,
              "image" + (i + 1).toString() + ".jpg"
            );
          });
        promises.push(promise);
      }

      for (let i = 0; i < this.state.addedUrls.length; i++) {
        let blob = new Blob([this.state.addedUrls[i]], { type: "image/jpeg" });
        let promise = this.props.firebase._uploadImageToFirebase(
          blob,
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" + this.state.host + "/" + this.state.selectedModel
            : "diseaseInfo/" +
                this.props.firebase.workgroupPath +
                "/" +
                this.state.host +
                "/" +
                this.state.selectedModel,
          "image" + (this.state.imgUrls.length + i + 1).toString() + ".jpg"
        );
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        this.props.firebase
          .treatmentPorotocolImagesInfo(
            this.props.firebase.workgroupPath,
            this.state.selectedModel,
            this.state.host
          )
          .set({
            folderName: this.state.selectedModel,
            numImages: this.state.imgUrls.length + this.state.addedUrls.length,
          });
        //TODO folderName might be different if the page is being edited..old folder need to be deleted
      });
    }
  };

  handleUpdateAddedImages = (obj) => {
    this.setState({ addedImages: obj.images, addedUrls: obj.urls });
  };

  handleAddHost = (event, selection) => {
    // console.log(selection);
    this.setState({ hosts: selection });
  };

  handleChangePublished = (event) => {
    this.setState({ published: event.target.checked });
  };

  handleModelNameChange = (event) => {
    this.setState({ modelName: event.target.value });
  };

  handleMinTmpChange = (event) => {
    this.setState({ minTmp: event.target.value });
  };

  handleMaxTmpChange = (event) => {
    this.setState({ maxTmp: event.target.value });
  };

  addStage = () => {
    let stages = this.state.stages.concat([]);

    stages.push({
      type: "gdd",
      trigger: "spraying",
      title: EditorState.createEmpty(),
      text: EditorState.createEmpty(),
    });

    this.setState({ stages: stages });
  };

  // handleStageChange = (index, field, event, selection) => {
  //   let stages = this.state.stages.concat([]);
  //   stages[index][field] = event.target.value;
  //
  //   console.log(event.target);
  //
  //   this.setState({stages: stages});
  // }

  handleStageChange = (index, field, value) => {
    let stages = this.state.stages.concat([]);
    stages[index][field] = value;

    this.setState({ stages: stages });
  };

  handleStageEditorChange = (index, field, editorState) => {
    let stages = this.state.stages.concat([]);
    stages[index][field] = editorState;
    this.setState({ stages: stages });
  };

  render() {
    const { classes } = this.props;

    let items = [...this.state.stages];

    items.push({ type: "Add" });

    const suggestions = this.state.modelOptions;

    const cropOptions = this.state.cropOptions;

    const pathogens = this.state.pageIDs.map((name, index) => name);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          backgroundColor: "transparent",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              zIndex: 5,
            }}
          >
            <Paper
              className={classes.paper}
              style={{ minHeight: 110, margin: 24, overflow: "visible" }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Autocomplete
                        disableClearable={false}
                        maxMenuHeight={240}
                        size="small"
                        id="cropSelector"
                        options={cropOptions}
                        getOptionLabel={(option) => option}
                        defaultValue={[]}
                        onChange={this.handleCropChange}
                        value={this.state.crop}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: "#ffffff" }}
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder="Search by crop name"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {this.state.crop == null ? (
                  <Typography color="textSecondary" align="center">
                    No crop was selected
                  </Typography>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        marginTop: 6,
                        marginLeft: 16,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={
                            Lists.cropIcons.hasOwnProperty(this.state.crop)
                              ? Lists.cropIcons[this.state.crop]
                              : require("../../Assests/crop_image_unavailable_illustration.png")
                          }
                          style={{ borderRadius: 25 }}
                          width={50}
                          height={50}
                        />
                      </div>
                      <div style={{ margin: 12 }}>
                        <Typography color="textPrimary" align="center">
                          {this.state.crop}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Paper>

            <Paper
              className={classes.paper}
              style={{
                zIndex: 5,
                minHeight: 70,
                margin: 24,
                overflow: "visible",
              }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Autocomplete
                        freeSolo
                        forcePopupIcon={true}
                        autoSelect={true}
                        disableClearable={false}
                        maxMenuHeight={240}
                        size="small"
                        id="tag"
                        options={suggestions}
                        getOptionLabel={(option) => option}
                        defaultValue={[]}
                        onChange={this.handleModelChange}
                        value={this.state.selectedModel}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: "#ffffff" }}
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder="Search by tag"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {this.state.selectedModel == null ? (
                  <Typography color="textSecondary" align="center">
                    No model was selected
                  </Typography>
                ) : (
                  <div
                    style={{
                      marginLeft: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      color={"primary"}
                      value={this.state.published}
                      onChange={this.handleChangePublished}
                      checked={this.state.published}
                      name="published"
                    />
                    <Typography color="textSecondary" align="center">
                      Publish
                    </Typography>
                  </div>
                )}
              </div>
            </Paper>

            <Paper
              className={classes.paper}
              style={{
                zIndex: 3,
                minHeight: 70,
                margin: 24,
                overflow: "visible",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  margin: 12,
                }}
              >
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    disableClearable
                    freeSolo
                    forcePopupIcon={true}
                    autoSelect={true}
                    size="small"
                    id="languages"
                    options={this.state.languages}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={this.handleLanguageChange}
                    value={this.state.language}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Language"
                      />
                    )}
                  />
                </div>
              </div>
            </Paper>

            <Paper
              className={classes.paper}
              style={{
                display: "flex",
                flex: 1,
                margin: 24,
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  margin: 12,
                }}
              >
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    disableClearable
                    freeSolo
                    forcePopupIcon={true}
                    autoSelect={true}
                    size="small"
                    id="pathogen"
                    options={pathogens}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={this.handlePathogenChange}
                    value={this.state.pathogen}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Pathogen"
                      />
                    )}
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <TextField
                    id="Model Name"
                    label="Model Name"
                    fullWidth
                    rowsMax={1}
                    defaultValue=""
                    onChange={this.handleModelNameChange}
                    value={this.state.modelName}
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <TextField
                    id="Min Temperture"
                    label="Min Temperture"
                    fullWidth
                    rowsMax={1}
                    defaultValue=""
                    onChange={this.handleMinTmpChange}
                    value={this.state.minTmp}
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <TextField
                    id="Max Temperture"
                    label="Max Temperture"
                    fullWidth
                    rowsMax={1}
                    defaultValue=""
                    onChange={this.handleMaxTmpChange}
                    value={this.state.maxTmp}
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    id="multiple-limit-tags"
                    options={hostsOptions} //need a list of all crops
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    value={this.state.hosts}
                    onChange={this.handleAddHost}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Potential hosts"
                      />
                    )}
                  />
                </div>
              </div>
            </Paper>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              margin: 12,
            }}
          >
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <div
                style={{
                  marginTop: 0,
                  width: 1000 * 1.3,
                  height: 430,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Carousel
                  deviceType={"desktop"}
                  responsive={responsive}
                  showDots={false}
                  containerClass="first-carousel-container container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-10-px"
                >
                  {items.map((item, index) => {
                    if (item.type === "Add")
                      return (
                        <Paper
                          className={classes.paper}
                          style={{
                            display: "flex",
                            flex: 3,
                            marginLeft: 24,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <div style={{ margin: 8 }}>
                            <IconButton
                              disabled={false}
                              onClick={this.addStage}
                              color="inherit"
                            >
                              <AddIcon
                                className={classes.searchBar}
                                color={"action"}
                              />
                            </IconButton>
                          </div>
                        </Paper>
                      );
                    else
                      return (
                        <Paper
                          className={classes.paper}
                          style={{
                            display: "flex",
                            flex: 3,
                            marginLeft: 24,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <div style={{ margin: 8 }}>
                            <div style={{ display: "flex" }} class="scroll">
                              {item.hasOwnProperty("imgUrl") &&
                              item.imgUrl.source.regular !== "Not Found" ? (
                                <img
                                  style={{
                                    borderRadius: 2,
                                    maxWidth: 156,
                                    maxHeight: 190,
                                  }}
                                  src={item.imgUrl.source.regular}
                                />
                              ) : (
                                <MediaCapture
                                  callback={this.handleUpdateAddedImages}
                                  images={this.state.addedImages}
                                  urls={this.state.addedUrls}
                                  classes={this.props.classes}
                                  maxImages={1}
                                  boxStyle={{
                                    margin: 12,
                                    borderRadius: 2,
                                    width: 160,
                                    height: 194,
                                  }}
                                />
                              )}
                            </div>

                            <div style={{ marginTop: 8, marginBottom: 8 }}>
                              <Autocomplete
                                limitTags={1}
                                size="small"
                                id="Type"
                                options={["biofix", "spraying"]}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                value={item.type}
                                onChange={(event, selection) =>
                                  this.handleStageChange(
                                    index,
                                    "type",
                                    selection
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder="Type"
                                  />
                                )}
                              />
                            </div>

                            <div style={{ marginTop: 8, marginBottom: 8 }}>
                              <Autocomplete
                                limitTags={1}
                                size="small"
                                id="Trigger"
                                options={["gdd", "date"]}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                value={item.trigger}
                                onChange={(event, selection) =>
                                  this.handleStageChange(
                                    index,
                                    "trigger",
                                    selection
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder="Trigger"
                                  />
                                )}
                              />
                            </div>

                            {item.trigger === "gdd" && (
                              <div style={{ marginTop: 16 }}>
                                <TextField
                                  id="prevStageGddThr"
                                  label="Prev GDD threshold"
                                  fullWidth
                                  rowsMax={1}
                                  defaultValue=""
                                  onChange={(event) =>
                                    this.handleStageChange(
                                      index,
                                      "prevStageGddThr",
                                      event.target.value
                                    )
                                  }
                                  value={item.prevStageGddThr}
                                  variant="outlined"
                                />
                              </div>
                            )}
                            {item.trigger === "gdd" && (
                              <div style={{ marginTop: 12 }}>
                                <TextField
                                  id="gddThr"
                                  label="GDD threshold"
                                  fullWidth
                                  rowsMax={1}
                                  defaultValue=""
                                  onChange={(event) =>
                                    this.handleStageChange(
                                      index,
                                      "gddThr",
                                      event.target.value
                                    )
                                  }
                                  value={item.gddThr}
                                  variant="outlined"
                                />
                              </div>
                            )}

                            <div
                              style={{
                                marginTop: 12,
                                marginLeft: 10,
                                padding: 4,
                                backgroundColor: "#ffffff",
                                zIndex: 5,
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: 0,
                                  textAlign: "left",
                                  color: "#000000",
                                }}
                              >
                                Title
                              </Typography>
                            </div>
                            <Editor
                              readOnly={false}
                              editorState={item.title}
                              onChange={(e) =>
                                this.handleStageEditorChange(index, "title", e)
                              }
                            />

                            <div
                              style={{
                                marginTop: 12,
                                marginLeft: 10,
                                padding: 4,
                                backgroundColor: "#ffffff",
                                zIndex: 5,
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: 0,
                                  textAlign: "left",
                                  color: "#000000",
                                }}
                              >
                                Text
                              </Typography>
                            </div>
                            <Editor
                              readOnly={false}
                              editorState={item.text}
                              onChange={(e) =>
                                this.handleStageEditorChange(index, "text", e)
                              }
                            />
                          </div>
                        </Paper>
                      );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              zIndex: 5,
            }}
          ></div>
        </div>

        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            zIndex: 100,
            width: "100%",
            height: 50,
          }}
        >
          <Paper
            className={classes.paper}
            square={true}
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 20,
              width: "100%",
              height: 50,
              backgroundColor: "#ffffff",
            }}
          >
            <Button
              disabled={this.state.selectedModel == null}
              onClick={this.handleDiscard}
              style={{ marginRight: 5 }}
              size="medium"
              className={classes.primary}
            >
              Discard Changes
            </Button>
            <Button
              disabled={this.state.selectedModel == null}
              onClick={this.handleSave}
              variant="contained"
              size="medium"
              color="primary"
              className={classes.primary}
            >
              Save
            </Button>
          </Paper>
        </div>

        <div style={{ height: 60 }} />
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(withFirebase(GDDPage));
