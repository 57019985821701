import React from "react";

import AuthUserContext, {
  WorkgroupIDContext,
  UserTypeContext,
  UserPremiumLevelContext,
} from "./context";
import { withFirebase } from "../Firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        workgroupID: null,
        userType: null,
        premiumLevel: null,
      };
    }

    async componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async (authUser) => {
          if (authUser) {
            // console.log(authUser.uid);
            this.props.firebase
              .workgroupAffiliation(authUser.uid)
              .on("value", async (snap) => {
                let workgroupID = null;
                let userType = "user";
                let premiumLevel = null;

                // console.log(snap.val());

                if (snap.val()) {
                  workgroupID = snap.val();

                  this.props.firebase.updateWorkgroupPath(workgroupID);

                  // console.log(authUser.uid);

                  let snap2 = await this.props.firebase
                    .workgroupAdminList(authUser.uid)
                    .once("value");
                  if (snap2.val()) userType = "admin";

                  // console.log(userType);

                  this.props.firebase.updateGeoRef(userType);
                } else {
                  this.props.firebase.updateWorkgroupPath(workgroupID);

                  let snap2 = await this.props.firebase
                    .workgroupAdminList(authUser.uid)
                    .once("value");
                  if (snap2.val()) userType = "admin";
                  else {
                    let snap3 = await this.props.firebase
                      .workgroupExpertList(authUser.uid)
                      .once("value");
                    if (snap3.val()) userType = "editor";
                  }

                  // console.log("before");

                  this.props.firebase.updateGeoRef(userType);

                  // console.log(userType);

                  let promise1 = new Promise((resolve, reject) => {
                    // console.log(authUser.uid);

                    this.props.firebase
                      .premium(authUser.uid)
                      .on("value", (pSnap) => {
                        // console.log(pSnap.val());

                        let premiumObj = pSnap.val();
                        if (premiumObj) {
                          if (
                            (premiumObj.hasOwnProperty("subscriptionType") &&
                              premiumObj.subscriptionType !== "Premium") ||
                            (premiumObj.hasOwnProperty("type") &&
                              premiumObj.type !== "Premium")
                          )
                            premiumLevel = "Pro";
                          else premiumLevel = "Premium";
                        }

                        resolve();
                      });
                  });

                  await Promise.all([promise1]);

                  // console.log(premiumLevel);
                }
                this.setState({
                  authUser,
                  workgroupID,
                  userType,
                  premiumLevel,
                });
              });
          } else {
            this.setState({
              authUser: null,
              workgroupID: null,
              userType: null,
              premiumLevel: null,
            });
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <WorkgroupIDContext.Provider value={this.state.workgroupID}>
            <UserTypeContext.Provider value={this.state.userType}>
              <UserPremiumLevelContext.Provider value={this.state.premiumLevel}>
                <Component {...this.props} />
              </UserPremiumLevelContext.Provider>
            </UserTypeContext.Provider>
          </WorkgroupIDContext.Provider>
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
