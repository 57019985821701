import React, { Component } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Input from '@material-ui/core/Input';

import TimerIcon from '@material-ui/icons/Timer';
import DialpadIcon from '@material-ui/icons/Dialpad';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from "@material-ui/core/IconButton";

import * as Lists from '../Lists.js';
import * as Helper from "../../Helper";


import { withFirebase } from "../Firebase";
import { withAuthorization } from '../Session';


//import RefreshIcon from '@material-ui/icons/Refresh';

class WorkgroupAnalytics extends Component {
  constructor() {
    super();

    this.state = {
      WGname: "",
      newWGname: "",
      workgroupLogo: null,
      postExpiration: null,
      WGnameDialogOpen: false,
      PEDialogOpen: false,
      totalArea: null
    };
  }

  async componentDidMount() {

    this.props.firebase.db
    .ref(this.props.firebase.workgroupPath + "/analytics")
    .once("value")
    .then(result => {
      if (result.val() && result.val().hasOwnProperty("totalPlotArea"))
       this.setState({totalArea: result.val().totalPlotArea.toFixed(0)});
    })
  }

  componentWillUnmount() {

  }

  render() {

    const { classes } = this.props;

    return (


      <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Paper className={classes.paper} style={{ zIndex: 5, width: 700, minHeight: 70, margin: 12, overflow: "visible" }}>

      <Typography
        style={{margin: 20,
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "#000000",
        }}
      >
        Workgroup analytics
      </Typography>

      <div style={{height:1, margin: 0, backgroundColor: "rgba(0,0,0,0.1)"}}/>

      <div style={{display: "flex", flexDirection: 'row', flex: 3, marginTop: 40}}>

      <div style={{marginBottom: 60, marginLeft: 60, display: "flex", flexDirection: 'row'}}>

{this.state.totalArea!=null &&
      <img style={{maxHeight: 128, maxWidth: 128}} src={require("../../Assests/ic_field_green_18_px.png")}/>
}
{this.state.totalArea!=null &&
      <div style={{display: "flex", flexDirection: 'row', flex: 1}}>
      <Typography
        style={{marginLeft: 40,
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: "normal",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "#000000",
        }}
      >
        {this.state.totalArea + " acres"}
      </Typography>

      </div>
}
      </div>
      </div>


      </Paper>
      <div style={{height: 300}}/>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType!=="admin")
};

export default withAuthorization(condition)(withFirebase(WorkgroupAnalytics));
