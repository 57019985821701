import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TextField from "@material-ui/core/TextField";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AlertDialog from "../AlertDialog";

import CircularProgress from "@material-ui/core/CircularProgress";

import FeedList from "./FeedList.js";

import Chat from "../Chat";
import ImageResponse from "../../Assests/Fast_time.png";
import * as Lists from "../Lists.js";
import * as Helper from "../../Helper";

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

//import RefreshIcon from '@material-ui/icons/Refresh';

class UserFeedbackPage extends Component {
  constructor() {
    super();

    this.state = {
      diseaseList: null,

      selectedImgIndex: 0,
      selectedAfterImgIndex: 0,
      feedPostData: null,

      leftLoaded: false,
      leftLargeLoaded: false,
      rightLoaded: false,
      rightLargeLoaded: false,
      responses: [],
      sharingUser: null,

      feedbackVector: null,
      selectedPostIndex: null,
    };

    this.libraryImgObjPerCrop = {};

    this.memItems = [];
    this.memFeedPostData = {};
  }

  async componentDidMount() {
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/feedbackList")
      .orderByChild("dateForSorting")
      .limitToLast(30)
      .on("value", (snap) => {
        this.updateList(snap.val(), (dataVector) => {
          this.setState(
            { feedbackVector: dataVector, selectedPostIndex: 0 },
            () => {
              this.handlePostSelection(dataVector[0]);
            }
          );
        });
      });
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/feedbackList")
      .off();
  }

  updateList = (Items, callback) => {
    var dataVector = this.state.feedbackVector
      ? [...this.state.feedbackVector]
      : [];
    let promises = [];
    // console.log(Items);
    for (let k in Items) {
      if (this.memItems.hasOwnProperty(k)) continue;

      let int_promises = [];

      let folderName =
        this.props.firebase.workgroupPath === ""
          ? "images/" + Items[k].uid
          : "images/" + this.props.firebase.workgroupPath + "/" + Items[k].uid;
      let fileName = "thumb_" + Items[k].imageName + "_1" + ".jpg";

      int_promises.push(
        this.props.firebase
          .getStorageImg(folderName, fileName, true)
          .then((thumbUrl) => {
            return thumbUrl;
          })
      );

      int_promises.push(
        this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/userNickname")
          .child(Items[k].uid)
          .once("value")
          .then((snapshot) => {
            return snapshot.val() ? snapshot.val().value : "Name not found";
          })
      );

      int_promises.push(
        this.props.firebase.db
          .ref(this.props.firebase.workgroupPath + "/feedback")
          .child(Items[k].uid)
          .child(Items[k].imageName)
          .once("value")
          .then((snapshot) => {
            return snapshot.val();
          })
      );

      promises.push(
        Promise.all(int_promises).then((result) => {
          let thing = {
            name: k,
            imageName: Items[k].imageName,
            thumbUrl: result[0],
            nickname: result[1],
            treatmentResult: result[2].treatmentApplied
              ? result[2].results
              : "Treatment was not applied",
            numAfterImages: result[2].hasOwnProperty("numImages")
              ? result[2].numImages
              : 0,
            sharingUser: Items[k].uid,
            dateForSorting: Items[k].dateForSorting,
          };

          dataVector.push(thing);
        })
      );
    }

    this.memItems = Items;

    Promise.all(promises).then(() => {
      dataVector.sort(function (a, b) {
        return parseFloat(b.dateForSorting) - parseFloat(a.dateForSorting);
      });

      callback(dataVector);
    });
  };

  downloadImages = async (cropName, diseaseTag) => {
    let libraryImgObj = this.libraryImgObjPerCrop.hasOwnProperty(cropName)
      ? { ...this.libraryImgObjPerCrop[cropName] }
      : {};
    //console.log(diseaseTag);
    if (libraryImgObj.hasOwnProperty(diseaseTag)) {
      return Promise.all([]).then(() => {
        return {
          diseaseTag: diseaseTag,
          imgArray: libraryImgObj[diseaseTag].imgArray,
          imgFullResArray: libraryImgObj[diseaseTag].imgFullResArray,
          imgFolderArray: libraryImgObj[diseaseTag].imgFolderArray,
          imgNameArray: libraryImgObj[diseaseTag].imgNameArray,
        };
      });
    }

    let imageInfo = this.state.diseaseList[diseaseTag];

    let promises = [];

    if (
      imageInfo &&
      imageInfo.hasOwnProperty("numImages") &&
      imageInfo.numImages > 0
    ) {
      let imgArray = new Array(imageInfo.numImages);
      let imgFullResArray = new Array(imageInfo.numImages);
      let imgFolderArray = new Array(imageInfo.numImages);
      let imgNameArray = new Array(imageInfo.numImages);

      for (let index = 0; index < imageInfo.numImages; index++) {
        let imgFolder =
          this.props.firebase.workgroupPath === ""
            ? "diseaseInfo/" + cropName + "/" + imageInfo.folderName
            : "diseaseInfo/" +
              this.props.firebase.workgroupPath +
              "/" +
              cropName +
              "/" +
              imageInfo.folderName;

        let imgName = "image" + (index + 1).toString() + ".jpg";

        imgFolderArray[index] = imgFolder;
        imgNameArray[index] = imgName;

        let promise = this.props.firebase
          .getStorageImg(imgFolder, imgName, false)
          .then((url) => {
            imgArray[index] = url;
          });

        promises.push(promise);

        promise = this.props.firebase
          .getStorageImg(imgFolder, imgName, true)
          .then((url2) => {
            imgFullResArray[index] = url2;
          });

        promises.push(promise);
      }

      return Promise.all(promises).then(() => {
        return {
          diseaseTag: diseaseTag,
          imgArray: imgArray,
          imgFullResArray: imgFullResArray,
          imgFolderArray: imgFolderArray,
          imgNameArray: imgNameArray,
        };
      });
    } else
      return {
        diseaseTag: diseaseTag,
        imgArray: [],
        imgFullResArray: [],
        imgFolderArray: [],
        imgNameArray: [],
      };
  };

  handlePostSelection = async (postData) => {
    let { sharingUser, imageName } = postData;

    if (this.memFeedPostData.hasOwnProperty(sharingUser + imageName)) {
      this.setState({
        feedPostData: this.memFeedPostData[sharingUser + imageName],
        selectedImgIndex: 0,
        selectedAfterImgIndex: 0,
      });

      return;
    }
    // console.log(sharingUser);
    // console.log(imageName);
    this.props.firebase.getImgMetaData(sharingUser, imageName, (metaData) => {
      let numOfImages = metaData.numOfImages;
      let cropName = metaData.cropName;
      let country = metaData.hasOwnProperty("country") ? metaData.country : "";
      let city = metaData.hasOwnProperty("city") ? metaData.city : "";
      let humidity = metaData.hasOwnProperty("humidity")
        ? metaData.humidity
        : "";
      let latitude = metaData.hasOwnProperty("latitude")
        ? metaData.latitude
        : "";
      let longitude = metaData.hasOwnProperty("longitude")
        ? metaData.longitude
        : "";
      let gps = metaData.hasOwnProperty("gps") ? metaData.gps : false;
      let imageHardwareSource = metaData.hasOwnProperty("imageSource")
        ? metaData.imageSource
        : "Folder";
      let temp_min = metaData.hasOwnProperty("temp_min")
        ? metaData.temp_min
        : "";
      let temp_max = metaData.hasOwnProperty("temp_max")
        ? metaData.temp_max
        : "";
      let folderGPSdate = metaData.hasOwnProperty("folderGPSdate")
        ? metaData.folderGPSdate
        : null;
      let questionsString = metaData.hasOwnProperty("questionsInfo")
        ? Helper.questionsInfoToString(metaData.questionsInfo)
        : "";

      let feedPostData = {
        numOfImages: numOfImages,
        cropName: cropName,
        country: country,
        city: city,
        humidity: humidity,
        latitude: latitude,
        longitude: longitude,
        gps: gps,
        imageHardwareSource: imageHardwareSource,
        temp_min: temp_min,
        temp_max: temp_max,
        folderGPSdate: folderGPSdate,
        questionsString: questionsString,
        imageSource: ["", "", "", "", "", ""],
        imageSourceHighRes: ["", "", "", "", "", ""],
        cropVerifiedName: [],
        afterImageSource: new Array(postData.numAfterImages),
        afterImageSourceHighRes: new Array(postData.numAfterImages),
      };

      let promises = [];
      //TODO showing the correspondence in the first channel only
      if (metaData.hasOwnProperty("sharedIn"))
        promises.push(
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/channels")
            .child(metaData.sharedIn[0])
            .child("sharedWithMe")
            .child(sharingUser + imageName)
            .child("responses")
            .once("value")
            .then((snapshot) => {
              if (snapshot.val()) {
                feedPostData["responses"] = Object.values(snapshot.val());
              }
            })
        );

      for (let imgNum = 0; imgNum < numOfImages; imgNum++) {
        let promise1 = this.props.firebase
          .getStorageImg(
            this.props.firebase.workgroupPath !== ""
              ? "images/" +
                  this.props.firebase.workgroupPath +
                  "/" +
                  sharingUser
              : "images/" + sharingUser,
            imageName + "_" + (imgNum + 1).toString() + ".jpg",
            false
          )
          .then((url) => {
            feedPostData["imageSource"][imgNum] = url;

            return;
          })
          .catch((err) => {
            console.log(err);
          });

        promises.push(promise1);

        let promise2 = this.props.firebase
          .getStorageImg(
            this.props.firebase.workgroupPath !== ""
              ? "images/" +
                  this.props.firebase.workgroupPath +
                  "/" +
                  sharingUser
              : "images/" + sharingUser,
            imageName + "_" + (imgNum + 1).toString() + ".jpg",
            true
          )
          .then((url2) => {
            feedPostData["imageSourceHighRes"][imgNum] = url2;

            // let img = new Image();
            // img.src = url2;

            return;
          })
          .catch((err) => {
            console.log(err);
          });

        promises.push(promise2);
      }

      for (let imgNum = 0; imgNum < postData.numAfterImages; imgNum++) {
        let promise1 = this.props.firebase
          .getStorageImg(
            this.props.firebase.workgroupPath !== ""
              ? "feedback/" +
                  this.props.firebase.workgroupPath +
                  "/" +
                  sharingUser
              : "feedback/" + sharingUser,
            imageName + "_" + (imgNum + 1).toString() + ".jpg",
            false
          )
          .then((url) => {
            feedPostData["afterImageSource"][imgNum] = url;

            return;
          })
          .catch((err) => {
            console.log(err);
          });

        promises.push(promise1);

        let promise2 = this.props.firebase
          .getStorageImg(
            this.props.firebase.workgroupPath !== ""
              ? "feedback/" +
                  this.props.firebase.workgroupPath +
                  "/" +
                  sharingUser
              : "feedback/" + sharingUser,
            imageName + "_" + (imgNum + 1).toString() + ".jpg",
            true
          )
          .then((url2) => {
            feedPostData["afterImageSourceHighRes"][imgNum] = url2;
            return;
          })
          .catch((err) => {
            console.log(err);
          });

        promises.push(promise2);
      }

      Promise.all(promises)
        .then(() => {
          this.setState({
            feedPostData: feedPostData,
            selectedImgIndex: 0,
            selectedAfterImgIndex: 0,
            leftLoaded: false,
            leftLargeLoaded: false,
          });

          this.memFeedPostData[sharingUser + imageName] = feedPostData;
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleLeftLoaded = () => {
    if (this.state.leftLoaded == false) this.setState({ leftLoaded: true });
  };

  handleLeftLargeLoaded = () => {
    if (this.state.leftLargeLoaded == false)
      this.setState({ leftLargeLoaded: true });
  };

  handleRightLoaded = () => {
    if (this.state.rightLoaded == false) this.setState({ rightLoaded: true });
  };

  handleRightLargeLoaded = () => {
    if (this.state.rightLargeLoaded == false)
      this.setState({ rightLargeLoaded: true });
  };

  handlePostSelected = (index) => {
    this.setState({ selectedPostIndex: index, feedPostData: null }, () => {
      this.handlePostSelection(this.state.feedbackVector[index]);
    });
  };

  render() {
    const { classes, selectedTab } = this.props;

    if (this.state.feedbackVector == null)
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      );
    if (this.state.feedbackVector && this.state.feedbackVector.length == 0)
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ height: "140px" }} src={ImageResponse} />
          <Typography
            style={{
              marginTop: 8,
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: "bold",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            No feedback to show
          </Typography>
          <Typography
            style={{
              marginTop: 8,
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "center",
              color: "#000000",
            }}
          >
            Once feedbacks are shared by the members they will show up here
          </Typography>
        </div>
      );
    else
      return (
        <div
          style={{
            marginTop: 10,
            marginLeft: 10,
            marginBottom: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.state.feedbackVector && (
            <Paper
              className={classes.paper}
              style={{
                overflowY: "scroll",
                height: 902,
                width: 200,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FeedList
                selectedIndex={this.state.selectedPostIndex}
                Entries={this.state.feedbackVector}
                callback={this.handlePostSelected}
              />
            </Paper>
          )}

          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.state.feedPostData && (
              <Paper
                className={classes.paper}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <AppBar
                  className={classes.searchBar}
                  position="sticky"
                  color="default"
                  elevation={0}
                >
                  {this.state.feedPostData && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{
                          margin: 16,
                          fontFamily: "Roboto",
                          fontSize: 18,
                          fontWeight: "normal",
                          fontStyle: "normal",
                          letterSpacing: 0,
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        {this.state.feedPostData.cropName}
                      </Typography>
                      <Typography
                        style={{
                          margin: 16,
                          fontFamily: "Roboto",
                          fontSize: 18,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          letterSpacing: 0,
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        {
                          this.state.feedbackVector[
                            this.state.selectedPostIndex
                          ].treatmentResult
                        }
                      </Typography>
                    </div>
                  )}
                </AppBar>

                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: 500,
                      height: 500,
                      overflowY: "scroll",
                      direction: "rtl",
                      margin: 10,
                    }}
                  >
                    <GlassMagnifier
                      style={{
                        width: "100%",
                        height: "auto",
                        opacity: this.state.leftLargeLoaded ? 1 : 0.1,
                      }}
                      imageSrc={
                        this.state.feedPostData.imageSource[
                          this.state.selectedImgIndex
                        ]
                      }
                      imageAlt={this.state.selectedImgIndex}
                      largeImageSrc={
                        this.state.feedPostData.imageSourceHighRes[
                          this.state.selectedImgIndex
                        ]
                      }
                      magnifierSize="50%"
                      onImageLoad={this.handleLeftLoaded}
                      onLargeImageLoad={this.handleLeftLargeLoaded}
                    />
                    {this.state.leftLargeLoaded == false && (
                      <CircularProgress
                        style={{
                          zIndex: 10,
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                        disableShrink
                      />
                    )}
                  </div>
                  {this.state.feedPostData.hasOwnProperty("afterImageSource") &&
                    this.state.feedPostData.hasOwnProperty(
                      "afterImageSourceHighRes"
                    ) &&
                    this.state.feedPostData.afterImageSource.length > 0 &&
                    this.state.feedPostData.afterImageSourceHighRes.length >
                      0 && (
                      <div
                        style={{
                          position: "relative",
                          width: 500,
                          height: 500,
                          overflowY: "scroll",
                          margin: 10,
                        }}
                      >
                        <div>
                          <GlassMagnifier
                            style={{
                              zIndex: 0,
                              width: "100%",
                              height: "auto",
                              opacity: this.state.rightLargeLoaded ? 1 : 0.1,
                            }}
                            imageSrc={
                              this.state.feedPostData.afterImageSource[
                                this.state.selectedAfterImgIndex
                              ]
                            }
                            imageAlt="User's image"
                            largeImageSrc={
                              this.state.feedPostData.afterImageSourceHighRes[
                                this.state.selectedAfterImgIndex
                              ]
                            }
                            magnifierSize="50%"
                            onImageLoad={this.handleRightLoaded}
                            onLargeImageLoad={this.handleRightLargeLoaded}
                          />
                          {this.state.rightLargeLoaded == false && (
                            <CircularProgress
                              style={{
                                zIndex: 10,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                              disableShrink
                            />
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </Paper>
            )}

            {this.state.feedPostData && this.state.feedbackVector && (
              <Paper
                className={classes.paper}
                style={{
                  marginTop: 20,
                  width: 520,
                  height: 300,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Chat
                  width={520}
                  myUID={null}
                  answeringUser={null}
                  sharingUser={
                    this.state.feedbackVector[this.state.selectedPostIndex]
                      .sharingUser
                  }
                  responses={
                    this.state.feedPostData.responses
                      ? this.state.feedPostData.responses
                      : []
                  }
                  includeMessaging={false}
                />
              </Paper>
            )}
          </div>

          <AlertDialog
            onRef={(actualChild) => (this.alertDialog = actualChild)}
          />
        </div>
      );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(withFirebase(UserFeedbackPage));
