import React, { Component } from "react";

import GDDPage from "../GDDPage";
import AItrainingPage from "../AItrainingPage";
import TreatmentProtocolsPage from "../TreatmentProtocolsPage";
import ResponseTool from "../FeedPage/ResponseTool";
import UserFeedbackPage from "../UserFeedbackPage";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

//import RefreshIcon from '@material-ui/icons/Refresh';

class TreatmentPage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  async componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, selectedTab } = this.props;

    return (
      <div>
        {(() => {
          switch (selectedTab) {
            case 0:
              return <TreatmentProtocolsPage classes={classes} />;
            case 1:
              return <ResponseTool classes={classes} />;
            case 2:
              return <UserFeedbackPage classes={classes} />;
            case 3:
              return <GDDPage classes={classes} />;
            case 4:
              return <AItrainingPage classes={classes} />;
            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(withFirebase(TreatmentPage));
