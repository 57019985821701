import React from "react";
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import "react-tabs/style/react-tabs.css";
import Base from "../Base/Base.js";

import { withAuthorization } from "../Session";

const Home = () => <Base />;

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  // console.log(userType);
  // console.log(!(!authUser || userType!=="admin"));
  return !(
    !authUser ||
    (workgroupID == null &&
      userType !== "admin" &&
      premiumLevel === null &&
      premiumLevel === "Premium")
  );
};

export default withAuthorization(condition)(Home);
