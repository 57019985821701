import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import SignUpComponent from "./SignUpComponent";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import * as Helper from "../../Helper";

import Background from "../../Assests/Background.png";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";
import { withTranslation } from "react-i18next";

const isMobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;
const width = window.innerWidth;

const SignUpPage = (props) => (
  <Fragment>
    <section style={sectionStyle}>
      <div style={{ width: "100%" }}>
        <Navigation type={"minimal"} />
      </div>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={0}
      >
        <Grid
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          xs={24}
        >
          <SignUpForm
            handleChangeToSignIn={() => props.history.push(ROUTES.SIGN_IN)}
          />
        </Grid>
      </Grid>

      <Grid
        style={{
          paddingLeft: isMobile ? 0 : 100,
          paddingRight: isMobile ? 0 : 100,
        }}
        container
        spacing={2}
      >
        <Grid item xs={24}>
          <div
            style={{
              marginLeft: isMobile ? "50px" : "0px",
              marginTop: isMobile ? "0px" : "80px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={24}>
                <p
                  style={{
                    width: isMobile ? width - 80 : "528px",
                    fontSize: 32,
                    font: "Roboto",
                    fontWeight: "Bold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Join Agrio, the most advanced crop protection app on the
                  planet
                </p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {false && (
                    <Link to={"/google-play"}>
                      <img
                        style={{ height: "35.23px" }}
                        src={GooglePlayButton}
                      />
                    </Link>
                  )}
                  {false && (
                    <Link to={"/apple-store"}>
                      <img
                        style={{ marginLeft: "12px", height: "35.23px" }}
                        src={AppStoreButton}
                      />
                    </Link>
                  )}
                </div>
              </Grid>
              <Grid item xs={24}>
                <p
                  style={{
                    width: isMobile ? width - 80 : "528px",
                    height: "75px",
                    fontSize: 32,
                    font: "Roboto",
                    fontWeight: "Bold",
                    color: "#ffffff",
                  }}
                ></p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Link to={"/facebook-page"} target="_blank">
                    <img style={{}} src={FacebookIcon} />
                  </Link>
                  <Link to={"/linkedin-page"} target="_blank">
                    <img src={LinkedInIcon} />
                  </Link>
                  <Link to={"/instegram-page"} target="_blank">
                    <img src={InstegramIcon} />
                  </Link>
                  <Link to={"/twitter-page"} target="_blank">
                    <img src={TwitterIcon} />
                  </Link>
                </div>
              </Grid>
            </Grid>
            <p
              style={{
                width: isMobile ? width - 80 : "528px",
                marginTop: "150px",
                fontSize: 11,
                font: "Roboto",
                fontWeight: "Regular",
                color: "#4A4A4A",
              }}
            >
              <a>All rights reserved to Saillog LTD. 2019 - </a>
              <Link to={"/terms-conditions"} target="_blank">
                Terms of Use
              </Link>
              <a> | </a>
              <Link to={"/privacy-policy"} target="_blank">
                Privacy Policy{" "}
              </Link>
              <a> | </a>
              <a>Contact</a>
            </p>
          </div>
        </Grid>
      </Grid>
    </section>
  </Fragment>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  confirmPassword: "",
  error: null,
};

var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      async (authUser) => {
        if (authUser) {
          let email = authUser.hasOwnProperty("email") ? authUser.email : "";

          this.handleSetUserInfo(email).then(() => {
            if (this.props.hasOwnProperty("handleSignUpComplete"))
              this.props.handleSignUpComplete();
            else this.props.history.push(ROUTES.USERROUTING);
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  nickNameToUserID = (workgroupPath, nickname) => {
    return new Promise((resolve, reject) => {
      this.props.firebase.db
        .ref("/userNickname/")
        .orderByChild("value")
        .equalTo(nickname)
        .once("value")
        .then((snapshot) => {
          //console.log(snapshot.val());
          if (snapshot.val()) {
            return resolve(Object.keys(snapshot.val())[0]);
          } else return resolve("");
        });
    });
  };

  generateNickname = () => {
    return new Promise((resolve, reject) => {
      var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
      let text = "";
      for (var i = 0; i < 12; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return this.nickNameToUserID("", text).then((userID) => {
        if (userID === "") {
          return resolve(text);
        } else
          return this.generateNickname().then((result) => {
            return resolve(result);
          });
      });
    });
  };

  handleSetUserInfo = async (email) => {
    let date = new Date();
    let dateForSorting = date * 1;

    let user = this.props.firebase.auth.currentUser;

    return this.props.firebase.db
      .ref("/user/")
      .child(user.uid)
      .child("info")
      .once("value")
      .then((exist) => {
        if (exist.val()) return;

        this.generateNickname().then((nickname) => {
          //console.log(user);

          let userPath = "/user/" + user.uid;

          let nicknamePath = "/userNickname/" + user.uid;

          let info = {
            nickname: nickname,
            aboutMe: "",
          };

          //console.log(nickname);

          this.props.firebase._setDatabase(
            userPath + "/email",
            email.trim(),
            1000
          );

          this.props.firebase._setDatabase(userPath + "/name", nickname, 1000);

          this.props.firebase._setDatabase(userPath + "/info", info, 1000);

          this.props.firebase._setDatabase(
            nicknamePath,
            { value: nickname },
            1000
          );

          if (global.referalUID)
            Helper.getUniqueBrowserHash((fp) => {
              this.props.firebase._setDatabase(
                "/referalCounter/" + global.referalUID + "/" + fp,
                { date: dateForSorting, uid: user.uid },
                1000
              );
            });

          return;
        });
      })
      .catch((err) => console.log(err));
  };

  onSubmit = (event) => {
    const { email, password, confirmPassword } = this.state;

    if (password !== confirmPassword)
      this.setState({ error: { message: "Passwords Don't Match" } });

    let fixed_email = email.replace(/\s/g, "");

    this.props.firebase.auth
      .createUserWithEmailAndPassword(fixed_email, password)
      .then((authUser) => {
        //global.premiumSubscribed = false;
        // this.handleSetUserInfo(fixed_email).then(() => {
        //   if (this.props.hasOwnProperty("handleSignUpComplete"))
        //     this.props.handleSignUpComplete();
        //   else this.props.history.push(ROUTES.USERROUTING);
        // });
      })
      .catch((error) => {
        this.setState({
          error: { message: error.message },
        });
      });

    //    this.props.firebase
    //      .doCreateUserWithEmailAndPassword(email, password)
    //      .then(authUser => {
    // Create a user in your Firebase realtime database
    //        return this.props.firebase
    //          .user(authUser.user.uid)
    //          .set({
    //            email,
    //          });
    // NEED TO CHANGE HERE THE SET PROPORTIES
    //      })
    //      .then(() => {
    //        this.setState({ ...INITIAL_STATE });
    //        this.props.history.push(ROUTES.HOME);
    //      })
    //      .catch(error => {
    //        this.setState({ error });
    //      });

    //    event.preventDefault();
  };
  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  onChangeConfirmPassword = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  signUpWithGoogle = async () => {
    this.props.firebase
      .signInWithGoogle()
      .then((snap) => {
        console.log(snap);
      })
      .catch((error) => {
        this.setState({ message: error });
      });
  };

  signUpWithApple = async () => {
    this.props.firebase.signInWithApple().catch((error) => {
      this.setState({ message: error });
    });
  };

  // handleChangeToSignIn = () => {
  //   this.props.history.push(ROUTES.SIGN_IN);
  // }

  render() {
    const { email, password, error } = this.state;
    const { t } = this.props;

    return (
      <SignUpComponent
        error={error}
        isMobile={isMobile}
        width={width}
        email={email}
        password={password}
        t={t}
        onSubmit={this.onSubmit}
        onChangeEmail={this.onChangeEmail}
        onChangePassword={this.onChangePassword}
        onChangeConfirmPassword={this.onChangeConfirmPassword}
        handleChangeToSignIn={this.props.handleChangeToSignIn}
        signUpWithGoogle={this.signUpWithGoogle}
        signUpWithApple={this.signUpWithApple}
      />
    );
  }
}

const SignUpLink = () => (
  <p style={{ fontSize: 16, font: "Roboto" }}>
    Dont have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
  withTranslation()
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
