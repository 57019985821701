export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const DEADEND = "/DeadEnd";
//export const PRICING = "/Pricing";
export const ACCOUNTDELETION = "/account-deletion";
export const TRIAL = "/trial";
export const ADS = "/advertisers";
export const USERROUTING = "/UserRouting";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const API_DOCS = "/api-documentation";

export const PHYTOPATHOLOGY = "/home/phytopathology";
export const FIELDS = "/home/fields";
export const MEMBERS = "/home/members";
export const FEED = "/home/feed";
