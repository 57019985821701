import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import PanToolIcon from "@material-ui/icons/PanTool";
import GestureIcon from "@material-ui/icons/Gesture";
import * as Lists from "./Lists.js";
import * as Helper from "../Helper.js";

import { withFirebase } from "./Firebase";

const suggestions = Object.keys(Lists.cropIcons).map((item) => {
  return { value: item, label: item };
});

class AddField extends Component {
  constructor() {
    super();
    this.state = {
      fieldName: "",
      cropName: null,
      fieldType: "Nonorganic",
      variety_suggestions: [],
      variety: null,
    };
  }

  componentDidMount() {}

  handleSelectCrop = (selected) => {
    this.setState({ cropName: selected.value, variety: null });

    this.props.firebase.db
      .ref("/cropVarieties/")
      .child(selected.value)
      .once("value", (result) => {
        if (result.val()) {
          let array_v = result.val().map((item) => {
            return { value: item, label: item };
          });
          this.setState({ variety_suggestions: array_v });
        } else this.setState({ variety_suggestions: [] });
      });
  };

  handleDoneClick = () => {
    let coords = this.props.drawingPolyCoordinates;

    let avgLat = 0;
    let avgLng = 0;

    let points = [];
    let coordinates = [];

    for (let i = 0; i < coords.length; i++) {
      avgLat = avgLat + coords[i].lat / coords.length;
      avgLng = avgLng + coords[i].lng / coords.length;
      points.push([coords[i].lng, coords[i].lat]);
      coordinates.push({ latitude: coords[i].lat, longitude: coords[i].lng });
    }

    let polygon = [{ coordinates: coordinates }];

    let marker = { latitude: avgLat, longitude: avgLng };

    let area = Helper.polygonArea(points);

    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    this.props.saveField({
      cropName: this.state.cropName,
      variety: this.state.variety,
      fieldName: this.state.fieldName,
      fieldType: this.state.fieldType,
      fieldArea: area,
      marker: marker,
      polygon: polygon,
      fieldID: dateForSorting,
    });
  };

  handlePanClick = () => {
    this.props.drawingManager.setDrawingMode(null);
  };

  handleDrawClick = () => {
    if (this.props.polygonShape) {
      this.props.polygonShape.setMap(null);
      this.props.updateCoords(null, null);
    }

    this.props.drawingManager.setDrawingMode(
      this.props.google.maps.drawing.OverlayType.POLYGON
    );
  };

  render() {
    const { drawingPolyCoordinates, classes } = this.props;
    // const suggestions = FarmerNames.map((name, index) => ({
    // value: name,
    // label: name,
    // UID: FarmerUIDs[index],
    // }));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            margin: 16,
            borderRadius: 4,
            backgroundColor: "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 3,
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <img src={require("../Assests/edit.png")} width={16} height={16} />
            <Input
              id="filled-basic"
              label="Password"
              margin="none"
              variant="outlined"
              style={{
                fontSize: 16,
                font: "Roboto",
                color: "#000000",
                borderRadius: 4,
                marginLeft: "8px",
                width: "120px",
                height: "16px",
                backgroundColor: "#ffffff",
              }}
              disableUnderline={true}
              placeholder={"Field name"}
              value={this.state.fieldName}
              onChange={(event) =>
                this.setState({ fieldName: event.target.value })
              }
            />
          </div>
          <div style={{ display: "flex", alignSelf: "center", flex: 1 }}>
            <IconButton
              disabled={0 == 1}
              onClick={this.handlePanClick}
              color="inherit"
            >
              <PanToolIcon
                className={classes.block}
                fontSize={"small"}
                color={0 == 0 ? "action" : "disabled"}
              />
            </IconButton>
          </div>
          <div style={{ display: "flex", alignSelf: "center", flex: 1 }}>
            <IconButton onClick={this.handleDrawClick} color="inherit">
              <GestureIcon
                className={classes.block}
                fontSize={"small"}
                color={"action"}
              />
            </IconButton>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            margin: 16,
            borderRadius: 4,
            width: "220px",
            backgroundColor: "#ffffff",
          }}
        >
          <div style={{ width: 70 }}>
            <Typography color="textSecondary" align="left">
              Crop:
            </Typography>
          </div>
          <div style={{ width: 145 }}>
            <CreatableSelect
              options={suggestions}
              value={{ label: this.state.cropName, value: this.state.cropName }}
              maxMenuHeight={200}
              onChange={this.handleSelectCrop}
              placeholder=""
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            margin: 16,
            borderRadius: 4,
            width: "220px",
            backgroundColor: "#ffffff",
          }}
        >
          <div style={{ width: 70 }}>
            <Typography color="textSecondary" align="left">
              Variety:
            </Typography>
          </div>
          <div style={{ width: 145 }}>
            <CreatableSelect
              options={this.state.variety_suggestions}
              value={{ label: this.state.variety, value: this.state.variety }}
              maxMenuHeight={200}
              onChange={(selected) =>
                this.setState({ variety: selected.value })
              }
              placeholder=""
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            margin: 16,
            borderRadius: 4,
            width: "220px",
            backgroundColor: "#ffffff",
          }}
        >
          <div style={{ width: 70 }}>
            <Typography color="textSecondary" align="left">
              Method:
            </Typography>
          </div>
          <div style={{ width: 145 }}>
            <Select
              options={[
                { label: "Organic", value: "Organic" },
                { label: "Nonorganic", value: "Nonorganic" },
              ]}
              value={{
                label: this.state.fieldType,
                value: this.state.fieldType,
              }}
              maxMenuHeight={240}
              onChange={(selected) =>
                this.setState({ fieldType: selected.value })
              }
              placeholder=""
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 16,
            borderRadius: 4,
            width: "220px",
            backgroundColor: "#ffffff",
          }}
        >
          <Typography variant="subtitle2" color="error" align="left">
            Draw on the map the boundary of the field and fill the information
            above
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            marginRight: 12,
            marginTop: 8,
            borderRadius: 4,
            backgroundColor: "#ffffff",
          }}
        >
          <IconButton
            disabled={
              this.state.cropName == null ||
              this.state.variety == null ||
              this.state.fieldName.length == 0 ||
              drawingPolyCoordinates == null
            }
            onClick={this.handleDoneClick}
            color="inherit"
          >
            <DoneIcon
              className={classes.block}
              color={
                this.state.cropName == null ||
                this.state.variety == null ||
                this.state.fieldName.length == 0 ||
                drawingPolyCoordinates == null
                  ? "disabled"
                  : "action"
              }
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

AddField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withFirebase(AddField);
