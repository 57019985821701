import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "../Firebase";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import ReactPixel from "react-facebook-pixel";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const script = document.createElement("script");
script.src = "https://gateway.bluesnap.com/js/cse/v1.0.4/bluesnap.js";
script.async = true;
document.body.appendChild(script);

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class Checkout extends Component {
  constructor() {
    super();
    this.state = {
      expDate: "",
      successValidation: true,
      errorMeessage: "",
      submitDisabled: false,
      cardType: null,
    };

    this.submitDisabled = false;
  }

  componentDidMount() {
    const script2 = document.createElement("script");
    script2.src = "./bluesnap.js";
    script2.async = true;
    document.body.appendChild(script2);
  }

  componentWillUnmount() {
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf("bluesnap") !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  }

  wait = (interval) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return resolve();
      }, interval);
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.submitDisabled == true) return;

    this.submitDisabled = true;
    this.setState({ submitDisabled: true });

    if (this.isValid()) {
      this.setState({ successValidation: true, error: false });

      let encryptedCardNumber = null;
      let encryptedSecurityCode = null;
      let delay = 100;

      while (encryptedCardNumber == null) {
        await this.wait(delay);

        // let formData = new FormData(event.currentTarget);

        let myForm = document.getElementById("checkout-form");
        let formData = new FormData(myForm);

        encryptedCardNumber = formData.get("encryptedCreditCard");
        encryptedSecurityCode = formData.get("encryptedCvv");

        delay = delay * 2;
      }

      let date = new Date();
      let dateForSorting = date * 1;

      let id = dateForSorting + this.props.firebase.auth.currentUser.uid;

      let oneTimeAmount = this.props.oneTimeAmount;
      let subscriptionType = this.props.packageType; //Or "Premium"
      let countryCode = this.props.countryCode; //Or "Premium"

      // console.log(id);
      // console.log(subscriptionType);
      // console.log(countryCode);

      let expDate = this.state.expDate.split("/");

      //console.log(expDate);

      this.props.firebase.db.ref("/FBfunctionJobs/BlueSnapJob/").child(id).set({
        uid: this.props.firebase.auth.currentUser.uid,
        email: this.state.email,
        nameOnInvoice: this.state.nameOnInvoice,
        firstName: this.state.firstname,
        lastName: this.state.lastname,
        oneTimeAmount: oneTimeAmount,
        subscriptionType: subscriptionType,
        countryCode: countryCode,
        expirationMonth: expDate[0],
        expirationYear: expDate[1],
        encryptedCardNumber: encryptedCardNumber,
        encryptedSecurityCode: encryptedSecurityCode,
        zip: this.state.zip,
        cardType: this.state.cardType,
      });

      //console.log("/FBfunctionResults/BlueSnapJob/" + this.props.firebase.auth.currentUser.uid + "/" + id);

      this.props.firebase.db
        .ref("/FBfunctionResults/BlueSnapJob/")
        .child(this.props.firebase.auth.currentUser.uid)
        .child(id)
        .on("value", (res) => {
          //console.log(res.val());
          if (res.val()) {
            //console.log("inside");
            if (res.val().success == true) {
              if (subscriptionType) {
                ReactPixel.trackCustom("Bluesnap started " + subscriptionType, {
                  country: countryCode,
                });

                this.props.firebase.analytics.logEvent(
                  "Bluesnap_started_" + subscriptionType
                );
              } else if (oneTimeAmount) {
                this.props.callback();
              }
              this.props.handleClose();
            } else {
              // console.log("add some error message");
              //res.val().error.message[0].description
              let err = JSON.parse(res.val().error);
              this.submitDisabled = false;
              this.setState({
                errorMessage: err.message[0].description,
                submitDisabled: false,
              });
            }

            this.props.firebase.db
              .ref("/FBfunctionResults/BlueSnapJob/")
              .child(this.props.firebase.auth.currentUser.uid)
              .child(id)
              .off();
          }
        });
    } else {
      this.submitDisabled = false;
      this.setState({
        successValidation: false,
        error: true,
        submitDisabled: false,
      });
    }

    // fetch('https://your-node-server-here.com/api/endpoint', {
    //     method: 'POST',
    //     // We convert the React state to JSON and send it as the POST body
    //     body: JSON.stringify(this.state)
    //   }).then(function(response) {
    //     console.log(response)
    //     return response.json();
    //   });
  };

  handleEmailChange = (event) => {
    const { value } = event.target;
    this.setState({ email: value });
  };

  handleInvoiceNameChange = (event) => {
    const { value } = event.target;
    this.setState({ nameOnInvoice: value });
  };

  handleFirstNameChange = (event) => {
    const { value } = event.target;
    this.setState({ firstname: value });
  };

  handleLastNameChange = (event) => {
    const { value } = event.target;
    this.setState({ lastname: value });
  };

  handleChangeExpDate = (event) => {
    const { value } = event.target;

    this.setState({
      expDate: value,
      error: false,
      successValidation: true,
    });

    // console.log(textTemp);
    // this.setState({expDate: textTemp})
  };

  handleZipChange = (event) => {
    const { value } = event.target;
    this.setState({
      zip: value,
    });
  };

  isValid = () => {
    const { expDate } = this.state;
    return (
      expDate.length == 7 &&
      parseInt(expDate[0]) < 2 &&
      parseInt(expDate.substring(0, 2)) < 13 &&
      parseInt(expDate.substring(0, 2)) > 0 &&
      expDate[2] === "/"
    );
  };

  handleChangeCardType = (event, selection) => {
    this.setState({ cardType: selection === "Other" ? null : selection });
  };

  render() {
    const { classes } = this.props;
    const { error, successValidation } = this.state;
    const expStyle = successValidation ? { color: "black" } : { color: "red" };

    return (
      <form
        onSubmit={this.handleSubmit}
        method="POST"
        id="checkout-form"
        name="checkout-form"
      >
        <CssBaseline />

        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={this.props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <main className={classes.layout}>
          <div className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>

            <React.Fragment>
              <React.Fragment>
                {this.props.packageType && (
                  <React.Fragment>
                    <Typography variant="h8">
                      * Email us at info@saillog.co to cancel at any time.
                    </Typography>
                  </React.Fragment>
                )}

                <React.Fragment>
                  <Typography variant="h6" gutterBottom>
                    Items
                  </Typography>
                </React.Fragment>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                    }}
                  >
                    <Typography variant="h8">
                      {this.props.packageType
                        ? "A monthly recurrent charges for the subscription plan"
                        : "API key creation with 1000 image inquery credits"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <Typography variant="h8" align="right">
                      {this.props.priceToShow}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    marginBottom: "16px",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Invoice
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="email"
                        onChange={this.handleEmailChange}
                        label="Email"
                        fullWidth
                        autoComplete="cc-email"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="invoiceName"
                        onChange={this.handleInvoiceNameChange}
                        label="Name on invoice"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </div>

                <React.Fragment>
                  <Typography variant="h6" gutterBottom>
                    Payment method
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="cardNumber"
                        label="Card number"
                        fullWidth
                        autoComplete="cc-number"
                        inputProps={{ "data-bluesnap": "encryptedCreditCard" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="creditCardFirstName"
                        onChange={this.handleFirstNameChange}
                        label="Cardholder first name"
                        fullWidth
                        autoComplete="cc-name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="creditCardLastName"
                        onChange={this.handleLastNameChange}
                        label="Cardholder last name"
                        fullWidth
                        autoComplete="cc-name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="zip"
                        onChange={this.handleZipChange}
                        name="zip"
                        label="Zip / Postal code"
                        fullWidth
                        autoComplete="shipping postal-code"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="expDate"
                        onChange={this.handleChangeExpDate}
                        value={this.state.expDate}
                        label="Expiry date"
                        helperText="MM/YYYY"
                        fullWidth
                        autoComplete="cc-exp"
                        InputProps={{
                          style: expStyle,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        freeSolo
                        forcePopupIcon={true}
                        autoSelect={true}
                        disableClearable={false}
                        maxMenuHeight={240}
                        size="small"
                        id="tag"
                        options={[
                          "American Express",
                          "Argencard",
                          "Cabal",
                          "Carte Bleue",
                          "China Union Pay",
                          "Cencosud",
                          "Diners Club",
                          "Discover",
                          "Elo",
                          "Hipercard",
                          "JCB",
                          "Master Card",
                          "Tarjeta Naranja",
                          "Tarjeta Shopping",
                          "Visa",
                          "Other",
                        ]}
                        style={{ marginTop: 12 }}
                        getOptionLabel={(option) => option}
                        defaultValue={[]}
                        onChange={this.handleChangeCardType}
                        value={this.state.cardType}
                        renderInput={(params) => (
                          <TextField
                            style={{ backgroundColor: "#ffffff" }}
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder="Card type"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="cvv"
                        label="CVV"
                        helperText="Last three digits on signature strip"
                        fullWidth
                        autoComplete="cc-csc"
                        inputProps={{ "data-bluesnap": "encryptedCvv" }}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>

                <div>
                  <Typography color="Secondary" align="center">
                    {this.state.errorMessage}
                  </Typography>
                </div>

                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="submit-button"
                    className={classes.button}
                    disabled={this.state.submitDisabled}
                  >
                    {this.props.packageType ? "Subscribe" : "Purchase"}
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 30,
                    alignItems: "center",
                  }}
                >
                  <a
                    href="//www.securitymetrics.com/site_certificate?id=1780474&tk=29507d67447c813c9a015bd7bf797568"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.securitymetrics.com/static/apps/super/assets/img/Credit_Card_Safe_blue.2.png"
                      alt="SecurityMetrics Credit Card Safe"
                    />
                  </a>
                </div>
              </React.Fragment>
            </React.Fragment>
          </div>
        </main>
      </form>
    );
  }
}

export default withStyles(styles)(withFirebase(Checkout));
