import React from 'react';
import { withFirebase } from '../Firebase';


const SignOutButton = ({ firebase }) => (
  <p style={{fontSize: 15, font: "Helvectica", fontWeight: "Bold", color:"#4A4A4A", backgroundColor: "#ffffff"}}
      onClick={firebase.doSignOut}>
    Sign Out
  </p>
);

export default withFirebase(SignOutButton);
