import React, { Component } from "react";
//import GoogleMapReact from 'google-map-react';
import { Map, Marker, Polygon } from "google-maps-react";

import MarkerCluster from "./MarkerClustering/MarkerCluster.js";
import UrlTiles, { initTiles } from "./UrlTiles/UrlTiles.js";
import PropTypes from "prop-types";
import * as Helper from "../Helper";

import { withFirebase } from "./Firebase";

import Typography from "@material-ui/core/Typography";

import MapDrawing, { initDrawing } from "./MapDrawing/MapDrawing.js";
import Paper from "@material-ui/core/Paper";

import { GeoFire } from "geofire";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class FarmersMap extends Component {
  constructor() {
    super();

    let cookieMapPosition = cookies.get("mapPosition");

    this.state = {
      locations: [],
      Markers: null,
      aggSW: cookieMapPosition ? cookieMapPosition.center : { lat: 5, lng: 30 },
      aggNE: cookieMapPosition ? cookieMapPosition.center : { lat: 5, lng: 30 },
      markerArray: [],
      polygons: [],
      tiles: [],
      markersWithPolygon: [],
      bounds: null,
      center: cookieMapPosition
        ? cookieMapPosition.center
        : { lat: 5, lng: 30 },
      zoom: cookieMapPosition ? cookieMapPosition.zoom : 5,
      selectedDate: null,
    };

    this.fieldInfo = {};
    this.tileZoom = 5;
    this.tileInfo = [];
    for (let i = 1; i < 1024; i++) this.tileInfo.push({});
  }

  async componentDidMount() {
    //boundsToCenterAndRadius
    initDrawing();
    initTiles();

    // let center = [
    //   (this.state.aggSW.lat + this.state.aggNE.lat) / 2,
    //   (this.state.aggSW.lng + this.state.aggNE.lng) / 2
    // ];

    this.setState({ tileVisState: this.props.tileVisState }, () => {
      if (this.props.menuType === "WGAdmin") this.queryLocationsOnMap();
      else
        this.importUserFields(this.props.firebase.auth.currentUser.uid).then(
          () => {
            this.fieldInFrame(this.fieldInfo);
          }
        );
    });

    window.addEventListener("beforeunload", this.handleWindowClose);
  }

  componentWillReceiveProps = (nextProps) => {
    //console.log(nextProps);

    if (
      nextProps.bounds &&
      JSON.stringify(this.props.bounds) !== JSON.stringify(nextProps.bounds)
    ) {
      var bounds = new this.props.google.maps.LatLngBounds();
      for (var i = 0; i < nextProps.bounds.length; i++) {
        bounds.extend(nextProps.bounds[i]);
      }

      this.setState({
        bounds: bounds,
      });
    }

    //TODO just need to refresh tile
    if (
      nextProps.selectedDate != this.state.selectedDate ||
      nextProps.remoteIndex != this.state.remoteIndex ||
      nextProps.tileVisState != this.state.tileVisState
    ) {
      this.setState(
        {
          selectedDate: nextProps.selectedDate,
          remoteIndex: nextProps.remoteIndex,
          tileVisState: nextProps.tileVisState,
        },
        () => {
          this.fieldInFrame(this.fieldInfo);
        }
      );
    }
  };

  componentWillUnmount = () => {
    //console.log("unmount");

    window.removeEventListener("beforeunload", this.handleWindowClose);

    if (this.props.menuType === "WGAdmin") this.geoQuery.cancel();

    this.handleWindowClose();
  };

  importUserFields = (uid) => {
    return this.props.firebase
      .plotList(uid)
      .once("value")
      .then(async (cList) => {
        //console.log("iterate 3");
        let plotList = [];

        if (cList.val()) plotList = Object.values(cList.val());

        //console.log(plotList);
        let promises = [];

        for (let j = 0; j < plotList.length; j++) {
          let fieldID = null;
          let polygon = [];
          //console.log(plotList[j].markerPosition + " " + location);

          let maxLat = 0,
            minLat = 0,
            maxLng = 0,
            minLng = 0;

          polygon = plotList[j].polygon;
          fieldID = plotList[j].hasOwnProperty("fieldID")
            ? plotList[j].fieldID.toString()
            : null;

          // console.log(fieldID);

          if (polygon && polygon.length > 0) {
            let latCoords = polygon[0].coordinates.map((item) => item.latitude);
            let lngCoords = polygon[0].coordinates.map(
              (item) => item.longitude
            );
            maxLat = Math.max(...latCoords);
            minLat = Math.min(...latCoords);
            maxLng = Math.max(...lngCoords);
            minLng = Math.min(...lngCoords);
          }

          let datePromise = [];
          let dateList = [];
          let dList;

          if (fieldID) {
            let promise = this.props.firebase
              .NDVIdates(uid, fieldID)
              .once("value");

            datePromise.push(promise);
          }

          let promise = Promise.all(datePromise).then((result) => {
            if (result.length > 0) {
              dList = result[0];
              if (dList && dList.val()) dateList = Object.keys(dList.val());
            }

            this.fieldInfo[j] = {
              location: plotList[j].markerPosition,
              userID: uid,
              fieldID: fieldID,
              userName: "Fix nickname",
              polygon: polygon,
              polygonBound: [minLng, maxLng, minLat, maxLat],
              ndviDateList: dateList,
            };
          });

          promises.push(promise);
        }

        return Promise.all(promises);
      });
  };

  importUserFieldInfoByLocation = (uid, location, nickname, key) => {
    if (this.fieldInfo.hasOwnProperty(key))
      return new Promise((resolve) => resolve());
    else
      return this.props.firebase
        .plotList(uid)
        .once("value")
        .then(async (cList) => {
          //console.log("iterate 3");
          let plotList = [];
          let polygon = [];
          let fieldID = null;

          if (cList.val()) plotList = Object.values(cList.val());

          //console.log(plotList);

          let maxLat = 0,
            minLat = 0,
            maxLng = 0,
            minLng = 0;

          for (let j = 0; j < plotList.length; j++) {
            //console.log(plotList[j].markerPosition + " " + location);
            if (
              plotList[j].hasOwnProperty("markerPosition") &&
              plotList[j].markerPosition.latitude == location[0] &&
              plotList[j].markerPosition.longitude == location[1]
            ) {
              //console.log("inside ");
              // console.log(plotList[j].polygon);
              polygon = plotList[j].polygon;
              fieldID = plotList[j].hasOwnProperty("fieldID")
                ? plotList[j].fieldID.toString()
                : null;

              //console.log(fieldID);

              if (polygon && polygon.length > 0) {
                let latCoords = polygon[0].coordinates.map(
                  (item) => item.latitude
                );
                let lngCoords = polygon[0].coordinates.map(
                  (item) => item.longitude
                );
                maxLat = Math.max(...latCoords);
                minLat = Math.min(...latCoords);
                maxLng = Math.max(...lngCoords);
                minLng = Math.min(...lngCoords);
              }

              break;
            }
          }

          let datePromise = [];
          let dateList = [];
          let dList;

          if (fieldID) {
            let promise = this.props.firebase
              .NDVIdates(uid, fieldID)
              .once("value");

            datePromise.push(promise);
          }

          return Promise.all(datePromise).then((result) => {
            if (result.length > 0) {
              dList = result[0];
              if (dList && dList.val()) dateList = Object.keys(dList.val());
            }

            this.fieldInfo[key] = {
              location: { latitude: location[0], longitude: location[1] },
              userID: uid,
              fieldID: fieldID,
              userName: nickname,
              polygon: polygon,
              polygonBound: [minLng, maxLng, minLat, maxLat],
              ndviDateList: dateList,
            };
          });
        });
  };

  queryLocationsOnMap = () => {
    try {
      var bounds = new this.props.google.maps.LatLngBounds(
        new this.props.google.maps.LatLng(
          this.state.aggSW.lat,
          this.state.aggSW.lng
        ),
        new this.props.google.maps.LatLng(
          this.state.aggNE.lat,
          this.state.aggNE.lng
        )
      );

      let center = [bounds.getCenter().lat(), bounds.getCenter().lng()];
      //console.log(center);

      let radius = Helper.getDistance(
        [this.state.aggSW.lat, this.state.aggSW.lng],
        center
      ); //in KM

      this.geoQuery = this.props.firebase.geofireRef_forQuery.query({
        center: center,
        radius: radius,
      });

      this.fieldInfo = {};
      let numQueries = 0;
      let numQueriesCompleted = 0;
      let numExitCompleted = 0;
      let numExitQueries = 0;
      this.finishedQuerying_enter = false;
      this.finishedQuerying_exited = false;

      this.geoQuery.on("key_entered", async (key, location, distance) => {
        numQueries = numQueries + 1;
        let splittedKey = key.split("_");

        //this condition is just for the public channel in which not all the users are premium and therefore can be skipped
        // better solution will be to copy the database geolocations to a seperate path just for preemiums.

        //It is not a good condition because i will need to check if the props were full when this component was mounted and if not to run it in compwillupdate

        // if (
        //   this.props.hasOwnProperty("FarmerUIDs") &&
        //   this.props.FarmerUIDs &&
        //   this.props.FarmerUIDs.indexOf(splittedKey[0]) != -1
        // ) {
        //console.log("iterate 1 " + splittedKey[0]);
        this.props.firebase
          .user2nickname(this.props.firebase.workgroupPath, splittedKey[0])
          .then(async (name) => {
            let nickname = "unknown";
            if (name !== "") nickname = name;
            // console.log("iterate 2");
            this.importUserFieldInfoByLocation(
              splittedKey[0],
              location,
              nickname,
              key
            ).then(() => {
              numQueriesCompleted = numQueriesCompleted + 1;
              // console.log(numQueriesCompleted);
              if (
                this.finishedQuerying_enter &&
                numQueries == numQueriesCompleted
              ) {
                // console.log("finish");

                numQueries = 0;
                numQueriesCompleted = 0;
                this.fieldInFrame(this.fieldInfo);
              }
            });
          });
        // } else {
        //   numQueriesCompleted = numQueriesCompleted + 1;
        // }
      });

      this.geoQuery.on("key_exited", (key, location, distance) => {
        // numExitQueries = numExitQueries + 1;
        // console.log(key + " exited");
        // delete this.fieldInfo[key];
        //
        // setTimeout(() => {
        //   numExitCompleted = numExitCompleted + 1;
        //   if (this.finishedQuerying_exited && numExitCompleted == numExitQueries) {
        //     numExitCompleted = 0;
        //     numExitQueries = 0;
        //     this.fieldInFrame(this.fieldInfo);
        //   }
        // }, 50);
      });

      this.geoQuery.on("ready", () => {
        // console.log("Query ready");

        this.finishedQuerying_enter = true;
        this.finishedQuerying_exited = true;

        // console.log(this.finishedQuerying_enter);
        // console.log(this.finishedQuerying_exited);

        // console.log("here");
        this.fieldInFrame(this.fieldInfo); // for the case that no exist no entries
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleWindowClose = () => {
    //ev.preventDefault();

    if (this.state.mapPosition) {
      let mapPosition = this.state.mapPosition;
      cookies.set("mapPosition", JSON.stringify(mapPosition), {
        path: "/",
        sameSite: "strict",
      });
    }

    //return ev.returnValue = 'Are you sure you want to close?';
  };

  checkInside = (point, boundSW, boundNE) => {
    // console.log(point);
    // console.log(boundSW);
    // console.log(boundNE);

    // console.log((point.lat > boundSW.lat && point.lat < boundNE.lat &&
    //     point.lng > boundSW.lng && point.lng < boundNE.lng));

    if (
      point.latitude > boundSW.lat &&
      point.latitude < boundNE.lat &&
      point.longitude > boundSW.lng &&
      point.longitude < boundNE.lng
    )
      return true;

    return false;
  };

  fieldInFrame = (markersInfo) => {
    var markerArray = [];
    let dateArray = [];
    // console.log("map");
    // console.log(this.state.mapSW);
    // console.log(this.state.mapNE);
    // console.log(markersInfo);
    var bounds;
    if (this.state.mapSW && this.state.mapNE)
      bounds = new this.props.google.maps.LatLngBounds(
        new this.props.google.maps.LatLng(
          this.state.mapSW.lat,
          this.state.mapSW.lng
        ),
        new this.props.google.maps.LatLng(
          this.state.mapNE.lat,
          this.state.mapNE.lng
        )
      );
    // console.log(Object.keys(markersInfo));
    Object.keys(markersInfo).map((key, index) => {
      // console.log(key);
      let latlng = new this.props.google.maps.LatLng(
        markersInfo[key].location.latitude,
        markersInfo[key].location.longitude
      );
      // console.log(latlng);
      //if (this.checkInside(markersInfo[key].location, this.state.mapSW, this.state.mapNE)) {
      if (bounds.contains(latlng)) {
        // console.log(markersInfo[key].polygon[0].coordinates);
        if (
          markersInfo[key].hasOwnProperty("fieldID") &&
          !(markersInfo[key].fieldID == null)
        ) {
          // console.log(markersInfo[key]);
          // console.log("fieldID");
          markerArray.push({
            position: {
              lat: markersInfo[key].location.latitude,
              lng: markersInfo[key].location.longitude,
            },
            polygon: markersInfo[key].polygon,
            polygonBound: markersInfo[key].polygonBound,
            name: key,
            userName: markersInfo[key].userName,
            ndviDateList: markersInfo[key].ndviDateList,
            UID: markersInfo[key].userID,
            fieldID: markersInfo[key].fieldID.toString(),
          });

          dateArray = [
            ...new Set([...dateArray, ...markersInfo[key].ndviDateList]),
          ];
        }
      }
    });

    dateArray.sort();

    // console.log(dateArray);

    let dateStringArray = dateArray.map((item) => {
      return Helper.milidateToDayMonthYear(item);
    });

    this.props.updateDatesArray(dateStringArray, dateArray);
    // console.log(markerArray);

    //----

    let polygons = [];

    let tiles = [];

    let markersWithPolygon = [];

    for (let i = 0; i < markerArray.length; i++) {
      let item = markerArray[i];
      // console.log(item);
      let polygon =
        item.polygon && item.polygon.length > 0
          ? item.polygon[0].coordinates
          : [];
      //console.log(polygon);
      let vertices = polygon.map((ve) => {
        let latlng = new this.props.google.maps.LatLng(
          ve.latitude,
          ve.longitude
        );
        return latlng;
      });

      let fillOpacity = 0.35;

      if (item.hasOwnProperty("ndviDateList") && item.ndviDateList.length > 0) {
        let date = "";
        let index = item.ndviDateList.indexOf(this.props.selectedDate);
        //console.log(item.UID);
        //console.log(index);
        if (index > -1) {
          date = this.props.selectedDate;
          //console.log("opac 0");
          fillOpacity = 0;
        }
        // console.log("here");
        // console.log(item);
        // console.log(this.props.selectedDate);
        // console.log(date);
        //TODO previousDate can be different to each of the fields
        let remoteIndex = this.props.remoteIndex;

        if (this.state.tileVisState == 2) {
          remoteIndex = remoteIndex + "_diff";
          if (this.props.previousDate && this.props.selectedDate)
            date =
              this.props.previousDate.toString() +
              this.props.selectedDate.toString();
          // console.log(date);
        }

        if (
          this.props.tileVisState == 0 ||
          this.props.tileVisState == 1 ||
          (this.state.tileVisState == 2 && this.props.previousDate != null)
        ) {
          // console.log(remoteIndex);

          tiles.push(
            <UrlTiles
              key={item.UID + item.fieldID + this.props.tileVisState.toString()}
              hide={this.state.tileVisState == 0}
              fieldData={item}
              date={date}
              remoteIndex={remoteIndex}
            />
          );
        } else if (
          this.state.tileVisState == 2 &&
          this.props.previousDate == null
        )
          tiles.push(
            <UrlTiles
              key={item.UID + item.fieldID + this.props.tileVisState.toString()}
              hide={true}
              fieldData={item}
              date={date}
              remoteIndex={remoteIndex}
            />
          );
      }

      if (polygon.length > 0) {
        // console.log(vertices);
        polygons.push(
          <Polygon
            paths={vertices}
            strokeColor="#D32121"
            strokeOpacity={0.8}
            strokeWeight={1}
            fillColor="#D32121"
            fillOpacity={fillOpacity}
            onClick={() => {
              this.props.callback({
                value: item.userName,
                UID: item.UID,
                fieldID: item.fieldID,
              });
              //console.log(item.UID + " " + item.fieldID)
            }}
          />
        );
      }

      if (vertices.length > 2) {
        //console.log(vertices);
        markersWithPolygon.push(markerArray[i]);
      }
    }

    this.setState({
      markerArray: markerArray,
      polygons: polygons,
      tiles: tiles,
      markersWithPolygon: markersWithPolygon,
    });
  };

  updateGeoQuery = (sw, ne) => {
    //latLngBounds: {north: northLat, south: southLat, west: -180, east: 180}

    // var bounds = new this.props.google.maps.LatLngBounds();
    //
    // bounds.extend(this.state.aggSW);
    // bounds.extend(this.state.aggNE);
    // bounds.extend(sw);
    // bounds.extend(ne);

    var bounds = new this.props.google.maps.LatLngBounds(
      new this.props.google.maps.LatLng(sw.lat, sw.lng),
      new this.props.google.maps.LatLng(ne.lat, ne.lng)
    );
    var bounds2 = new this.props.google.maps.LatLngBounds(
      new this.props.google.maps.LatLng(
        this.state.aggSW.lat,
        this.state.aggSW.lng
      ),
      new this.props.google.maps.LatLng(
        this.state.aggNE.lat,
        this.state.aggNE.lng
      )
    );

    // Expand bounds1 to include bounds2
    if (
      Helper.getDistance(
        [
          (this.state.aggNE.lat - this.state.aggSW.lat) / 2,
          (this.state.aggNE.lng - this.state.aggSW.lng) / 2,
        ],
        [(ne.lat - sw.lat) / 2, (ne.lng - sw.lng) / 2]
      ) < 10000
    )
      //reset if the map jumped a very long distance
      bounds.union(bounds2);
    else {
      //console.log("reset bounds");
    }

    var boundsSW = bounds.getSouthWest();
    var wrappedSW = new this.props.google.maps.LatLng(
      boundsSW.lat(),
      boundsSW.lng()
    );

    var boundsNE = bounds.getNorthEast();
    var wrappedNE = new this.props.google.maps.LatLng(
      boundsNE.lat(),
      boundsNE.lng()
    );

    let newSW = { lat: wrappedSW.lat(), lng: wrappedSW.lng() };
    let newNE = { lat: wrappedNE.lat(), lng: wrappedNE.lng() };

    if (
      this.props.menuType === "WGAdmin" &&
      (newSW.lat != this.state.aggSW.lat ||
        newSW.lng != this.state.aggSW.lng ||
        newNE.lat != this.state.aggNE.lat ||
        newNE.lng != this.state.aggNE.lng)
    ) {
      // let center = [(newSW.lat + newNE.lat) / 2, (newSW.lng + newNE.lng) / 2];
      let center = bounds.getCenter();
      var wrappedCenter = new this.props.google.maps.LatLng(
        center.lat(),
        center.lng()
      );

      let radius = Helper.getDistance(
        [newSW.lat, newSW.lng],
        [wrappedCenter.lat(), wrappedCenter.lng()]
      ); //in KM

      // console.log(center);
      // console.log(radius);

      this.setState({ aggSW: newSW, aggNE: newNE }, () => {
        //console.log("updating: " + wrappedCenter.lat() + " " + wrappedCenter.lng() + " " + radius);
        this.geoQuery.updateCriteria({
          center: [wrappedCenter.lat(), wrappedCenter.lng()],
          radius: radius,
        });
      });
    } else {
      // console.log("rerender");
      this.fieldInFrame(this.fieldInfo); // to stop render things outside the map
    }
  };

  handleChange = (mapProps, map) => {
    // console.log("zoom: " + map.getZoom());

    var center = map.getCenter();
    var wrapped = new this.props.google.maps.LatLng(center.lat(), center.lng());

    let mapPosition = {
      center: { lat: wrapped.lat(), lng: wrapped.lng() },
      zoom: map.getZoom(),
    };

    this.finishedQuerying_enter = false;
    this.finishedQuerying_exited = false;

    var boundsSW = map.getBounds().getSouthWest();
    var wrappedSW = new this.props.google.maps.LatLng(
      boundsSW.lat(),
      boundsSW.lng()
    );

    var boundsNE = map.getBounds().getNorthEast();
    var wrappedNE = new this.props.google.maps.LatLng(
      boundsNE.lat(),
      boundsNE.lng()
    );

    let mapSW = { lat: wrappedSW.lat(), lng: wrappedSW.lng() };

    let mapNE = { lat: wrappedNE.lat(), lng: wrappedNE.lng() };
    // console.log(mapSW);
    // console.log(mapNE);
    this.setState(
      { mapSW: mapSW, mapNE: mapNE, mapPosition: mapPosition },
      () => {
        // console.log(mapSW);
        // console.log(mapNE);
        if (mapSW.lat) this.updateGeoQuery(mapSW, mapNE);
      }
    );

    //this.loopJob(e.bounds.nw,e.bounds.se)
  };

  onMarkerClick = (e) => {
    //console.log(e.marker);
    this.props.callback({
      value: e.marker.userName,
      UID: e.marker.UID,
      fieldID: e.marker.fieldID,
    });
  };

  render() {
    const { google, drawerCallback, scoutingReportPins, scoutPinCallback } =
      this.props;

    const { polygons, tiles, markersWithPolygon } = this.state;
    // console.log(polygons);
    let scoutingReportPins2Render = [];
    if (scoutingReportPins) {
      for (let i = 0; i < scoutingReportPins.length; i++)
        scoutingReportPins2Render.push(
          <Marker
            onClick={() => scoutPinCallback(scoutingReportPins[i].key)}
            position={{
              lat: scoutingReportPins[i].location.latitude,
              lng: scoutingReportPins[i].location.longitude,
            }}
            label={{
              text: (i + 1).toString(),
              fontFamily: "Roboto",
              fontSize: "14px",
            }}
          />
        );
    }

    return (
      // Important! Always set the container height explicitly
      <div
        style={{
          position: "relative",
          height: "100vh",
          display: "flex",
          flexFlow: "row nowrap",
          backgroundColor: "#ffffff",
        }}
      >
        <Map
          google={google}
          streetViewControl={false}
          mapType={"satellite"}
          mapTypeControlOptions={{
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT,
          }}
          zoomControlOptions={{
            position: google.maps.ControlPosition.TOP_RIGHT,
          }}
          minZoom={0}
          maxZoom={18}
          containerStyle={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          zoom={this.state.zoom}
          initialCenter={this.state.center}
          bounds={this.state.bounds}
          onBoundsChanged={this.handleChange}
        >
          <MapDrawing
            google={this.props.google}
            map={this.props.map}
            callback={this.props.drawerCallback}
            updateCoords={this.props.updateCoords}
            updatePins={this.props.updatePins}
            handleSelectPin={this.props.handleSelectPin}
          />
          {polygons}
          {tiles}
          {scoutingReportPins2Render}
          {true && (
            <MarkerCluster
              markers={
                this.state.mapPosition && this.state.mapPosition.zoom < 12
                  ? markersWithPolygon
                  : []
              }
              click={this.onMarkerClick}
            />
          )}
        </Map>

        {this.props.selectedDate && (
          <div
            style={{
              position: "absolute",
              right: 4,
              top: 160,
              width: "40px",
              margin: "8px",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            {this.state.tileVisState == 1 && (
              <Paper square={true} style={{ overflow: "auto" }}>
                <div
                  style={{ position: "absolute", top: 0, right: 5, height: 20 }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 8,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    High
                  </Typography>
                </div>

                <div
                  style={{
                    marginLeft: 6,
                    marginTop: 12,
                    width: 8,
                    height: 15,
                    backgroundColor: "#011301",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#011D01",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#012E01",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#023B01",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#004C00",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#056201",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#207401",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#3E8601",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#529400",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#66A000",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#74A901",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#99B718",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#FCD163",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#F1B555",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#DF923D",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#CE7E45",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#ffffff",
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 5,
                    height: 12,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 8,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    Low
                  </Typography>
                </div>
              </Paper>
            )}
            {this.state.tileVisState == 2 && (
              <Paper square={true} style={{ overflow: "auto" }}>
                <div
                  style={{ position: "absolute", top: 0, right: 5, height: 20 }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 8,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    Increase
                  </Typography>
                </div>

                <div
                  style={{
                    marginLeft: 6,
                    marginTop: 12,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(0,255,0)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(36,255,36)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(73,255,73)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(109,255,109)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(146,255,146)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(182,255,182)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(219,255,219)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,255,255)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,255,255)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,219,219)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,182,182)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,146,146)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,109,109)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,73,73)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,36,36)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "rgb(255,0,0)",
                  }}
                />
                <div
                  style={{
                    marginLeft: 6,
                    width: 8,
                    height: 15,
                    backgroundColor: "#ffffff",
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 5,
                    height: 12,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 8,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    Decrease
                  </Typography>
                </div>
              </Paper>
            )}
          </div>
        )}
      </div>
    );
  }
}

FarmersMap.propTypes = {
  google: PropTypes.object,
};

export default withFirebase(FarmersMap);
