import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Background from "../../Assests/Background.png";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";

import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email.trim())
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <Fragment>
        <section style={sectionStyle}>
          <div style={{ width: "100%" }}>
            <Navigation type={"minimal"} />
          </div>
          <Grid
            style={{
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginRight: this.state.isMobile ? "0px" : "50px",
                }}
              >
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "403px",
                    fontSize: 44,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Reset your password{" "}
                </p>
                <Grid container spacing={24}>
                  <Grid item xs={24}>
                    <div
                      style={{
                        borderRadius: 4,
                        width: this.state.isMobile
                          ? this.state.width - 110
                          : "290px",
                        height: "67px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <Input
                        id="filled-basic"
                        label="Email"
                        margin="none"
                        variant="outlined"
                        style={{
                          fontSize: 16,
                          font: "Roboto",
                          color: "#000000",
                          borderRadius: 4,
                          marginLeft: "20px",
                          width: this.state.isMobile
                            ? this.state.width - 120
                            : "250px",
                          height: "67px",
                          backgroundColor: "#ffffff",
                        }}
                        disableUnderline={true}
                        placeholder={"Email Address"}
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={24}>
                    {error && (
                      <p
                        style={{
                          fontSize: 16,
                          font: "Roboto",
                          color: "#D32121",
                        }}
                      >
                        {error.message}
                      </p>
                    )}
                    <div style={{ width: "143px", height: "67px" }}>
                      <Button
                        disabled={this.state.email === ""}
                        onClick={this.onSubmit}
                        style={{
                          fontSize: 16,
                          font: "Roboto",
                          color: "#ffffff",
                          borderRadius: 4,
                          width: "143px",
                          height: "67px",
                          backgroundColor:
                            this.state.email === "" ? "#9b9b9b" : "#01496D",
                        }}
                        variant="contained"
                      >
                        RESET
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                onClick={this.onOpenModal}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            </Grid>
            <Grid item xs={24}>
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginTop: this.state.isMobile ? "0px" : "80px",
                }}
              >
                <Grid container spacing={24}>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Join Agrio, the most advanced crop protection app on the
                      planet
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {false && (
                        <Link to={"/google-play"}>
                          <img
                            style={{ height: "35.23px" }}
                            src={GooglePlayButton}
                          />
                        </Link>
                      )}
                      {false && (
                        <Link to={"/apple-store"}>
                          <img
                            style={{ marginLeft: "12px", height: "35.23px" }}
                            src={AppStoreButton}
                          />
                        </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        height: "75px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    ></p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Link to={"/facebook-page"} target="_blank">
                        <img style={{}} src={FacebookIcon} />
                      </Link>
                      <Link to={"/linkedin-page"} target="_blank">
                        <img src={LinkedInIcon} />
                      </Link>
                      <Link to={"/instegram-page"} target="_blank">
                        <img src={InstegramIcon} />
                      </Link>
                      <Link to={"/twitter-page"} target="_blank">
                        <img src={TwitterIcon} />
                      </Link>
                    </div>
                  </Grid>
                </Grid>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "528px",
                    marginTop: "150px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2019 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    Terms of Use
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy{" "}
                  </Link>
                  <a> | </a>
                  <a>Contact</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link
      style={{ fontSize: 16, font: "Roboto", color: "blue" }}
      to={ROUTES.PASSWORD_FORGET}
    >
      Forgot Password?
    </Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
