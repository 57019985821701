import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
//import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";

import BackgroundTop from "../../Assests/Background_Top.jpg";
import BackgroundBottom from "../../Assests/Background_Bottom.jpg";
import DashboardImg from "../../Assests/dashboard_img.png";

import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";
import WorkgroupImg from "../../Assests/workgroup_together_illustration.png";

import Landing1 from "../../Assests/Landing/Group-9.png";
import Landing2 from "../../Assests/Landing/Group-91-1.png";
import Landing3 from "../../Assests/Landing/Group-11.png";
import Landing4 from "../../Assests/Landing/Group-13.png";

import Landing5 from "../../Assests/Landing/Group 57.jpg";

import Landing1B from "../../Assests/Landing/Alert Icon.png";
import Landing2B from "../../Assests/Landing/Group-111.png";
import Landing3B from "../../Assests/Landing/icon_identification.png";
import Landing4B from "../../Assests/Landing/icon_management.png";

import ReactPixel from "react-facebook-pixel";

import * as ROUTES from "../../constants/routes";

import Drift from "react-driftjs";

import sha256 from "crypto-js/sha256";

import { withFirebase } from "../Firebase";

// var sectionStyle = {
//   height: "100%",
//   backgroundImage: "url(" + Background + ")",
//   backgroundSize: 'cover',
//   overflow: "hidden"
// };

var sectionStyle = {
  height: "100%",
  backgroundSize: "cover",
  overflow: "hidden",
};

class Landing extends React.Component {
  state = {
    emailAddress: "",
    videoOpen: false,
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile:
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1,
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    if (this.props.match.params.hasOwnProperty("id")) {
      this.props.firebase.analytics.logEvent("landing_from_referal");
      global.referalUID = this.props.match.params.id;
    } else global.referalUID = null;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  onOpenModal = () => {
    if (this.state.videoOpen == false)
      this.props.firebase.analytics.logEvent("watch_demo_video");

    this.setState((prevState) => ({
      videoOpen: !prevState.videoOpen,
    }));
  };

  ////////////////////////////////////////////////////////////////////////////
  //
  // <a style={{flex: 1, display: "flex", marginLeft: 20, marginTop: 20, width: this.state.isMobile ? this.state.width - 80 : 400, fontSize: 34, font: "Roboto", fontWeight: "bold", color:"#000000"}}>Check your email!</a>
  // <a style={{flex: 1, display: "flex", marginLeft: 20, width: this.state.isMobile ? this.state.width - 80 : 400, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>We've sent instructions on how to start using workgroup.</a>
  //

  //HELMET does not work in client-side rendering when it comes to url preview...
  render() {
    return (
      <Fragment>
        {this.props.match.params.hasOwnProperty("id") ? (
          <Helmet>
            <title>Agrio | Precision farming made easy</title>
            <meta
              id="meta-description"
              name="description"
              content="A friend has referred you to join our vibrant community. Receive an exclusive welcome offer from [Your Service Name] with 10 credits and a one-year pest alert subscription when you sign up."
            />
            <meta
              id="og-title"
              property="og:title"
              content="Agrio | Precision farming made easy"
            />
            <meta
              id="og-description"
              property="og:description"
              content="A friend has referred you to join our vibrant community. Receive an exclusive welcome offer from [Your Service Name] with 10 credits and a one-year pest alert subscription when you sign up."
            />
            <meta
              id="og-image"
              property="og:image"
              content="https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/Misc%2Fpreview.jpg?alt=media&token=608018cb-a332-4df6-8f23-a8e852401666"
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>Agrio | Precision farming made easy</title>
            <meta
              id="meta-description"
              name="description"
              content="An end to end precision farming solution for plant protection."
            />
            <meta
              id="og-title"
              property="og:title"
              content="Agrio | Precision farming made easy"
            />
            <meta
              id="og-description"
              property="og:description"
              content="An end to end precision farming solution for plant protection."
            />
            <meta
              id="og-image"
              property="og:image"
              content="https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/Misc%2Fpreview.jpg?alt=media&token=608018cb-a332-4df6-8f23-a8e852401666"
            />
          </Helmet>
        )}

        <section style={sectionStyle}>
          <div
            style={{ position: "fixed", top: 0, zIndex: 100, width: "100%" }}
          >
            <Navigation history={this.props.history} type={"landing"} />
          </div>
          <Grid
            style={{
              marginTop: 20,
              minHeight: (this.state.width * 593) / 1439,
              overflow: "visible",
              backgroundSize: "cover",
              backgroundImage: "url(" + BackgroundTop + ")",
              paddingBottom: 0,
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                style={{
                  marginLeft: this.state.isMobile ? "0px" : "0px",
                  marginRight: this.state.isMobile ? "0px" : "50px",
                }}
              >
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "403px",
                    fontSize: 44,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Precision farming made easy!{" "}
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "451px",
                    fontSize: 24,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Agrio is the world’s first digital management platform that
                  streamlines the entire crop protection workflow{" "}
                </p>

                <Button
                  onClick={() => {
                    //this.props.history.push(ROUTES.PRICING);

                    window.open("https://mduy8.app.goo.gl/nqnW", "_blank");
                  }}
                  style={{
                    height: 50,
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: "bold",
                    backgroundColor: "#01496d",
                    color: "#ffffff",
                  }}
                >
                  Start now
                </Button>
              </div>
            </Grid>
            <Grid
              style={{
                marginTop: this.state.isMobile ? "80px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                onClick={this.onOpenModal}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    maxWidth: this.state.isMobile
                      ? this.state.width - 40
                      : "711px",
                    maxHeight: this.state.isMobile
                      ? ((this.state.width - 40) / 711) * 489
                      : "489px",
                  }}
                  src={DashboardImg}
                />
                {this.state.videoOpen && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0%",
                      left: "0%",
                      width: this.state.width,
                      height: this.state.height,
                      zIndex: 1000,
                      backgroundColor: "transparent", // You can change the background color
                      padding: "60px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      // Add any additional styling here
                    }}
                  >
                    <ReactPlayer
                      playing={true}
                      width={this.state.width - 120}
                      height={this.state.width / 2}
                      url="https://www.youtube.com/watch?v=jZ0sPS8oIIU"
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid
            style={{
              marginTop: 0,
              overflow: "visible",
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
              alignItems: "center",
            }}
            container
            spacing={24}
          >
            <Grid item xs={24}>
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginTop: this.state.isMobile ? "0px" : "0px",
                }}
              >
                <Grid container spacing={24}>
                  <Grid item xs={24}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {false && (
                        <Link to={"/google-play"}>
                          <img
                            style={{ height: "35.23px" }}
                            src={GooglePlayButton}
                          />
                        </Link>
                      )}
                      {false && (
                        <Link to={"/apple-store"}>
                          <img
                            style={{ marginLeft: "12px", height: "35.23px" }}
                            src={AppStoreButton}
                          />
                        </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        height: "75px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#000000",
                      }}
                    ></p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Link to={"/facebook-page"} target="_blank">
                        <img style={{}} src={FacebookIcon} />
                      </Link>
                      <Link to={"/linkedin-page"} target="_blank">
                        <img src={LinkedInIcon} />
                      </Link>
                      <Link to={"/instegram-page"} target="_blank">
                        <img src={InstegramIcon} />
                      </Link>
                      <Link to={"/twitter-page"} target="_blank">
                        <img src={TwitterIcon} />
                      </Link>
                    </div>
                  </Grid>
                </Grid>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "528px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2020 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    Terms of Use
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy{" "}
                  </Link>
                  <a> | </a>
                  <a>Contact</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>
        <Drift appId="kx8hpvu6f7dh" />
      </Fragment>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  //return !(!authUser || (userType!=="admin" && premiumLevel!=="Pro"))
  return true;
};

export default withFirebase(Landing);

// <Grid style={{paddingBottom: 70, paddingTop: 70, alignItems: 'center', paddingLeft: this.state.isMobile ? 0 : 100, paddingRight: this.state.isMobile ? 0 : 100}} container spacing={24}>
//
//   <Grid style={{display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
//   <div style={{marginLeft: this.state.isMobile ? "0px" : "0px", marginRight: this.state.isMobile ? "0px" : "50px"}}>
//   <p style={{width: this.state.isMobile ? this.state.width - 80 : "803px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000", textAlign: 'left'}}>
//   Manage crop protection endeavors on a large scale with enhanced capabilities </p>
// <div style={{marginLeft: 20, }}>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
//   <a style={{marginLeft: 12, marginRight: 12, marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
//   Map crop pests, diseases, and nutritional deficiencies</a>
// </div>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", marginTop: 18, display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
// <a style={{marginLeft: 12, marginRight: 12,marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Monitor fields using remote sensing technology</a>
// </div>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", marginTop: 18, display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
// <a style={{marginLeft: 12, marginRight: 12,marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Use artificial intelligence to identify plant anomalies </a>
// </div>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", marginTop: 18, display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
// <a style={{marginLeft: 12, marginRight: 12, marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Depict field Workgroup activities in real time</a>
// </div>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", marginTop: 18, display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
// <a style={{marginLeft: 12, marginRight: 12, marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Allow inspectors to create digital scouting reports</a>
// </div>
// <div style={{width: this.state.isMobile ? this.state.width - 80 : "651px", marginTop: 18, display: "flex", flexDirection: 'row' }}>
// <CheckBoxIcon style={{ color: "#000000", marginTop: 5 }} fontSize={"normal"}/>
// <a style={{marginLeft: 12, marginRight: 12, marginTop: 2, fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Monitor farmers’ progress and productivity</a>
// </div>
// </div>
//   </div>
//   </Grid>
// </Grid>
//
// <Grid style={{marginTop: 50, alignItems: 'center', paddingLeft: this.state.isMobile ? 0 : 100, paddingRight: this.state.isMobile ? 0 : 100}} container spacing={24}>
//
//   <Grid style={{display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
//   <div style={{marginLeft: this.state.isMobile ? "20px" : "0px", marginRight: this.state.isMobile ? "50px" : "0px"}}>
//   <img style={{maxWidth: this.state.isMobile ? this.state.width - 40 : "711px",
//               maxHeight: this.state.isMobile ? (this.state.width - 40) / 711 * 489 : "489px"}}
//               src={Landing5}/>
//
//   </div>
//   </Grid>
// </Grid>
//
//
// <Grid style={{marginTop: 140, alignItems: 'center', paddingLeft: this.state.isMobile ? 0 : 100, paddingRight: this.state.isMobile ? 0 : 100}} container spacing={24}>
//
//   <Grid style={{display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
//   <div style={{marginLeft: this.state.isMobile ? "0px" : "0px", marginRight: this.state.isMobile ? "0px" : "0px"}}>
//   <p style={{width: this.state.isMobile ? this.state.width - 80 : "670px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000", textAlign: 'left'}}> Powerfull tool that supports crop protection
// endeavors </p>
//
// <p style={{marginLeft: 12, marginRight: 12, marginTop: 2, width: this.state.isMobile ? this.state.width - 80 : "651px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}>
// Agrio leverages and deploys proprietary artificial intelligence and
// computer vision algorithms. The algorithms contain the knowledge of
// numerous agriculture experts and continuously improve.</p>
//   </div>
//   </Grid>
// </Grid>
//
//
//
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1}} container spacing={24}>
// <Grid style={{marginTop: this.state.isMobile ? "100px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-end'}} item xs={24}>
// <div style={{justifyContent: this.state.isMobile ? 'center' : 'flex-end', marginRight: 40}}>
// <img style={{maxWidth: "100px",
//             maxHeight: "100px"}}
//             src={Landing1B}/>
// </div>
// </Grid>
//   <Grid style={{marginTop: this.state.isMobile ? "10px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-start'}} item xs={24}>
//   <div style={{marginLeft: this.state.isMobile ? "40px" : "0px", justifyContent: this.state.isMobile ? 'center' : 'flex-end'}}>
//             <a style={{width: this.state.isMobile ? this.state.width - 80 : "403px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000"}}> The first AI-based alert
//           system in agriculture </a>
//             <p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}> Notifies workgroup members after crop diseases and pests are detected and expected to arrive on fields. The alerts come with written preventative measures to aid in impeding infestations. </p>
//
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
// <div style={{display: 'flex', width: "100%", alignItems: 'center', justifyContent: this.state.isMobile ? 'center' : 'center'}}>
// <img style={{maxWidth: this.state.isMobile ? this.state.width - 40 : "711px",
//             maxHeight: this.state.isMobile ? (this.state.width - 40) / 711 * 689 : "689px"}}
//             src={Landing1}/>
// </div>
// </Grid>
// </Grid>
//
//
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1}} container spacing={24}>
// <Grid style={{marginTop: this.state.isMobile ? "100px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-end'}} item xs={24}>
// <div style={{justifyContent: this.state.isMobile ? 'center' : 'flex-end', marginRight: 40}}>
// <img style={{maxWidth: "100px",
//           maxHeight: "100px"}}
//           src={Landing2B}/>
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "10px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-start'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "40px" : "0px", justifyContent: this.state.isMobile ? 'center' : 'flex-end'}}>
// <a style={{width: this.state.isMobile ? this.state.width - 80 : "403px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000"}}> Easy to use remote sensing technology </a>
// <p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}> With remote sensing technology farmers can solve problems before large-scale damage is done. Our AI algorithms detect vegetation issues fast to allow workgroup members to know precisely when and where to act. </p>
//
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "0px" : "85px", display: 'flex', width: "100%", alignItems: 'center', justifyContent: this.state.isMobile ? 'center' : 'center'}}>
// <img style={{maxWidth: this.state.isMobile ? this.state.width - 40 : "711px",
//             maxHeight: this.state.isMobile ? (this.state.width - 40) / 711 * 689 : "689px"}}
//             src={Landing2}/>
// </div>
// </Grid>
// </Grid>
//
//
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1}} container spacing={24}>
// <Grid style={{marginTop: this.state.isMobile ? "100px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-end'}} item xs={24}>
// <div style={{justifyContent: this.state.isMobile ? 'center' : 'flex-end', marginRight: 40}}>
// <img style={{maxWidth: "100px",
//           maxHeight: "100px"}}
//           src={Landing3B}/>
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "10px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-start'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "40px" : "0px", justifyContent: this.state.isMobile ? 'center' : 'flex-end'}}>
//             <a style={{width: this.state.isMobile ? this.state.width - 80 : "403px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000"}}> Fast and easy diagnosis
//           and treatment advice </a>
//             <p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}> Images of plant problems are uploaded to the app then analyzed through the artificial intelligence algorithms. The system provides workgroup members with identifications and suggested solutions. </p>
//
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "0px" : 230, display: 'flex', width: "100%", alignItems: 'center', justifyContent: this.state.isMobile ? 'center' : 'center'}}>
// <img style={{maxWidth: this.state.isMobile ? this.state.width - 40 : "711px",
//             maxHeight: this.state.isMobile ? (this.state.width - 40) / 711 * 689 : "689px"}}
//             src={Landing3}/>
// </div>
// </Grid>
// </Grid>
//
//
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1}} container spacing={24}>
// <Grid style={{marginTop: this.state.isMobile ? "100px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-end'}} item xs={24}>
// <div style={{justifyContent: this.state.isMobile ? 'center' : 'flex-end', marginRight: 40}}>
// <img style={{maxWidth: "100px",
//           maxHeight: "100px"}}
//           src={Landing4B}/>
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "10px" : "180px", display: "flex", flex: 0.5, justifyContent: this.state.isMobile ? 'center' : 'flex-start'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "40px" : "0px", justifyContent: this.state.isMobile ? 'center' : 'flex-end'}}>
// <a style={{width: this.state.isMobile ? this.state.width - 80 : "403px", fontSize: 44, font: "Roboto", fontWeight: "bold", color:"#000000"}}> Management tool for Inspectors </a>
// <p style={{width: this.state.isMobile ? this.state.width - 80 : "451px", fontSize: 24, font: "Roboto", fontWeight: "regular", color:"#000000"}}> Pictures are automatically uploaded to fields
// that are organized by name to track
// progress within designated areas. </p>
//
// </div>
// </Grid>
// <Grid style={{marginTop: this.state.isMobile ? "0px" : "80px", display: "flex", flex: 1, alignItems: "center", justifyContent: 'center'}} item xs={24}>
// <div style={{marginLeft: this.state.isMobile ? "0px" :  210, display: 'flex', width: "100%", alignItems: 'center', justifyContent: this.state.isMobile ? 'center' : 'center'}}>
// <img style={{maxWidth: this.state.isMobile ? this.state.width - 40 : "711px",
//             maxHeight: this.state.isMobile ? (this.state.width - 40) / 711 * 689 : "689px"}}
//             src={Landing4}/>
// </div>
// </Grid>
// </Grid>

// <!-- Start of Async Drift Code -->
// <script>
// "use strict";
//
// !function() {
//   var t = window.driftt = window.drift = window.driftt || [];
//   if (!t.init) {
//     if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
//     t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
//     t.factory = function(e) {
//       return function() {
//         var n = Array.prototype.slice.call(arguments);
//         return n.unshift(e), t.push(n), t;
//       };
//     }, t.methods.forEach(function(e) {
//       t[e] = t.factory(e);
//     }), t.load = function(t) {
//       var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
//       o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
//       var i = document.getElementsByTagName("script")[0];
//       i.parentNode.insertBefore(o, i);
//     };
//   }
// }();
// drift.SNIPPET_VERSION = '0.3.1';
// drift.load('kx8hpvu6f7dh');
// </script>
// <!-- End of Async Drift Code -->
