import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import PanToolIcon from "@material-ui/icons/PanTool";
import PinDropIcon from "@material-ui/icons/PinDrop";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { withFirebase } from "./Firebase";

class CreateNote extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
    };
  }

  componentDidMount() {
    const initString = {
      en: "Please check the regions that are marked on the map as problems with the vegetation were observed.",
      es: "Por favor revise las regiones que están marcadas en el mapa ya que se observaron problemas con la vegetación.",
      pt: "Por favor, verifique as regiões que estão marcadas no mapa, pois foram observados problemas com a vegetação.",
      ru: "Пожалуйста, проверьте регионы, отмеченные на карте, так как были замечены проблемы с растительностью.",
      ar: "يرجى التحقق من المناطق المحددة على الخريطة حيث لوحظت مشاكل مع الغطاء النباتي.",
      he: "אנא בדקו את האזורים המסומנים במפה שכן נצפו בעיות בצמחייה.",
      iw: "אנא בדקו את האזורים המסומנים במפה שכן נצפו בעיות בצמחייה.",
    };
    this.props.firebase.db
      .ref("language/user")
      .child(this.props.uid)
      .child("value")
      .once("value")
      .then((lang) => {
        let language = lang.val() ? lang.val().substring(0, 2) : "en";
        let languages = Object.keys(initString);
        // console.log(language);
        if (languages.indexOf(language) != -1)
          this.setState({ message: initString[language] });
        else this.setState({ message: initString["en"] });
      });
  }

  handleDoneClick = () => {
    this.props.handleSendNote(this.state.message);
  };

  handlePanClick = () => {
    this.props.drawingManager.setDrawingMode(null);
  };

  handleDrawClick = () => {
    // if (this.props.polygonShape) {
    //    this.props.polygonShape.setMap(null);
    //    this.props.updateCoords(null, null);
    // }

    this.props.drawingManager.setDrawingMode(
      this.props.google.maps.drawing.OverlayType.MARKER
    );
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleDeletePin = (event) => {
    this.props.handleDeletePin();
  };

  render() {
    const { drawingPolyCoordinates, classes } = this.props;
    // const suggestions = FarmerNames.map((name, index) => ({
    // value: name,
    // label: name,
    // UID: FarmerUIDs[index],
    // }));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            margin: 16,
            borderRadius: 4,
            backgroundColor: "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 3,
              justifyContent: "flex-start",
              alignSelf: "center",
            }}
          >
            <Typography color="textSecondary" align="left">
              Pinpoint
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "center",
              flex: 1,
            }}
          >
            <IconButton
              disabled={0 == 1}
              onClick={this.handlePanClick}
              color="inherit"
            >
              <PanToolIcon
                className={classes.block}
                fontSize={"small"}
                color={0 == 0 ? "action" : "disabled"}
              />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "center",
              flex: 1,
            }}
          >
            <IconButton
              disabled={this.props.selectedPinID == null}
              onClick={this.handleDeletePin}
              color="inherit"
            >
              <DeleteForeverIcon
                className={classes.block}
                fontSize={"small"}
                color={this.props.selectedPinID == null ? "disabled" : "action"}
              />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "center",
              flex: 1,
            }}
          >
            <IconButton onClick={this.handleDrawClick} color="inherit">
              <PinDropIcon
                className={classes.block}
                fontSize={"small"}
                color={"action"}
              />
            </IconButton>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 2,
            margin: 14,
            borderRadius: 4,
            width: "218px",
            backgroundColor: "#ffffff",
          }}
        >
          <TextField
            multiline
            id="Your message"
            label="Your message"
            fullWidth
            rows={7}
            defaultValue=""
            value={this.state.message}
            onChange={this.handleMessageChange}
            variant="outlined"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginLeft: 16,
            borderRadius: 4,
            width: "220px",
            backgroundColor: "#ffffff",
          }}
        >
          <Typography variant="subtitle2" color="error" align="left">
            Drop pins on the map in locations that need attention and write your
            message
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            marginRight: 12,
            marginTop: 8,
            borderRadius: 4,
            backgroundColor: "#ffffff",
          }}
        >
          <Tooltip title="The note will be shared with the user">
            <IconButton
              disabled={this.state.message === ""}
              onClick={this.handleDoneClick}
              color="inherit"
            >
              <DoneIcon
                className={classes.block}
                color={this.state.message === "" ? "disabled" : "action"}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
}

CreateNote.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withFirebase(CreateNote);
