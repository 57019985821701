import React, { Component, Fragment } from "react";

import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import LoginComponent from "./LoginComponent";

//import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Background from "../../Assests/Background.png";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";

const isMobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;
const width = window.innerWidth;

const SignInPage = (props) => (
  <Fragment>
    <section style={sectionStyle}>
      <div style={{ width: "100%" }}>
        <Navigation type={"minimal"} />
      </div>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={0}
      >
        <Grid
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          xs={24}
        >
          <SignInForm
            handleChangeToSignUp={() => props.history.push(ROUTES.SIGN_UP)}
          />
        </Grid>
      </Grid>

      <Grid
        style={{
          paddingLeft: isMobile ? 0 : 100,
          paddingRight: isMobile ? 0 : 100,
        }}
        container
        spacing={2}
      >
        <Grid item xs={24}>
          <div
            style={{
              marginLeft: isMobile ? "50px" : "0px",
              marginTop: isMobile ? "0px" : "80px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={24}>
                <p
                  style={{
                    width: isMobile ? width - 80 : "528px",
                    fontSize: 32,
                    font: "Roboto",
                    fontWeight: "Bold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  Join Agrio, the most advanced crop protection app on the
                  planet
                </p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {false && (
                    <Link to={"/google-play"}>
                      <img
                        style={{ height: "35.23px" }}
                        src={GooglePlayButton}
                      />
                    </Link>
                  )}
                  {false && (
                    <Link to={"/apple-store"}>
                      <img
                        style={{ marginLeft: "12px", height: "35.23px" }}
                        src={AppStoreButton}
                      />
                    </Link>
                  )}
                </div>
              </Grid>
              <Grid item xs={24}>
                <p
                  style={{
                    width: isMobile ? width - 80 : "528px",
                    height: "75px",
                    fontSize: 32,
                    font: "Roboto",
                    fontWeight: "Bold",
                    color: "#ffffff",
                  }}
                ></p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Link to={"/facebook-page"} target="_blank">
                    <img style={{}} src={FacebookIcon} />
                  </Link>
                  <Link to={"/linkedin-page"} target="_blank">
                    <img src={LinkedInIcon} />
                  </Link>
                  <Link to={"/instegram-page"} target="_blank">
                    <img src={InstegramIcon} />
                  </Link>
                  <Link to={"/twitter-page"} target="_blank">
                    <img src={TwitterIcon} />
                  </Link>
                </div>
              </Grid>
            </Grid>
            <p
              style={{
                width: isMobile ? width - 80 : "528px",
                marginTop: "150px",
                fontSize: 11,
                font: "Roboto",
                fontWeight: "Regular",
                color: "#4A4A4A",
              }}
            >
              <a>All rights reserved to Saillog LTD. 2019 - </a>
              <Link to={"/terms-conditions"} target="_blank">
                Terms of Use
              </Link>
              <a> | </a>
              <Link to={"/privacy-policy"} target="_blank">
                Privacy Policy{" "}
              </Link>
              <a> | </a>
              <a>Contact</a>
            </p>
          </div>
        </Grid>
      </Grid>
    </section>
  </Fragment>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

var sectionStyle = {
  height: "100%",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email.trim(), password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });

        //this.props.history.push(ROUTES.HOME);
        if (this.props.hasOwnProperty("handleSignUpComplete"))
          this.props.handleSignUpComplete();
        else this.props.history.push(ROUTES.USERROUTING);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  signInWithGoogle = () => {
    this.props.firebase
      .signInWithGoogle()
      .then((result) => {
        // console.log(result);
        if (this.props.hasOwnProperty("handleSignUpComplete"))
          this.props.handleSignUpComplete();
        else this.props.history.push(ROUTES.USERROUTING);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error });
      });
  };

  signUpWithApple = () => {
    this.props.firebase
      .signInWithApple()
      .then((result) => {
        // console.log(result);
        if (this.props.hasOwnProperty("handleSignUpComplete"))
          this.props.handleSignUpComplete();
        else this.props.history.push(ROUTES.USERROUTING);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error });
      });
  };

  render() {
    const { email, password, error } = this.state;

    return (
      <LoginComponent
        error={error}
        isMobile={isMobile}
        width={width}
        email={email}
        password={password}
        onSubmit={this.onSubmit}
        onChangeEmail={this.onChangeEmail}
        onChangePassword={this.onChangePassword}
        handleChangeToSignUp={this.props.handleChangeToSignUp}
        signInWithGoogle={this.signInWithGoogle}
        signUpWithApple={this.signUpWithApple}
      />
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
