import React from "react";

import AgrioLogo from "../../Assests/Agrio Logo.png";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const {
    classes,
    onDrawerToggle,
    onTabChange,
    selectedTab,
    profile_image_uri,
    onNotifiationDrawerToggle,
    onContactUs,
    onOpenChat,
    unReadNotification,
    userType,
    workgroupPath,
    path,
    numUnReadOtherWG,
  } = props;
  //console.log(numUnReadOtherWG);

  let sumUnReadOtherWG = Object.values(numUnReadOtherWG).reduce(
    (a, b) => a + b,
    0
  );

  var title, tabs;
  switch (path) {
    case "/home":
      title = "Welcome";
      tabs = [<Tab textColor="inherit" label="Getting started" />];
      break;
    case "/home/feed":
      title = "Feed";
      tabs = [<Tab textColor="inherit" label={"Response tool"} />];
      break;
    case "/home/members":
      title = "Workgroup";
      tabs = [
        <Tab textColor="inherit" label="Members" />,
        <Tab textColor="inherit" label="Analytics" />,
        <Tab textColor="inherit" label="Settings" />,
      ];
      break;
    case "/home/phytopathology":
      title = "Image diagnosis";
      tabs =
        userType === "superAdmin"
          ? [
              <Tab textColor="inherit" label="Protocols" />,
              <Tab textColor="inherit" label="Feed" />,
              <Tab textColor="inherit" label="User Feedback" />,
              <Tab textColor="inherit" label="GDD" />,
              <Tab textColor="inherit" label="AI" />,
            ]
          : [
              <Tab textColor="inherit" label="Protocols" />,
              <Tab textColor="inherit" label="Feed" />,
              <Tab textColor="inherit" label="UserFeedback" />,
            ];
      break;
    case "/home/fields":
      title = "Fields";
      tabs = <Tab textColor="inherit" label="Viewer" />;
      break;
  }

  return (
    <div>
      <AppBar
        style={{ height: 80, justifyContent: "center" }}
        color="#ffffff"
        position="fixed"
        elevation={5}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <IconButton
                color="primary"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {sumUnReadOtherWG > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: 6,
                    left: 40,
                    backgroundColor: "red",
                    height: 19,
                    width: 19,
                    borderRadius: 9.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: 2.5 }}>
                    <Typography
                      style={{
                        fontSize: 9,
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        fontWeight: "bold",
                      }}
                      color="textSecondary"
                      align="center"
                    >
                      {sumUnReadOtherWG < 100 ? sumUnReadOtherWG : "99"}
                    </Typography>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item />
            <Grid xs item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: 130, height: 40 }} src={AgrioLogo} />
              </div>
            </Grid>

            <Grid item>
              <Tooltip title="Need help? contact us!">
                <div
                  style={{
                    position: "relative",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: 30,
                      width: 30,
                      top: -5,
                      left: 0,
                    }}
                  >
                    <IconButton color="primary">
                      <ContactSupportIcon onClick={onContactUs} />
                    </IconButton>
                  </div>
                </div>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Chat">
                <div
                  style={{
                    position: "relative",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: 30,
                      width: 30,
                      top: -5,
                      left: 0,
                    }}
                  >
                    <IconButton color="primary">
                      <QuestionAnswerIcon onClick={onOpenChat} />
                    </IconButton>
                  </div>
                </div>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Alerts • No alters">
                <div
                  style={{
                    position: "relative",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: 30,
                      width: 30,
                      top: -5,
                      left: 0,
                    }}
                  >
                    <IconButton color="primary">
                      <NotificationsIcon onClick={onNotifiationDrawerToggle} />
                    </IconButton>
                  </div>
                  {unReadNotification > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: -3,
                        left: 20,
                        backgroundColor: "red",
                        height: 19,
                        width: 19,
                        borderRadius: 9.5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ marginTop: 2.5 }}>
                        <Typography
                          style={{
                            fontSize: 9,
                            fontFamily: "Roboto",
                            fontStyle: "bold",
                            fontWeight: "bold",
                          }}
                          color="textSecondary"
                          align="center"
                        >
                          {unReadNotification < 100 ? unReadNotification : "99"}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <div style={{ alignItems: "center", justifyContent: "center" }}>
                <IconButton
                  color="inherit"
                  className={classes.iconButtonAvatar}
                >
                  <Avatar className={classes.avatar} src={profile_image_uri} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="#ffffff"
        position="static"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          height: 140,
        }}
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={8}>
            <Grid item xs>
              <Typography color="primary" variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item></Grid>
            <Grid item></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="#ffffff"
        position="static"
        elevation={0}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: 50,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#7ed321",
            },
          }}
          textColor="primary"
        >
          {tabs}
        </Tabs>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  notifications: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
