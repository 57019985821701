import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  versionSecondary: {
    fontSize: 12,
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, workgroupLogo, menuType, unReadNotification, ...other } =
    props;

  const categories =
    menuType === "User"
      ? [
          {
            id: "Dashboard",
            path: "/home",
            children: [
              { id: "Fields", icon: <DashboardIcon />, path: "/home/fields" },
              { id: "Sign Out", icon: <ExitToAppIcon />, path: "signout" },
            ],
          },
        ]
      : [
          {
            id: "Dashboard",
            path: "/home",
            children: [
              { id: "Workgroup", icon: <PeopleIcon />, path: "/home/members" },
              { id: "Fields", icon: <DashboardIcon />, path: "/home/fields" },
              {
                id: "Image diagnosis",
                icon: <DescriptionIcon />,
                path: "/home/phytopathology",
              },
              { id: "Sign Out", icon: <ExitToAppIcon />, path: "signout" },
            ],
          },
        ];

  let WGs = Object.keys(props.numUnReadOtherWG);
  let numUnRead = Object.values(props.numUnReadOtherWG);

  let buttons = WGs.map((item, index) => {
    return (
      <div>
        <Button onClick={() => {}} color="primary">
          {item.substring(11)}
        </Button>
        {numUnRead[index] > 0 && (
          <div
            style={{
              position: "absolute",
              top: 5,
              backgroundColor: "red",
              height: 19,
              width: 19,
              borderRadius: 9.5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginTop: 2.5 }}>
              <Typography
                style={{
                  fontSize: 9,
                  fontFamily: "Roboto",
                  fontStyle: "bold",
                  fontWeight: "bold",
                }}
                color="textSecondary"
                align="center"
              >
                {numUnRead[index] < 100 ? numUnRead[index] : "99"}
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.item,
            classes.itemCategory
          )}
        >
          <Button onClick={props.openWGMenu} color="primary">
            {workgroupLogo == "Not Found" ? (
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: "#ffffff",
                }}
              ></div>
            ) : (
              <img
                src={workgroupLogo}
                style={{ borderRadius: 25 }}
                width={50}
                height={50}
              />
            )}
          </Button>
          <Dialog
            style={{ position: "absolute", top: 10, left: 10 }}
            open={props.isWGOpen}
            onClose={props.handleWGMenuClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogActions>{buttons}</DialogActions>
          </Dialog>
          {unReadNotification > 0 && (
            <div
              style={{
                position: "absolute",
                top: 15,
                left: 55,
                backgroundColor: "red",
                height: 19,
                width: 19,
                borderRadius: 9.5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: 2.5 }}>
                <Typography
                  style={{
                    fontSize: 9,
                    fontFamily: "Roboto",
                    fontStyle: "bold",
                    fontWeight: "bold",
                  }}
                  color="textSecondary"
                  align="center"
                >
                  {unReadNotification < 100 ? unReadNotification : "99"}
                </Typography>
              </div>
            </div>
          )}
        </ListItem>
        <Link style={{ textDecoration: "none" }} to={ROUTES.LANDING}>
          <ListItem
            button
            className={classNames(classes.item, classes.itemCategory)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Homepage
            </ListItemText>
          </ListItem>
        </Link>
        {categories.map(({ id, children, path }) => (
          <React.Fragment key={id}>
            <ListItem
              button
              onClick={() => props.handleChildrenClick(path)}
              className={classes.categoryHeader}
            >
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, path }) => (
              <ListItem
                button
                dense
                key={childId}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  path === props.path && classes.itemActiveItem
                )}
                onClick={() => props.handleChildrenClick(path)}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    textDense: classes.textDense,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
        <ListItem>
          <ListItemText
            classes={{
              secondary: classes.versionSecondary,
            }}
            secondary={"Version 1.6.5"}
          />
        </ListItem>
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
