import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as Lists from "./Lists.js";
import Timestamp from "./Timestamp";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function ReportsList(props) {
  const { classes, Entries, selected, callback } = props;

  const Items = Entries.map((item, index) => {
    const date = item.substring(0, 13);
    const dateStr = new Date(parseFloat(date))
      .toString()
      .split(" ")
      .slice(1, 4)
      .join(" ");

    return (
      <ListItem
        style={{
          backgroundColor:
            !(selected == null) && selected == index
              ? "rgba(0,0,0,0.05)"
              : "transparent",
        }}
        onClick={() => callback(index)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../Assests/crop_image_unavailable_illustration.png")}
            style={{ borderRadius: 3 }}
            width={50}
            height={50}
          />
        </div>
        <div style={{ marginLeft: 12 }}>
          <ListItemText primary={dateStr} secondary={"Scouting Report"} />
        </div>
      </ListItem>
    );
  });

  return <List className={classes.root}>{Items}</List>;
}

ReportsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportsList);
