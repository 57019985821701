import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Navigation from "../Navigation";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
//import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";

import BackgroundTop from "../../Assests/Background_Top.jpg";
import BackgroundBottom from "../../Assests/Background_Bottom.jpg";
import DashboardImg from "../../Assests/dashboard_img.png";

import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AppStoreButton from "../../Assests/App Store Button.png";
import GooglePlayButton from "../../Assests/Google Play Button.png";
import TwitterIcon from "../../Assests/Twitter Icon.png";
import FacebookIcon from "../../Assests/Facbook Icon.png";
import InstegramIcon from "../../Assests/Instegram Icon.png";
import LinkedInIcon from "../../Assests/LinkedIn Icon.png";
import { AuthUserContext, withAuthorization } from "../Session";
import { SignUpForm } from "../SignUp";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactPixel from "react-facebook-pixel";
import { withTranslation } from "react-i18next";

import * as ROUTES from "../../constants/routes";

import sha256 from "crypto-js/sha256";

import { withFirebase } from "../Firebase";

// var sectionStyle = {
//   height: "100%",
//   backgroundImage: "url(" + Background + ")",
//   backgroundSize: 'cover',
//   overflow: "hidden"
// };

var videoURLs = {
  en: "https://www.youtube.com/watch?v=jZ0sPS8oIIU",
  es: "https://www.youtube.com/watch?v=0gESzxeTa8Y",
  pt: "https://www.youtube.com/watch?v=n3E9AEoLTuQ",
  vi: "https://www.youtube.com/watch?v=PsgWR4nCqIE",
  th: "https://www.youtube.com/watch?v=6Vv-W2pVZig",
  id: "https://www.youtube.com/watch?v=uFVwMulGqC4",
};

var sectionStyle = {
  height: "100%",
  backgroundSize: "cover",
  overflow: "hidden",
};

class ReferalAg extends React.Component {
  state = {
    emailAddress: "",
    videoOpen: false,
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile:
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1,
    signupModalOpen: false,
    openDownloadDialog: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    if (this.props.match.params.hasOwnProperty("id")) {
      if (process.env.NODE_ENV !== "development")
        this.props.firebase.analytics.logEvent("landing_from_referal_farmer");
      global.referalUID = this.props.match.params.id;

      if (this.props.firebase.auth.currentUser !== null)
        this.setState({ openDownloadDialog: true });
    } else global.referalUID = null;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  onOpenModal = () => {
    if (this.state.videoOpen == false && process.env.NODE_ENV !== "development")
      this.props.firebase.analytics.logEvent("watch_demo_video_farmer");

    this.setState((prevState) => ({
      videoOpen: !prevState.videoOpen,
    }));
  };

  handleOpen = (packageType) => {
    if (this.props.firebase.auth.currentUser == null) {
      this.setState({ signupModalOpen: true });
    }
  };

  handleCloseSignUp = () => {
    this.setState({ signupModalOpen: false });
  };

  handleSignUpComplete = () => {
    this.handleCloseSignUp();
  };

  handleDownloadClose = () => {
    this.setState({ openDownloadDialog: false });
  };

  handleDownloadOpen = () => {
    this.setState({ openDownloadDialog: true });
  };

  //HELMET does not work in client-side rendering when it comes to url preview...
  render() {
    const { t, i18n } = this.props;
    const currentLanguage = i18n.language.split("-")[0];
    const videoURL = videoURLs.hasOwnProperty(currentLanguage)
      ? videoURLs[currentLanguage]
      : videoURLs["en"];

    return (
      <Fragment>
        {this.props.match.params.hasOwnProperty("id") ? (
          <Helmet>
            <title>Agrio | Precision farming made easy</title>
            <meta
              id="meta-description"
              name="description"
              content="A friend has referred you to join our vibrant community. Receive an exclusive welcome offer from Agrio with 10 credits and a one-year pest alert subscription when you sign up."
            />
            <meta
              id="og-title"
              property="og:title"
              content="Agrio | Precision farming made easy"
            />
            <meta
              id="og-description"
              property="og:description"
              content="A friend has referred you to join our vibrant community. Receive an exclusive welcome offer from Agrio with 10 credits and a one-year pest alert subscription when you sign up."
            />
            <meta
              id="og-image"
              property="og:image"
              content="https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/Misc%2Fpreview.jpg?alt=media&token=608018cb-a332-4df6-8f23-a8e852401666"
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>Agrio | Precision farming made easy</title>
            <meta
              id="meta-description"
              name="description"
              content="An end to end precision farming solution for plant protection."
            />
            <meta
              id="og-title"
              property="og:title"
              content="Agrio | Precision farming made easy"
            />
            <meta
              id="og-description"
              property="og:description"
              content="An end to end precision farming solution for plant protection."
            />
            <meta
              id="og-image"
              property="og:image"
              content="https://firebasestorage.googleapis.com/v0/b/agrio-c2539.appspot.com/o/Misc%2Fpreview.jpg?alt=media&token=608018cb-a332-4df6-8f23-a8e852401666"
            />
          </Helmet>
        )}

        <section style={sectionStyle}>
          <div
            style={{ position: "fixed", top: 0, zIndex: 100, width: "100%" }}
          >
            <Navigation history={this.props.history} type={"minimal"} />
          </div>
          <Grid
            style={{
              marginTop: 20,
              minHeight: (this.state.width * 593) / 1439,
              overflow: "visible",
              backgroundSize: "cover",
              backgroundImage: "url(" + BackgroundTop + ")",
              paddingBottom: 0,
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                style={{
                  marginLeft: this.state.isMobile ? "0px" : "0px",
                  marginRight: this.state.isMobile ? "0px" : "50px",
                }}
              >
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "403px",
                    fontSize: 44,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  {t("agSlogan")}
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "451px",
                    fontSize: 24,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#ffffff",
                  }}
                >
                  {t("welcomeInvited")}
                </p>

                {this.props.firebase.auth.currentUser == null ? (
                  <Button
                    onClick={this.handleOpen}
                    style={{
                      height: 50,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontWeight: "bold",
                      backgroundColor: "#01496d",
                      color: "#ffffff",
                    }}
                  >
                    {t("createAPIAccount")}
                  </Button>
                ) : (
                  <Button
                    onClick={this.handleDownloadOpen}
                    style={{
                      height: 50,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontWeight: "bold",
                      backgroundColor: "#01496d",
                      color: "#ffffff",
                    }}
                  >
                    {t("downloadApp")}
                  </Button>
                )}
              </div>
            </Grid>
            <Grid
              style={{
                marginTop: this.state.isMobile ? "80px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div
                onClick={this.onOpenModal}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    maxWidth: this.state.isMobile
                      ? this.state.width - 40
                      : "711px",
                    maxHeight: this.state.isMobile
                      ? ((this.state.width - 40) / 711) * 489
                      : "489px",
                  }}
                  src={DashboardImg}
                />
                {this.state.videoOpen && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0%",
                      left: "0%",
                      width: this.state.width,
                      height: this.state.height,
                      zIndex: 1000,
                      backgroundColor: "transparent", // You can change the background color
                      padding: "60px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      // Add any additional styling here
                    }}
                  >
                    <ReactPlayer
                      playing={true}
                      width={this.state.width - 120}
                      height={this.state.width / 2}
                      url={videoURL}
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid
            style={{
              marginTop: 0,
              overflow: "visible",
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
              alignItems: "center",
            }}
            container
            spacing={24}
          >
            <Grid item xs={24}>
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginTop: this.state.isMobile ? "0px" : "0px",
                }}
              >
                <Grid container spacing={24}>
                  <Grid item xs={24}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {false && (
                        <Link to={"/google-play"}>
                          <img
                            style={{ height: "35.23px" }}
                            src={GooglePlayButton}
                          />
                        </Link>
                      )}
                      {false && (
                        <Link to={"/apple-store"}>
                          <img
                            style={{ marginLeft: "12px", height: "35.23px" }}
                            src={AppStoreButton}
                          />
                        </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        height: "75px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#000000",
                      }}
                    ></p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Link to={"/facebook-page"} target="_blank">
                        <img style={{}} src={FacebookIcon} />
                      </Link>
                      <Link to={"/linkedin-page"} target="_blank">
                        <img src={LinkedInIcon} />
                      </Link>
                      <Link to={"/instegram-page"} target="_blank">
                        <img src={InstegramIcon} />
                      </Link>
                      <Link to={"/twitter-page"} target="_blank">
                        <img src={TwitterIcon} />
                      </Link>
                    </div>
                  </Grid>
                </Grid>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "528px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2020 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    Terms of Use
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy{" "}
                  </Link>
                  <a> | </a>
                  <a>Contact</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>

        <Dialog
          PaperProps={{
            style: { backgroundColor: "transparent" },
          }}
          open={this.state.signupModalOpen}
          onClose={this.handleCloseSignUp}
        >
          <div style={{ padding: 20, backgroundColor: "rgb(63,201,101,0.9)" }}>
            <div style={{}}>
              <SignUpForm
                handleChangeToSignIn={null}
                handleSignUpComplete={this.handleSignUpComplete}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.openDownloadDialog}
          onClose={this.handleDownloadClose}
        >
          <DialogTitle id="form-dialog-title">
            {t("gettingStarted")}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 5,
                margin: "20px",
              }}
            >
              <Link to={"/google-play"}>
                <img style={{ height: "35.23px" }} src={GooglePlayButton} />
              </Link>
              <Link to={"/apple-store"}>
                <img
                  style={{ marginLeft: "12px", height: "35.23px" }}
                  src={AppStoreButton}
                />
              </Link>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  //return !(!authUser || (userType!=="admin" && premiumLevel!=="Pro"))
  return true;
};

export default withTranslation()(
  withAuthorization(condition)(withFirebase(ReferalAg))
);
