import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
// import { Modal, ModalGateway } from "react-images";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Carousel from "react-multi-carousel";
import Typography from "@material-ui/core/Typography";
import * as Helper from "../../Helper";
import * as Lists from "../Lists.js";

import {
  createContainer,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryBar,
  VictoryScatter,
  VictoryLabel,
  VictoryZoomContainer,
} from "victory";

import "react-multi-carousel/lib/styles.css";
import { withFirebase } from "../Firebase";

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
const VictoryLineScatter = createContainer("line", "scatter");

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

class NDVIModal extends Component {
  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      imgUrls: [],
      selectedIndex: 0,
      chartData: null,
      gddData: null,
      gddDataPredict: null,
      crop: null,
      fieldName: null,
      cropPhenology: null,
      variety: null,
    };
  }

  componentDidMount() {
    this.props.innerRef(this);
  }

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  showTiff = (uid, fieldID, scanDate) => {
    this.uid = uid;
    this.fieldID = fieldID;
    this.scanDate = scanDate;

    let promises = [];

    console.log(uid);
    console.log(fieldID);

    let fieldData = null;

    console.log("showtiff");
    promises.push(
      this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/plotList")
        .child(uid)
        .child(fieldID)
        .once("value")
        .then((result) => {
          fieldData = result.val();
          if (fieldData)
            //check in case the field was deleted
            this.setState({
              crop: fieldData.crop,
              fieldName: fieldData.fieldName,
              variety: fieldData.hasOwnProperty("variety")
                ? fieldData.variety
                : null,
            });
        })
    );

    promises.push(
      this.props.firebase.db
        .ref(this.props.firebase.workgroupPath + "/CropPhenologyUsers")
        .child(uid)
        .child(fieldID)
        .once("value")
        .then((result) => {
          this.setState({ cropPhenology: result.val() });
        })
    );

    this.props.handleSelectField(this.uid, this.fieldID);

    //TODO add lazy loading...no load with pagination

    console.log(fieldID);

    this.props.firebase
      .NDVIdates(uid, fieldID)
      .limitToLast(5)
      .once("value")
      .then((result) => {
        console.log(result.val());

        let images = [];

        let dates = result.val() ? Object.keys(result.val()) : [];

        for (let index = 0; index < dates.length; index++) {
          console.log(index);

          let date = dates[index];
          images.push({});

          let url = this.props.firebase.getStorageImg(
            "remoteSensing/NDVI/" + uid + "/" + fieldID + "/" + date,
            "image.png",
            true
          );

          let rgb_url = this.props.firebase.getStorageImg(
            "remoteSensing/RGB/" + uid + "/" + fieldID + "/" + date,
            "image.png",
            true
          );

          let redEdge_url = this.props.firebase.getStorageImg(
            "remoteSensing/redEdge/" + uid + "/" + fieldID + "/" + date,
            "image.png",
            true
          );

          let meanNDVI = this.props.firebase
            .NDVImean(uid, fieldID, date)
            .once("value");
          let meanRedEdge = this.props.firebase
            .RedEdgeMean(uid, fieldID, date)
            .once("value");

          // console.log(meanNDVI);

          let promise = Promise.all([
            url,
            rgb_url,
            redEdge_url,
            meanNDVI,
            meanRedEdge,
          ]).then((result) => {
            images[index] = {
              source: {
                regular: result[0] !== "Not Found" ? result[0] : null,
              },
              redEdge_source: {
                regular: result[2] !== "Not Found" ? result[2] : null,
              },
              rgb_source: {
                regular: result[1] !== "Not Found" ? result[1] : null,
              },
              dateForSorting: date,
              date: Helper.milidateToDayMonthYear(date),
              meanNDVI: result[3].val(),
              meanRedEdge: result[4].val(),
            };
          });

          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          if (fieldData) {
            let sortedImages = Helper.sortByValue(
              images,
              "dateForSorting",
              "num"
            );
            //console.log(sortedImages);
            this.setState({ tab: 2, imgUrls: sortedImages }, () => {
              this.toggleLightbox(images.length);
            });
          }
        });
      });
  };

  handleChangeNDVI = () => {
    this.setState({ tab: 0 });
  };

  handleChangeRedEdge = () => {
    this.setState({ tab: 1 });
  };

  handleChangeRGB = () => {
    this.setState({ tab: 2 });
  };

  handleSelectField = () => {
    this.props.handleSelectField(this.uid, this.fieldID);
    this.toggleLightbox();
  };

  prepareGDDdata = (chartData) => {
    let nowDate = new Date();
    nowDate = parseFloat(nowDate * 1);

    let keys = Object.keys(chartData);

    let minChartDate = parseFloat(keys[0]);
    let maxChartDate = parseFloat(keys[0]);

    let data = [];
    let dataPredict = [];

    let maxY = 0;

    for (let index in keys) {
      let date = parseFloat(keys[index]);

      //console.log(date);

      //TODO - change "tmpUnits" to the actual variable
      let y =
        "tmpUnits" === "F" ? chartData[date] * (9 / 5) + 32 : chartData[date];
      maxY = y > maxY ? y : maxY;

      minChartDate = date < minChartDate ? date : minChartDate;
      maxChartDate = date > maxChartDate ? date : maxChartDate;

      if (date < nowDate) {
        data.push({ x: new Date(date), y: y });
        dataPredict = [{ x: new Date(date), y: y }];
      } else dataPredict.push({ x: new Date(date), y: y });
    }

    return {
      gddData: data,
      gddDataPredict: dataPredict,
      gddDataMaxima: maxY,
      minChartDate: minChartDate,
      maxChartDate: maxChartDate,
    };
  };

  interpolateGDD = (data, date) => {
    let dates;

    if (data) dates = Object.keys(data);
    else return null;
    // console.log(dates);
    var dates2 = dates.map((value) => {
      return Math.abs(value - date);
    });
    // console.log(dates2);
    let min = Math.min(...dates2);
    // console.log(min);
    let ind = dates2.indexOf(min);
    // console.log(data[dates[ind]]);
    if (min < 1 * 24 * 60 * 60 * 1000) return data[dates[ind]];
    else return null;
  };

  prepareNDVIChart = async () => {
    let uid = this.uid;
    let fieldID = this.fieldID;

    let gddChartData = null;

    let promise3 = this.props.firebase
      .cropGDD(uid, fieldID)
      .once("value")
      .then((result) => {
        //console.log(result.val());
        if (result.val()) {
          gddChartData = result.val();

          let dataObj = this.prepareGDDdata(gddChartData);

          return {
            gddData: dataObj.gddData,
            gddDataPredict: dataObj.gddDataPredict,
            gddDataMaxima: dataObj.gddDataMaxima,
            minChartDate: dataObj.minChartDate,
            maxChartDate: dataObj.maxChartDate,
          };
        } else
          return {
            gddData: null,
            gddDataPredict: null,
            gddDataMaxima: null,
            minChartDate: null,
            maxChartDate: null,
          };
      });

    await promise3;

    let promise1 = this.props.firebase
      .NDVImeanAll(uid, fieldID)
      .once("value")
      .then((result) => {
        let remoteMeta = result.val();

        if (remoteMeta) {
          let keys = Object.keys(remoteMeta);

          let data = [];
          let dataGDDnormalized = [];
          let minChartDate = parseFloat(keys[0]);
          let maxChartDate = parseFloat(keys[0]);

          //console.log(gddChartData);

          for (let index in keys) {
            let date = parseFloat(keys[index]);

            // console.log(date);
            let gdd = this.interpolateGDD(gddChartData, date);

            if (gdd != null)
              dataGDDnormalized.push({ x: gdd, y: remoteMeta[date].meanNDVI });

            data.push({ x: new Date(date), y: remoteMeta[date].meanNDVI });
            minChartDate = date < minChartDate ? date : minChartDate;
            maxChartDate = date > maxChartDate ? date : maxChartDate;
          }

          //console.log(data);

          return {
            chartData: data,
            minChartDate: minChartDate,
            maxChartDate: maxChartDate,
            chartDataGDDnormalized: dataGDDnormalized,
          };
        } else
          return {
            chartData: [],
            minChartDate: null,
            maxChartDate: null,
            chartDataGDDnormalized: [],
          };
      });

    await promise1; //for the minChartDate

    let promise2 = this.props.firebase
      .RedEdgeMeanAll(uid, fieldID)
      .once("value")
      .then((result) => {
        let redEdgeMeta = result.val();

        if (redEdgeMeta) {
          let keys = Object.keys(redEdgeMeta);

          let data = [];
          let dataGDDnormalized = [];
          let minChartDate = parseFloat(keys[0]);
          let maxChartDate = parseFloat(keys[0]);

          for (let index in keys) {
            let date = parseFloat(keys[index]);

            //console.log(date);

            let gdd = this.interpolateGDD(gddChartData, date);

            if (gdd != null)
              dataGDDnormalized.push({ x: gdd, y: redEdgeMeta[date].meanNDVI });

            data.push({ x: new Date(date), y: redEdgeMeta[date].meanNDVI });
            minChartDate = date < minChartDate ? date : minChartDate;
            maxChartDate = date > maxChartDate ? date : maxChartDate;
          }

          //console.log(data);

          return {
            chartData: data,
            minChartDate: minChartDate,
            maxChartDate: maxChartDate,
            chartDataGDDnormalized: dataGDDnormalized,
          };
        } else
          return {
            chartData: [],
            minChartDate: null,
            maxChartDate: null,
            chartDataGDDnormalized: [],
          };
      });

    return Promise.all([promise1, promise2, promise3]).then((result) => {
      let minChartDate = result[0].minChartDate;
      let maxChartDate = result[0].maxChartDate;

      minChartDate =
        result[1].minChartDate && result[1].minChartDate < minChartDate
          ? result[1].minChartDate
          : minChartDate;
      minChartDate =
        result[2].minChartDate && result[2].minChartDate < minChartDate
          ? result[2].minChartDate
          : minChartDate;
      maxChartDate =
        result[1].maxChartDate && result[1].maxChartDate > maxChartDate
          ? result[1].maxChartDate
          : maxChartDate;
      maxChartDate =
        result[2].maxChartDate && result[2].maxChartDate > maxChartDate
          ? result[2].maxChartDate
          : maxChartDate;

      this.setState({
        chartData: result[0].chartData,
        chartDataGDDnormalized: result[0].chartDataGDDnormalized,
        redEdgeChartData: result[1].chartData,
        redEdgeChartDataGDDnormalized: result[1].chartDataGDDnormalized,
        gddData: result[2].gddData,
        gddDataPredict: result[2].gddDataPredict,
        gddDataMaxima: result[2].gddDataMaxima,
        minChartDate: minChartDate,
        maxChartDate: maxChartDate,
      });
    });
  };

  handleNDVIChart = () => {
    let promise = this.prepareNDVIChart();
    promise.then(() => {
      this.setState({ tab: 3 });
    });
  };

  handleNormalizedChart = () => {
    let promise = this.prepareNDVIChart();
    promise.then(() => {
      this.setState({ tab: 4 });
    });
  };

  handlePressDelete = (index, cloudFlag) => {
    if (
      this.props.firebase.auth.currentUser.uid !==
      "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
    )
      return;

    // this.props.firebase.db.ref(this.props.firebase.workgroupPath + "/remoteSensing/NDVI_history/" + this.uid + "/" + this.fieldID + "/" + this.state.imgUrls[index].dateForSorting).remove();
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/remoteSensing/NDVI_meta/" +
          this.uid +
          "/" +
          this.fieldID +
          "/" +
          this.state.imgUrls[index].dateForSorting
      )
      .remove();
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/remoteSensing/NDVI/" +
          this.uid +
          "/" +
          this.fieldID +
          "/" +
          this.state.imgUrls[index].dateForSorting
      )
      .remove();
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/remoteSensing/redEdge_meta/" +
          this.uid +
          "/" +
          this.fieldID +
          "/" +
          this.state.imgUrls[index].dateForSorting
      )
      .remove();
    this.props.firebase.db
      .ref(
        this.props.firebase.workgroupPath +
          "/remoteSensing/redEdge/" +
          this.uid +
          "/" +
          this.fieldID +
          "/" +
          this.state.imgUrls[index].dateForSorting
      )
      .remove();

    // Query the database for nodes with a specific fieldID
    this.props.firebase.db
      .ref(this.props.firebase.workgroupPath + "/remoteSensing/warnings")
      .child(this.uid)
      .orderByChild("fieldID")
      .equalTo(this.fieldID)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          // Remove each child

          // console.log(childSnapshot);
          childSnapshot.ref
            .remove()
            .then(() => {
              console.log("Child removed");
            })
            .catch((error) => {
              console.error("Remove failed: " + error.message);
            });
        });
      });

    /////////////////////////////////////////////////////////
    // this.props.firebase.db
    //   .ref(
    //     this.props.firebase.workgroupPath +
    //       "/remoteSensing/NDVI_history/" +
    //       this.uid +
    //       "/" +
    //       this.fieldID +
    //       "/" +
    //       this.state.imgUrls[index].dateForSorting
    //   )
    //   .remove();

    if (cloudFlag) {
      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/remoteSensing/NDVI_history/" +
            this.uid +
            "/" +
            this.fieldID +
            "/" +
            this.state.imgUrls[index].dateForSorting
        )
        .child("state")
        .set("too cloudy");

      let setObj = {
        gid: this.props.firebase.workgroupID,
        uid: this.uid,
        scanDate: this.state.imgUrls[index].dateForSorting,
        fieldID: this.fieldID,
        fieldName: this.state.fieldName,
      };

      console.log(setObj);

      this.props.firebase.db
        .ref("FBfunctionJobs/RemoteCloudyNotifications")
        .child(this.uid)
        .child(this.fieldID + this.state.imgUrls[index].dateForSorting)
        .set(setObj);
    }

    this.props.firebase.db
      .ref(
        "FBfunctionJobs/RemoteSensingNotifications/" +
          this.uid +
          "/" +
          this.fieldID +
          this.scanDate
      )
      .remove();

    console.log("deleted");
  };

  render() {
    const { classes } = this.props;
    const { selectedIndex, lightboxIsOpen } = this.state;

    return (
      <div>
        <Dialog
          maxWidth={1200}
          fullWidth={true}
          open={lightboxIsOpen}
          onClose={this.toggleLightbox}
        >
          <div
            style={{
              display: "flex",
              width: 1200,
              backgroundColor: "rgba(250,250,250,1)",
              flexDirection: "row",
              height: 150,
            }}
          >
            <div
              style={{
                flexDirection: "column",
                backgroundColor: "transparent",
                width: 200,
                marginLeft: 5,
                marginTop: 5,
                borderRadius: 10,
              }}
            >
              <div>
                <Typography variant="caption" color="textPrimary" align="left">
                  {this.state.fieldName
                    ? this.state.fieldName.toUpperCase()
                    : ""}
                </Typography>
              </div>
              <div>
                <img
                  src={
                    Lists.cropIcons.hasOwnProperty(this.state.crop)
                      ? Lists.cropIcons[this.state.crop]
                      : require("../../Assests/crop_image_unavailable_illustration.png")
                  }
                  style={{ borderRadius: 3 }}
                  width={45}
                  height={45}
                />
              </div>
              <div>
                <Typography variant="caption" color="textPrimary" align="left">
                  {this.state.variety ? this.state.variety : ""}
                </Typography>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "transparent",
                margin: 20,
                borderRadius: 10,
              }}
            >
              <Button
                onClick={this.handleChangeNDVI}
                style={{
                  color: this.state.tab === 0 ? "#7ed321" : "#000000",
                }}
              >
                NDVI
              </Button>
            </div>
            <div
              style={{
                backgroundColor: "transparent",
                margin: 20,
                borderRadius: 10,
              }}
            >
              <Button
                onClick={this.handleChangeRedEdge}
                style={{
                  color: this.state.tab === 1 ? "#7ed321" : "#000000",
                }}
              >
                CHLOROPHYLL
              </Button>
            </div>
            {this.props.firebase.auth.currentUser.uid ===
              "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
              <div
                style={{
                  backgroundColor: "transparent",
                  margin: 20,
                  borderRadius: 10,
                }}
              >
                <Button
                  onClick={this.handleChangeRGB}
                  style={{
                    color: this.state.tab === 2 ? "#7ed321" : "#000000",
                  }}
                >
                  RGB
                </Button>
              </div>
            )}
            <div
              style={{
                backgroundColor: "transparent",
                margin: 20,
                borderRadius: 10,
              }}
            >
              <Button
                onClick={this.handleNDVIChart}
                style={{
                  color: this.state.tab === 3 ? "#7ed321" : "#000000",
                }}
              >
                CHART
              </Button>
            </div>
            <div
              style={{
                backgroundColor: "transparent",
                margin: 20,
                borderRadius: 10,
              }}
            >
              <Button
                onClick={this.handleNormalizedChart}
                style={{
                  color: this.state.tab === 4 ? "#7ed321" : "#000000",
                }}
              >
                NORMALIZED CHART
              </Button>
            </div>

            <div
              style={{
                backgroundColor: "transparent",
                marginLeft: 300,
                marginTop: 20,
                borderRadius: 10,
              }}
            >
              <Button
                onClick={this.handleSelectField}
                style={{ color: "#000000" }}
              >
                SHOW ON MAP
              </Button>
            </div>
          </div>

          {this.state.tab < 3 && (
            <div style={{}}>
              <Carousel deviceType={"desktop"} responsive={responsive}>
                {this.state.imgUrls.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",

                        width: 300,
                        marginTop: 20,
                        marginLeft: 105,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.tab === 0 && (
                        <img
                          src={item.source.regular}
                          style={{
                            borderRadius: 0,
                            maxWidth: 400,
                            maxHeight: 550,
                          }}
                        />
                      )}

                      {this.state.tab === 1 && (
                        <img
                          src={item.redEdge_source.regular}
                          style={{
                            borderRadius: 0,
                            maxWidth: 400,
                            maxHeight: 550,
                          }}
                        />
                      )}

                      {this.state.tab === 2 && (
                        <img
                          src={item.rgb_source.regular}
                          style={{
                            borderRadius: 0,
                            maxWidth: 400,
                            maxHeight: 550,
                          }}
                        />
                      )}

                      <div
                        style={{
                          width: 200,
                          marginTop: 15,
                          height: 20,
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Typography color="textSecondary" align="center">
                          {item.date}
                        </Typography>
                      </div>
                      {this.state.tab === 0 && (
                        <div
                          style={{
                            width: 200,
                            height: 20,
                            marginTop: 5,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Typography color="textSecondary" align="center">
                            {item.meanNDVI
                              ? "Avg NDVI: " + item.meanNDVI.toFixed(2)
                              : "no mean computed"}
                          </Typography>
                        </div>
                      )}

                      {this.state.tab === 1 && (
                        <div
                          style={{
                            width: 200,
                            height: 20,
                            marginTop: 5,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Typography color="textSecondary" align="center">
                            {item.meanRedEdge
                              ? "Avg CHLOROPHYLL: " +
                                item.meanRedEdge.toFixed(2)
                              : "no mean computed"}
                          </Typography>
                        </div>
                      )}

                      {this.props.firebase.auth.currentUser.uid ===
                        "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                        <div
                          style={{
                            backgroundColor: "transparent",
                            marginLeft: 0,
                            marginTop: 20,
                            borderRadius: 10,
                          }}
                        >
                          <Button
                            onClick={() => this.handlePressDelete(index, false)}
                            style={{ color: "#000000" }}
                          >
                            JUST DELETE
                          </Button>
                        </div>
                      )}

                      {this.props.firebase.auth.currentUser.uid ===
                        "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                        <div
                          style={{
                            backgroundColor: "transparent",
                            marginLeft: 0,
                            marginTop: 20,
                            marginBottom: 60,
                            borderRadius: 10,
                          }}
                        >
                          <Button
                            onClick={() => this.handlePressDelete(index, true)}
                            style={{ color: "#000000" }}
                          >
                            TOO CLOUDY
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}

          {this.state.tab == 3 && (
            <VictoryChart
              height={250}
              scale={{ x: "time" }}
              domain={{
                x: [
                  new Date(this.state.minChartDate - 200000000),
                  new Date(this.state.maxChartDate + 200000000),
                ],
                y: [0, 1.1],
              }}
              containerComponent={
                <VictoryZoomVoronoiContainer
                  labels={({ datum }) =>
                    `${new Date(datum.x)
                      .toString()
                      .split(" ")
                      .slice(0, 4)
                      .join(" ")}, ${datum.y.toFixed(2)}`
                  }
                  minimumZoom={{ x: 1, y: 1 }}
                  zoomDomain={{
                    x: [
                      new Date(this.state.maxChartDate - 2000000000),
                      new Date(this.state.maxChartDate + 200000000),
                    ],
                  }}
                  allowZoom={true}
                />
              }
              tickLabels={{
                fontFamily: "Roboto",
                fontSize: 5,
                padding: 5,
              }}
            >
              <VictoryLegend
                x={45}
                y={10}
                orientation="horizontal"
                title="Legend"
                centerTitle
                gutter={10}
                style={{
                  border: { stroke: "black" },
                  title: { fontSize: 8 },
                  labels: { fontSize: 8 },
                }}
                data={[
                  { name: "NDVI", symbol: { fill: "#01496d" } },
                  { name: "Cholorphyll", symbol: { fill: "red" } },
                  { name: "GDD", symbol: { fill: "#7ed321" } },
                ]}
              />

              <VictoryAxis
                orientation="bottom"
                style={{
                  ticks: { stroke: "grey", size: 5 },
                  tickLabels: { fontFamily: "Roboto", fontSize: 6 },
                }}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  grid: {
                    stroke: ({ tick }) =>
                      tick === -10 ? "transparent" : "#01496d",
                    strokeWidth: 1,
                    strokeOpacity: 0.2,
                  },
                  axis: { stroke: "blue", strokeWidth: 0 },
                  ticks: { strokeWidth: 0 },
                  tickLabels: {
                    fill: "#01496d",
                    fontFamily: "Roboto",
                    fontSize: 6,
                  },
                }}
              />

              {this.state.cropPhenology &&
                this.state.cropPhenology.Stages.map((value) => {
                  let y = value.gdd;

                  if (y < this.state.gddDataMaxima)
                    return (
                      <VictoryLine
                        y={() => y / this.state.gddDataMaxima}
                        style={{
                          data: {
                            strokeWidth: 0.5,
                            stroke: "red",
                            strokeOpacity: 0.8,
                          },
                        }}
                        labels={["", value.description["en"]]}
                        labelComponent={
                          <VictoryLabel
                            textAnchor="start"
                            backgroundPadding={{ top: 3 }}
                            backgroundStyle={{
                              fill: "white",
                              opacity: 1,
                            }}
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "bold",
                              fontSize: 6,
                              fill: "red",
                              textAlign: "right",
                            }}
                            renderInPortal
                            dy={-4}
                            dx={0}
                          />
                        }
                      />
                    );
                })}

              <VictoryLine
                style={{
                  labels: { fontFamily: "Roboto", fontSize: 6 },
                  data: { stroke: "#01496d", strokeWidth: 4 },
                  parent: { border: "1px solid #ccc" },
                }}
                interpolation="monotoneX"
                data={this.state.chartData}
              />

              <VictoryLine
                style={{
                  labels: { fontFamily: "Roboto", fontSize: 6 },
                  data: { stroke: "red", strokeWidth: 4 },
                  parent: { border: "1px solid #ccc" },
                }}
                interpolation="monotoneX"
                data={this.state.redEdgeChartData}
              />

              {this.state.gddData && (
                <VictoryLine
                  style={{
                    labels: { fontFamily: "Roboto", fontSize: 6 },
                    data: { stroke: "#7ed321", strokeWidth: 4 },
                    parent: { border: "1px solid #ccc" },
                  }}
                  interpolation="monotoneX"
                  data={this.state.gddData}
                  y={(datum) => datum.y / this.state.gddDataMaxima}
                />
              )}
              {this.state.gddData && (
                <VictoryLine
                  style={{
                    labels: { fontFamily: "Roboto", fontSize: 6 },
                    data: {
                      stroke: "rgba(126, 211, 33, 0.4)",
                      strokeWidth: 4,
                    },
                    parent: { border: "1px solid #ccc" },
                  }}
                  interpolation="monotoneX"
                  data={this.state.gddDataPredict}
                  y={(datum) => datum.y / this.state.gddDataMaxima}
                />
              )}

              {this.state.gddData && (
                <VictoryAxis
                  dependentAxis
                  offsetX={430}
                  // Use normalized tickValues (0 - 1)
                  tickValues={[0.2, 0.4, 0.6, 0.8, 1]}
                  // Re-scale ticks by multiplying by correct maxima
                  tickFormat={(t) => Math.round(t * this.state.gddDataMaxima)}
                  style={{
                    axis: { stroke: "blue", strokeWidth: 0 },
                    ticks: { strokeWidth: 0 },
                    tickLabels: {
                      fill: "#7ed321",
                      fontFamily: "Roboto",
                      fontSize: 6,
                    },
                  }}
                />
              )}
            </VictoryChart>
          )}

          {this.state.tab == 4 && (
            <VictoryChart
              height={250}
              domain={{ x: [0, 1000], y: [0, 1.1] }}
              containerComponent={
                <VictoryZoomVoronoiContainer
                  labels={({ datum }) => `${datum.x}, ${datum.y.toFixed(2)}`}
                  minimumZoom={{ x: 1, y: 1 }}
                  zoomDomain={{ x: [0, 1000] }}
                  allowZoom={true}
                />
              }
              tickLabels={{
                fontFamily: "Roboto",
                fontSize: 5,
                padding: 5,
              }}
            >
              <VictoryLegend
                x={45}
                y={10}
                orientation="horizontal"
                title="Legend"
                centerTitle
                gutter={10}
                style={{
                  border: { stroke: "black" },
                  title: { fontSize: 8 },
                  labels: { fontSize: 8 },
                }}
                data={[
                  { name: "NDVI", symbol: { fill: "#01496d" } },
                  { name: "Cholorphyll", symbol: { fill: "red" } },
                ]}
              />

              <VictoryAxis
                orientation="bottom"
                style={{
                  ticks: { stroke: "grey", size: 5 },
                  tickLabels: { fontFamily: "Roboto", fontSize: 6 },
                }}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  grid: {
                    stroke: ({ tick }) =>
                      tick === -10 ? "transparent" : "#01496d",
                    strokeWidth: 1,
                    strokeOpacity: 0.2,
                  },
                  axis: { stroke: "blue", strokeWidth: 0 },
                  ticks: { strokeWidth: 0 },
                  tickLabels: {
                    fill: "#01496d",
                    fontFamily: "Roboto",
                    fontSize: 6,
                  },
                }}
              />
              <VictoryLine
                style={{
                  labels: { fontFamily: "Roboto", fontSize: 6 },
                  data: { stroke: "#01496d", strokeWidth: 4 },
                  parent: { border: "1px solid #ccc" },
                }}
                interpolation="monotoneX"
                data={this.state.chartDataGDDnormalized}
              />

              <VictoryLine
                style={{
                  labels: { fontFamily: "Roboto", fontSize: 6 },
                  data: { stroke: "red", strokeWidth: 4 },
                  parent: { border: "1px solid #ccc" },
                }}
                interpolation="monotoneX"
                data={this.state.redEdgeChartDataGDDnormalized}
              />
            </VictoryChart>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withFirebase(NDVIModal);
