import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import Timestamp from '../Timestamp';
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import * as Lists from '../Lists.js';
import * as Helper from "../../Helper";
import Carousel, { Modal, ModalGateway } from "react-images";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MessagingDialog from "../MessagingDialog";

import { withFirebase } from "../Firebase";

import { withAuthorization } from '../Session';

const cropOptions = Object.keys(Lists.cropIcons);

//import RefreshIcon from '@material-ui/icons/Refresh';

class TeamPage extends Component {
  constructor() {
    super();

    this.state = {
      memberUIDs: [],
      memberNames: null,
      memberTypes: [],
      thumburls: [],

      requestUIDs: [],
      requestNames: [],
      requestUserTypes: [],
      requestThumburls: [],

      inviteEmails: [],
      inviteEmailsDates: [],

      dialogOpen: false,
      settingDialogOpen: false,

      inviteDialogOpen: false,

      inviteEmail: "",
    };
  }

  async componentDidMount() {
    //this.buildDataBaseProtocols();

    if (this.props.firebase.workgroupID !== "")
    this.props.firebase.db
     .ref("WGjoinRequests/" + this.props.firebase.workgroupID)
     .orderByChild("dateForSorting")
     .on("value", snapshot => {
       // A dirty way to avoid a shifting index bug
       this.handleCloseDialog();
       //
       let requestUIDs = snapshot.val() ? Object.keys(snapshot.val()) : [];
       //console.log(requestUIDs);
       this.props.firebase.fetchUsersInfo("", requestUIDs, true, false)
        .then(info => {
         //console.log(info);
         this.setState({
           requestUIDs: info.uids,
           requestNames: info.nicknames,
           requestUserTypes: info.memberTypes,
           requestThumburls: info.thumburls,
         });
       });
     });
//TODO add pagination
     if (this.props.firebase.workgroupPath === "")
     this.props.firebase
      .premiumFarmers()
      .orderByChild("expireDate")
      .limitToLast(20)
      .on("value", snapshot => {
        // A dirty way to avoid a shifting index bug
        this.handleSettingDialogClose();
        //
        let membersUIDs = Object.keys(snapshot.val());
        this.props.firebase.fetchUsersInfo(this.props.firebase.workgroupPath, membersUIDs, true, true)
        .then(info => {
          this.setState({
            memberUIDs: info.uids,
            memberNames: info.nicknames,
            memberTypes: info.memberTypes,
            thumburls: info.thumburls,
            lastOnlineTimes: info.lastOnlineTimes
          });
        });
      });
    else
     this.props.firebase
      .organizationFarmers()
      .on("value", snapshot => {
        // A dirty way to avoid a shifting index bug
        this.handleSettingDialogClose();
        //
        let membersUIDs = snapshot.val() ? Object.keys(snapshot.val()) : [];
        this.props.firebase.fetchUsersInfo(this.props.firebase.workgroupPath, membersUIDs, true, true)
        .then(info => {
          this.setState({
            memberUIDs: info.uids,
            memberNames: info.nicknames,
            memberTypes: info.memberTypes,
            thumburls: info.thumburls,
            lastOnlineTimes: info.lastOnlineTimes
          });
        });
      });

      this.props.firebase.db
       .ref(this.props.firebase.workgroupPath + "/emailInvites")
       .on("value", snapshot => {
         let inviteEmails = snapshot.val() ? Object.values(snapshot.val()) : [];
         let inviteEmailsDates = snapshot.val() ? Object.keys(snapshot.val()) : [];

           this.setState({
             inviteEmails: inviteEmails,
             inviteEmailsDates: inviteEmailsDates,
           });

       });


  }

  componentWillUnmount() {
    this.props.firebase.organizationFarmers().off();
  }

  handleOpenRejectDialog = (index) => {
    this.rejectUserUID = this.state.requestUIDs[index];
    this.setState({
      dialogTitle: "Reject user",
      dialogMessage: "Are you sure that you want to reject this user?",
      dialogButton: ["No", "Yes"],
      dialogOpen: true,
      dialogFunction: this.handleRejectRequest
    });
  }

  handleOpenDeletetDialog = (index) => {
    this.deleteUserUID = this.state.memberUIDs[index];
    this.setState({
      dialogTitle: "Delete memeber",
      dialogMessage: "Are you sure you want to remove this user from the workgroup",
      dialogButton: ["No", "Yes"],
      dialogOpen: true,
      dialogFunction: this.handleDeleteMember
    });
  }

  handleCloseDialog = () => {
    this.setState({dialogOpen: false});
  }

  handleRejectRequest = () => {
    this.setState({dialogOpen: false});
    this.props.firebase.deleteJoiningRequest(this.props.firebase.workgroupID, this.rejectUserUID);
  }

  handleDeleteMember = () => {
    this.setState({dialogOpen: false});
    this.props.firebase.deleteJoiningUser(this.props.firebase.workgroupID, this.deleteUserUID);
  }

  handleAcceptRequest = (index) => {

    //TODO need to add admin or expert registration

    this.props.firebase.writeJoiningUser(this.props.firebase.workgroupID, this.state.requestUIDs[index]);

    this.props.firebase.deleteJoiningRequest(this.props.firebase.workgroupID, this.state.requestUIDs[index]);
  }

  handleSettingDialogOpen = (index) => {
    this.setState({settingUserIndex: index, settingUserUID: this.state.memberUIDs[index], settingDialogOpen: true});
  }

  handleSettingDialogClose = () => {
    this.setState({settingDialogOpen: false});
  }

  handleChangeMemberType = (memberType) => {

    let _ref1 = this.props.firebase.workgroupPath + "/workgroupExpertList/" + this.state.settingUserUID;
    let _ref2 = this.props.firebase.workgroupPath + "/workgroupAdminList/" + this.state.settingUserUID;


    this.props.firebase.db.ref(_ref1).remove();
    this.props.firebase.db.ref(_ref2).remove();

    if (memberType==="Expert")
      this.props.firebase._setDatabase(_ref1, {flag: true}, 1000);
    else if (memberType==="Admin")
      this.props.firebase._setDatabase(_ref2, {flag: true}, 1000);

    let newMemberTypes = this.state.memberTypes.concat([]);
    newMemberTypes[this.state.settingUserIndex] = memberType;
    this.setState({memberTypes: newMemberTypes, settingDialogOpen: false});
  }

  handleMessagingOpen = (index) => {
    this.setState({messagingUID: this.state.memberUIDs[index]}, () => {
      this.messagingRef.handleMessagingOpen();
    });
  }

  handleInviteDialogOpen = () => {
    this.setState({inviteDialogOpen: true});
  }

  handleInviteDialogClose = () => {
    this.setState({inviteDialogOpen: false});
  }

  handleInvite = () => {

    if (this.state.inviteEmails.indexOf(this.state.inviteEmail) == -1) {

    let nowDate = new Date();
    let dateForSorting = nowDate * 1;

    this.props.firebase._setDatabase(
        "/workgroups/" + this.props.firebase.workgroupID + "/emailInvites/" + dateForSorting,
        { email: this.state.inviteEmail },
        1000
      );
    } else {
      //TODO add dialog that explains that invite was send
    }

    this.setState({inviteDialogOpen: false, inviteEmail: ""});
  }

  handleInviteEmailChange = (event) => {
    //console.log(event.target.value);
    this.setState({inviteEmail: event.target.value});
  }

  handleDeleteInvite = (index) => {
//console.log("/workgroups/" + this.props.firebase.workgroupID + "/emailInvites/" + this.state.inviteEmailsDates[index]);
   this.props.firebase.db.ref("/workgroups/" + this.props.firebase.workgroupID + "/emailInvites/" + this.state.inviteEmailsDates[index]).remove();

  }


  render() {

    const { classes } = this.props;

    return (


      <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

      {this.state.requestNames.length > 0 &&
      <Paper className={classes.paper} style={{ zIndex: 5, width: 700, minHeight: 70, margin: 12, overflow: "visible" }}>

      <Typography
        style={{margin: 20,
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "#000000",
        }}
      >
        Pending requests
      </Typography>

      <div style={{height:1, margin: 0, backgroundColor: "rgba(0,0,0,0.1)"}}/>
      <List disablePadding>
      {this.state.requestNames.map((item, index) => (
      <div>
      <div style={{display: "flex",
      flexDirection: 'row', marginTop: 24, alignItems: 'center'}}>
        <div
          style={{marginLeft: 24,
            width: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={
              this.state.requestThumburls[index] == "Not Found"
                ? require("../../Assests/user_icon.png")
                : this.state.requestThumburls[index]
            }
            style={{ borderRadius: 40 }}
            width={80}
            height={80}
          />
        </div>
        <div style={{ display: "flex", marginLeft: 0, justifyContent: "flex-start"}}>

      <ListItem className={null}>
        <ListItemText
          primary={item}
          secondary={this.state.requestUserTypes[index]}
        />
      </ListItem>
      </div>

      <div style={{position: 'absolute', right: 24}}>

      <IconButton
        onClick={() => this.handleOpenRejectDialog(index)}
        color="inherit"
      >
        <ClearIcon className={classes.block} color="action" />
      </IconButton>
    </div>

    <div style={{position: 'absolute', right: 72}}>

      <IconButton
        onClick={() => this.handleAcceptRequest(index)}
        color="inherit"
      >
        <CheckIcon className={classes.block} color="action" />
      </IconButton>
    </div>



      </div>

      <div style={{height:1, margin: index < this.state.requestNames.length - 1 ? 20 : 10, backgroundColor: index < this.state.requestNames.length - 1 ? "rgba(0,0,0,0.1)" : "#ffffff"}}/>

      </div>
    ))}
      </List>

      </Paper>
    }


{this.state.memberNames ?
      <Paper className={classes.paper} style={{ zIndex: 5, width: 700, minHeight: 70, margin: 12, overflow: "visible" }}>
      <List disablePadding>

      <div style={{display: "flex", flexDirection: 'row'}}>
      <Typography
        style={{flex: 2,
          margin: 20,
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "#000000",
        }}
      >
        Members ({this.state.memberNames.length})
      </Typography>

      <div style={{display: "flex", flex:1, alignItems: 'center', justifyContent: 'flex-end', marginRight: 16}}>
      <IconButton
        onClick={this.handleInviteDialogOpen}
        color="inherit"
      >
        <PersonAddOutlinedIcon className={classes.block} style={{fontSize: 28}} color="action" />
      </IconButton>
      </div>
      </div>

      <div style={{height:1, margin: 0, backgroundColor: "rgba(0,0,0,0.1)"}}/>

      {this.state.memberNames.map((item, index) => (
      <div>
      <div style={{display: "flex",
      flexDirection: 'row', marginTop: 24, alignItems: 'center'}}>
        <div
          style={{marginLeft: 24,
            width: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={
              this.state.thumburls[index] == "Not Found"
                ? require("../../Assests/user_icon.png")
                : this.state.thumburls[index]
            }
            style={{ borderRadius: 40 }}
            width={80}
            height={80}
          />
        </div>
        <div style={{ display: "flex", marginLeft: 0, justifyContent: "flex-start"}}>


      <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start'}} className={null}>
        <ListItemText
          primary={item}
          secondary={this.state.memberTypes[index]}
        />
        <Typography
          style={{marginTop: 2,
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: "200",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: "#000000",
          }}
        >
        {this.state.lastOnlineTimes[index] != 0 ? "last seen " + Timestamp(this.state.lastOnlineTimes[index]) : ""}
        </Typography>
      </ListItem>

      </div>

      <div style={{position: 'absolute', right: 24}}>

      <IconButton
        onClick={() => this.handleOpenDeletetDialog(index)}
        color="inherit"
      >
        <ClearIcon className={classes.block} color="action" />
      </IconButton>
    </div>

    <div style={{position: 'absolute', right: 72}}>

      <IconButton
        onClick={() => this.handleSettingDialogOpen(index)}
        color="inherit"
      >
        <SettingsOutlinedIcon className={classes.block} color="action" />
      </IconButton>
    </div>

    <div style={{position: 'absolute', right: 120}}>

      <IconButton
        onClick={() => this.handleMessagingOpen(index)}
        color="inherit"
      >
        <MailOutlineIcon className={classes.block} color="action" />
      </IconButton>
    </div>



      </div>

      <div style={{height:1, margin: index < this.state.memberNames.length - 1 ? 20 : 10, backgroundColor: index < this.state.memberNames.length - 1 ? "rgba(0,0,0,0.1)" : "#ffffff"}}/>

      </div>
    ))}
      </List>
      </Paper>
: <CircularProgress />}

     {this.state.inviteEmails.length > 0 &&
      <Paper className={classes.paper} style={{ zIndex: 5, width: 700, minHeight: 70, margin: 12, overflow: "visible" }}>
      <List disablePadding>

      <Typography
        style={{margin: 20,
          fontFamily: "Roboto",
          fontSize: 24,
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "#000000",
        }}
      >
        Pending Invites
      </Typography>

      <div style={{height:1, margin: 0, backgroundColor: "rgba(0,0,0,0.1)"}}/>

      {this.state.inviteEmails.map((item, index) => (
      <div>
      <div style={{display: "flex",
      flexDirection: 'row', marginTop: 24, alignItems: 'center'}}>
        <div
          style={{marginLeft: 24,
            width: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={
              require("../../Assests/user_icon.png")
            }
            style={{ borderRadius: 40 }}
            width={80}
            height={80}
          />
        </div>
        <div style={{ display: "flex", marginLeft: 0, justifyContent: "flex-start"}}>

      <ListItem className={null}>
        <ListItemText
          primary={item.email}
          secondary={Helper.milidateToDayMonthYear(this.state.inviteEmailsDates[index])}
        />
      </ListItem>
      </div>

      <div style={{position: 'absolute', right: 24}}>

      <IconButton
        onClick={() => this.handleDeleteInvite(index)}
        color="inherit"
      >
        <ClearIcon className={classes.block} color="action" />
      </IconButton>
    </div>
{false &&
    <div style={{position: 'absolute', right: 72}}>

      <IconButton
        onClick={() => {}}
        color="inherit"
      >
        <RefreshIcon className={classes.block} color="action" />
      </IconButton>
    </div>
  }




      </div>

      <div style={{height:1, margin: index < this.state.inviteEmails.length - 1 ? 20 : 10, backgroundColor: index < this.state.inviteEmails.length - 1 ? "rgba(0,0,0,0.1)" : "#ffffff"}}/>

      </div>
    ))}
      </List>
      </Paper>
}
<Dialog
  open={this.state.dialogOpen}
  onClose={this.handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {this.state.dialogMessage}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    {this.state.dialogButton && this.state.dialogButton.length>0 &&
    <Button onClick={this.handleCloseDialog} color="primary">
      {this.state.dialogButton[0]}
    </Button>
    }
    {this.state.dialogButton && this.state.dialogButton.length>1 &&
    <Button onClick={this.state.dialogFunction} color="primary" autoFocus>
      {this.state.dialogButton[1]}
    </Button>
    }
  </DialogActions>
</Dialog>

<Dialog onClose={this.handleSettingDialogClose} aria-labelledby="simple-dialog-title" open={this.state.settingDialogOpen}>
      <DialogTitle id="simple-dialog-title">Choose user type</DialogTitle>
      <List>

          <ListItem button style={{backgroundColor: (this.state.memberTypes[this.state.settingUserIndex] === "Farmer" ? "rgba(0, 0, 0, 0.1)" : "transparent")}}
              onClick={() => this.handleChangeMemberType("Farmer")}
              key={"farmer"}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Farmer" />
          </ListItem>

          <ListItem button style={{backgroundColor: (this.state.memberTypes[this.state.settingUserIndex] === "Expert" ? "rgba(0, 0, 0, 0.1)" : "transparent")}}
              onClick={() => this.handleChangeMemberType("Expert")}
              key={"expert"}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SchoolIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Expert" />
          </ListItem>

          <ListItem button
              style={{backgroundColor: (this.state.memberTypes[this.state.settingUserIndex] === "Admin" ? "rgba(0, 0, 0, 0.1)" : "transparent")}}
              onClick={() => this.handleChangeMemberType("Admin")}
              key={"admin"}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SupervisorAccountIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Admin" />
          </ListItem>

      </List>
    </Dialog>

    <Dialog onClose={this.handleInviteDialogClose} aria-labelledby="invite-dialog-title" open={this.state.inviteDialogOpen}>
    <DialogTitle id="form-dialog-title">Invite new members</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Type the email of the person you want to invite to the workgroup.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="Email"
        type="email"
        fullWidth
        multiline
        onChange={this.handleInviteEmailChange}
        value={this.state.inviteEmail}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={this.handleInviteDialogClose} color="primary">
        Cancel
      </Button>
      <Button onClick={this.handleInvite} color="primary">
        Send
      </Button>
    </DialogActions>
        </Dialog>


    <MessagingDialog targetUID={this.state.messagingUID} onRef={actualChild => this.messagingRef = actualChild}/>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType!=="admin")
};

export default withAuthorization(condition)(withFirebase(TeamPage));
