import React, { PureComponent } from "react";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";

import Timestamp from "../Timestamp";

import { withFirebase } from "../Firebase";

const styles = (theme) => ({
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },

  titlePrimary: {
    color: theme.typography.white,
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondary: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    "&$textDense": {
      fontSize: 12,
    },
  },
});

class ChatRoomItem extends PureComponent {
  constructor() {
    super();
    this.state = {
      chatName: null,
    };
  }

  componentDidMount() {
    this.props.chatName(this.props.item).then((chatName) => {
      this.setState({ chatName: chatName });
    });
  }

  render() {
    const { classes, handleClick, item } = this.props;

    return (
      <ListItem
        button
        dense
        key={item.id}
        onClick={() => handleClick(item)}
        className={classNames(classes.item, classes.itemCategory)}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          maxHeight: 120,
          flexDirection: "column",
          backgroundColor:
            item.seen == false ? "rgba(255,255,255,0.1)" : "transparent",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ListItemIcon style={{ flex: 1 }}>
            {item.type === "group" ? (
              <PeopleIcon style={{ fontSize: 40 }} />
            ) : (
              <PersonIcon style={{ fontSize: 40 }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={this.state.chatName}
            secondary={item.answerText}
            classes={{
              primary: classes.titlePrimary,
              secondary: classes.textPrimary,
            }}
            style={{
              textAlign: "left",
              flex: 6,
              maxHeight: 60,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          />
        </div>
        <ListItemText
          primary={""}
          secondary={Timestamp(item.dateForSorting)}
          classes={{
            secondary: classes.itemSecondary,
          }}
          style={{
            height: 10,
            alignSelf: "flex-end",
            textAlign: "right",
            flex: 1,
          }}
        ></ListItemText>
      </ListItem>
    );
  }
}

export default withStyles(styles)(withFirebase(ChatRoomItem));
