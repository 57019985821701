import React, { Component, Fragment } from "react";

import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Navigation from "../Navigation";
import Grid from "@material-ui/core/Grid";
import { Typography, Code } from "@material-ui/core";
import { withAuthorization } from "../Session";
import Highlight from "react-highlight";
import Background from "../../Assests/Background.png";
import BackgroundTop from "../../Assests/Background_Top_nologo.jpg";
import BackgroundBottom from "../../Assests/Background_Bottom.jpg";

const styles = (theme) => ({
  code: {
    fontFamily: '"Roboto Mono", monospace',
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(0.5),
    borderRadius: 4,
  },
});

var sectionStyle = {
  height: "100%",
  // backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  overflow: "hidden",
};

class APIdocs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <section style={sectionStyle}>
          <div style={{ width: "100%" }}>
            <Navigation type={"signout"} />
          </div>
          <Grid
            style={{
              marginTop: 20,
              minHeight: (this.state.width * 593) / 1439,
              overflow: "visible",
              backgroundSize: "cover",
              backgroundImage: "url(" + BackgroundTop + ")",
              paddingBottom: 0,
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
              }}
              item
              xs={24}
            >
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginRight: this.state.isMobile ? "0px" : "50px",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 44,
                    font: "Roboto",
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Image Processing API documentation
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid
            style={{
              paddingBottom: 0,
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            >
              <div>
                <a
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  The Agrio image processing API has three main endpoints:
                </a>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Check available credits
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  This endpoint allows a user to check their current credit
                  balance. The user can access this endpoint by sending a GET
                  request. We provide with a Node.js API call example:
                </p>
                <div style={{ padding: 10, backgroundColor: "#efefef" }}>
                  <Highlight language="javascript">
                    {`const fs = require("fs");
const request = require("request");

const options = {
  method: "GET",
  url: "https://agrio-api-gateway-6it0wqn1.uc.gateway.dev/v1/get-credit?key=API_KEY",
};

request(options, (err, res, body) => {
  if (err) {
    console.error(err);
    return;
  }
  console.log(body);
});`}
                  </Highlight>
                </div>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  The above code prints the unused credits that are available in
                  your API account. One credit is consumed for every diagnosis
                  request.
                </p>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Get supported crops
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  This endpoint returns a list of all the crops that the
                  diagnosis service supports. The user can access this endpoint
                  by sending a GET request. We provide with a Node.js API call
                  example:
                </p>
                <div style={{ padding: 10, backgroundColor: "#efefef" }}>
                  <Highlight language="javascript">
                    {`const fs = require("fs");
const request = require("request");

const options = {
  method: "GET",
  url: "https://agrio-api-gateway-6it0wqn1.uc.gateway.dev/v1/get-supported-crops?key=API_KEY",
};

request(options, (err, res, body) => {
  if (err) {
    console.error(err);
    return;
  }
  console.log(body);
});`}
                  </Highlight>
                </div>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  The response is an array of crops that the artificial
                  intelligence can diagnose. This list is being updated
                  frequently.
                </p>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Diagnose Endpoint
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  This endpoint allows a user to upload an image of a plant and
                  receive a diagnosis of any diseases present in the plant. The
                  user can access this endpoint by sending a POST request. We
                  provide with a Node.js API call example:
                </p>
                <div style={{ padding: 10, backgroundColor: "#efefef" }}>
                  <Highlight language="javascript">
                    {`const fs = require("fs");
const request = require("request");

const options = {
  method: "POST",
  url: "https://agrio-api-gateway-6it0wqn1.uc.gateway.dev/v1/diagnose?key=API_KEY",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  formData: {
    file: {
      value: fs.createReadStream(IMAGE_FILE_PATH), //32 MB limit
      options: {
        filename: "unique_file_name.jpg",
        contentType: "image/jpeg",
      },
    },
    payload: JSON.stringify({
      crop: "Banana", //Optional field, if not included, the AI will attempt to identify the crop
    }),
  },
};

request(options, (err, res, body) => {
  if (err) {
    console.error(err);
    return;
  }
  console.log(body);
});`}
                  </Highlight>
                </div>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  The response contains the crop that was identified (if crop
                  was not provided in the API call) together with the confidence
                  level. It also includes an array of pathologies that were
                  identified by the algorithm with confidence levels.
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  For example:
                </p>
                <div style={{ padding: 10, backgroundColor: "#efefef" }}>
                  <Highlight language="html">
                    {`{
 "crop":"Cucumber",
 "cropConfidence":"0.99559766",
 "idArray": 
  [
    {
      "id":"Whitefly",
      "confidence":0.869,
      "commonName":"Whitefly",
      "scientificName": "Aleyrodidae"
    },
    {
      "id":"Aphids",
      "confidence":0.103,
      "commonName":"Aphids",
      "scientificName": "Aphidoidea"
    },
    {
      "id":"Leafhoppers",
      "confidence":0.028,
      "commonName":"Leafhoppers",
      "scientificName": "Cicadellidae"
    },
  ]
}`}
                  </Highlight>
                </div>

                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Getting started
                </p>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "693px",
                    fontSize: 22,
                    font: "Roboto",
                    fontWeight: "regular",
                    color: "#000000",
                  }}
                >
                  <a href={"mailto:info@saillog.co"}>Contact us</a>
                </p>
                <Grid container spacing={2}></Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            style={{
              height: (this.state.width * 525) / 1441,
              overflow: "visible",
              backgroundSize: "cover",
              backgroundImage: "url(" + BackgroundBottom + ")",
              paddingLeft: this.state.isMobile ? 0 : 100,
              paddingRight: this.state.isMobile ? 0 : 100,
              alignItems: "center",
            }}
            container
            spacing={24}
          >
            <Grid
              style={{
                marginTop: this.state.isMobile ? "0px" : "80px",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={24}
            ></Grid>
            <Grid item xs={24}>
              <div
                style={{
                  marginLeft: this.state.isMobile ? "50px" : "0px",
                  marginTop: this.state.isMobile ? "0px" : "80px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    ></p>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                  </Grid>
                  <Grid item xs={24}>
                    <p
                      style={{
                        width: this.state.isMobile
                          ? this.state.width - 80
                          : "528px",
                        height: "75px",
                        fontSize: 32,
                        font: "Roboto",
                        fontWeight: "Bold",
                        color: "#ffffff",
                      }}
                    ></p>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                  </Grid>
                </Grid>
                <p
                  style={{
                    width: this.state.isMobile
                      ? this.state.width - 80
                      : "528px",
                    marginTop: "150px",
                    fontSize: 11,
                    font: "Roboto",
                    fontWeight: "Regular",
                    color: "#4A4A4A",
                  }}
                >
                  <a>All rights reserved to Saillog LTD. 2019 - </a>
                  <Link to={"/terms-conditions"} target="_blank">
                    Terms of Use
                  </Link>
                  <a> | </a>
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy{" "}
                  </Link>
                  <a> | </a>
                  <a>Contact</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

export default withStyles(styles)(APIdocs);
