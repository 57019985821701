import React, { Component, Fragment } from "react";

import { Link } from "react-router-dom";

import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GoogleButton from "react-google-button";
import ApplesigninButton from "../../Assests/applesignin.png";
//import { SignUpLink } from '../SignUp';
import * as ROUTES from "../../constants/routes";

function SignUpComponent(props) {
  const {
    error,
    isMobile,
    width,
    email,
    password,
    confirmPassword,
    onSubmit,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    handleChangeToSignIn,
    signUpWithGoogle,
    signUpWithApple,
    t,
  } = props;

  return (
    <Grid
      style={{
        width: isMobile ? width - 120 : "450px",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      item
      xs={24}
    >
      <div
        style={{
          marginLeft: isMobile ? "0px" : "0px",
          marginRight: isMobile ? "0px" : "0px",
        }}
      >
        <p
          style={{
            width: isMobile ? width - 120 : "403px",
            fontSize: 44,
            font: "Roboto",
            fontWeight: "bold",
            color: "#ffffff",
          }}
        >
          {t("joinUs")}
        </p>
        <p
          style={{
            width: isMobile ? width - 120 : "450px",
            fontSize: 24,
            font: "Roboto",
            fontWeight: "regular",
            color: "#ffffff",
          }}
        >
          {t("streamlines")}
        </p>
        <Grid container spacing={4}>
          <Grid item xs={24}>
            <div
              style={{
                borderRadius: 4,
                width: isMobile ? width - 120 : "450px",
                height: "67px",
                backgroundColor: "#ffffff",
              }}
            >
              <Input
                id="filled-basic"
                label="Email"
                margin="none"
                variant="outlined"
                style={{
                  fontSize: 16,
                  font: "Roboto",
                  color: "#000000",
                  borderRadius: 4,
                  marginLeft: "20px",
                  width: isMobile ? width - 140 : "410px",
                  height: "67px",
                }}
                disableUnderline={true}
                colorSecondary={"red"}
                placeholder={t("emailAddress")}
                value={email}
                onChange={onChangeEmail}
                autoComplete="email"
              />
            </div>
            <div
              style={{
                marginTop: 16,
                borderRadius: 4,
                width: isMobile ? width - 120 : "450px",
                height: "67px",
                backgroundColor: "#ffffff",
              }}
            >
              <Input
                id="filled-basic2"
                type="password"
                label="Password"
                margin="none"
                variant="outlined"
                style={{
                  fontSize: 16,
                  font: "Roboto",
                  color: "#000000",
                  borderRadius: 4,
                  marginLeft: "20px",
                  width: isMobile ? width - 140 : "410px",
                  height: "67px",
                }}
                disableUnderline={true}
                placeholder={t("password")}
                value={password}
                onChange={onChangePassword}
                autoComplete="current-password"
              />
            </div>
            <div
              style={{
                marginTop: 16,
                borderRadius: 4,
                width: isMobile ? width - 120 : "450px",
                height: "67px",
                backgroundColor: "#ffffff",
              }}
            >
              <Input
                id="filled-basic2"
                type="password"
                label="confirmPassword"
                margin="none"
                variant="outlined"
                style={{
                  fontSize: 16,
                  font: "Roboto",
                  color: "#000000",
                  borderRadius: 4,
                  marginLeft: "20px",
                  width: isMobile ? width - 140 : "410px",
                  height: "67px",
                }}
                disableUnderline={true}
                placeholder={t("confirmPassword")}
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                autoComplete="current-password"
              />
            </div>
          </Grid>
          <Grid item xs={24}>
            {error && (
              <p style={{ fontSize: 16, font: "Roboto", color: "#D32121" }}>
                {error.message}
              </p>
            )}
            <div
              style={{
                width: isMobile ? width - 120 : "450px",
                height: "67px",
              }}
            >
              <Button
                disabled={
                  email === "" || password === "" || confirmPassword === ""
                }
                onClick={onSubmit}
                style={{
                  fontSize: 16,
                  font: "Roboto",
                  color: "#ffffff",
                  borderRadius: 4,
                  width: isMobile ? width - 120 : "450px",
                  height: "67px",
                  backgroundColor:
                    email === "" || password === "" ? "#9b9b9b" : "#01496D",
                }}
                variant="contained"
              >
                {t("signup").toUpperCase()}
              </Button>
            </div>
          </Grid>
        </Grid>
        {handleChangeToSignIn && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: isMobile ? width - 120 : "450px",
            }}
          >
            <p
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Link
                type="submit"
                onClick={handleChangeToSignIn}
                style={{ fontSize: 16, font: "Roboto", color: "blue" }}
              >
                Already have an account? Sign in
              </Link>
            </p>
          </div>
        )}
        {true && (
          <div style={{ marginTop: 50 }}>
            <GoogleButton
              style={{ width: isMobile ? width - 120 : "450px" }}
              type="light"
              onClick={signUpWithGoogle}
            />
          </div>
        )}
        {true && (
          <div style={{ marginTop: 30 }}>
            <img
              style={{ width: isMobile ? width - 120 : "450px" }}
              src={ApplesigninButton}
              onClick={signUpWithApple}
            />
          </div>
        )}
      </div>
    </Grid>
  );
}

export default SignUpComponent;
