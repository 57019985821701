import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as Helper from "../Helper";

class AlertDialog extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      subtitle: "",
      isOpen: false,
    };
  }

  componentDidMount() {
     this.props.onRef(this);
  }

  handleOpen = (title, subtitle) => {
     this.setState({isOpen: true,
       title: title,
       subtitle: subtitle,
     });
  }

  handleClose = () => {
     this.setState({isOpen: false});
  }


  render() {

    return (
      <div>
      <Dialog open={this.state.isOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
         {this.state.subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
