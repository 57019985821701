import React, { Component } from "react";
import PropTypes from "prop-types";

import XMLParser from "react-xml-parser";

import Input from "@material-ui/core/Input";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import PanToolIcon from "@material-ui/icons/PanTool";
import GestureIcon from "@material-ui/icons/Gesture";
import * as Lists from "./Lists.js";
import * as Helper from "../Helper.js";

import { withFirebase } from "./Firebase";

var tj = require("@mapbox/togeojson");
var DOMParser = require("xmldom").DOMParser;

const suggestions = Object.keys(Lists.cropIcons).map((item) => {
  return { value: item, label: item };
});

class ImportKML extends Component {
  constructor() {
    super();
    this.state = {
      fieldName: "",
      cropName: null,
      fieldType: "Nonorganic",
      variety_suggestions: [],
      variety: null,
    };
  }

  componentDidMount() {}

  getFileExtension = (file) => {
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    return name.substring(lastDot + 1);
  };

  parseTextAsKml = (text) => {
    console.log(text);

    var xml = new XMLParser().parseFromString(text);
    console.log(xml.children[0].children[0].children[1].children);

    let children = xml.children[0].children[0].children[1].children;

    let ind = 1;

    for (let i = 0; i < children.length; i++)
      for (let j = 0; j < children[i].children.length; j++)
        if (children[i].children[j].name === "Polygon") {
          let polygon =
            children[i].children[
              j
            ].children[0].children[0].children[0].value.split(" ");
          //   console.log(polygon);
          let polygonToCreate = [];
          for (let k = 0; k < polygon.length; k++) {
            let coordinates = polygon[k].split(",");
            let longitude = parseFloat(coordinates[0]);
            let latitude = parseFloat(coordinates[1]);
            // console.log(longitude);
            // console.log(latitude);
            if (
              k == polygon.length - 1 &&
              longitude == polygonToCreate[0].lng &&
              latitude == polygonToCreate[0].lat
            )
              continue;

            polygonToCreate.push({ lng: longitude, lat: latitude });
          }

          this.handleAddField(polygonToCreate, ind);
          ind = ind + 1;
        }

    // const dom = new DOMParser().parseFromString(text, "text/xml"); // create xml dom object
    // console.log(dom);
    // console.log(dom.childNodes[1]);
    // const converted = tj.kml(dom); // convert xml dom to geojson
    // // rewind(converted, false); // correct right hand rule
    // console.log(converted);
    // // setLayer(converted); // save converted geojson to hook state
  };

  handleFileSelection = (event) => {
    const file = event.target.files[0]; // get file

    // let value = URL.createObjectURL(event.target.files[0]);

    // console.log(value);
    const ext = this.getFileExtension(file);
    const reader = new FileReader();

    // on load file end, parse the text read
    reader.onloadend = (event) => {
      var text = event.target.result;
      //   console.log(reader.result);
      if (ext === "kml") {
        this.parseTextAsKml(text);
      } else {
        // // imported geojson
        // const json = JSON.parse(text);
        // rewind(json, false);
        // console.log(json);
        // setLayer(json);
      }
    };

    reader.readAsText(file); // start reading file
  };

  handleAddField = (coords, ind) => {
    let avgLat = 0;
    let avgLng = 0;

    let points = [];
    let coordinates = [];

    for (let i = 0; i < coords.length; i++) {
      avgLat = avgLat + coords[i].lat / coords.length;
      avgLng = avgLng + coords[i].lng / coords.length;
      points.push([coords[i].lng, coords[i].lat]);
      coordinates.push({ latitude: coords[i].lat, longitude: coords[i].lng });
    }

    let polygon = [{ coordinates: coordinates }];

    let marker = { latitude: avgLat, longitude: avgLng };

    let area = Helper.polygonArea(points);

    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    this.props.saveField({
      cropName: "Avocado",
      variety: "Unknown",
      fieldName: "Field " + ind.toString(),
      fieldType: "Nonorganic",
      fieldArea: area,
      marker: marker,
      polygon: polygon,
      fieldID: (parseFloat(dateForSorting) + ind).toString(),
    });
  };

  render() {
    const { classes } = this.props;
    // const suggestions = FarmerNames.map((name, index) => ({
    // value: name,
    // label: name,
    // UID: FarmerUIDs[index],
    // }));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <input type="file" onChange={this.handleFileSelection} />
      </div>
    );
  }
}

ImportKML.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withFirebase(ImportKML);
