import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalGateway } from "react-images";
import Carousel from "react-multi-carousel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ReactPlayer from "react-player";

import Tutorial1 from "../../Assests/OnBoarding/01 Cover.jpg";
import Tutorial2 from "../../Assests/OnBoarding/02 Cover.jpg";

import * as Helper from "../../Helper";
import * as ROUTES from "../../constants/routes";
import "react-multi-carousel/lib/styles.css";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

const videos = [
  "https://www.youtube.com/watch?v=yq6LocGeTWo",
  "https://www.youtube.com/watch?v=Xr_u5oI8f0w",
  "https://www.youtube.com/watch?v=t7FeWlnJE6A",
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class OnBoarding extends Component {
  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
    };
  }

  componentDidMount() {
    //this.props.innerRef(this);
    //this.props.history.push(ROUTES.MEMBERS);
  }

  toggleLightbox = (selectedIndex: number) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const { classes } = this.props;
    const { selectedIndex, lightboxIsOpen } = this.state;

    const items =
      this.props.userType === "admin"
        ? [
            { img: Tutorial1, pdf: "Dashboard Guidebook for Agrio.pdf" },
            { img: Tutorial2, pdf: "Agrio App Guidebook.pdf" },
          ]
        : [{ img: Tutorial2, pdf: "Agrio App Guidebook.pdf" }];

    return (
      <div
        style={{
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ marginTop: 20 }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "left",
              color: "#01496d",
            }}
          >
            Manuals
          </Typography>
        </div>
        <div
          style={{
            marginTop: 20,
            width: 1000 * 1.3,
            height: 430,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel
            deviceType={"desktop"}
            responsive={responsive}
            showDots={false}
            containerClass="first-carousel-container container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10-px"
          >
            {items.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    width: 290 * 1.1,
                    backgroundColor: "transparent",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href={process.env.PUBLIC_URL + "/pdfs/" + item.pdf}
                    target="_blank"
                  >
                    <Button onClick={() => {}} color="inherit">
                      <Paper
                        elevation={2}
                        square={true}
                        style={{
                          borderRadius: 10,
                          overflow: "hidden",
                          height: 400,
                          width: 285,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: 285, height: 400 }}
                          src={item.img}
                        />
                      </Paper>
                    </Button>
                  </a>
                </div>
              );
            })}
          </Carousel>
        </div>

        <div style={{ marginTop: 80 }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: "normal",
              fontStyle: "normal",
              letterSpacing: 0,
              textAlign: "left",
              color: "#01496d",
            }}
          >
            Video Demos
          </Typography>
        </div>
        <div
          style={{
            marginTop: 20,
            width: 1000 * 1.3,
            height: 240,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel
            deviceType={"desktop"}
            responsive={responsive}
            showDots={false}
            containerClass="first-carousel-container container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-10-px"
          >
            {videos.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    width: 290 * 1.1,
                    backgroundColor: "transparent",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={() => {}} color="inherit">
                    <Paper
                      elevation={2}
                      square={true}
                      style={{
                        borderRadius: 10,
                        overflow: "hidden",
                        height: 150,
                        width: 285,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactPlayer
                        playing={false}
                        width={this.state.width - 120}
                        height={this.state.width / 2}
                        url={item}
                      />
                    </Paper>
                  </Button>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  // console.log(userType);
  // console.log(!(!authUser || userType!=="admin"));
  return !(
    !authUser ||
    (workgroupID == null &&
      userType !== "admin" &&
      premiumLevel === null &&
      premiumLevel === "Premium")
  );
};

export default withAuthorization(condition)(withFirebase(OnBoarding));
