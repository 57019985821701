import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from "@material-ui/core/IconButton";
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import * as Lists from '../Lists.js';
import * as Helper from "../../Helper";
import Carousel, { Modal, ModalGateway } from "react-images";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TeamPage from "../TeamPage";
import WorkgroupSettingsPage from "../WorkgroupSettingsPage";
import WorkgroupAnalytics from "../WorkgroupAnalytics";

import { withFirebase } from "../Firebase";
import { withAuthorization } from '../Session';

//import RefreshIcon from '@material-ui/icons/Refresh';

class WorkgroupPage extends Component {
  constructor() {
    super();

    this.state = {

    };
  }

  async componentDidMount() {

  }

  componentWillUnmount() {

  }


  render() {

    const { classes, selectedTab } = this.props;

    return (
      <div>
      {(() => {switch (selectedTab) {
               case 0:
                 return <TeamPage classes={classes} />;
               case 1:
                 return <WorkgroupAnalytics classes={classes} />;
               case 2:
                 return <WorkgroupSettingsPage classes={classes} />;
               default:
                 return null;
             }
      })()}
      </div>
    );
  }
}


const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType!=="admin")
};

export default withAuthorization(condition)(withFirebase(WorkgroupPage));
